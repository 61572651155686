import React, { useEffect, useState } from 'react';
import { Space, Row, Col, Typography, Segmented, Button, FloatButton, message, Popover } from 'antd';
import {
  CheckSquareOutlined, CaretDownOutlined, SyncOutlined, ProjectFilled, FilterOutlined,
  BranchesOutlined, MinusCircleOutlined, PlusCircleOutlined
} from '@ant-design/icons';
import { useReactive, useUpdateEffect, useUnmount } from 'ahooks';
import {
  handleIndexValue, arraysMinusDiv, handleRetreat, handleRetreatExtra, arraysMinus, renderRetreatOption, handleDaliyRates,
  renderSlice, calRate, calNets, isFullTimeRange, isSliderFirstZero, handleRateOption, multiplication,
  RATE_KEYS_OBJ, COMMON_CHART, RETREAT_OPTIONS, MAIN_RATE_OPTIONS, TOOLTIP_SHOW_NUM, RATE_CONFIG, PRE_DAY_CN // 常量
} from '@/utils/chartInfo'; //
import { INDEX_OPTIONS } from '@/utils/indexCode';
import { DATE_TYPE_OPTIONS, isValidNumber, isValidArray } from '@/utils/utils';
import { ValuesColor, LabelValueComp, Selections, MainSlider, CascaderMobile, NewCalendar, BaseDrawer } from '@/utils/widgets';
import moment from 'moment';
import * as echarts from 'echarts';
import _ from 'lodash';

let timer = null;
const { Text } = Typography;
const LINE_COLOR = ['#0859AB', '#B5495B', '#e4b100']; // 收益率、指数、超额
const NO_SHOW_TODAY = ['866001', '866006', '000985'];
const MOB_CARRY = 2; // 移动默认2位
let TOOLTIP_SHOW_OBJ_2 = {};
RATE_KEYS_OBJ.all.map(item => {// 用中文名称作为key，keyname作为value，tooltips时可以快速反向查找object
  TOOLTIP_SHOW_OBJ_2[item.name] = item.key;
});
const todayIcon = <svg t="1704155605157" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4303" width="18" height="20"><path d="M268.4 287.6c24.7 0 44.8-20.1 44.8-44.8v-89.6c0-24.7-20.1-44.8-44.8-44.8s-44.8 20.1-44.8 44.8v89.6c0 24.8 20.1 44.8 44.8 44.8zM755.6 287.6c24.7 0 44.8-20.1 44.8-44.8v-89.6c0-24.7-20.1-44.8-44.8-44.8s-44.8 20.1-44.8 44.8v89.6c0 24.8 20.1 44.8 44.8 44.8z" p-id="4304" fill="#1296db"></path><path d="M914.4 198.8H846c-0.5 0-0.8 0.4-0.8 0.8v38.5c0 47.2-34.4 89.7-81.4 93.9-53.1 4.8-97.8-37.1-97.8-89.2v-43.2c0-0.5-0.4-0.8-0.8-0.8H358.8c-0.5 0-0.8 0.4-0.8 0.8v38.5c0 47.2-34.4 89.7-81.4 93.9-53.1 4.8-97.8-37.1-97.8-89.2v-43.2c0-0.5-0.4-0.8-0.8-0.8h-68.3c-25.2 0-45.6 20.4-45.6 45.6v625.5c0 25.2 20.4 45.6 45.6 45.6h804.7c25.2 0 45.6-20.4 45.6-45.6V244.4c0-25.2-20.4-45.6-45.6-45.6zM904 803.5c0 24.8-20.1 44.8-44.8 44.8H164.8c-24.8 0-44.8-20.1-44.8-44.8V445.2c0-24.8 20.1-44.8 44.8-44.8h694.3c24.8 0 44.8 20.1 44.8 44.8v358.3z" p-id="4305" fill="#1296db"></path><path d="M660.9 575.6c-78.5-36.8-110.9-83.4-119.7-98.3 4.6-10.9 0.1-23.6-10.6-28.8-11.1-5.4-24.5-0.8-29.9 10.3-0.4 0.7-37.5 74.3-137.7 123-11.1 5.4-15.8 18.8-10.4 29.9 3.9 8 11.9 12.6 20.2 12.6 3.3 0 6.6-0.7 9.8-2.3 66.4-32.3 108.3-73.9 132.2-104.1 19.5 26 58.1 65.8 127.3 98.2 3.1 1.4 6.3 2.1 9.5 2.1 8.4 0 16.5-4.8 20.3-12.9 5-11.1 0.2-24.4-11-29.7z" p-id="4306" fill="#1296db"></path><path d="M521.5 619.8c4.2 3.5 9.3 5.2 14.3 5.2 6.4 0 12.8-2.7 17.2-8 7.9-9.5 6.7-23.6-2.8-31.5l-33.3-27.8c-9.5-7.9-23.6-6.7-31.5 2.8-7.9 9.5-6.7 23.6 2.8 31.5l33.3 27.8zM585 634.1H419.3c-12.4 0-22.4 10-22.4 22.4 0 12.4 10 22.4 22.4 22.4h143.3l-68.4 89.8c-7.5 9.8-5.6 23.9 4.2 31.4 4.1 3.1 8.8 4.6 13.6 4.6 6.8 0 13.4-3 17.8-8.8l81.6-107.1c7.9-10.4 9.2-24.2 3.4-35.9-5.6-11.6-17.1-18.8-29.8-18.8z" p-id="4307" fill="#1296db"></path></svg>
/**
 * 产品，子账户收益率图表；
 */
export default function MainCharts(props) {
  const pageKeys = _.get(props, 'pageKey', '');
  const isAccount = pageKeys === 'account' ? true : false;
  const isHosting = pageKeys === 'hosting' ? true : false; // 托管页面
  const isTamp = _.get(props, 'plateform', '') === 'TAMP' ? true : false;
  const [option, setoption] = useState({
    ...MAIN_RATE_OPTIONS,
    tooltip: {
      ...COMMON_CHART.tooltipCustom,
      formatter: renderFormatter
    }
  });
  const [option2, setoption2] = useState(RETREAT_OPTIONS);
  const mainState = useReactive({
    dateType: isHosting ? 'TODAY' : _.get(props, 'pms.dateType', ''),
    lastPoint: 0, lastIdx: 0, lastEx: 0,
    isRetreat: false, visible: false,
    otherList: [], points: [], pointsNet: [], extraList: [], proTools: [], idxName: '',
    timelist: [], tabYears: [], showYearTab: false, // 【bug-fix】tooltips 从porps拿不到数据，使用本地state
    visible2: false, soptions: [], diffShow: false, diffRate: _.get(props, 'idxPms.rate') ?? 0,//count: 0,
    sdate: _.get(props, 'pms.date', ''), timeOpen: { key: '', open: false, value: '', stamp: new Date() },
  });
  const [mains, setMains] = useState({});
  const [sliderValue, setSliderValue] = useState([]);
  const [sliderValue2, setSliderValue2] = useState([]);
  const [timeArr, setTimeArr] = useState([0]);
  const [timeNameArr, setTimeNameArr] = useState([]);
  const [updateCount, setUpdateCount] = useState(0);
  const [rateVal, setRateVal] = useState([]);

  useUnmount(() => {
    timer && clearTimeout(timer);
  });

  function renderFormatter(params) {
    // 展示时小数点保留两位；【！！仅限展示，计算时按照默认多进位计算】
    const getx = _.get(params, '[0].name', '');
    // console.log('getx', getx)
    const isNotToday = mainState.dateType !== 'TODAY';
    let finalString = '';
    params.map(n => {
      let extraStringValue = '';
      const renderValue = _.round(n.value, MOB_CARRY);
      const gKey = TOOLTIP_SHOW_OBJ_2[n.seriesName]; // 通过seriesName的中文名称找到key
      // 产品层面从proTools里面直接找到对应数据
      const cur_net = _.get(mainState.proTools, `nets.${getx}`, 0);
      const cur_index = _.get(mainState.proTools, `index.${getx}`, 0);
      if (gKey === 'pointList' && isNotToday) {
        extraStringValue = `(日:${_.round(cur_net, MOB_CARRY)}%)`
      }
      if (n.seriesName === mainState.idxName && isNotToday) {
        extraStringValue = `(日:${_.round(cur_index, MOB_CARRY)}%)`
      }
      // 括号里是当前点位的超额收益率，不进行累加； 所以需要拿到当天收益率和index进行相减；
      if (n.seriesName === '超额收益率' && isNotToday) {
        extraStringValue = `(日:${_.round(cur_net - cur_index, MOB_CARRY)}%)`
      }
      const ffidx = _.findIndex(mainState.timelist, o => o === n.name); //用time找到index
      const gdata = _.get(mainState.otherList, `[${ffidx}][${gKey}]`, undefined) // 取值
      const nval = _.round(gdata, MOB_CARRY); //原始返回数据,value是经过累加等计算值，所以获取原始值
      // 增加额外个数显示的判断
      if (gKey in TOOLTIP_SHOW_NUM) {
        extraStringValue = ` (${nval}) (${_.get(mainState.otherList, `[${ffidx}][${TOOLTIP_SHOW_NUM[gKey]}]`, '')}个)`
      }
      //收益率超额 不显示额外string内容
      const isNotShowExtra = _.includes(n.seriesName, '(超额)') || _.includes(n.seriesName, '(几何)');
      if (isNotShowExtra) {
        extraStringValue = '';
      }
      finalString = finalString
        + n.marker + ' ' + n.seriesName
        + `<span style='font-weight:600;margin-left:12px'>${renderValue}%</span>    
                ${!!isNotToday ? extraStringValue : ''}` // 实时无需展示额外括号数据
        + '<br/>'
    });
    return finalString;
  }

  //计算逻辑与平台相同
  useEffect(() => {
    let myChart = props.myChart; let myChart2 = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('main_charts'));
    myChart2 = echarts.init(document.getElementById('main_charts_retreat'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    //当前收益率拥有的年份
    const getValidDate = _.get(props, 'validDateRange', []);
    let years = [];
    getValidDate.map(y => {
      const this_year = moment(y).year();
      if (!_.includes(years, this_year)) {
        years.push(this_year);
      }
    });
    mainState.tabYears = years.map(y => { return { 'label': y, 'value': y } });

    let newOption = _.cloneDeep(option); let newOption2 = _.cloneDeep(option2);

    const date_type = _.get(props, 'pms.dateType', '');
    const isNotToday = date_type !== 'TODAY' ? true : false;
    const newSeriesData = _.get(props, 'datas.newData', {}); // 已处理及计算的所有数据；
    const newTimes = renderRateTimes(isNotToday);
    const pointsData = isHosting ? _.get(props, 'datas.pointList') : _.get(newSeriesData, 'pointList', []);
    //console.log('newSeriesData', newSeriesData)  
    // 处理指数数据；
    const newIndexs = handleIndexValue(
      date_type,
      newTimes,
      { data: _.get(props, 'indexValue'), lastIndex: _.size(pointsData) - 1 }
    );
    let indexValues = newIndexs.indexValues;
    let priceValues = newIndexs.priceValues;
    const pointNet = _.get(newSeriesData, 'pointsNetList', []);
    const idNet = newIndexs.dayPriceVals
    const newExtra = handleExtraRate(pointsData, indexValues, [0, ...pointNet], idNet);
    mainState.otherList = _.get(newSeriesData, 'other', []);
    mainState.pointsNet = _.get(newSeriesData, 'pointsNetList', []);
    mainState.points = pointsData;
    mainState.idxName = _.get(props, 'pms.symbolName', '');
    mainState.timelist = _.get(props, 'datas.timeList', []);
    // tooltips 使用{日期:值}的方式，快速建立每日收益的对应关系，减少findindex查找
    const pnet = _.concat([0], _.get(newSeriesData, 'pointsNetList', []));
    let proTool = { 'index': {}, 'nets': {} };
    newTimes.map((date, i) => {
      _.set(proTool.index, date, newIndexs.dayPriceVals[i]);
      _.set(proTool.nets, date, pnet[i]);
    });
    mainState.proTools = proTool;

    let sArray = newIndexs.stimeArr;
    setTimeArr(sArray);
    setTimeNameArr(newTimes);
    // 赋值slider的滑动数据
    if (_.last(sArray)) {
      setSliderValue([0, _.last(sArray)]);
      setSliderValue2([0, _.last(sArray)]);
    }

    newOption.xAxis.data = newTimes
    newOption.series[0].data = pointsData;
    newOption.series[1].data = indexValues;
    newOption.series[1].name = _.get(props, 'pms.symbolName', '');
    newOption.series[2].data = isNotToday ? newExtra.extraGeo : newExtra.extra;
    mainState.lastPoint = _.round(_.last(pointsData), MOB_CARRY);
    mainState.lastIdx = _.round(_.last(indexValues), MOB_CARRY);
    mainState.lastEx = _.round(_.last(_.get(newOption, 'series[2].data')), MOB_CARRY);
    // 多种收益率曲线计算：
    newOption = handleRateOption(
      rateVal,
      newOption,
      newSeriesData,
      0,
      [0, _.last(timeArr)],
      'full'
    );
    // 回撤部分
    const idxRetreat = handleRetreat(priceValues); // 指数计算*100
    const getPointRetreat = isHosting ? _.get(props, 'datas.pointRetreat') : _.get(newSeriesData, 'pointRetreat', []);
    const extraRetreat = handleRetreatExtra(_.get(newOption, 'series[2].data', []));
    newOption2 = renderRetreatOption(
      newOption2,
      newOption,
      getPointRetreat,
      idxRetreat,
      extraRetreat,
      newTimes,
    );
    // 超额图表; 跳转赋值； [tradeDates]统一从props拿到
    const newExtraDaliy = handleDaliyRates(
      newExtra.extra3,
      idNet,
      newTimes,
      _.get(props, 'tradeDates', []),
      false, false
    );
    // console.log('newExtraDaliy', newExtraDaliy)
    setMains({
      'points': pointsData, 'indexs': indexValues, 'idNet': idNet,
      'extra': isNotToday ? newExtra.extraGeo : newExtra.extra, // 基础
      'points_retreat': getPointRetreat, 'idx_retreat': idxRetreat, 'extra_retreat': extraRetreat, // 回撤
      'rates': newExtraDaliy, 'pointNet': _.concat([0], pointNet), 'extra3': newExtra.extra3, // 统计
      'times': newTimes, 'limits': _.get(newSeriesData, 'fullMarks', [])
    });

    //【新】增加年份裁切，使用成立按照对应年份裁切；删除今年以来，与年份功能相同
    if (_.includes(years, parseInt(mainState.dateType))) {
      handleFromYear(parseInt(mainState.dateType), newTimes);
    };

    setoption(newOption); setoption2(newOption2);
    myChart.setOption(newOption, true); myChart2.setOption(newOption2, true);
    myChart.hideLoading();
    myChart.resize(); myChart2.resize();
    echarts.connect([myChart, myChart2]); // 增加联动
  }, [props.upcount]);

  useUpdateEffect(() => {
    let myChart = props.myChart; let myChart2 = props.myChart;
    myChart = echarts.init(document.getElementById('main_charts'));
    myChart2 = echarts.init(document.getElementById('main_charts_retreat'));

    let newOption = _.cloneDeep(option); let newOption2 = _.cloneDeep(option2);
    const date_type = _.get(props, 'pms.dateType', '');
    const isNotToday = date_type !== 'TODAY' ? true : false;
    const newSeriesData = _.get(props, 'datas.newData', {}); // 已处理及计算的所有数据；
    const newTimes = renderRateTimes(isNotToday);
    const pointsData = _.get(newSeriesData, 'pointList', []);
    const pointsNet = calNets(pointsData); // 收益率计算波幅需按照每个收益率的净值计算 : 1*(1+(净值/100))
    const isFullRange = isFullTimeRange(sliderValue, timeArr); // 全部时间轴时，说明无需截取，用收盘价作为第一点位数据。
    const isFisrtZero = isSliderFirstZero(sliderValue, timeArr); //sliderValue[0] !== 0时才需要截取数据，右侧滑动时无需重更改startVal值
    const newIndexs = handleIndexValue(
      date_type,
      newTimes,
      { data: _.get(props, 'indexValue'), lastIndex: _.size(pointsData) - 1 }
    );
    const indexPrice = newIndexs.priceValues; // 只获取价格
    const indexNet = newIndexs.dayPriceVals;
    const newIndexClose = newIndexs.close;
    const sliceTimes = isFullRange ? newTimes : renderSlice(newTimes, sliderValue[0], sliderValue[1]);
    // 根据slider的结果截取数据；
    const sliceRateOrg = renderSlice(pointsNet, sliderValue[0], sliderValue[1]); // 净值slice
    // 初始计算净值slice，需要增加首位0; 【bug-fix】TODAY没有净值数据mainState.pointNet=空，需要使用计算净值来显示
    let sliceNetOrg = renderSlice(_.concat([0], isNotToday ? mainState.pointsNet : pointsNet), sliderValue[0], sliderValue[1]);
    let sliceIndexNet = renderSlice(indexNet, sliderValue[0], sliderValue[1]);// 指数净值slice
    let sliceIndexPriceOrg = renderSlice(indexPrice, sliderValue[0], sliderValue[1]); // 指数价格slice
    const indexStartVal = (isFullRange || isFisrtZero) ? newIndexClose : _.get(sliceIndexPriceOrg, '[0]', 0); //指数收盘价取值
    // 根据截取indexValue及point收益率 数据渲染指数波幅
    let indexRate = calRate(indexPrice, newIndexClose); // 计算完整的指数波幅数据
    let sliceIndexRate = calRate(sliceIndexPriceOrg, indexStartVal); // 指数波幅slice
    // 【bug-fix】如计算时已添加0为第一位，只滑动右侧，开始的计算会出问题，所以slice时start默认支取第0位的数据
    const getStart = parseFloat(_.get(sliceRateOrg, `[0]`, 0));
    let sliceRates = calRate(sliceRateOrg, getStart);
    // 非实时数据第一位置为0, 【PS】当前计算流程中，赋值第一位0操作可保证计算准确性，必要操作！
    if (isNotToday) {
      indexRate[0] = 0; sliceIndexRate[0] = 0; sliceNetOrg[0] = 0; sliceIndexNet[0] = 0;
      sliceIndexPriceOrg[0] = indexStartVal;
    }
    newOption.series[0].data = isFullRange ? pointsData : sliceRates; // 全时间段无需使用净值数据
    newOption.xAxis.data = sliceTimes;
    newOption.series[1].data = isFullRange ? indexRate : sliceIndexRate;
    // 涨跌停幅收益率：根据开关生成数据，每次都重新计算option及最大最小值；
    newOption = handleRateOption(
      rateVal,
      newOption,
      newSeriesData,
      0,
      [sliderValue[0], sliderValue[1]],
      isFullRange ? 'full' : 'slice'
    );
    const sliceExtra = handleExtraRate(_.get(newOption, 'series[0].data'), _.get(newOption, 'series[1].data'), sliceNetOrg, sliceIndexNet);
    //实时显示超额，其他使用几何超额
    newOption.series[2].data = _.get(sliceExtra, isNotToday ? 'extraGeo' : 'extra', []);
    mainState.lastPoint = _.round(_.last(_.get(newOption, 'series[0].data', [])), MOB_CARRY);
    mainState.lastIdx = _.round(_.last(_.get(newOption, 'series[1].data', [])), MOB_CARRY);
    mainState.lastEx = _.round(_.last(_.get(newOption, 'series[2].data', [])), MOB_CARRY);
    // 回撤部分
    const getPointRetreat = isFullRange ? _.get(newSeriesData, 'pointRetreat') : handleRetreat(sliceRateOrg);
    const idxRetreat = handleRetreat(sliceIndexPriceOrg)
    const extraRetreat = handleRetreatExtra(sliceExtra.extraGeo);
    newOption2 = renderRetreatOption(
      newOption2,
      newOption,
      getPointRetreat,
      idxRetreat,
      extraRetreat,
      sliceTimes,
    );

    setoption(newOption); setoption2(newOption2);
    myChart.hideLoading();
    myChart.setOption(newOption, true); myChart2.setOption(newOption2, true);
    myChart.resize(); myChart2.resize();
  }, [updateCount]);
  //修复切换tab chart缩小，切换延时resize
  useUpdateEffect(() => {
    if (props.active === 'mainchart' && isHosting) {
      timer = setTimeout(() => {
        let myChart = props.myChart; let myChart2 = props.myChart;
        myChart = echarts.init(document.getElementById('main_charts'));
        myChart2 = echarts.init(document.getElementById('main_charts_retreat'));
        myChart.setOption(option, true); myChart2.setOption(option2, true);
        myChart.resize(); myChart2.resize();
        // console.log('active-update')
      }, 800);
    }
  }, [props.active]);

  // 渲染x轴数据
  function renderRateTimes(notToday) {
    let newTimeList = _.get(props, 'datas.timeList', []);
    return notToday ? [PRE_DAY_CN, ...newTimeList] : newTimeList;
  }
  // 计算超额收益率
  function handleExtraRate(rates = [], indexRates = [], nets = [], indexNets = []) {
    const extraVal = arraysMinus(rates, indexRates);
    const extraVal2 = multiplication(arraysMinusDiv(nets, indexNets));
    const extraVal3 = arraysMinus(nets, indexNets); // 超额统计计算数据
    return { 'extra': extraVal, 'extraGeo': extraVal2, 'extra3': extraVal3 }
  }
  // 滑动change
  function afterSliderChange(v) {
    setSliderValue(v);
    setSliderValue2(v);
    updateThisChart();
  }
  // 单日时间切换
  function onSingleDateChange(type = '', v = '') {
    const getTradeDate = _.get(props, 'tradeDates', []);
    let finalDate = '';
    if (type === 'open') {
      mainState.timeOpen = { open: true, value: mainState.sdate, stamp: moment(mainState.sdate).toDate(), key: 'single' };
      return
    } else if (type === 'last' || type === 'next') {
      let curIndex = _.findIndex(getTradeDate, o => o.date === mainState.sdate);
      if (curIndex > 0) {
        finalDate = _.get(getTradeDate, `[${type === 'last' ? curIndex - 1 : curIndex + 1}].date`);
      } else {
        return
      }
    } else if (type === 'finish') {
      mainState.timeOpen = { key: '', open: false, value: '', stamp: new Date() };
      finalDate = v;
    }
    mainState.sdate = finalDate;
    props.onDateChange(finalDate);
  };
  // 增加收益率曲线
  function onRateChange(v, type) {
    let temp = _.cloneDeep(rateVal);
    if (type === 'add') {
      temp.push(_.last(v));
    } else {
      _.pull(temp, _.last(v));
    }
    setRateVal(_.uniq(temp));
  };
  // 时间轴切换配置
  function handleDateTypeChange(v) {
    const new_type = parseInt(v) > 1000 ? 'ALL' : v;
    mainState.dateType = v;
    props.onDatetypeChange(new_type);
  };
  // 处理今年以来数据及时间
  function handleFromYear(initYear = 0, newTime = []) {
    // 按顺序找到第一个与今年同年的日期，因不确定今年第一个交易日是哪天
    const sizeTime = _.size(newTime);
    let fIndex = -1; let lastIndex = -1;
    for (let index = 0; index < sizeTime; index++) {
      const sYear = _.split(newTime[index], '-')[0];
      if (initYear === parseInt(sYear)) {
        fIndex = index;
        break;
      }
    };
    // 反向查找当年份最后一个index
    for (let index = sizeTime - 1; index > 0; index--) {
      const sYear = _.split(newTime[index], '-')[0];
      if (initYear === parseInt(sYear)) {
        lastIndex = index;
        break;
      }
    };
    // 有第一个值直接更新slider及图表
    if (fIndex !== -1 && lastIndex !== -1) {
      setSliderValue([fIndex - 1, lastIndex]);
      setSliderValue2([fIndex - 1, lastIndex]);
      updateThisChart();
    }
  };
  function updateThisChart() {
    setUpdateCount(_.round(updateCount + 0.1, 1));
  };
  // 裁剪名字
  function sliceIndexName(name = '') {
    const n_size = _.size(name);
    if (n_size > 6) {
      return name.substring(0, 6) + '...';
    } else {
      return name;
    }
  };
  // 跳转rate详情页面
  function goPageRates(gotoday) {
    let otherRates = [];
    if (isValidArray(rateVal)) {
      const seriesOther = _.get(props, 'datas.newData.other', []);
      rateVal.map(key => {
        let fidx = _.findIndex(RATE_KEYS_OBJ.all, o => o.key === key);
        const rkey = _.get(RATE_KEYS_OBJ.all, `[${fidx}].key`);
        const rname = _.get(RATE_KEYS_OBJ.all, `[${fidx}].name`);
        let item = { 'key': rkey, 'name': rname, 'data': seriesOther.map(n => _.get(n, rkey)) };
        otherRates.push(item);
      });
    };
    props.goRates(
      _.assign(mains, {
        sliderValue, sliderValue2, timeNameArr, timeArr,
        'dateType': mainState.dateType, 'otherRates': otherRates
      }),
      gotoday
    );
  };
  // dropshow = 是否可以操作弹出选项
  const headerShowItem = [
    { name: isAccount ? '账户' : '产品', lastKey: 'lastPoint', colorIndex: 0, dropShow: !isHosting },
    { name: sliceIndexName(_.get(props, 'pms.symbolName', '')), lastKey: 'lastIdx', colorIndex: 1, dropShow: true },
    { name: '超额几何', lastKey: 'lastEx', colorIndex: 2, dropShow: false },
  ];
  const labelProps = { txtType: 'default', type: 'downv', space: 3 };
  const chartProps = { style: { width: '99%', height: 330 } };
  const extraBtnStyle = { backgroundColor: '#f5f5f5', borderRadius: 6, textAlign: 'center' };
  const extraDis = mainState.dateType === 'TODAY' ? true : false;
  const isGoToday = mainState.dateType === 'TODAY' && isAccount ? true : false;
  const singleDate = _.get(props, 'pms.date', '');
  const isFilterDate = _.includes(mainState.tabYears.map(y => parseInt(y.value)), parseInt(mainState.dateType));
  const newIndexOptions = _.get(props, 'plateform', '') === 'TAMP' && mainState.dateType !== 'TODAY' ? _.concat(_.get(props, 'customIndex', []), INDEX_OPTIONS) : INDEX_OPTIONS;
  const yearContent = ( // 年份选择
    <Space direction='vertical'>
      {mainState.tabYears.map(n => <div key={n.value} style={{ cursor: 'pointer' }} onClick={() => {
        handleDateTypeChange(n.value);
        mainState.showYearTab = true;
      }}>
        <Text>{n.label}</Text>
      </div>)}
    </Space>
  );

  return <div style={{ minHeight: 383 }}>
    {!isHosting && <Row style={{ marginBottom: 12, marginTop: 4 }} align='middle'>
      <Col span={20}>
        <Space size={2}>
          <Segmented
            value={mainState.dateType}
            options={DATE_TYPE_OPTIONS}
            onChange={handleDateTypeChange}
          />
          {_.size(mainState.tabYears) > 0 && <Popover placement="bottom" trigger='click' content={yearContent}>
            <Button type='text' icon={<FilterOutlined style={{ color: isFilterDate ? '#0859ab' : '#b1b1b1' }} />} />
          </Popover>}
        </Space>
      </Col>
      <Col span={4}>
        <div style={extraBtnStyle}>
          <Button type='text' disabled={extraDis}
            icon={isGoToday ? todayIcon : <ProjectFilled style={{ color: extraDis ? 'grey' : '#4ba9e7' }} />}
            onClick={() => goPageRates(isGoToday)}
          />
        </div>
      </Col>
    </Row>}
    <Row style={{ marginTop: 16, marginBottom: 8 }}>
      {headerShowItem.map(item => <Col key={item.lastKey} span={8}>
        <div onClick={() => {
          mainState.showYearTab = false;
          if (item.lastKey === 'lastIdx') {
            mainState.visible = true;
          } else if (item.lastKey === 'lastPoint' && item.dropShow) {
            mainState.visible2 = true;
          }
        }}>
          <LabelValueComp
            label={<Space size={3}>
              <CheckSquareOutlined style={{ color: LINE_COLOR[item.colorIndex] }} />
              <Text type='secondary'>{item.name}</Text>
              {item.dropShow && <CaretDownOutlined style={{ color: '#ababab', fontSize: 14 }} />}
            </Space>}
            vComp={<ValuesColor value={_.get(mainState, item.lastKey)} size={16} showIcon={false} />}
            {...labelProps}
          />
        </div>
      </Col>)}
    </Row>

    <div style={{ display: 'flex' }}>
      <div id="main_charts" {...chartProps} />
    </div>

    <MainSlider
      isNotToday={mainState.dateType !== 'TODAY' ? true : false}
      type={isHosting ? '' : 'single_date'}
      timeArray={timeNameArr}
      max={_.last(timeArr) || 0}
      date={singleDate}
      svalue={sliderValue2}
      onSliderChange={(v) => setSliderValue2(v)}
      onSliderAfterChange={(v) => afterSliderChange(v)}
      onRedo={() => afterSliderChange([0, _.last(timeArr)])}
      onPressDate={(type) => onSingleDateChange(type)}
    />

    <div style={{ display: 'flex' }}>
      <div id="main_charts_retreat" {...chartProps} />
    </div>

    <Selections
      visible={mainState.visible}
      options={newIndexOptions}
      onPress={(val) => {
        // 指数选择
        if (mainState.dateType === 'TODAY' && _.includes(NO_SHOW_TODAY, val)) {
          message.info('暂无当日指数！');
          return;
        }
        props.onSymbolChange(val);
        mainState.visible = false;
      }}
      onClose={() => mainState.visible = false}
    />

    <CascaderMobile
      curKey={'main_rate'}
      mode={'multi'}
      visible={mainState.visible2}
      mvalue={rateVal}
      options={RATE_CONFIG}
      onSelect={onRateChange}
      onFinish={() => {
        mainState.visible2 = false;
        updateThisChart();
      }}
      onClose={() => mainState.visible2 = false}
      onClear={() => {
        mainState.visible2 = false;
        setRateVal([]);
        updateThisChart();
      }}
    />

    <FloatButton.Group>
      {!isTamp && !isAccount && <FloatButton icon={<BranchesOutlined />}
        type={isValidNumber(mainState.diffRate) ? 'primary' : 'default'}
        onClick={() => mainState.diffShow = true}
      />}
      <FloatButton icon={<SyncOutlined />} onClick={() => props.onSync()} />
    </FloatButton.Group>

    <NewCalendar
      values={[mainState.sdate, mainState.sdate]}
      show={_.get(mainState, 'timeOpen.open') ?? false}
      single
      onCancel={() => mainState.timeOpen = false}
      onSubmit={(val) => onSingleDateChange('finish', val[0])}
    />

    <BaseDrawer
      visible={mainState.diffShow}
      height={225}
      onClose={() => mainState.diffShow = false}
    >
      <div style={{ height: 185, padding: 8 }}>
        <Row align='middle' style={{ marginTop: 6 }}>
          <Col span={4}><Text>差异：</Text></Col>
          <Col span={4}>
            <Button type='text' icon={<MinusCircleOutlined />} onClick={() => mainState.diffRate = _.round(mainState.diffRate - 0.1, 1)} />
          </Col>
          <Col span={12}>
            <Text strong>{mainState.diffRate}%</Text>
          </Col>
          <Col span={4}>
            <Button type='text' icon={<PlusCircleOutlined />} onClick={() => mainState.diffRate = _.round(mainState.diffRate + 0.1, 1)} />
          </Col>
        </Row>
        <div style={{ position: 'absolute', bottom: 20, width: '90%' }}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Button block type='primary' onClick={() => {
                mainState.diffShow = false;
                props.rateDiffChange(mainState.diffRate);
              }}>
                确定
              </Button>
            </Col>
            <Col span={12}>
              <Button block onClick={() => {
                mainState.diffShow = false;
                mainState.diffRate = 0;
                props.rateDiffChange(0);
              }}>
                重置
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </BaseDrawer>
  </div>
}
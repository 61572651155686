import React, { useState } from 'react';
import { message, Tabs, Button } from 'antd';
import {
  FundFilled, PieChartFilled, DatabaseFilled, ControlFilled, SwitcherFilled, StockOutlined,
  PullRequestOutlined, ProjectFilled
} from '@ant-design/icons';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { showSubAccountHistory, showSubAccountData, showIndexKline, showAllSubAccountHistory } from '@/api/main';
import { useReactive, useMount, useUnmount } from 'ahooks';
import { INDEX_CODE_NAME, INDEX_CODE_VALUE } from '@/utils/indexCode';
import { labelValues, DATE_TYPE, isValidArray, initDateType } from '@/utils/utils';
import { NewCard, HeaderIcons, Selections, TopInfoHeader } from '@/utils/widgets';
import { handleHistoryRate, handleCustomIndexPms } from '@/utils/list_utils';
import MainCharts from '../Components/MainCharts';
import Position from '../Components/Position';
import TowCharts from '../Components/TwoCharts';
import Records from '../Components/Records';
import BtCompare from '../Comp2/BtCompare';
import AttributeCharts from '../Comp2/AttributeCharts';
import KRateCharts from '../Components/KRateCharts';
import moment from 'moment';
import _ from 'lodash';

const TODAY = moment().format('YYYY-MM-DD');
const TOP_ITEMS = [
  labelValues(['总收益率', 'totalRevenueRate']),
  labelValues(['日收益率', 'dailyRevenueRate']),
  labelValues(['净值', 'netValue']),
];
const DESC_ITEMS = [
  ['总资产', 'totalAssets'],
  ['当前市值', 'positionsAssets'],
  ['冻结资金', 'totalFrozenBalance'],
  ['总收益', 'totalRevenue'],
  ['日收益', 'dailyRevenue'],
];
const DEFAULT_SYMBOL = INDEX_CODE_NAME['中证1000'];
const tabIcons = (txt, icon) => <span>{icon}{txt}</span>;
let timer = null;
/**
 * 账户详情页
 */
const AccountsPage = (props) => {
  const mobxStore = useLocalObservable(() => store);
  const [tradeDates, setTradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const [userInfo] = useState(JSON.parse(JSON.stringify(mobxStore.userInfo)) ?? {});
  const [routemobx] = useState(JSON.parse(JSON.stringify(mobxStore.routeMobx)) ?? {});
  const [isTrading] = useState(JSON.parse(JSON.stringify(mobxStore.inTrading)) === 'trading' ? true : false);
  const [accLists] = useState(JSON.parse(JSON.stringify(mobxStore.curAccount)) ?? []);
  const [customIndex, setCustomIndex] = useState(JSON.parse(JSON.stringify(mobxStore.customIndex)) ?? [])
  const userPlateform = _.get(userInfo, 'plateform', '');
  const routeDatas = _.get(props, 'location.state', {});
  const isVisitor = _.get(userInfo, 'role', '') === 'visitor' ? true : false;
  const TAB_NUM = userPlateform === 'BT' ? '9' : '1';
  const initType = initDateType(tradeDates, TAB_NUM);
  const [idxParams, setIdxParams] = useState({
    'symbol': DEFAULT_SYMBOL, 'symbolName': INDEX_CODE_VALUE[DEFAULT_SYMBOL],
    'dateType': userPlateform === 'BT' ? DATE_TYPE['9'] : initType.type,
    'date': TODAY
  });
  const [pointDatas, setPointDatas] = useState({ timeList: [], datas: [], newData: {} });
  const [indexValue, setIndexValue] = useState({});
  const [countMain, setCountMain] = useState(0); // 收益率图表更新
  const [assetData, setassetData] = useState({});
  const [validDate, setValidDate] = useState([]); // 收益率时间区间
  const [indexCodeName2, setIndexCodeName2] = useState({});
  const isUpdateTab = _.get(routemobx, 'from', '') === 'account' && _.get(routemobx, 'active') !== 'mainchart' ? true : false;
  const pageState = useReactive({
    active: isUpdateTab ? _.get(routemobx, 'active') : 'mainchart',
    symbol: idxParams.symbol,
    tabUpdate: false,
    accInfo: _.cloneDeep(routeDatas), switchCount: 0, visible: false, //账户信息变量（可切换 
    singleDate: TODAY,
  });
  const isTrader = _.get(routeDatas, 'isTrader', false); // 交易员无账户切换，直接使用routeData
  const isReverse = _.get(pageState.accInfo, 'centralizedLogin') === 2 ? true : false;

  useMount(() => {
    //console.log('子账户路由，', routeDatas);
    if (isUpdateTab) { // k线页面返回，跳转到特定tab
      pageState.tabUpdate = true;
      mobxStore.changeRouteMobx({});
    } else {
      _showSubAccountHistory();
    }
    _showSubAccountData();
    _showAllSubAccountHistory();

    if (!isValidArray(tradeDates)) {
      mobxStore._getAllTradeDay();
      timer = setTimeout(() => {
        setTradeDates(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
      }, 2500);
    }
  });

  useUnmount(() => {
    timer && clearTimeout(timer);
  });

  // 获取资产信息
  async function _showSubAccountData() {
    if (_.get(pageState.accInfo, 'id')) {
      const res = await showSubAccountData({ subAccountId: pageState.accInfo.id }, isReverse ? 'FC' : 'NOR');
      if (_.get(res, 'code', '') === '200') {
        setassetData(res.data);
      }
    }
  }
  // 收益率获取
  async function _showSubAccountHistory(type) {
    if (_.get(pageState.accInfo, 'id')) {
      let pms = {
        'subAccountId': pageState.accInfo.id,
        'dateType': type ? type : _.get(idxParams, 'dateType'),
        'date': pageState.singleDate,
      }
      let index_pms = {
        'symbol': pageState.symbol,
        'dateType': type ? type : _.get(idxParams, 'dateType'),
        'date': pageState.singleDate,
      };
      const idxPms2 = handleCustomIndexPms(index_pms, customIndex);
      if (idxPms2.symbolInit) { // 切换回实时，将symbol切换回默认值；
        pageState.symbol = DEFAULT_SYMBOL;
      };
      if (idxPms2.isCusFromStorage) { //如 mobx无数据；从storage拿数据重新赋值
        setCustomIndex(_.get(idxPms2, 'customs', []));
      };
      const newIndexCode = _.assign(INDEX_CODE_VALUE, _.get(idxPms2, 'codeName', {}));
      const res = await showSubAccountHistory(pms, isReverse ? 'FC' : 'NOR');
      const resIdx = await showIndexKline(idxPms2.params, idxPms2.isBatch ? 'batch' : 'nor');
      setIdxParams(_.assign(
        { 'symbolName': newIndexCode[pageState.symbol] },
        index_pms
      ));
      if (_.get(res, 'message', '') !== 'success') {
        message.info(_.get(res, 'message'))
      }
      if (_.get(resIdx, 'message', '') !== 'success') {
        message.info(_.get(resIdx, 'message'))
      }
      const getPoints = _.get(res, 'data.pointList', []);
      if (_.get(res, 'code', '') === '200' && isValidArray(getPoints)) {
        const rateData = handleHistoryRate(
          res,
          _.assign(resIdx, idxPms2),
          pms.dateType
        );
        setPointDatas({ 'timeList': rateData.timeList, 'datas': rateData.datas, 'newData': rateData.newData });
        setIndexValue(rateData.indexVal);
      } else {
        setPointDatas({ timeList: [], datas: {}, newData: {} });
        setIndexValue({});
      }
      setCountMain(countMain + 1);
      setIndexCodeName2(newIndexCode);
    }
  };
  // 获取子账户有收益率数据的时间区间
  async function _showAllSubAccountHistory() {
    if (_.get(pageState.accInfo, 'id')) {
      const res = await showAllSubAccountHistory(pageState.accInfo.id, isReverse ? 'FC' : 'NOR');
      if (_.get(res, 'code', '') === '200') {
        let getDateArray = _.get(res, 'data', [])
        if (getDateArray.indexOf(TODAY) === -1) {
          getDateArray.push(TODAY);
        }
        setValidDate(getDateArray);
      }
    }
  };
  // 切换账户
  function updatePage() {
    _showSubAccountData();
    _showSubAccountHistory();
    _showAllSubAccountHistory();
  }
  // 路由跳转
  function goRates(pms, goToday) {
    props.history.push({
      pathname: goToday ? '/mobile/todays' : '/mobile/ratecal',
      state: {
        subname: _.get(pageState.accInfo, 'name', ''),
        pageKey: 'account',
        subAccountId: pageState.accInfo.id,
        isReverse: _.get(pageState.accInfo, 'centralizedLogin') === 2 ? true : false,
        ...pms, ...idxParams
      }
    });
  }
  function goRecord(pms, range) {
    props.history.push({ pathname: '/mobile/recordcal', state: { data: pms, drange: range, subname: _.get(pageState.accInfo, 'name', '') } });
  }
  function goDisplay() {
    props.history.push({
      pathname: '/mobile/displays', state: {
        'pageKey': 'account',
        'subAccountId': pageState.accInfo.id,
        'subname': _.get(pageState.accInfo, 'name', ''),
        isReverse
      }
    });
  }
  function goStock(key, record, list) {
    mobxStore.changeRouteMobx({ 'active': pageState.active, 'from': 'account' });
    props.history.push({
      pathname: '/mobile/kline',
      state: { 'pageKey': key, 'symbol': _.get(record, 'stockCode'), 'subname': _.get(pageState.accInfo, 'productName', ''), list, ...record }
    });
  }
  // 交易员调整交易页面; 交易员也无法切换账户，路由不使用proxy的pageState
  function goTrading() {
    props.history.push({ pathname: '/mobile/trading', state: { ...routeDatas } });
  }

  const baseProps = {
    'subAccountId': _.get(pageState.accInfo, 'id'), 'active': pageState.active, 'pageKey': 'account', 'upcount': pageState.switchCount,
    'isReverse': isReverse
  };
  const mainProps = {
    'pageKey': 'account', 'upcount': countMain, 'plateform': userPlateform, 'datas': pointDatas, 'active': pageState.active,
    'indexValue': indexValue, 'pms': idxParams, 'tradeDates': tradeDates, 'isReverse': isReverse,
    'validDateRange': validDate, 'customIndex': customIndex,
  };
  return (
    <>
      <div className='basic-title'>
        <HeaderIcons
          title={_.get(pageState.accInfo, 'name', '')}
          pageKey='account'
          isHide={isVisitor || isTrader || _.size(accLists) <= 1}
          onPress={() => {
            props.history?.goBack();
            mobxStore.changeCurrentAcc([]);
          }}
          onSwap={() => pageState.visible = true}
        />
      </div>
      <NewCard pad={12}>
        <TopInfoHeader
          topItems={TOP_ITEMS}
          desc={DESC_ITEMS}
          value={assetData}
        />

        {isTrader && <div style={{ marginTop: 12 }}>
          <Button block type='primary' icon={<StockOutlined />} onClick={goTrading}>交易</Button>
        </div>}
      </NewCard>
      {/* <div style={{ height: 8 }}></div> */}
      <Tabs activeKey={pageState.active}
        onChange={(key) => {
          pageState.active = key;
          if ((pageState.tabUpdate && key === 'mainchart') || (isTrading && key === 'mainchart')) {
            _showSubAccountHistory();
            pageState.tabUpdate = false;
          }
        }}
        items={[
          {
            key: 'mainchart', label: tabIcons('收益率', <FundFilled />),
            children: <NewCard>
              <MainCharts
                {...mainProps}
                onDatetypeChange={(type) => {
                  _showSubAccountHistory(type);
                }}
                onSymbolChange={(symbol) => {
                  pageState.symbol = symbol;
                  _showSubAccountHistory();
                }}
                goRates={(val, goToday) => goRates(val, goToday)}
                onSync={() => {
                  _showSubAccountHistory();
                  _showSubAccountData();
                }}
                onDateChange={(date) => {
                  pageState.singleDate = date;
                  _showSubAccountHistory();
                }}
              />
            </NewCard>
          },
          _.get(pageState.accInfo, 'strategyType', 0) === 1 && userPlateform === 'TAMP' ? {
            key: 'attribute', label: tabIcons('收益归因', <PullRequestOutlined />),
            children: <AttributeCharts {...baseProps} />
          } : {},
          {
            key: 'kratechart', label: tabIcons('收益K', <ProjectFilled />),
            children: <NewCard pad={4}>
              <KRateCharts  {...baseProps}
                indexPms={idxParams}
                newProps={props}
              />
            </NewCard>
          },
          {
            key: 'position', label: tabIcons('持仓', <PieChartFilled />),
            children: <NewCard pad={4}>
              <Position {...baseProps}
                role={_.get(userInfo, 'role', '')}
                plateform={userPlateform}
                goDisplay={goDisplay}
                jumpStock={(record, list) => goStock('acc_pos', record, list)}
              />
            </NewCard>
          },
          !isTrader ? {
            key: 'compare', label: tabIcons('对比', <SwitcherFilled />),
            children: <NewCard>
              <TowCharts {...baseProps}
                idxPms={idxParams}
                plateform={userPlateform}
                customIndex={customIndex}
                newIdxCodeName={indexCodeName2}
              />
            </NewCard>
          } : {},
          {
            key: 'record', label: tabIcons('记录', <DatabaseFilled />),
            children: <NewCard pad={4}>
              <Records {...baseProps}
                jumpRecord={goRecord}
                jumpStock={(record, list) => goStock('acc_record', record, list)}
              />
            </NewCard>
          },
          userPlateform === 'TAMP' && !isTrader ? {
            key: 'BTCOM', label: tabIcons('回测对比', <ControlFilled />),
            children: <NewCard>
              <BtCompare {...baseProps} />
            </NewCard>
          } : {}
        ]}
      />

      {_.size(accLists) > 1 && <Selections
        visible={pageState.visible}
        options={accLists}
        onPress={(v) => {
          let findex = _.findIndex(accLists, o => o.value === v);
          if (findex !== -1) {
            pageState.accInfo = _.cloneDeep(accLists[findex]);
          }
          pageState.visible = false;
          pageState.switchCount++;
          // 收益率tab直接更新；否则切换状态，切换时更新
          if (pageState.active === 'mainchart') {
            updatePage();
          } else {
            pageState.tabUpdate = true;
          }
        }}
        onClose={() => pageState.visible = false}
      />}
    </>
  )
}

export default AccountsPage;
import { action, observable, makeObservable, autorun } from 'mobx';
import { getAccountSubs, showIndexConfig } from '@/api/main';
import { isAllStockHoliday, getAllTradeDay } from '@/api/stock';
import {
  CUSTOMIDX_STRING,
  isValidArray, accountOptionIndex, labelValues, createDatasTimeKey, isMarketTrading
} from '@/utils/utils';
import { getPlateform, saveStorage } from '@/utils/saveUtils';
import moment from 'moment';
import _ from 'lodash';

const getval = function (name, val = "") {
  try {
    if (localStorage.getItem(name) !== undefined && localStorage.getItem(name) !== '') {
      // return JSON.parse(localStorage.getItem(name));
      if (name === 'token') {
        return localStorage.getItem(name);
      } else {
        return JSON.parse(localStorage.getItem(name));
      }
    } else {
      return val;
    }
  } catch (e) {
    return "";
  }
}

const setVal = function (name, obj) {
  if (name === 'token' || name === 'tokenFC') {
    localStorage.setItem(name, obj);
  } else {
    let ret = JSON.stringify(obj);
    localStorage.setItem(name, ret);
  }
}

function createAccountItem(datas, loginType) {
  let product = []; let account = []; let accObj = {}; let productObj = {};
  let accLogin = {}; let productLogin = {};
  if (isValidArray(datas)) {
    datas.map(p => {
      let productItem = {
        label: _.get(p, 'productName'),
        value: _.get(p, 'productId'),
        centralizedLogin: loginType
      };
      product.push(productItem);
      _.set(productObj, productItem.value, productItem.label);
      _.set(productLogin, productItem.value, loginType);

      isValidArray(_.get(p, 'securitiesAccountList', [])) && p.securitiesAccountList.map(acc => {
        const getAccList = _.get(acc, 'subAccountList', []);
        isValidArray(getAccList) && acc.subAccountList.map(sub => {
          let newSub = {
            ...sub,
            label: _.get(sub, 'subAccountName', ''),
            value: _.get(sub, 'subAccountId', 0),
            centralizedLogin: loginType
          };
          _.set(accObj, newSub.value, newSub.label);// 用id作为key，label作为value，可根据id找到名称
          _.set(accLogin, newSub.value, loginType);
          account.push(newSub);
        })
      });
    })
  }
  return { product, account, accObj, productObj, accLogin, productLogin }
}

// ________________________________mobx class_________________________________
class Store {
  constructor() {
    makeObservable(this)
  }
  // 登陆令牌
  @observable userLogin = getval('token');
  @observable userLoginFC = getval('tokenFC');
  // 用户信息
  @observable userInfo = getval('userInfo');
  @observable userInfoFC = getval('userInfoFC');
  // 账户下证券账户及子账户列表
  @observable mobxAccountSubs = getval('mobxAccountSubs');
  @observable allProductAcc = {};
  // 跳转记录，用于返回是回到对应的active页面
  @observable routeMobx = {};
  // 当前是否在盘中
  @observable inTrading = '';
  // 所有交易日期
  @observable tradeDates = [];
  // 当前全部产品信息(详情)
  @observable allProducts = {};
  // 当前子账户列表
  @observable curAccount = [];
  //自定义配置指数
  @observable customIndex = [];
  // 登陆
  @action landLogin = (token) => {
    this.userLogin = token;
    setVal('token', token);
  }
  @action landLoginFC = (token) => {
    this.userLoginFC = token;
    setVal('tokenFC', token);
  }
  // imToken
  @action landImLogin = (token) => {
    setVal('im-token', token);
  }
  // 退出登陆
  @action cancelLogin = () => {
    this.userLogin = null; this.userLoginFC = null;
    this.userInfo = {}; this.userInfoFC = {};
    localStorage.removeItem('token');
    localStorage.removeItem('tokenFC');
    localStorage.removeItem('menuKey');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('userInfoFC');
    localStorage.removeItem('mobxAccountSubs');
  }
  // 登陆接口保存用户信息
  @action landUserInfo = (userInfo) => {
    this.userInfo = userInfo;
    setVal('userInfo', userInfo);
  }
  @action landUserInfoFC = (userInfo) => {
    this.userInfoFC = userInfo;
    setVal('userInfoFC', userInfo);
  }
  // 基金经理 - 保存账户下证券账户及子账户列表
  @action _mobxAccountSubs = (data) => {
    this.mobxAccountSubs = data;
    setVal('mobxAccountSubs', data);
  }
  // 每次离开页面清空mobx内的token
  @action clearUserToken = () => {
    this.userLogin = null;
  }
  // 当前全部产品信息
  @action changeAllProducts = (data) => {
    this.allProducts = data;
  }
  // 当前子账户列表
  @action changeCurrentAcc = (data) => {
    this.curAccount = data;
  }

  @action _getProductAccount = async (hasFc) => { // 全局获取账户信息 [增加配置时试用]
    const init_plate = getPlateform();
    const data = await getAccountSubs();
    const resFc = hasFc && init_plate === 'TAMP' ? await getAccountSubs(null, 'FC') : {};
    if (_.get(data, 'code', '') === '200') {
      // 处理产品和子账户，分别生成options
      const getData = _.get(data, 'data', []);
      const getFcData = _.get(resFc, 'data', []);
      const nors = createAccountItem(getData, 1);
      const fcs = createAccountItem(getFcData, 2);
      // 产品/账户对比修改格式，首页缓存增加分类逻辑；保留原select两组options数据
      if (init_plate === 'BT') {
        let finalProductAcc = {
          'product': _.orderBy(nors.product, ['value'], ['desc']),
          'account': _.orderBy(nors.account, ['value'], ['desc']),
          'accObj': nors.accObj,
          'productObj': nors.productObj
        }
        let productTwo = ['每日更新', '回测中', '其他'].map(b => labelValues([b, b, []]));
        let accountTwo = ['每日更新', '回测中', '其他'].map(b => labelValues([b, b, []]));
        // 【tips】当前接口返回isShow判断首页与AssetsPage拿到的不同，
        //资产页获取到非括号产品都设置为aindex=1，并不是根据该字段判断
        finalProductAcc.product = finalProductAcc.product.map(item => {
          const aindex = accountOptionIndex(item.label, parseFloat(_.get(item, 'isShow')) ? true : false);
          // 产品增加分类aindex字段
          const newItem = _.assign(item, { aindex });
          productTwo[aindex].children.push(newItem);
          return newItem;
        });
        finalProductAcc.account.map(item => {
          const aindex = accountOptionIndex(item.label, parseFloat(_.get(item, 'isShow')) ? true : false);
          accountTwo[aindex].children.push(item);
        });
        this.allProductAcc = {
          ...finalProductAcc,
          productTwo, accountTwo
        }
      } else {
        //【新】 acc/proLogin 通过id快速找到账户\产品是否是反采账户；
        this.allProductAcc = {
          'product': _.orderBy(_.concat(nors.product, fcs.product), ['value'], ['desc']),
          'account': _.orderBy(_.concat(nors.account, fcs.account), ['value'], ['desc']),
          'accObj': _.assign(nors.accObj, fcs.accObj),
          'productObj': _.assign(nors.productObj, fcs.productObj),
          'accLogin': _.assign(nors.accLogin, fcs.accLogin),
          'productLogin': _.assign(nors.productLogin, fcs.productLogin),
        }
      }
    }
  }

  @action changeRouteMobx = (data) => {
    this.routeMobx = data;
  }
  // 获取并判断是否在盘中
  @action _getTradingInfo = async () => {
    const TODAY = moment().format('YYYY-MM-DD');
    const holiRes = await isAllStockHoliday({ beginDate: TODAY, endDate: TODAY })
    let globalTrading = 'rest'
    if (_.get(holiRes, 'code', '') === '200') {
      if (_.get(holiRes, 'data.isAllHoliday') === false) {
        globalTrading = isMarketTrading() ? 'trading' : 'rest';
      }
    }
    this.inTrading = globalTrading;
  }
  // 获取所有交易日期
  @action _getAllTradeDay = async () => {
    const year = moment().year();
    const yearString = `${year - 2},${year - 1},${year}`;
    const res = await getAllTradeDay({ 'year': yearString });
    if (_.get(res, 'code', '') === '200') {
      const getData = _.get(res, 'data', []);
      let orders = createDatasTimeKey(getData, 'pure_date', 'd', 'asc');
      let final = [];
      // 增加周的信息，间隔日>1则周增加；
      let week_num = 1; let wstart = ''; // 每周的开始日期
      let seaStart = '';
      let curYear = moment(_.get(orders, '[0].date')).year();
      orders.map((n, i) => {
        let cur = _.get(n, 'date');
        let next = _.get(orders, `[${i + 1}].date`);
        let diff = next ? moment(cur).diff(moment(next), 'd') : 0;
        const thisSeason = moment(cur).quarter(); const nextSeaon = moment(next).quarter();
        const splits = _.split(cur, '-');
        if (i === 0) {
          wstart = splits[1] + splits[2];
          seaStart = splits[1] + splits[2];
        }
        if (parseFloat(splits[0]) !== curYear) { // 跨年度，周重新计算
          week_num = 1;
          curYear = parseFloat(splits[0]);
        }
        final.push(_.assign(n, {
          'week': week_num,
          'start': wstart,
          'season': thisSeason,
          'seaStart': seaStart
        }));
        // 交易日大于1则跨周末，周递增
        const nex_split = _.split(next, '-');
        if (Math.abs(diff) > 1) {
          week_num++;
          wstart = nex_split[1] + nex_split[2];
        };
        if (nextSeaon !== thisSeason) {
          seaStart = nex_split[1] + nex_split[2];
        }
      })

      this.tradeDates = final;
    }
  };
  // 获取自定义配置指数
  @action _showIndexConfig = async () => {
    const res = await showIndexConfig();
    if (_.get(res, 'code', '') === '200') {
      const getData = _.get(res, 'data', []);
      const newData = getData.map(n => {
        return { 'label': n.name, 'value': CUSTOMIDX_STRING + n.id, 'index': n.index };
      });
      saveStorage('cidx', JSON.stringify(newData));
      this.customIndex = newData;
    }
  };
}

export const store = new Store();

autorun(() => {
  // console.log(`登陆${store.userInfo}`)
})

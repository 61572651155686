import React, { useEffect, useState } from 'react';
import { useUpdateEffect } from 'ahooks';
import { Spin, Segmented } from 'antd';
import { isValidArray, isValidString, labelValues } from '@/utils/utils';
import _ from 'lodash';
import * as echarts from 'echarts';

const SEG_OPTIONS = [labelValues(['平均', 'avg']), labelValues(['板块', 'plate'])]
const createKeys = (array) => array.map((a, i) => { return { ...a, skey: i } });
const renderDefaultSerires = (color, type = '') => {
  let config = {
    type: 'line',
    symbol: 'circle',
    data: [],
    lineStyle: { color: color },
    itemStyle: { color: color },
  }
  if (type === 'dash') {
    config.lineStyle.type = 'dashed';
    config.lineStyle.width = 1;
  }
  return config
}
const avg_markLine = {
  markLine: {
    silent: true,
    lineStyle: {
      color: '#333'
    },
    data: [{ yAxis: 0 }]
  }
};
// 图表配置数组
const DIFF_PMS = createKeys([
  { name: '总价格平均差', group: '1', seriresObj: { ...renderDefaultSerires('#007297'), ...avg_markLine }, dataKey: 'avg' },
  { name: '买入平均差', group: '1', seriresObj: renderDefaultSerires('#ea6232'), dataKey: 'bavg' },
  { name: '卖出平均差', group: '1', seriresObj: renderDefaultSerires('#3aa75e'), dataKey: 'savg' },
  { name: '非拆单买入平均差', group: '1', seriresObj: renderDefaultSerires('#ca0031', 'dash'), dataKey: 'bbavg' },
  { name: '非拆单卖出平均差', group: '1', seriresObj: renderDefaultSerires('#00b06e', 'dash'), dataKey: 'ssavg' },
  { name: '上证买', group: '2', dataKey: 'sh_bavg', seriresObj: renderDefaultSerires('#dc0065') },
  { name: '上证卖', group: '2', dataKey: 'sh_savg', seriresObj: renderDefaultSerires('#cddd33') },
  { name: '深证买', group: '2', dataKey: 'sz_bavg', seriresObj: renderDefaultSerires('#c64057') },
  { name: '深证卖', group: '2', dataKey: 'sz_savg', seriresObj: renderDefaultSerires('#bacb22') },
  { name: '创业买', group: '2', dataKey: 'cy_bavg', seriresObj: renderDefaultSerires('#e82200') },
  { name: '创业卖', group: '2', dataKey: 'cy_savg', seriresObj: renderDefaultSerires('#9ba035') },
  { name: '科创买', group: '2', dataKey: 'ks_bavg', seriresObj: renderDefaultSerires('#f26a5d') },
  { name: '科创卖', group: '2', dataKey: 'ks_savg', seriresObj: renderDefaultSerires('#78ae55') },
]);
const plate_shortname = ['sh', 'sz', 'cy', 'kz'];
// 根据代码拆分 上证、深证、创业、科创板块统计
const handleCodeBelong = (code) => {
  let final = '';
  if (isValidString(code)) {
    const init2 = code.substring(0, 2);
    const init3 = code.substring(0, 3);
    if (init2 === '00') {
      final = plate_shortname[0];
    } else if (init2 === '60') {
      final = plate_shortname[1];
    }
    if (init3 === '300') {
      final = plate_shortname[2];
    } else if (init3 === '688') {
      final = plate_shortname[3];
    }
  }
  return final;
}
// 价格差区间时间统计图表
export default function DiffCharts(props) {
  const [option, setOption] = useState({
    grid: { left: '10%', right: '8%', bottom: '10%', top: '10%' },
    legend: { data: [], type: 'scroll' },
    xAxis: { type: 'category', data: [] },
    yAxis: {
      type: 'value',
      // splitNumber: 6,
      interval: 0.1 // 轴拆分线间隔大小
    },
    series: [],
    tooltip: { trigger: 'axis', }
  });
  const [update, setUpdate] = useState(0);
  const [segVal, setSegVal] = useState('avg');

  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();
    }
    myChart = echarts.init(document.getElementById('diffCharts'));

    let newOption = _.cloneDeep(option);

    // const plate_status = _.get(props, 'plateStatus', false);
    const getDatas = _.get(props, 'datas', []);
    const pmsArray = segVal === 'plate' ? DIFF_PMS : _.filter(DIFF_PMS, o => o.group === '1' ? true : false);
    let times = []; let dataObj = {}; let legend = [];
    if (_.size(getDatas) > 0) {
      getDatas.map((item, index) => {
        times.push(_.get(item, 'date', ''));
        const splitCal = splitPlate(_.get(item, 'left', []), item.date);
        pmsArray.map(n => {
          const is_plate = _.includes(n.dataKey, '_bavg') || _.includes(n.dataKey, '_savg'); // 板块统计dataKey中包含buysell
          if (n.skey in dataObj) {
            dataObj[n.skey][index] = is_plate ? _.get(splitCal, n.dataKey, 0) : _.get(item, `leftCal.${n.dataKey}`, 0);
          } else {
            dataObj[n.skey] = is_plate ? [_.get(splitCal, n.dataKey, 0)] : [_.get(item, `leftCal.${n.dataKey}`, 0)];
          }
        })
      })
    };
    newOption.xAxis.data = times;
    newOption.series = pmsArray.map(n => {
      legend.push(n.name);
      return {
        'name': n.name, ...n.seriresObj, 'data': validDataArray(dataObj[n.skey]),
      };
    });
    newOption.legend.data = legend;

    setOption(newOption);
    myChart.setOption(newOption, true);
    myChart.resize();
  }, [props.upCount, update]);

  useUpdateEffect(() => {
    if (props.active === '4') {
      let myChart = props.myChart;
      myChart = echarts.init(document.getElementById('diffCharts'));
      myChart.resize();
    }
  }, [props.upcount3]);

  // 计算拆分板块数据
  function splitPlate(leftAll) {
    let cals = {};
    if (isValidArray(leftAll)) {
      // 创建计算字段
      plate_shortname.map(sn => {
        _.set(cals, sn + '_buy', 0);
        _.set(cals, sn + '_sell', 0);
        _.set(cals, sn + '_bsize', 0);
        _.set(cals, sn + '_ssize', 0);
      });
      // 用left基准全部数据进行计算你
      leftAll.map(item => {
        const getSide = _.get(item, 'side', '');
        const isDiff = 'hasDiff' in item; // 是否有对比项
        const diffRate = _.get(item, 'pricef.diffRate', 0);
        const isValidDiffRate = isDiff && diffRate !== undefined && diffRate !== null ? true : false;
        // 符合差条件计算，同上层计算
        if (isValidDiffRate) {
          const short_name = handleCodeBelong(_.get(item, 'stockCode', ''));
          const side_name = getSide === 'BUY' ? '_buy' : '_sell';
          const size_name = getSide === 'BUY' ? '_bsize' : '_ssize';
          if (isValidString(short_name)) {
            _.set(cals, short_name + side_name, _.get(cals, short_name + side_name) + diffRate);
            _.set(cals, short_name + size_name, _.get(cals, short_name + size_name) + 1);
          }
          // if (date === '2023-06-19' && short_name === 'cy') {
          //   console.log(item.stockCode, diffRate)
          // }
        }
      });
      // 创建avg平均字段..
      plate_shortname.map(sn => {
        _.set(cals, sn + '_bavg', calAvg(_.get(cals, sn + '_buy'), _.get(cals, sn + '_bsize')));
        _.set(cals, sn + '_savg', calAvg(_.get(cals, sn + '_sell'), _.get(cals, sn + '_ssize')));
      });
    }
    return cals;
  }

  function calAvg(a = 0, b = 0, pos = 4) {
    return a !== 0 && b !== 0 ? _.round(a / b, pos) : 0;
  }

  const validDataArray = (array) => {
    return isValidArray(array) ? array.map(n => n ?? '-') : [];
  }

  return (
    <>
      <div style={{ marginBottom: 12, textAlign: 'center' }}>
        <Segmented value={segVal} options={SEG_OPTIONS} size='small' onChange={(v) => {
          setSegVal(v);
          setUpdate(update + 1);
        }} />
      </div>

      <Spin spinning={_.get(props, 'loading', false)}>
        <div style={{ display: 'flex' }}>
          <div
            id="diffCharts"
            style={{ width: '100%', height: 450 }}
          />
        </div>
      </Spin>
    </>
  )
}
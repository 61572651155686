import axios from '@/utils/axios';

//股票-股票交易-买入
export function buy(data) {
  return axios.post(global.api + '/trade/buy', data);
}
//股票-股票交易-卖出
export function sell(data) {
  return axios.post(global.api + '/trade/sell', data);
}
//股票-获取实时股票行情
export function market(data) {
  return axios.get(global.api + '/stock/market', { params: data });
}
// 撤单
export function cancel(params) {
  return axios.post(global.api + '/trade/cancel', params);
}
// 记录
export function showList(data) {
  return axios.post(global.api + '/trade/showList', data);
}
// 股票-获取当前子账户所有可交易股票信息
export function getCurrentStocks(data) {
  return axios.get(global.api + '/stock/getCurrentStocks', { params: data });
}
//股票-获取当前子账户所有可卖出股票信息
export function getCurrentPositionStocks(data) {
  return axios.get(global.api + '/stock/getCurrentPositionStocks', { params: data });
}
//是否节假日
export function isAllStockHoliday(params) {
  return axios.post(global.api + '/trader/control/isAllStockHoliday', params);
}
// 所有交易日期
export function getAllTradeDay(params) {
  return axios.get(global.api + '/fundManager/getAllTradeDay', { params });
}
//管理人批量卖出
export function getAllProductPosition() {
  return axios.get(global.api + '/fundManager/getAllProductPosition');
}
export function batchSellProportionByFundManager(params) {
  return axios.post(global.api + '/trade/batchSellProportionByFundManager', params);
}
import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Descriptions, Segmented, Popover, Button, Space, FloatButton } from 'antd';
import { CaretDownOutlined, BoxPlotOutlined, DeploymentUnitOutlined } from '@ant-design/icons';
import { useMount, useReactive } from 'ahooks';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import {
  COMMON_CHART, UPDOWN_COLOR, INTERVAL_KEYS, STR_PLUS,
  renderSlice, handleDaliyRates, isFullTimeRange, createBaseLine, calMaLine,
} from '@/utils/chartInfo';
import { RateText, NewCard, HeaderIcons, MainSlider } from '@/utils/widgets';
import { labelValues } from '@/utils/utils';
import * as echarts from 'echarts';
import _ from 'lodash';

const { Text } = Typography;
const RANGE_OPTIONS = [labelValues(['区间', 'ranges']), labelValues(['季度', 'season']), labelValues(['月度', 'months']), labelValues(['周度', 'weeks'])];
const TYPE_OPTIONS = [labelValues(['超额', 'extra']), labelValues(['收益', 'profit'])];
const CRANGE = { 'day': '日', 'weeks': '周', 'months': '月', 'season': '季' };
const RANGE_BAR = {
  grid: { left: '8%', right: '5%', bottom: '10%', top: '10%' },
  tooltip: { trigger: 'axis' },
  xAxis: { type: 'category', data: [] },
  yAxis: { type: 'value' },
  series: [
    { name: '', type: 'bar', data: [], }
  ]
};
// 超额均线及ma值
const EXTRA_AVG = { 'ranges': ['日', 5], 'weeks': ['周', 4], 'months': ['月', 3], 'season': ['季', 4] };
/**
 *  收益率分析页面; 
 *  增加slider功能，路由带slider参数，赋值本地状态；用同一个effect进行更新
 *  231207增加周、月饼图功能；通过seg可切换数据源；1219增加收益数据源，可切换
 */
const RateCalPage = (props) => {
  const mobxStore = useLocalObservable(() => store);
  const routeDatas = _.get(props, 'location.state', {}); // 路由传递数据源，完整区间数据已计算
  const [tradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const [option4, setoption4] = useState(COMMON_CHART.extraBar);
  const [option5, setoption5] = useState(COMMON_CHART.extraPie);
  const [update, setUpdate] = useState(0);
  const [sliderValue, setSliderValue] = useState(_.get(routeDatas, 'sliderValue', []));
  const [sliderValue2, setSliderValue2] = useState(_.get(routeDatas, 'sliderValue2', []));
  const [timeArr] = useState(_.get(routeDatas, 'timeArr', [0]));
  const [timeNameArr] = useState(_.get(routeDatas, 'timeNameArr', []));
  const [rates, setRates] = useState(_.get(routeDatas, 'rates', {}));
  const [others] = useState(_.get(routeDatas, 'otherRates', []));
  const rateState = useReactive({
    rtype: 'ranges', type: 'extra', typeName: '',
    isExtra: false, noSelect: false, isOverlap: false
  });

  useMount(() => {
    setUpdate(update + 1);
  })

  useEffect(() => {
    let myChart4 = props.myChart4; let myChart5 = props.myChart4; let myChart6 = props.myChart6;
    if (myChart4 !== null && myChart4 !== "" && myChart4 !== undefined) {
      myChart4.dispose();//销毁
    }
    myChart4 = echarts.init(document.getElementById('main_extra_down'));
    myChart5 = echarts.init(document.getElementById('main_extra_range'));
    myChart6 = echarts.init(document.getElementById('main_extra_overlap'));
    myChart4.showLoading({ text: '数据获取中', effect: 'whirling' });

    let newOption4 = _.cloneDeep(option4);
    let newOption5 = rateState.rtype !== 'ranges' ? _.cloneDeep(RANGE_BAR) : _.cloneDeep(COMMON_CHART.extraPie);
    let newOption6 = _.cloneDeep(RANGE_BAR);

    const isFullRange = isFullTimeRange(sliderValue, timeArr);
    const sliceTimes = isFullRange ? _.get(routeDatas, 'times') : renderSlice(_.get(routeDatas, 'times'), sliderValue[0], sliderValue[1]);
    const getPointNet = _.get(routeDatas, 'pointNet', []);
    const getExtra = _.get(routeDatas, 'extra3', []);
    const indexNet = _.get(routeDatas, 'idNet', []);
    const isExCompare = _.get(routeDatas, 'isExCompare', false);
    rateState.noSelect = isExCompare;
    // 【新】增加收益的数据源
    let daliy_rate = []; let dname = ''; let isAccumulate = false;
    if (rateState.type === 'extra') {
      daliy_rate = _.cloneDeep(getExtra);
      dname = '超额';
    } else if (rateState.type === 'profit') {
      daliy_rate = _.cloneDeep(getPointNet);
      dname = '收益';
    } else {
      const oitem = _.chain(others).filter(o => o.key === rateState.type).head().value();
      daliy_rate = _.get(oitem, 'data', []);
      dname = _.get(oitem, 'name', '');
      isAccumulate = _.includes(_.get(oitem, 'key'), STR_PLUS);
    };
    const newDaliy = handleDaliyRates(
      isFullRange ? daliy_rate : renderSlice(daliy_rate, sliderValue[0], sliderValue[1]),
      isFullRange ? indexNet : renderSlice(indexNet, sliderValue[0], sliderValue[1]),
      sliceTimes,
      tradeDates,
      rateState.isExtra,
      isAccumulate
    );
    // console.log('newDaliy', newDaliy)
    newOption4.series[0].data = _.get(newDaliy, 'bar4');
    newOption4.series[0].name = dname;
    newOption4.series[1] = createBaseLine('#879494', {
      'name': '日均',
      'data': calMaLine(_.get(newDaliy, 'bar4').map(n => n.value), 5, 'rate')
    });
    newOption4.xAxis.data = sliceTimes;
    let final5 = []; let axis5 = []; let val5 = [];
    // 增加周、月饼图功能；从newDaliy直接处理数据
    if (rateState.rtype !== 'ranges') {
      _.keys(_.get(newDaliy, rateState.rtype, {})).map(keys => {
        const gval = _.get(newDaliy, `${rateState.rtype}.${keys}`, 0);
        let colorIdx = _.findIndex(INTERVAL_KEYS, o => gval > _.get(o, 'min') && gval < _.get(o, 'max'));
        let obj5 = {
          'value': gval,
          'itemStyle': { 'color': _.get(UPDOWN_COLOR, colorIdx !== -1 ? INTERVAL_KEYS[colorIdx]['colorPath'] : 'flat') }
        };
        final5.push(obj5);
        axis5.push(keys);
        val5.push(gval);
      });
      newOption5.series[0].data = final5;
      newOption5.series[1] = createBaseLine('#879494', {
        'name': EXTRA_AVG[rateState.rtype][0] + '均',
        'data': calMaLine(val5, EXTRA_AVG[rateState.rtype][1], 'rate')
      });
      newOption5.xAxis.data = axis5;
    } else {
      newOption5.series[0].data = _.get(newDaliy, 'pies');
    };
    //叠加功能计算
    if (rateState.isOverlap && rateState.rtype !== 'ranges') {
      const newBatchDaliy = batchCalDaliy(isFullRange, indexNet, sliceTimes);
      let newBar = []; let batchTime = [];
      _.keys(newBatchDaliy).map(keyname => {
        const getItemData = _.get(newBatchDaliy, `${keyname}.${rateState.rtype}`, []);
        let item = {
          'name': _.chain(others).filter(o => o.key === keyname).head().get('name').value(),
          'type': 'bar', 'stack': 'batch', 'data': getItemData,
          'label': { show: false }
        };
        newBar.push(item);
        batchTime = _.get(newBatchDaliy, `${keyname}.rtimes.${rateState.rtype}`, []);
      });
      newOption6.series = newBar;
      newOption6.xAxis.data = batchTime;
    };

    setRates(newDaliy);
    setoption4(newOption4); setoption5(newOption5);
    myChart4.setOption(newOption4, true); myChart5.setOption(newOption5, true);
    myChart6.setOption(newOption6, true);
    myChart4.hideLoading(); myChart6.resize();
    myChart4.resize(); myChart5.resize();
  }, [update]);
  // 滑动change
  function afterSliderChange(v) {
    setSliderValue(v);
    setSliderValue2(v);
    setUpdate(_.round(update + 0.1, 1));
  };
  // 批量计算daliy数据；符合条件的收益率批量计算统计数据
  function batchCalDaliy(isfull, idxnet, times) {
    let finals = {};
    others.map(itm => {
      if (!_.includes(['extra', 'profit'], itm.key)) {
        let isAccumulate = _.includes(itm.key, STR_PLUS);
        let daliy_rate = _.concat([0], itm.data)
        const newDaliy = handleDaliyRates(
          isfull ? daliy_rate : renderSlice(daliy_rate, sliderValue[0], sliderValue[1]),
          isfull ? idxnet : renderSlice(idxnet, sliderValue[0], sliderValue[1]),
          times,
          tradeDates,
          rateState.isExtra,
          isAccumulate
        );
        let rangeObj = {}; let rtimes = {};
        ['weeks', 'months', 'season'].map(rangeKey => {
          let rangeFinal = []; let rangeTimes = [];
          _.keys(_.get(newDaliy, rangeKey, {})).map(keys => {
            const gval = _.get(newDaliy, `${rangeKey}.${keys}`, 0);
            let colorIdx = _.findIndex(INTERVAL_KEYS, o => gval > _.get(o, 'min') && gval < _.get(o, 'max'));
            let ritem = {
              'value': gval,
              'itemStyle': { 'color': _.get(UPDOWN_COLOR, colorIdx !== -1 ? INTERVAL_KEYS[colorIdx]['colorPath'] : 'flat') }
            };
            rangeFinal.push(ritem);
            rangeTimes.push(keys);
          });
          _.set(rangeObj, rangeKey, rangeFinal);
          _.set(rtimes, rangeKey, rangeTimes);
          _.set(rangeObj, 'rtimes', rtimes);
        });
        _.set(finals, itm.key, rangeObj);
      };
    });
    return finals;
  };
  // 其他收益率数据源选择
  const otherContent = (
    <Space direction='vertical'>
      {others.map(n => <div key={n.key} style={{ cursor: 'pointer' }} onClick={() => {
        rateState.type = n.key;
        rateState.typeName = n.name;
        setUpdate(_.round(update + 0.1, 1));
      }}>
        <Text>{n.name}</Text>
      </div>)}
    </Space>
  );
  // extra数据内字段与options内的字段相同；
  const newRates = _.get(rates, rateState.rtype, {});
  const newCtype = rateState.rtype === 'ranges' ? 'day' : rateState.rtype;
  return <>
    <div className='basic-title'>
      <HeaderIcons
        title={rateState.noSelect ? '超额统计' : '统计分析'}
        subs={rateState.noSelect ? `${_.get(routeDatas, 'name1')}+${_.get(routeDatas, 'name2')}` : _.get(routeDatas, 'subname', '')}
        pageKey='extra'
        onPress={() => props.history?.goBack()}
      />
    </div>
    <NewCard pad={4}>
      {rateState.noSelect ? <></> : <Row style={{ marginTop: 12 }} align='middle'>
        <Col span={6}></Col>
        <Col span={12} style={{ textAlign: 'center' }}>
          <Segmented options={TYPE_OPTIONS} value={rateState.type}
            onChange={v => {
              rateState.type = v;
              rateState.typeName = '';
              if (_.includes(['extra', 'profit'], v)) {
                rateState.isExtra = false;
                rateState.isOverlap = false;
              }
              setUpdate(_.round(update + 0.1, 1));
            }}
          />
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          {_.size(others) > 0 && <Popover placement="bottom" trigger='click' content={otherContent}>
            <Button type='text' icon={<CaretDownOutlined />} >{rateState.typeName}</Button>
          </Popover>}
        </Col>
      </Row>}

      <div style={{ display: 'flex' }}>
        <div
          id="main_extra_down"
          style={{ width: '99%', height: 350 }}
        />
      </div>

      <MainSlider
        type='up_redo'
        isNotToday={_.get(routeDatas, 'dateType', '') !== 'TODAY' ? true : false}
        timeArray={timeNameArr}
        max={_.last(timeArr) || 0}
        svalue={sliderValue2}
        onSliderChange={(v) => setSliderValue2(v)}
        onSliderAfterChange={(v) => afterSliderChange(v)}
        onRedo={() => afterSliderChange([0, _.last(timeArr)])}
      />

      <Row style={{ marginBottom: 8, marginTop: 24 }} align='middle'>
        <Col span={18}>
          <Segmented options={RANGE_OPTIONS} value={rateState.rtype}
            onChange={v => {
              rateState.rtype = v;
              setUpdate(_.round(update + 0.1, 1));
            }}
          />
        </Col>
        <Col span={6} style={{ textAlign: 'right', paddingRight: 8 }}>
          {rateState.isExtra && <Text strong keyboard>超额</Text>}
        </Col>
      </Row>

      <div style={{ marginTop: 16 }}>
        <Descriptions size='small' bordered column={1}>
          <Descriptions.Item label={'区间'}>
            <Text strong>{_.get(rates, `cal.${newCtype}.size`, '')}</Text>
            <Text>个交易{CRANGE[newCtype]}</Text>
          </Descriptions.Item>
          <Descriptions.Item label={'平均'}>
            <RateText rate={_.get(rates, `cal.${newCtype}.avg`, '')} carry={4} />
          </Descriptions.Item>
          <Descriptions.Item label={'胜率'}>
            <RateText rate={_.get(rates, `cal.${newCtype}.psize`, '')} carry={2} />
          </Descriptions.Item>
        </Descriptions>
      </div>

      <div style={{ display: 'flex' }}>
        <div
          id="main_extra_range"
          style={{ width: '100%', height: 350 }}
        />
      </div>

      {rateState.isOverlap && <div style={{ marginTop: 12 }}><Text strong>合并统计</Text></div>}

      <div style={{ display: rateState.isOverlap ? 'flex' : 'none', marginBottom: 12 }}>
        <div id="main_extra_overlap" style={{ width: '100%', height: 410 }} />
      </div>

      <Descriptions size='small' bordered column={2}>
        {_.keys(newRates).map((keyname, i) => {
          let finalLabel = '';
          if (rateState.rtype === 'month') {
            const split_month = _.split(keyname, '-');
            const year = split_month[0]; const mon = parseInt(split_month[1]);
            finalLabel = mon === 1 ? `${year}-${mon}月` : `${mon}月`;
          } else {
            finalLabel = keyname;
          }
          return <Descriptions.Item key={'mon' + i}
            label={finalLabel}
            labelStyle={rateState.rtype === 'week' ? { padding: '8px 4px' } : { padding: '8px 8px' }}
          >
            {rateState.rtype === 'ranges' ?
              <Text>{newRates[keyname]}</Text>
              : <RateText rate={newRates[keyname]} carry={2} />}
          </Descriptions.Item>
        })}
      </Descriptions>

      <div style={{ height: 25 }}></div>
    </NewCard>

    <FloatButton.Group>
      {!_.includes(['extra', 'profit'], rateState.type) && <FloatButton
        shape='circle'
        type={rateState.isExtra ? 'primary' : 'default'}
        icon={<BoxPlotOutlined />}
        onClick={() => {
          rateState.isExtra = !rateState.isExtra;
          setUpdate(_.round(update + 0.1, 1));
        }}
      />}
      {_.size(others) > 1 && <FloatButton
        shape='circle'
        type={rateState.isOverlap ? 'primary' : 'default'}
        icon={<DeploymentUnitOutlined />}
        onClick={() => {
          rateState.isOverlap = !rateState.isOverlap;
          setUpdate(_.round(update + 0.1, 1));
        }}
      />}
    </FloatButton.Group>
  </>
}

export default RateCalPage;
import React from 'react';
import { Typography, Row, Col, Descriptions, Avatar, Image } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { autoCol, labelValues } from '@/utils/utils';
import { RateText, NewCard, LabelValueComp, NewDivider } from '@/utils/widgets';
import logoMin from '@/images/logoMini.png';
import _ from 'lodash';

const { Text } = Typography;
const TOP_ITEMS = [
  labelValues(['总收益率', 'totalRevenueRate']),
  labelValues(['日收益率', 'dailyRevenueRate']),
  labelValues(['管理的产品数', 'totalProduct']),
];
const TOP_DESC = [
  ['产品总金额', 'totalAssets'],
  ['空闲资金', 'freeBalance'],
];
const TRADER_ITEMS = [
  labelValues(['总资产', 'totalAssets']),
  labelValues(['可用资金', 'totalBalance']),
  labelValues(['总收益', 'totalRevenue']),
];
const TRADER_DESC = [['日收益', 'dailyRevenue']]
// 资产卡片
const AssetsCards = ({ assetss, isTrader, isMore, openMore }) => {
  // console.log(assetss);
  const descItems = _.concat([], isTrader ? TRADER_DESC : TOP_DESC).map(n => {
    return { label: n[0], children: _.get(assetss, n[1], '') }
  });
  const newItems = isTrader ? TRADER_ITEMS : TOP_ITEMS;
  return <>
    <Row className='basic-title' align='middle'>
      <Col span={18}>
        <Avatar shape="square" src={
          <Image
            src={logoMin}
            style={{ width: 25, height: 25 }}
            preview={false}
          />}
        />
        <Text strong style={{ fontSize: 18, marginLeft: 5 }}>一量科技</Text>
      </Col>
      <Col span={6} style={{ textAlign: 'right' }}>
        {isMore && !isTrader && <div onClick={openMore}>
          <EllipsisOutlined />
        </div>}
      </Col>
    </Row>
    <NewCard pad={12}>
      <Row>
        {newItems.map((n, i) => <Col key={'asset' + i} {...autoCol([6, 8, 8])}>
          <LabelValueComp
            label={_.get(n, 'label', '')}
            vComp={n.value === 'totalProduct' || isTrader ? <Text strong style={{ fontSize: 18 }}>
              {_.get(assetss, n.value, '')}
            </Text> : <RateText
              rate={_.get(assetss, n.value, '')}
              rType='replace'
              restStyle={{ fontSize: 18, fontWeight: 600 }}
            />}
          />
        </Col>)}
      </Row>
      <NewDivider />
      <Descriptions column={1} items={descItems} size='small' />
    </NewCard>
  </>
}

export default AssetsCards;
import { message } from "antd";

const savePlateform = (val) => {
  try {
    localStorage.setItem('plate_form', val);
  } catch (error) {
    message.error('设置平台url失败！');
  }
}
const getPlateform = () => {
  try {
    return localStorage.getItem('plate_form');
  } catch (error) {
    message.error('获取平台url失败！');
  }
}

const STORAGE_TYPE = {
  '1': 'url_string',
  '2': 'trading_status', // 全局存储交易状态，全局缓存数据用
  'cmp': 'compare_account', // 对比交易时存储上一次账户id
  'h_pdt': 'home_product', // 首页卡片存储习惯
  'sub_acc': 'sub_account', // subAccount 分页
  's_width': 'screen_width', // subAccount 分页
  'cidx': 'custom_index', // 自定义指数
}
const saveStorage = (type, val) => {
  try {
    localStorage.setItem(STORAGE_TYPE[type], val);
  } catch (error) { }
}
const getStorage = (type) => {
  try {
    let val = localStorage.getItem(STORAGE_TYPE[type]);
    return val ?? ''
  } catch (error) { }
}

export {
  savePlateform, getPlateform,
  saveStorage, getStorage
}
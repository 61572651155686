import React, { useState } from 'react';
import { Table, Tag, FloatButton } from 'antd';
import { getfundManagerAssets, visShowAssetsAndProducts } from '@/api/main';
import { useMount, useReactive } from 'ahooks';
import { singleColumns } from '@/utils/utils';
import { NewCard, HeaderIcons, RateText } from '@/utils/widgets';
// import moment from 'moment';
import _ from 'lodash';

const createColumnsRate = (keyarray = [], type = '') => {
  const props_type = { 'noprefix': { noPrefix: true }, 'rest': { restStyle: { fontWeight: 400, color: 'black' } } };
  let newCol = singleColumns(keyarray);
  let rateProps = {};
  if (type === 'noprefix') {
    rateProps = _.assign(rateProps, props_type['noprefix']);
  }
  if (type === 'noprefix_rest') {
    rateProps = _.assign(rateProps, props_type['noprefix'], props_type['rest']);
  }
  if (type !== '') {
    newCol.render = (text, record) => <RateText rate={text} carry={3} rType='replace' {...rateProps} />
  }
  return newCol;
}
// 对比列表
const AllTestPage = (props) => {
  const routeDatas = _.get(props, 'location.state', {});
  const [allassets, setallassets] = useState({});
  const [filterList, setFilterList] = useState([]);
  const [isHomeArray, setIsHomeArray] = useState([]);
  const testState = useReactive({ page: 1, pageSize: 10, total: 0, loading: false });
  const isVisitor = _.get(routeDatas, 'userRole', '') === 'visitor' ? true : false;

  const columns = [
    { ...createColumnsRate(['名称', 'productName', 185]), fixed: 'left' },
    createColumnsRate(['总资产', 'totalAssets', 105]),
    createColumnsRate(['总收益', 'totalRevenue', 128], 'noprefix'),
    createColumnsRate(['总收益率', 'totalRevenueRate', 100], 'normal'),
    createColumnsRate(['日收益', 'dailyRevenue', 108], 'noprefix'),
    createColumnsRate(['日收益率', 'dailyRevenueRate', 95], 'normal'),
    createColumnsRate(['总市值', 'positionsAssets', 115], 'noprefix_rest'),
    createColumnsRate(['可用资金', 'productBalance', 125], 'noprefix_rest'),
    {
      ...singleColumns(['在首页', 'isHome', 75]),
      render: (text, record) => {
        const pid = _.get(record, 'productId');
        const isCheck = isHomeArray.indexOf(pid) !== -1 ? true : false
        return <Tag color={isCheck ? 'blue' : 'defalut'}>{isCheck ? '是' : '否'}</Tag>
      }
    },
  ];

  useMount(() => {
    _gets();
  });

  // 管理员获取及visitor权限接口
  async function _gets() {
    testState.loading = true;
    let params = {
      isShow: false,
      pageNum: testState.page,
      pageSize: testState.pageSize
    }
    const res = isVisitor ? await visShowAssetsAndProducts() : await getfundManagerAssets(params);
    if (_.get(res, 'code', '') === '200') {
      res.data.totalRevenueRate = parseFloat(res.data.totalRevenueRate).toFixed(2) + '%';
      res.data.dailyRevenueRate = parseFloat(res.data.dailyRevenueRate).toFixed(2) + '%';
      const getList = _.get(res, 'data.currentProductResultList', []);
      const getInfoList = _.get(res, 'data.pageInfo.list', []);
      setallassets(res.data);
      setFilterList(getList);
      setIsHomeArray(_.chain(getInfoList).filter(o => o.isShow === 1).map('id').value());
      testState.total = _.get(res, 'data.pageInfo.total', 0);
    }
    testState.loading = false;
  }

  return <>
    <HeaderIcons
      title={'全部回测'}
      pageKey='test_all'
      onPress={() => props.history?.goBack()}
    />

    <NewCard pad={4} restStyle={{ marginTop: 12 }}>
      <Table
        rowKey="productId"
        dataSource={filterList}
        columns={columns}
        bordered={false}
        size='small'
        scroll={{ x: _.sum(columns.map(n => n.width)) }}
        loading={testState.loading}
        pagination={{
          showSizeChanger: false,
          current: testState.page,
          pageSize: testState.pageSize,
          total: testState.total,
          onChange: (e) => {
            testState.page = e;
            _gets();
          },
        }}
      />

      <FloatButton.BackTop />
    </NewCard>
  </>
}

export default AllTestPage;
import React, { useState, useEffect } from 'react';
import { List, Row, Col, Space, Typography, Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { getProductDetailss } from '@/api/main';
import { isValidArray } from '@/utils/utils';
import { RateText, NewDivider } from '@/utils/widgets';
import _ from 'lodash';

const { Text } = Typography;
const LIST_TITLE = [['账户', 8], ['日收益', 8], ['净值', 4], ['操作', 4]];
// 持仓页面
const AccountList = ({
  active = '',
  productId = 0,
  upcount = 0,
  isReverse = false,

  jumpToAccout,
  onListChange
}) => {
  const [accVal, setAccVal] = useState({});
  const [listData, setlistData] = useState([]);
  const [secAccs, setSecAccs] = useState([]);

  useEffect(() => {
    if (active === 'accountList') {
      _getAccounts();
    }
  }, [active, upcount]);

  //求证券账户表格的总收益和今日收益
  async function _getAccounts() {
    if (productId) {
      const res = await getProductDetailss({ params: { productId: productId } }, isReverse ? 'FC' : 'NOR');
      if (_.get(res, 'code', '') === '200') {
        let accs = [];
        let securitiesAccounts = _.get(res, 'data.securitiesAccounts', []);
        securitiesAccounts.map(item => {
          let totalRevenue = 0;
          let dailyRevenue = 0;
          item.subAccountList.map(subs => {
            totalRevenue += subs.totalRevenue;
            dailyRevenue += subs.dailyRevenue;
            _.set(subs, 'label', _.get(subs, 'name'));
            _.set(subs, 'value', _.get(subs, 'id'));
            _.set(subs, 'centralizedLogin', isReverse ? 2 : 1);
            return subs;
          })
          item.totalRevenue = totalRevenue;
          item.dailyRevenue = dailyRevenue;

          if (isValidArray(_.get(item, 'subAccountList'))) {
            accs = _.concat(accs, _.get(item, 'subAccountList'));
          }

          return item;
        })

        setSecAccs(securitiesAccounts);
        setlistData(accs);
        setAccVal(_.get(res, 'data', {}));
        onListChange(accs);
      }
    }
  }

  return <>
    <div style={{ marginBottom: 6 }}>
      <Space>
        <Text type='secondary'>今日手续费：</Text><Text strong>{_.round(_.get(accVal, 'commission', 0), 2)}</Text>
        <Text type='secondary' style={{ marginLeft: 8 }}>总手续费：</Text><Text strong>{_.round(_.get(accVal, 'totalCommission', 0), 2)}</Text>
      </Space>
    </div>
    <NewDivider />
    <Row style={{ marginBottom: 6 }}>
      {LIST_TITLE.map((n, i) => <Col span={n[1]} key={'col' + i}>
        <Text type='secondary'>{n[0]}</Text>
      </Col>)}
    </Row>
    <List
      grid={{ gutter: 8, column: 1 }}
      dataSource={listData}
      renderItem={(item) => {
        return <List.Item>
          <Row align='middle'>
            <Col span={8} style={{ paddingRight: 8 }}>
              <Text>{_.get(item, 'name', '')}</Text>
            </Col>
            <Col span={8}>
              <RateText rate={_.get(item, 'dailyRevenue')} restStyle={{ fontSize: 16, fontWeight: 600 }} noPrefix />
            </Col>
            <Col span={4}>
              <Text strong>{_.round(_.get(item, 'netValue', 0), 2)}</Text>
            </Col>
            <Col span={4}>
              <Button type='text'
                icon={<RightOutlined style={{ color: '#a9a9a9' }} />}
                onClick={() => {
                  // 调整需要从账户字段中添加strategyType，子账户页面使用
                  let findex = _.findIndex(secAccs, o => o.id === _.get(item, 'securitiesAccountId', 0));
                  jumpToAccout({ ...item, strategyType: findex !== -1 ? _.get(secAccs, `[${findex}].strategyType`) : 0 });
                }}
              />
            </Col>
          </Row>
        </List.Item>
      }}
    />
  </>
}

export default AccountList;
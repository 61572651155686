import React, { useState, useRef } from 'react';
import { Row, Col, Space, Typography, Modal, FloatButton, message, Button, Radio, Segmented } from 'antd'; //InputNumber
import { UpSquareFilled, ExclamationCircleOutlined, SyncOutlined, AccountBookOutlined, CloseOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { getAllProductPosition, batchSellProportionByFundManager } from '@/api/stock';
import { useMount, useReactive, useSize } from 'ahooks';
import { cryptoDatas } from '@/utils/cryptoUtils';
import { isValidArray, isValidNumber, isValidObj, labelValues } from '@/utils/utils';
import { NewCard, HeaderIcons, BaseDrawer } from '@/utils/widgets';
import SellTables from './pcomp/SellTable';
import moment from 'moment';
import _ from 'lodash';
import './pages.scss'

const { Text } = Typography;
const { confirm } = Modal;
const MS_FORMAT = 'YY-MM-DD HH:mm:ss:SSS'; const DAY_FORMAT = 'YYYY-MM-DD';
const TODAY = moment().format(DAY_FORMAT);
const TYPE_OPTIONS = [labelValues(['集中', '1']), labelValues(['反采', '2'])];
/* 
  实盘批量卖出页面
  增加反采格式，暂不可切换
*/
const BatchSellPage = (props) => {
  // const routeDatas = _.get(props, 'location.state', {});
  const pageState = useReactive({
    acs: [], acObj: {}, acName: {}, acProp: {}, // 账户样式，账户index对应，账户名称对应，账户仓位
    show: false, type: '1',// 区分集中于反采
  });
  const [accs, setAccs] = useState([]);
  const [resData, setResData] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selection, setSelection] = useState([]);
  const [propAcc, setPropAcc] = useState([]);
  const ref = useRef(null);
  const divSize = useSize(ref);

  useMount(() => {
    _getAllProductPosition();
  });
  // 获取所有账户及持仓数据
  async function _getAllProductPosition() {
    const res = await getAllProductPosition();
    if (_.get(res, 'code', '') === '200') {
      setResData(res);
      handleAccounts(_.get(res, `data.${pageState.type}`, {}))
    }
  }
  // 处理账户
  function handleAccounts(assets) {
    const openMoment = moment(TODAY + ' 09:00:00');
    let alls = []; let accMap = new Map(); let stockObj = {};
    const getStocks = _.get(assets, 'subStockAssets', {});
    if (isValidObj(getStocks)) {
      let count = 1;
      _.keys(getStocks).map(pid => { // 账户id : [持仓列表]
        const getList = getStocks[pid] ?? [];
        if (isValidArray(getList)) {
          let newList = [];
          getList.map(item => {
            const acc_name = _.get(item, 'subAccountName');
            const stock_code = _.get(item, 'stockCode');
            const sub_id = _.get(item, 'sbuAccountId');
            // 记录账户map
            if (acc_name && !accMap.has(acc_name)) {
              accMap.set(acc_name, sub_id);
            }
            // 股票object,重复的将subid进行累加，金额数量进行累加，逗号分隔，区分账户
            if (stock_code in stockObj) {
              _.set(stockObj, stock_code, {
                'ids': _.get(stockObj, `${stock_code}.ids`) + ',' + sub_id,
                'stockAmount': _.get(stockObj, `${stock_code}.stockAmount`) + _.get(item, 'stockAmount'),
                'availableStockAmount': _.get(stockObj, `${stock_code}.availableStockAmount`) + _.get(item, 'availableStockAmount'),
                'totalMarketValue': _.get(stockObj, `${stock_code}.totalMarketValue`) + _.get(item, 'totalMarketValue'),
              });
            } else {
              _.set(stockObj, stock_code, {
                'ids': String(sub_id),
                'stockAmount': _.get(item, 'stockAmount'),
                'availableStockAmount': _.get(item, 'availableStockAmount'),
                'totalMarketValue': _.get(item, 'totalMarketValue'),
              })
            }
            // 增加时间及排序key
            const buyDate_moment = moment(_.get(item, 'buyDate', ''));
            let newItem = _.assign(item, {
              'key': stock_code + count,
              'formatDate': buyDate_moment.format(MS_FORMAT),
              'orderKey': openMoment.diff(buyDate_moment, 'millisecond'), // 排序用
            });
            newList.push(newItem);
            count++;
          })
          alls = _.concat(alls, newList);
        }
      });

      let account = []; let accObj = {};
      // 根据账户创建选择、名称、及仓位比例的state对象
      Array.from(accMap)?.map((arr, i) => {
        // '1:账户名称' : 112  
        account.push(labelValues([`${i + 1}:` + arr[0], arr[1]]));
        _.set(accObj, arr[1], `${i + 1}`); // 112 : 1 (id，排序index)
        pageState.acs[i] = '0';
        pageState.acName[arr[1]] = arr[0];
        pageState.acProp[arr[1]] = { nu: 1, deno: 3, radio: 1 } // 初始化选项
      });
      // 将所有票去重，并标记关联账户字段
      const uniqAll = _.uniqBy(alls, 'stockCode');
      const finalStock = uniqAll.map(n => {
        const subid = _.get(stockObj, `${n.stockCode}.ids`);
        const subarr = _.split(subid, ',');
        return _.assign(n, { subid, subarr }, _.get(stockObj, n.stockCode));
      });
      // console.log('finalStock', finalStock)
      setStocks(finalStock);
      setFilters(finalStock);
      setAccs(account);
      pageState.acObj = accObj;
    } else {
      setStocks([]);
      setFilters([]);
      setAccs([]);
      pageState.acObj = {};
    }
  }
  // 执行批量卖出
  async function _batchSellProportionByFundManager() {
    if (!isValidArray(selection)) {
      message.info('请选择股票！');
      return;
    }
    // [tips]：取消仓位值的判断，可以无操作，所以仓位正确则增加参数，反之则不添加；
    const newParams = createAccountParams(selection);
    const res = await batchSellProportionByFundManager(cryptoDatas(newParams.params));
    if (_.get(res, 'code', '') === '200') {
      message.success(_.get(res, 'message', '卖出成功！'));
      _getAllProductPosition();
      pageState.show = false;
    } else {
      message.info(_.get(res, 'message', '卖出失败！'));
    }
  }
  // 区分账户，并生成提交需要的params; 选择与提交都需要对选项进行过遍历，故用同一个方法
  function createAccountParams(selects = []) {
    let params = { bachParam: [] };
    let pmMap = new Map(); let pmIdx = 0;
    if (isValidArray(selects)) {
      selects.map(key => {
        let findex = _.findIndex(stocks, o => o.key === key);
        const getSubarr = _.get(stocks, `[${findex}].subarr`, []);
        const getCode = _.get(stocks, `[${findex}].stockCode`);
        // 单一key的创建
        if (_.size(getSubarr) === 1) {
          let sid = _.head(getSubarr);
          const calProp = calFraction(pageState.acProp[sid]);
          // 有参数将sellList进行push
          if (pmMap.has(sid)) {
            params.bachParam[pmMap.get(sid)]?.sellList.push(getCode);
          } else {
            _.set(params, `bachParam[${pmIdx}]`, {
              'subAccountId': sid,
              'sellList': [getCode],
              'proportion': calProp
            });
            pmMap.set(sid, pmIdx);
            pmIdx++;
          }
        } else if (_.size(getSubarr) > 1) {
          // 多个key，则遍历进行创建
          getSubarr.map(sid => {
            const calProp = calFraction(pageState.acProp[sid]);
            if (pmMap.has(sid)) {
              params.bachParam[pmMap.get(sid)]?.sellList.push(getCode);
            } else {
              _.set(params, `bachParam[${pmIdx}]`, {
                'subAccountId': sid,
                'sellList': [getCode],
                'proportion': calProp
              });
              pmMap.set(sid, pmIdx);
              pmIdx++;
            }
          });
        }
      });
      // 提交前参数进行字符串化，统一将数组进行字符串
      if (isValidArray(params.bachParam)) {
        //【tips】统一删除持仓=0的参数，用none或者不创建，会导致再次选择该账户时UI会无效不渲染
        let newBach = _.filter(params.bachParam, o => isValidNumber(o.proportion));
        _.set(params, 'bachParam', JSON.stringify(newBach));
      }
    }
    return { params, pmMap }
  }
  // 点击账户，选择或取消对应的票
  function onAccChange(key, index) {
    const filterKeys = _.filter(stocks, o => _.includes(o.subid, key)).map(n => n.key);
    let newSelection = _.cloneDeep(selection);
    if (_.includes(pageState.acs, key)) {
      pageState.acs[index] = '0'; // 对应index，0为没选中，key值为选中
      _.pullAll(newSelection, filterKeys);
    } else {
      pageState.acs[index] = key;
      newSelection = _.concat(selection, filterKeys);
    }
    setSelection(newSelection);
    onPropChange(newSelection);
  }
  // 计算及检查自定义分数是否正确并返回比例值
  const calFraction = (obj) => {
    let fval = 0;
    if (obj.radio === 'other') { //自定义分数转化为小数，保留2位【例:0.33 = 1/3
      const num_nu = parseInt(obj.nu); const num_deno = parseInt(obj.deno);
      if (isValidNumber(num_nu) && isValidNumber(num_deno)) {
        fval = _.round(num_nu / num_deno, 2);
      }
    } else { // 无操作时返回 0，最后统一处理
      fval = obj.radio === 'none' ? 0 : parseFloat(obj.radio);
    }
    return fval;
  }
  // 多选发送变化，并更新右侧持仓设置；排序按照账户生成的排序index值进行展示
  function onPropChange(list = []) {
    let saccount = [];
    if (isValidArray(list)) {
      const newAccout = createAccountParams(list);
      Array.from(newAccout.pmMap)?.map(arr => {
        saccount.push({ 'id': arr[0], 'order': pageState.acObj[arr[0]] });
      });
    }
    setPropAcc(_.orderBy(saccount, ['order'], ['asc']));
  }

  return <>
    <HeaderIcons
      title={'批量卖出'}
      pageKey='batch_sell'
      onPress={() => props.history?.goBack()}
    />

    <div style={{ textAlign: 'center' }}>
      <Segmented options={TYPE_OPTIONS} value={pageState.type} onChange={v => {
        pageState.type = v;
        handleAccounts(_.get(resData, `data.${v}`, {}));
        setSelection([]);
        setPropAcc([]);
      }} />
    </div>

    <div ref={ref} style={{ width: '100%', height: 24 }}></div>

    <div className='acc-select-div'>
      {isValidArray(accs) && accs.map((a, i) => <div
        key={a.value}
        style={{ 'width': _.round(divSize?.width / 2.5) }}
        className={_.includes(pageState.acs, a.value) ? 'acc-active' : 'acc-select'}
        onClick={() => onAccChange(a.value, i)}
      >
        {a.label}
      </div>)}
    </div>

    <NewCard pad={4} restBody={{ minHeight: 410 }}>
      <SellTables
        datas={filters}
        values={selection}
        accNum={pageState.acObj}
        onSelect={(list) => { // 选择后动态创建右侧比例选项
          setSelection(list);
          onPropChange(list);
        }}
      />
    </NewCard>

    <BaseDrawer
      visible={pageState.show}
      height={415}
      onClose={() => pageState.show = false}
    >
      <div style={{ paddingBottom: 45, minHeight: 200 }}>
        {isValidArray(propAcc) && propAcc.map((a, idx) => {
          const btnObj = { size: 'small', type: 'text' }
          return <Row key={a.id} gutter={[16, 8]} style={{ marginTop: idx > 0 ? 12 : 8 }}>
            <Col span={24}>
              <Space size={2}>
                <AccountBookOutlined style={{ color: 'grey' }} />
                <Text type='secondary'>{_.get(pageState.acName, a.id, '')}</Text>
              </Space>
            </Col>
            <Col span={24}>
              {pageState.acProp[a.id].radio === 'other' ? <Row>
                <Col span={12}>
                  <Space>
                    <Text strong>{pageState.acProp[a.id].nu}</Text>
                    {/* <InputNumber {...inputNumObj} value={pageState.acProp[a.id].nu} onChange={(v) => pageState.acProp[a.id].nu = v} /> */}
                    <Text type='secondary'>/</Text>
                    <Text strong>{pageState.acProp[a.id].deno}</Text>
                    {/* <InputNumber {...inputNumObj} value={pageState.acProp[a.id].deno} onChange={(v) => pageState.acProp[a.id].deno = v} /> */}
                  </Space>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Space>
                    <Button {...btnObj} icon={<ArrowUpOutlined />} onClick={() => pageState.acProp[a.id].deno++} />
                    <Button {...btnObj} icon={<ArrowDownOutlined />} onClick={() => {
                      if (pageState.acProp[a.id].deno > 1) {
                        pageState.acProp[a.id].deno--
                      }
                    }} />
                    <Button {...btnObj} danger icon={<CloseOutlined />} onClick={() => pageState.acProp[a.id].radio = 1} />
                  </Space>
                </Col>
              </Row> : <Radio.Group
                onChange={(e) => pageState.acProp[a.id].radio = e.target.value}
                value={pageState.acProp[a.id].radio}
              >
                <Radio value={1}>全仓</Radio>
                <Radio value={0.5}>半仓</Radio>
                <Radio value={'none'}>无操作</Radio>
                <Radio value={'other'}>其他</Radio>
              </Radio.Group>}
            </Col>
          </Row>
        })}
      </div>
      <Row justify='center' className='bs-submit'>
        <Col span={20}>
          <Button block type='primary'
            onClick={() => {
              confirm({
                title: '是否批量卖出？',
                icon: <ExclamationCircleOutlined />,
                centered: true,
                onOk: () => _batchSellProportionByFundManager(),
                onCancel() { console.log('Cancel') },
              });
            }}
          >
            卖出
          </Button>
        </Col>
      </Row>
    </BaseDrawer>

    <FloatButton.Group>
      <FloatButton.BackTop />
      <FloatButton icon={<SyncOutlined />} onClick={_getAllProductPosition} />
      <FloatButton
        shape="circle"
        disabled={_.size(selection) === 0 ? true : false}
        icon={<UpSquareFilled style={{ color: _.size(selection) === 0 ? 'grey' : '#2774b8' }} />}
        onClick={() => pageState.show = true}
      />
    </FloatButton.Group>
  </>
}

export default BatchSellPage;
import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, Space, Spin, Badge } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { showList } from '@/api/main';
import { createBaseLine, COLOR_ARRAY } from '@/utils/chartInfo';
import { useReactive, useUpdateEffect, useMount } from 'ahooks';
import { isValidArray, isValidObj, labelValues } from '@/utils/utils';
import { NewCalendar, NewDivider, NewCard, Selections, TextSelect } from '@/utils/widgets';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const FORMAT = 'YYYY-MM-DD';
const TODAY = moment().format(FORMAT);
const RANGE_TIME = [moment().subtract(7, 'd').format(FORMAT), TODAY];
// 收益归因
const AttributeCharts = (props) => {
  const [checkVal, setCheckValue] = useState([]);
  const [checkItem, setCheckItem] = useState([]);
  const [fullSerise, setFullSerise] = useState([]);
  const [gcount, setGcount] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);
  const attrState = useReactive({
    begin: RANGE_TIME[0], end: RANGE_TIME[1], loading: false, plateObj: {},
    timeOpen: { key: '', open: false, value: '', stamp: new Date() },
    visible: false,
  });
  const [plateRate, setPlateRate] = useState({});
  const [option, setoption] = useState({
    xAxis: {
      name: '时间',
      type: 'category',
      data: []
    },
    yAxis: { type: 'value' },
    series: [],
    tooltip: {
      trigger: 'axis',
      // enterable: true,
      // extraCssText: 'max-height:85%;overflow:scroll',
      // confine: true,
      formatter: renderFormatter
    },
  });

  function renderFormatter(params) {
    let finalString = '';
    params.map(n => {
      //const get_time = _.get(n, 'name'); // 日期
      const get_plate = _.get(n, 'seriesName'); // 板块名称
      const data_index = _.get(n, 'dataIndex'); // 数据index位置
      const getCount = _.get(attrState.plateObj, `${get_plate}[${data_index}]`);
      finalString = finalString
        + n.marker + ' ' + n.seriesName
        + `<span style='font-weight:600;margin-left:12px'>${n.value}</span>    
              (${get_plate in attrState.plateObj ? getCount : ''}次)` // 实时无需展示额外括号数据
        + '<br/>'
    });
    // console.log('renderFormatter', params);
    return finalString;
  }

  useMount(() => {
    _showList();
  });

  useEffect(() => {
    if (props.active === 'attribute') {
      _showList();
    }
  }, [props.upcount]);

  async function _showList() {
    const is_reverse = _.get(props, 'isReverse', false);
    if (_.get(props, 'subAccountId', 0)) {
      attrState.loading = true;
      let params = {
        subAccountId: _.get(props, 'subAccountId'),
        state: true,
        beginTime: attrState.begin,
        endTime: attrState.end,
        content: '',
        continuation: false
      }
      const res = await showList(params, is_reverse ? 'FC' : 'NOR');
      if (_.get(res, 'code', '') === '200' || _.get(res, 'code', '') === '205') {
        setPlateRate(_.get(res, 'data.plateRate', {}));
        setGcount(_.round(gcount + 0.1, 1));
      }
      attrState.loading = false;
    }
  }

  useEffect(() => {
    //console.log('charts', props)
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }

    myChart = echarts.init(document.getElementById('attr_charts'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    let newOption = _.cloneDeep(option);
    let times = []; let finals = {}; let finalsNum = {}; let newSerise = [];
    if (isValidObj(plateRate)) {
      let timeList = Object.keys(plateRate).map(date => { // 处理时间object-key的时间
        return { 'date': date, 'timeKey': moment().diff(moment(date), 'd') }
      });
      timeList = _.orderBy(timeList, ['timeKey'], ['desc']); // 时间排序
      timeList.map((t, index) => {
        times[index] = t.date;
        const rateMap = _.get(plateRate, `${t.date}.plateRateMap`, {}); // 板块收益率
        const plateNum = _.get(plateRate, `${t.date}.plateNum`, {}); // 板块个数
        if (isValidObj(rateMap)) {
          for (const platename in rateMap) {
            const plateVal = _.get(rateMap, platename, 0);
            const plateCount = _.get(plateNum, platename, 0);
            if (platename in finals) { // 赋值对应的index位置，板块信息不是固定的，没有就还会是0
              finals[platename][index] = plateVal;
              finalsNum[platename][index] = plateCount;
            } else { // final板块空的时候，创建getDatas大小相同的全0数组
              finals[platename] = _.fill(Array(_.size(plateRate)), 0);
              finalsNum[platename] = _.fill(Array(_.size(plateRate)), 0);
              _.set(finals, `${platename}[${index}]`, plateVal);
              _.set(finalsNum, `${platename}[${index}]`, plateCount);
            }
          }
        }
      })
    }
    attrState.plateObj = isValidObj(finalsNum) ? finalsNum : {}; //赋值个数，tooltip里面直接调用
    // console.log('final', finals)
    // console.log('finalsNum', finalsNum)
    const finalKeys = _.keys(finals);
    let checkOptions = []; let count = 0;
    finalKeys.map((keyname, i) => { // 创建series数据
      let sitem = createBaseLine(COLOR_ARRAY[count], { 'name': keyname, 'order': i });
      sitem.data = trimPlateArray(finals[keyname]);
      newSerise.push(sitem);
      checkOptions.push(_.assign(labelValues([keyname, i]), { 'color': COLOR_ARRAY[count] })); // 【bug-fix】用中文做value，checkbox会发生点击不选中的问题，使用Number||英文
      if (count >= (_.size(finalKeys) - 1)) {
        count = 0;
      } else {
        count++;
      }
    })
    setFullSerise(newSerise);
    const sliceKeys = isValidArray(checkVal) ? checkVal : [0, 1, 2, 3, 4];
    newOption.series = _.filter(newSerise, o => _.includes(sliceKeys, o.order));
    newOption.xAxis.data = times;
    setCheckItem(checkOptions);
    setCheckValue(sliceKeys);
    // console.log('newOption', newOption)
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
  }, [gcount]);
  // checkbox选择更新
  useUpdateEffect(() => {
    let myChart = props.myChart;
    myChart = echarts.init(document.getElementById('attr_charts'));
    let newOption = _.cloneDeep(option);
    newOption.series = _.filter(fullSerise, o => _.includes(checkVal, o.order));
    myChart.setOption(newOption, true);
    myChart.resize();
  }, [updateCount]);
  // 板块并不是每天都会记录，所以空数据进行处理
  const trimPlateArray = (array) => {
    let newArray = [];
    if (isValidArray(array)) {
      array.map((n, i) => newArray[i] = !n ? 0 : _.round(n, 4));
    }
    return newArray
  }
  //更新操作
  function updateSelect(type) {
    if (type === 'clear') {
      setCheckValue([]);
    }
    attrState.visible = false;
    setUpdateCount(_.round(updateCount + 0.1, 1));
  }

  const badgeItems = _.filter(checkItem, o => _.includes(checkVal, o.value));
  const labelItems = [
    { name: attrState.begin, key: 'begin' },
    { name: attrState.end, key: 'end' },
  ];
  const iconStyle = { color: '#ababab', fontSize: 14 };
  return (
    <>
      <NewCard pad={4}>
        <Row align='middle' style={{ marginTop: 8 }}>
          {labelItems.map(n => <Col key={'label' + n.key} span={9} style={{ textAlign: 'center' }}>
            <TextSelect name={n.name} onPress={() => {
              attrState.timeOpen = { open: true, value: n.name, stamp: moment(n.name).toDate(), key: n.key };
            }}/>
          </Col>)}
          <Col span={6} style={{ textAlign: 'center' }} onClick={() => attrState.visible = true}>
            <MoreOutlined />
          </Col>
        </Row>
        <NewDivider />

        <div className='compare-bar'>
          <Space size={4} wrap>
            {badgeItems.map(item => <Badge color={item.color} text={item.label} />)}
          </Space>
        </div>

        <Spin spinning={attrState.loading}>
          <div style={{ display: 'flex' }}>
            <div
              id="attr_charts"
              style={{ width: '100%', height: 395 }}
            />
          </div>
        </Spin>
      </NewCard>

      <NewCalendar
        values={[attrState.begin, attrState.end]}
        show={_.get(attrState, 'timeOpen.open') ?? false}
        onCancel={() => attrState.timeOpen = false}
        onSubmit={(val) => {
          attrState.begin = val[0];
          attrState.end = val[1];
          attrState.timeOpen = { key: '', open: false, value: '', stamp: new Date() };
          _showList();
        }}
      />

      <Selections
        visible={attrState.visible}
        options={checkItem}
        isMulti={true}
        mvalue={checkVal}
        onClose={() => updateSelect()}
        onPress={(v, checked) => {
          let temp = _.cloneDeep(checkVal);
          if (checked) {
            temp.push(v);
          } else {
            _.pull(temp, v);
          }
          setCheckValue(_.uniq(temp));
        }}
        onFinish={() => updateSelect()}
        onClear={() => updateSelect('clear')}
      />
    </>
  )
}

export default AttributeCharts;
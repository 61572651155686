import axios from '@/utils/axios';

// 展示资产和当前产品
export function getfundManagerAssets(params, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/showAssetsAndProducts', params);
}
//访客展示接口
export function visShowTraderTrends(data) {
  return axios.post(global.api + '/visitorFundManager/showTraderTrends', data);
}
export function visShowAssetsAndProducts(data) {
  return axios.get(global.api + '/visitorFundManager/showAssetsAndProducts', data);
}
// 获取账号下所拥有产品及其证券账户和子账户列表
export function getAccountSubs(data, type) {
  return axios.get((type === 'FC' ? global.fc : global.api) + '/fundManager/balance/getAccount', { ...data, timeout: 2600 });
}
// 产品详情-获取账户列表
export function getProductDetailss(data, type) {
  return axios.get((type === 'FC' ? global.fc : global.api) + '/fundManager/showProductSecuritiesAndSub', data);
}
// 产品收益率
export function showSingleProductHistory(data, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/showSingleProductHistory', data);
}
export function showBatchProductHistory(data) {
  return axios.post(global.api + '/fundManager/showBatchProductHistory', data);
}
// 获取子账户/产品历史区间
export function showAllSubAccountHistory(id, type) {
  return axios.get((type === 'FC' ? global.fc : global.api) + '/subAccount/showAllSubAccountHistory?subAccountId=' + id);
}
export function showAllProductHistory(id, type) {
  return axios.get((type === 'FC' ? global.fc : global.api) + '/product/showAllProductHistory?productId=' + id);
}
//* 根据时间获取指数数据
export function showIndexKline(params, type = '') {  // 增加批量获取接口
  return axios.post(global.api + `/${type === 'batch' ? 'stock' : 'fundManager'}/showIndexKline`, params);
}
//*交易员工作台 - 展示子账户持仓收益率
export function showPositionReturn(data, type) {
  return axios.get((type === 'FC' ? global.fc : global.api) + '/subAccount/showPositionReturn', { params: data });
}
//产品层面持仓
export function getAllPosition(data, type) {
  return axios.get((type === 'FC' ? global.fc : global.api) + '/product/getAllPosition', { params: data });
}
// 子账户收益率
export function showSubAccountHistory(data, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + '/subAccount/showSubAccountHistory', data);
}
export function showBatchSubAccountHistory(data) {
  return axios.post(global.api + '/subAccount/showBatchSubAccountHistory', data);
}
//* 查看子账户数据
export function showSubAccountData(data, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + '/subAccount/showSubAccountAssetsAndRevenue', data);
}
// 交易记录
export function showList(data, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + '/trade/showList', data);
}
export function getAllTrade(data, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + '/product/getAllTrade', data);
}
export function showTradeDetail(id, type) {
  return axios.get((type === 'FC' ? global.fc : global.api) + `/fundManager/showTradeDetail?clientOrderId=${id}`);
}
//*交易员-子账户列表
export function showAllSubAccount(data) {
  return axios.get(global.api + '/trader/control/showAllSubAccount', data);
}
//* 交易员-我的账户信息
export function showAssetsAndRevenue(data) {
  return axios.get(global.api + '/trader/control/showAssetsAndRevenue', data);
}
// 盈亏分布
export function showContribution(data, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/showContribution', data);
}
// 分钟、日 k线
export function klineDetail(data) {
  return axios.post(global.api + '/stock/kLineDetail', data);
}
// 仓位展示
export function getPlateDisplayProductLevel(data, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + `/${type === 'vir' ? 'virtualProduct' : 'fundManager'}/getPlateDisplayProductLevel`, data);
}
export function getPersonalDisplayProductLevel(data, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + `/${type === 'vir' ? 'virtualProduct' : 'fundManager'}/getPersonalDisplayProductLevel`, data);
}
export function getStocksDisplayProductLevel(data, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + `/${type === 'vir' ? 'virtualProduct' : 'fundManager'}/getStocksDisplayProductLevel`, data);
}
export function getPersonalDisplaySubAccountLevel(data, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/getPersonalDisplaySubAccountLevel', data);
}
export function getPlateDisplaySubAccountLevel(data, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/getPlateDisplaySubAccountLevel', data);
}
export function getStocksDisplaySubAccountLevel(data, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/getStocksDisplaySubAccountLevel', data);
}
// 风格仓位: 子账户+产品
export function showTradeIndexSort(params, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/showTradeIndexSort', params);
}
export function showTradeIndexSortProduct(params, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + `/${type === 'vir' ? 'virtualProduct' : 'product'}/showTradeIndexSort`, params);
}
// 重仓分布
export function getHighPositionDistribution(params, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + `/${type === 'vir' ? 'virtualProduct' : 'fundManager'}/getHighPositionDistribution`, params);
}
// 京沪深分布
export function getPositionDistribution(params, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + `/${type === 'vir' ? 'virtualProduct' : 'fundManager'}/getPositionDistribution`, params);
}

// bt 对比 【tamp】
export function compareSubHistory(params, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + '/subAccount/compareSubHistory', params);
}
export function compareProductHistory(params, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/compareProductHistory', params);
}
export function getAllAccount(type = '') {
  return axios.get((type === 'FC' ? global.fc : global.api) + '/tradeAnalysis/getAllAccount');
}
// 实测对比
export function showAllList(params, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + '/tradeAnalysis/showAllList', params);
}
//* BT 对比产品曲线
export function generateNewHistory(params) {
  return axios.post(global.api + '/fundManager/generateNewHistory', params);
}
// 3分钟涨幅超5%
export function getPositionUp5(id, type) {
  return axios.get((type === 'FC' ? global.fc : global.api) + `/product/getPositionUp5?productId=${id}`);
}
// 指数变化速率
export function showIndexSpeed(params) {
  return axios.post(global.api + '/fundManager/showIndexSpeed', params);
}
// 持仓分类（今，昨，前）
export function showPositionSort(params, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + '/subAccount/showPositionSort', params);
}
// 盘中获取 湘财证券
export function showTradeReport() {
  return axios.get(global.api + '/fundManager/showTradeReport');
}
export function showTradeReportSub() {
  return axios.get(global.api + '/fundManager/showTradeReportSub');
}
//全量指数变化速率
export function showCurrentIndexSpeed() {
  return axios.get(global.api + '/fundManager/showCurrentIndexSpeed');
}
// simx虚拟产品
export function showVirtualProduct() {
  return axios.get(global.api + '/virtualProduct/showVirtualProduct');
}
export function showSingleProductHistoryVir(params) {
  return axios.post(global.api + '/virtualProduct/showSingleProductHistory', params);
}
//重仓分布档位
export function getHighPositionLevel(data, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + `/${type === 'vir' ? 'virtualProduct' : 'fundManager'}/getHighPositionLevel`, data);
}
//按日期获取收益率
export function showSubAccountHistoryByDate(data, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/showSubAccountHistory', data);
}
// 收益率k线
export function indexDailyKline(params) {
  return axios.post(global.api + '/stock/indexDailyKline', params);
}
export function showProductKLine(params, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/showProductKLine', params);
}
export function showSubKLine(params, type) {
  return axios.post((type === 'FC' ? global.fc : global.api) + '/subAccount/showSubKLine', params);
}
// 自定义指数配置
export function showIndexConfig() {
  return axios.get(global.api + '/stock/showIndexConfig');
};
//获取私募基金信息
export function findFund(content = '') {
  return axios.get(global.api + `/stock/findFund?content=${content}`);
};
export function showFundKline(params) {
  return axios.post(global.api + '/fundManager/showFundKline', params);
}
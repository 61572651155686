import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Space, Typography, Tag, FloatButton, message, Button, Modal } from 'antd';
import { InsertRowLeftOutlined } from '@ant-design/icons';
import { showList, getAllTrade, showTradeDetail } from '@/api/main';
import { useReactive } from 'ahooks';
import { isValidArray, isValidNumber, singleColumns, createDatasTimeKey, colWidth } from '@/utils/utils';
import { NewDivider, NewCalendar, TextSelect } from '@/utils/widgets';
import { getStorage } from '@/utils/saveUtils';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const FORMAT = 'YYYY-MM-DD';
const RANGE_TIME = [moment().subtract(7, 'd').format(FORMAT), moment().add(1, 'd').format(FORMAT)];
const renderOrderStatus = (text) => {
  const color_type = { '完成': 'green', '未完成': 'red' };
  return <span style={{ color: color_type[text] ?? 'blue' }}>{text}</span>
}
// 持仓页面
const Records = ({
  active = '',
  pageKey = '',
  productId = 0,
  subAccountId = 0,
  upcount = 0,
  isReverse = false,
  hostList = [],

  jumpRecord,
  jumpStock
}) => {
  const isProductPage = pageKey === 'product' ? true : false;
  const [orderList, setorderList] = useState({ list: [], rate: {}, org_list: [], exeRate: {} });
  const [orderCount, setOrderCount] = useState(0);
  const recordState = useReactive({
    begin: RANGE_TIME[0], end: RANGE_TIME[1], loading: false, isTow: false,
    timeOpen: { key: '', open: false, value: '', stamp: new Date() },
  });
  const [details, setDetails] = useState({ 'list': [], 'stock': '' }); // 获取分笔成交记录
  const [show, setShow] = useState(false);
  const swidth = getStorage('s_width'); // 资产页保存的屏幕宽度
  const [sceenWidth] = useState(isValidNumber(parseInt(swidth)) ? parseInt(swidth) : 565);
  const isHosting = pageKey === 'hosting' ? true : false;
  // 分笔显示id或图表
  const renderShowDetail = (type, record) => {
    const stockInfo = `${_.get(record, 'stockCode')}-${_.get(record, 'stockName')}`;
    const clientId = _.get(record, 'clientOrderId');
    return <div onClick={() => _showTradeDetail(clientId, stockInfo)}>
      {type === 'id' && <Text>{clientId}</Text>}
      <InsertRowLeftOutlined
        style={{ fontSize: 11, color: '#959595', marginLeft: 3, cursor: 'pointer' }}
      />
    </div>
  }
  // 账户col
  let columns = [
    {
      ...singleColumns(['股票代码', 'stockCode', 100]),
      fixed: 'left',
      render: (text, record, index) => <div
        onClick={() => _.isFunction(jumpStock) ? jumpStock(record, _.get(orderList, 'org_list', [])) : {}}
        style={{ fontWeight: 500, lineBreak: 'anywhere' }}
      >
        <Text >{`${index + 1}) ${_.get(record, 'stockName')} `}</Text>
        <br />
        <Text type='secondary'>{`${_.get(record, 'stockCode')}`}</Text>
      </div>,
    },
    {
      ...singleColumns(['', 'detail2', 40]),
      render: (text, record) => renderShowDetail('icon', record)
    },
    singleColumns(['子账户', 'name', 130]),
    singleColumns(['委托数量', 'quantity', 80]),
    singleColumns(['成交数量', 'cumQty', 80]),
    singleColumns(['委托均价', 'price', 80]),
    {
      ...singleColumns(['成交均价', 'avgPrice', 80]),
      render: (text) => <Text strong>{_.round(text, 4)}</Text>,
    },
    {
      ...singleColumns(['收益(元)', 'profit', 100], 'updown'),
      sorter: (a, b) => a.profit - b.profit,
      sortDirections: ['descend', 'ascend'],
    },
    {
      ...singleColumns(['买卖方向', 'side', 90]),
      filters: [
        { text: '买入', value: 'BUY' },
        { text: '卖出', value: 'SELL' },
      ],
      onFilter: (value, record) => record?.side?.includes(value),
      render: (text) => text === 'BUY' ? <Tag color={'red'}>买入</Tag> : <Tag color={'green'}>卖出</Tag>
    },
    {
      ...singleColumns(['状态', 'orderStatus', 80]),
      render: (text) => renderOrderStatus(text)
    },
    {
      ...singleColumns(['委托编号', 'clientOrderId', 118]),
      render: (text, record) => renderShowDetail('id', record)
    },
    {
      ...singleColumns(['委托时间', 'formatDate', 120]),
      sorter: (a, b) => {
        let aTime = new Date(a.commissionTime).getTime();
        let bTime = new Date(b.commissionTime).getTime();
        return !isProductPage ? a.timeKey - b.timeKey : aTime - bTime;
      },
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
    },
    singleColumns(['备注', 'remarks', 130]),
  ];
  // 账户col
  const acc_col = _.filter(columns, o => o.key !== 'name');
  // 分笔col
  const columnsDetail = [
    singleColumns(['编号', 'clientOrderId', 110]),
    singleColumns(['数量', 'tradeQuantity', 72]),
    singleColumns(['价格', 'tradePrice', 72]),
    singleColumns(['金额', 'tradeAmount', 72]),
    {
      ...singleColumns(['时间', 'formatDate', 115]),
      sorter: (a, b) => a.timeKey - b.timeKey,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
    },
  ];

  useEffect(() => {
    if ((isProductPage && active === 'record') || (!isProductPage && active === 'record')) {
      beforeGet();
    }
    if (isHosting) {
      setorderList({ 'org_list': hostList });
    }
  }, [active, upcount]);

  // 获取持仓数据
  function beforeGet() {
    const validGet = isProductPage ? productId : subAccountId;
    if (validGet) {
      let params = {
        subAccountId: subAccountId,
        state: true,
        beginTime: recordState.begin,
        endTime: recordState.end,
        content: '',
        continuation: recordState.isTow
      }
      params.endTime = moment(params.endTime).add(1, 'd').format('YYYY-MM-DD');// 获取默认多加一天，服务器查询到当日00:00
      let pro_params = {
        productId,
        'beginTime': params.beginTime,
        'endTime': params.endTime,
        'state': params.state,
        'content': params.content,
        'continuation': recordState.isTow
      }
      _showList(isProductPage ? pro_params : params);
    }
  }
  // 获取记录及统计信息
  async function _showList(params) {
    recordState.loading = true;
    const res = isProductPage ? await getAllTrade(params, isReverse ? 'FC' : 'NOR') : await showList(params, isReverse ? 'FC' : 'NOR');
    if (_.get(res, 'code', '') === '200' || _.get(res, 'code', '') === '205') {
      const getList = _.get(res, 'data.result', []);
      let stCodeObj = {}; let newList = []; let newOrgList = []; let keyCount = 0;
      if (isValidArray(getList)) {
        const newTimeKeyList = createDatasTimeKey(getList, 'commissionTime', 'fms', 'asc');
        newTimeKeyList.map((item, i) => {
          const getObjCode = stCodeObj[item.stockCode];
          const newItem = { ...item, 'profit': _.get(item, 'profit') ?? 0, 'newKey': item.stockCode + i }; // profit没有会返回null，字段空=0
          newOrgList.push(newItem);
          if (!(item.stockCode in stCodeObj)) {
            newList[keyCount] = newItem;
            newList[keyCount].otherRecord = [];
            stCodeObj[item.stockCode] = keyCount;
            keyCount = keyCount + 1;
          } else {
            newList[getObjCode].otherRecord.push(newItem);
          }
        })
      } else {
        message.info(_.get(res, 'message', ''));
      }
      setorderList({
        list: newList,
        rate: _.get(res, 'data.rateResult', {}),
        org_list: newOrgList,
        exeRate: _.get(res, 'data.excessRateResult', {})
      });
      setOrderCount(orderCount + 1);
    } else {
      message.error(_.get(res, 'message', '获取错误！'));
    }
    recordState.loading = false;
  }
  //获取分笔成交记录
  async function _showTradeDetail(id, stock) {
    const res = await showTradeDetail(id, isReverse ? 'FC' : 'NOR');
    if (_.get(res, 'code', '') === '200') {
      const getData = _.get(res, 'data', []);
      if (isValidArray(getData)) {
        const newList = createDatasTimeKey(getData, 'transactTime', 'fms', 'asc');
        setDetails({ 'list': newList, 'stock': stock });
        setShow(true);
      } else {
        message.info('暂无分笔订单！');
      }
    }
  }

  const labelItems = [
    { name: recordState.begin, key: 'begin' },
    { name: recordState.end, key: 'end' },
  ];
  const newCol = (isProductPage || isHosting) ? columns : acc_col;
  const modal_props = { 'visible': show, 'title': null, 'footer': null, 'width': sceenWidth - 16 };
  const modal_table_props = { 'showHeader': true, 'pagination': false, 'size': 'small', 'bordered': false };
  return <>
    {!isHosting && <Row align='middle'>
      {labelItems.map(n => <Col key={'label' + n.key} span={8} style={{ textAlign: 'center' }}>
        <TextSelect name={n.name} onPress={() => {
          recordState.timeOpen = { open: true, value: n.name, stamp: moment(n.name).toDate(), key: n.key };
        }} />
      </Col>)}
      <Col span={8} style={{ textAlign: 'right' }}>
        <Space size={2}>
          <div style={{ padding: 4, color: recordState.isTow ? '#2774b8' : '#434343', fontWeight: recordState.isTow ? 600 : 400 }}
            onClick={() => {
              recordState.isTow = !recordState.isTow;
              beforeGet();
            }}
          >
            两日
          </div>
          <Button type='text' onClick={() => jumpRecord(
            _.get(orderList, 'rate', {}),
            { 'begin': recordState.begin, 'end': recordState.end }
          )}>
            {'统计*'}
          </Button>
        </Space>
      </Col>
    </Row >}

    {!isHosting && <NewDivider />}

    <Table
      rowKey="newKey"
      dataSource={_.get(orderList, 'org_list', [])}
      columns={newCol}
      scroll={{ x: colWidth(newCol) }}
      bordered={false}
      size='small'
      pagination={false}
      loading={recordState.loading}
    />

    <Modal {...modal_props}
      onCancel={() => setShow(false)}
    >
      <div style={{ marginBottom: 12 }}><Text strong>{_.get(details, 'stock', '')}</Text></div>
      <Table
        {...modal_table_props}
        rowKey="id"
        dataSource={_.get(details, 'list', [])}
        columns={columnsDetail}
        scroll={{ x: colWidth(columnsDetail), y: 520 }}
      />
    </Modal>

    <NewCalendar
      values={[recordState.begin, recordState.end]}
      show={_.get(recordState, 'timeOpen.open') ?? false}
      onCancel={() => recordState.timeOpen = false}
      onSubmit={(val) => {
        recordState.begin = val[0];
        recordState.end = val[1];
        recordState.timeOpen = { key: '', open: false, value: '', stamp: new Date() };
        beforeGet();
      }}
    />

    <FloatButton.BackTop />
  </>
}

export default Records;
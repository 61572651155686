import React, { useEffect, useState } from 'react';
import {
  Typography, Row, Col, Checkbox, message, Space, Segmented, Tabs, Button,
  List, FloatButton, Tag, Input
} from 'antd';
import {
  CaretDownOutlined, MoreOutlined, BlockOutlined, BarChartOutlined, MinusCircleOutlined, BranchesOutlined,
  PlusOutlined, SearchOutlined, CloseOutlined, CloseCircleOutlined, PlusCircleOutlined
} from '@ant-design/icons';
import { store } from '@/store/mobx';
import { useLocalObservable } from 'mobx-react';
import {
  showSubAccountHistory, showSingleProductHistory, showBatchProductHistory, showBatchSubAccountHistory, showIndexKline,
  generateNewHistory, showSingleProductHistoryVir, findFund, showFundKline
} from '@/api/main';
import { useReactive, useUnmount, useUpdateEffect, useDebounceFn } from 'ahooks';
import { INDEX_OPTIONS, DEFAUL_INDEX_CODE } from '@/utils/indexCode';
import {
  TWO_CHARTS, STATIC_RATE_COLOR, PRE_DAY_CN,
  calMaxMin, calNets, renderFloats, handleRetreat, calRate, handleIndexValue, createBaseLine, renderSlice, isFullTimeRange
} from '@/utils/chartInfo';
import { BT_FILTER_TYPE, isValidArray, isValidObj, isValidNumber, labelValues, isEmptyStringV2 } from '@/utils/utils';
import { Selections, NewDivider, MainSlider, BaseDrawer, FullScreenModal } from '@/utils/widgets';
import { handleCustomIndexPms, calCustomIndex } from '@/utils/list_utils';
import { CardSelection } from '../pages/comp/page_widgets';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const TODAY = moment().format('YYYY-MM-DD');
const DATE_OPTIONS = [labelValues(['今日', 'TODAY']), labelValues(['周', 'ONE_WEEK']),
labelValues(['月', 'ONE_MONTH']), labelValues(['季', 'THREE_MONTH']), labelValues(['半年', 'SIX_MONTH']),
labelValues(['年', 'ONE_YEAR']), labelValues(['三年', 'THREE_YEAR']), labelValues(['全部', 'ALL'])
];
const STEP_OPTIONS = [3, 5, 10, 20].map(n => labelValues([String(n), String(n)]));
const MA_OPTIONS = [5, 10, 20].map((n, i) => _.assign(labelValues([String(n) + '日', n]), { index: i }));
const MA_COLORS = { 5: '#6fc6d2', 10: '#72ad92', 20: '#7f4886' };
const VTYPES = {
  'vol': { itemStyle: { color: '#c3e071', opacity: 0.3 }, name: '成交量(万)', yname: '成交量', format: `{value}万`, rev: 'margin' },
  'margin': { itemStyle: { color: '#eec801', opacity: 0.3 }, name: '两融(亿)', yname: '两融', format: `{value}亿`, rev: 'vol' }
};
const CP_NAME = '对比';
const INDEX_COLOR_OBJ = {
  lineStyle: { color: '#B5495B', type: 'dashed', width: 1 },
  itemStyle: { color: '#B5495B' },
}
const GET_TYPE = { 'account': 'subAccountId', 'product': 'productId', 'vir': 'productId', 'fund': 'jid' };
const PAGEKEY_SHORT = { 'A': 'account', 'P': 'product', 'V': 'virtual' };
const VSTRING = 'V';
let timer = null; let timer2 = null;
// 创建option；叠加虚拟产品的选项
const createAccountState = (alls, virs) => {
  let virObj = {}; let virOptions = [];
  if (isValidArray(virs)) {
    virs.map(itm => {
      virOptions.push({
        'label': itm.name,
        'value': VSTRING + itm.id,
        'comp': <Tag color='orange'>虚拟</Tag>,
        'aindex': -1,
      });
      _.set(virObj, VSTRING + itm.id, itm.name);
    })
  };

  const concatProduct = _.concat(virOptions, _.get(alls, 'product'));
  return {
    'productObj': _.assign(_.get(alls, 'productObj'), virObj),
    'accObj': _.get(alls, 'accObj'),
    'accountOptions': _.get(alls, 'account'),
    'productOptions': concatProduct,
    'accLogin': _.get(alls, 'accLogin'),
    'productLogin': _.get(alls, 'productLogin'),
    'btCards': _.orderBy(_.filter(concatProduct, o => o.aindex !== 2), ['aindex'], ['asc'])
  }
};
//V字符的删减与判断
const trimId = (id) => parseInt(_.trim(id, VSTRING));
const isVid = (id) => _.includes(id, VSTRING);
const isFund = (id) => !isValidNumber(parseInt(id)) && !isVid(id) && id !== 0 ? true : false;
/* 
  产品/子账户对比页面；
  bt：成交量融资显示，可创建对比曲线；tamp：fc反采账户的对比
  240321 对比页面包含：产品、账户、fc反采、虚拟四种对比场景，三个平台共用；
*/
const TowCharts = (props) => {
  const mobxStore = useLocalObservable(() => store);
  const isBt = _.get(props, 'plateform', '') === 'BT' ? true : false;
  const isTamp = _.get(props, 'plateform', '') === 'TAMP' ? true : false;
  const [all_product_acc, set_all_product_acc] = useState(JSON.parse(JSON.stringify(mobxStore.allProductAcc)));
  const [accState, setAccState] = useState(createAccountState(all_product_acc, _.get(props, 'vOptions')));
  const [update, setUpdate] = useState(0);
  const [update2, setUpdate2] = useState(0);
  const [sliderValue, setSliderValue] = useState([]);
  const [sliderValue2, setSliderValue2] = useState([]);
  const [timeArr, setTimeArr] = useState([0]);
  const [timeNameArr, setTimeNameArr] = useState([]); // 完整时间数据
  const [fullSeries, setFullSeries] = useState([]); // 完整series数据
  const [fullSeriesRetreat, setFullSeriesRetreat] = useState([]); // 完整series数据
  const [rateDatas, setRateDatas] = useState({});
  const [checkboxValues, setCheckBoxValues] = useState([]);
  const [checkboxItem, setCheckBoxItem] = useState([]);
  const [option, setoption] = useState({
    ...TWO_CHARTS.main,
    grid: { left: '8%', right: isBt ? '8%' : '3%', bottom: '10%', top: '10%' },
  });
  const [option2, setoption2] = useState(TWO_CHARTS.retreat);
  const compareState = useReactive({
    type: _.get(props, 'plateform', '') !== 'TAMP' && _.get(props, 'pageKey', '') === PAGEKEY_SHORT['V'] ? 'ALL' : _.get(props, 'idxPms.dateType', 'TODAY'),
    getType: '', account: '', product: '',
    idxSymbol: DEFAUL_INDEX_CODE,
    visible: false, soptions: [], skey: '', vtype: 'vol',
    maVal: [0, 0, 0], ismore: false, moreKey: 'step', step: '3',
    fundShow: false, searchTxt: '', floading: false, fund: [], fundNames: {},
    diff_id: '', diffStatus: false, diffRate: isValidNumber(_.get(props, 'idxPms.rate')) ? _.get(props, 'idxPms.rate') * 100 : 0
  });
  const [compareData, setCompareData] = useState({ show: false });
  const [fundOptions, setFundOptions] = useState([]);
  const { run } = useDebounceFn(
    () => _findFund(),
    { wait: 800 },
  );
  const getPageKeys = _.get(props, 'pageKey', '');
  const isAccount = getPageKeys === PAGEKEY_SHORT['A'];
  const isProduct = getPageKeys === PAGEKEY_SHORT['P'];
  const isVirtual = getPageKeys === PAGEKEY_SHORT['V'];

  useUnmount(() => {
    timer && clearTimeout(timer);
    timer2 && clearTimeout(timer2);
  });

  // tab切换获取当前收益率数据
  useEffect(() => {
    if (props.active === 'compare') {
      _updatePage();
    }
  }, [props.active, props.upcount]);

  function _updatePage() {
    const hasAccObject = () => isValidObj(_.get(accState, isAccount ? 'accObj' : 'productObj'));
    const hasAccOptions = () => isValidArray(_.get(accState, isAccount ? 'accountOptions' : 'productOptions'));

    let is_get = false; let is_acc_get = false;
    const isActive = props.active === 'compare' ? true : false;
    if (isActive) {
      _getRates(isAccount ? 'account' : 'product');
      is_get = true;
      is_acc_get = hasAccObject() && hasAccOptions() ? false : true;
    }
    //【230926】账户改为mobx全局获取; bool的参数判断是否需要调用fc接口
    if (is_get && is_acc_get) {
      mobxStore._getProductAccount(_.get(props, 'plateform', '') === 'TAMP' ? true : false);
      // 延时获取更新
      timer = setTimeout(() => {
        let newProductAcc = JSON.parse(JSON.stringify(mobxStore.allProductAcc));
        set_all_product_acc(newProductAcc);
        setAccState(createAccountState(newProductAcc, _.get(props, 'vOptions')));
      }, 2000);
    }
  }
  //【新】 获取收益率，集合fc，虚拟，私募获取功能; 该方法集成三个平台的判断及id判断
  async function _getRates(type = '', gtype = '', id = 0) {
    const isSingle = gtype !== 'batch';
    // promise需要的获取单一产品的方法
    const singleGetRate = async (id, pms, isRev) => {
      const singleGet = type === 'account' ? await showSubAccountHistory(pms, isRev ? 'FC' : 'NOR') : await showSingleProductHistory(pms, isRev ? 'FC' : 'NOR');
      // 返回格式与批量返回相同 {id:{data数据}}
      return new Promise(resolve => resolve({ [id]: _.get(singleGet, 'data', {}) }));
    }
    // promise需要的获取单一产品的方法； 普通产品接口和虚拟产品接口
    const virtualGet = async (id, pms, isVir) => {
      const singleGet = isVir ? await showSingleProductHistoryVir(pms) : await showSingleProductHistory(pms)
      // 如果是单一获取，则返回数据; 虚拟统一返回虚拟字段
      return new Promise(resolve => resolve(
        isSingle ? _.assign(_.get(singleGet, 'data', {}), { 'is_virtual': isVir })
          : { [id]: _.get(singleGet, 'data', {}), 'is_virtual': isVir }
      ));
    };
    // 获取单一私募
    const singleGetFund = async (id, pms) => {
      const singleFund = await showFundKline(pms);
      return new Promise(resolve => resolve(
        isSingle ? _.assign(_.get(singleFund, 'data', {}), { 'is_fund': true })
          : { [id]: _.get(singleFund, 'data', {}), 'is_fund': true }));
    };
    const currentOptions = _.get(accState, type === 'account' ? 'accountOptions' : 'productOptions', []);
    const typeKeys = _.filter(currentOptions, o => o.centralizedLogin === 2).map(n => n.value); // 所有反采id
    // 处理接口参数； vOptions只在虚拟产品页面的props才有
    const getVirOptions = _.get(props, 'vOptions', []);
    const virIds = getVirOptions.map(itm => VSTRING + itm.id);
    const propsId = _.get(props, GET_TYPE[type], 0);
    const newId = id ? id : propsId;
    if (newId) {
      let params = {
        'dateType': compareState.type,
        'date': TODAY,
        [GET_TYPE[type]]: isVid(newId) ? trimId(newId) : newId
      };
      //id传入判断是否是特殊vid
      let did = propsId;
      if (isValidNumber(id)) {
        _.set(params, GET_TYPE[type], id);
        did = id;
      } else if (isVid(id)) {
        _.set(params, GET_TYPE[type], trimId(id));
        did = id;
      };
      // 收益率执行差异，对比带入获取参数
      if (!isFund(did)) {
        if (compareState.diffStatus && compareState.type !== 'TODAY') {
          _.set(params, 'rate', compareState.diffRate / 100);
          if (_.size(compareState.diff_id) > 0) { // id单次进行累加
            compareState.diff_id = compareState.diff_id + ',' + did;
          } else {
            compareState.diff_id = String(did)
          }
        } else {
          compareState.diff_id = '';
          compareState.diffStatus = false;
        };
      };
      // 判断是否批量
      const isNorBatch = gtype === 'batch' ? true : false;
      let needProGet = false; // 是否使用promise进行获取
      let proAll = []; // promise 数组
      // 当前选项中是否包含虚拟账户
      const isValidCheckbox = isValidArray(checkboxItem);
      const virArray = isValidCheckbox ? checkboxItem.map(c => {
        const kId = isVid(c.keyId) ? c.keyId : parseInt(c.keyId);
        return _.includes(virIds, kId);
      }) : [];
      const fundArray = isValidCheckbox ? _.concat(checkboxItem.map(c => isFund(c.keyId)), id ? isFund(id) : false) : [];
      // 当前选项中是否包含反采账户
      const revArray = isValidCheckbox ? checkboxItem.map(c => {
        const get_key_id = parseInt(c.keyId);
        const is_rev = _.get(accState, `${type === 'account' ? 'accLogin' : 'productLogin'}.${get_key_id}`) === 2 ? true : false;
        return is_rev;
      }) : [];
      //要使用promise的情况进行分别获取
      if (isSingle && _.includes(virIds, id)) {
        // id是虚拟产品
        proAll.push(virtualGet(id, params, true));
        needProGet = true;
      } else if (gtype === 'batch' && _.includes(virArray, true)) {
        // 批量（切换时间区间）；并且账户包含虚拟产品
        checkboxItem.map(c => {
          const kId = isVid(c.keyId) ? c.keyId : parseInt(c.keyId);
          const is_virtual = _.includes(virIds, kId);
          let singlePms = { ...params };
          if (isValidNumber(kId)) {
            _.set(singlePms, GET_TYPE[type], kId);
          } else if (isVid(kId)) {
            _.set(singlePms, GET_TYPE[type], trimId(kId));
          }
          proAll.push(virtualGet(kId, singlePms, is_virtual));
          needProGet = true;
        });
      } else if (!id && _.includes(virIds, propsId) && isSingle) {
        // 首次进入或updatePage，并且当前对比产品id是虚拟产品
        proAll.push(virtualGet(id, params, true));
        needProGet = true;
      } else if (!id && _.includes(typeKeys, propsId)) {
        // 首次进入或updatePage，并且是反采
        proAll.push(singleGetRate(propsId, params, true));
        needProGet = true;
      };
      if (_.includes(revArray, true) && isTamp) {
        checkboxItem.map(c => {
          const get_key_id = parseInt(c.keyId);
          const is_rev = _.get(accState, `${type === 'account' ? 'accLogin' : 'productLogin'}.${get_key_id}`) === 2 ? true : false;
          let singlePms = { ...params, [GET_TYPE[type]]: get_key_id };
          // console.log(get_key_id, singlePms, is_rev);
          proAll.push(singleGetRate(get_key_id, singlePms, is_rev));
          // 普通批量 = false；执行promise = true
          if (_.includes(typeKeys, get_key_id) && gtype === 'batch') {
            isNorBatch = false;
            needProGet = true;
          }
        });
      };
      if (_.includes(fundArray, true) && gtype === 'batch') {
        const curAllIds = checkboxItem.map(c => c.keyId);
        curAllIds.map(id => {
          if (isFund(id)) {
            let fundPms = {
              'dateType': params.dateType,
              'date': params.date,
              'jjdm': id
            };
            proAll.push(singleGetFund(id, fundPms));
          }
        });
        needProGet = true;
      } else if (isFund(id)) {
        let fundPms = {
          'dateType': params.dateType,
          'date': params.date,
          'jjdm': id
        };
        proAll.push(singleGetFund(id, fundPms));
        params.jid = id;
        needProGet = true;
      };
      // 普通批量时删除虚拟的id
      if (isNorBatch) {
        let newKeyIds = []; let diffStr = '';
        checkboxItem.map(c => {
          const unFund = !isFund(c.keyId);
          const unVir = !isVid(c.keyId);
          if (unFund) {
            diffStr = diffStr + c.keyId + ',';
          }
          if (unFund && unVir) {
            newKeyIds.push(c.keyId);
          };
          // 【bug-fix】 批量包含虚拟以及私募，则普通id也进行promise操作
          if (unFund && unVir && needProGet) {
            let normalParams = {
              ...params,
              'dateType': params.dateType,
              'date': params.date,
              [GET_TYPE[type]]: c.keyId
            };
            proAll.push(singleGetRate(c.keyId, normalParams));
          };
        });
        _.set(params, GET_TYPE[type], _.join(newKeyIds, ','));
        compareState.diff_id = diffStr;
      };

      let res = {};
      const isAccGet = type === 'account' ? true : false;
      if (isAccGet && !needProGet) {
        res = isNorBatch ? await showBatchSubAccountHistory(params) : await showSubAccountHistory(params);
      } else if (!isAccGet && !needProGet) {
        res = isNorBatch ? await showBatchProductHistory(params) : await showSingleProductHistory(params);
      }
      // 批量用单一接口获取数据，将使用response的统一格式进行处理
      if (needProGet) {
        const allResult = await Promise.all(proAll);
        let resData = {}
        allResult.map(result => {
          let newResult = {};
          // 对fund数据进行收益率计算
          if (_.get(result, 'is_fund')) {
            let dataSource = result;
            if (isNorBatch) {
              const getId = _.head(_.keys(result));
              dataSource = result[getId];
            };
            let newFundData = {
              'netValueList': _.get(dataSource, 'netValue', []),
              'timeList': _.drop(_.get(dataSource, 'timeList', [])),
            };
            if (isValidArray(newFundData.netValueList)) {
              const getNets = _.get(dataSource, 'netValue', []).map(n => parseFloat(n));
              _.set(newFundData, 'pointList', calRate(getNets, getNets[0]));
              _.set(newFundData, 'netValueList', getNets);
            };
            newResult = isNorBatch ? { [_.head(_.keys(result))]: newFundData } : newFundData;
          } else {
            newResult = result;
          }
          resData = _.assign(resData, newResult);
        });
        res = { 'code': '200', 'data': resData };
      }
      const idxRes = isTamp ? {} : await _handleIndexKline(params.date);
      if (_.get(res, 'code', '') === '200') {
        handleResData(
          res,
          _.assign(params, { 'id': _.get(params, GET_TYPE[type]), 'isBatch': isNorBatch }),
          idxRes
        );
      }
    }
  }
  // 处理指数数据
  async function _handleIndexKline(date, newDate) {
    let params = {
      'symbol': compareState.idxSymbol,
      'dateType': compareState.type,
      'date': date,
    };
    const idxPms2 = handleCustomIndexPms(params, _.get(props, 'customIndex', []));
    if (idxPms2.symbolInit) {
      compareState.idxSymbol = DEFAUL_INDEX_CODE;
    };
    const res = await showIndexKline(idxPms2.params, idxPms2.isBatch ? 'batch' : 'nor');
    // 格式为原始res，嵌套一层data字段
    let indexRes = idxPms2.isBatch
      ? { 'data': calCustomIndex(_.get(res, 'data', {}), idxPms2.rates, newDate, params.dateType) }
      : res;
    return indexRes;
  }
  // 统一处理返回数据
  async function handleResData(res, pms, idxResponse) {
    const is_batch = _.get(pms, 'isBatch');
    const is_reverse = _.get(pms, 'isReverse');
    const isNeedBatch = is_batch || is_reverse ? true : false;
    // 批量和反采都是以空创建数据；【tips】反采切换周期时直接清空累加数据，需要重新选择
    let initTemp = isNeedBatch ? {} : _.cloneDeep(rateDatas);
    const getData = _.get(res, 'data', {});
    // 处理批量数据
    if (isNeedBatch && isValidObj(getData)) {
      _.keys(getData).map(ids => {
        const sub_data = _.get(getData, ids, {});
        if (isValidObj(sub_data) && isValidArray(_.get(sub_data, 'timeList'))) {
          // 虚拟vid重新增加v字符
          const rates = handleRateData(sub_data, pms.dateType, !isFund(ids));
          _.set(initTemp, _.get(rates, 'is_virtual') ? VSTRING + ids : ids, rates);
        }
      })
    } else { // 单独增加的数据进行累加赋值
      if (!isValidObj(getData) || !isValidArray(_.get(getData, 'timeList'))) {
        message.info('暂无数据!');
        compareState.account = '';
        compareState.product = '';
        return;
      }
      const rates = handleRateData(getData, pms.dateType, !isFund(pms.id));
      _.set(initTemp, _.get(rates, 'is_virtual') ? VSTRING + pms.id : pms.id, rates);
    }
    let temp = sliceUnmatchDates(initTemp, pms.dateType); // 裁剪数据
    // 指数计算；使用main图表计算逻辑，计算指数曲线
    const newTimes = _.get(temp, 'times');
    const idxRes = isTamp ? await _handleIndexKline(pms.date, newTimes) : idxResponse;
    const newIndexs = handleIndexValue(pms.dateType, newTimes, _.assign(idxRes, { 'lastIndex': temp.dataSize }));
    _.set(temp, 'INDEX', {
      'retreat': handleRetreat(newIndexs.priceValues),
      'vol': newIndexs.vol,
      'margin': newIndexs.margin,
      ...newIndexs,
    });
    // 设置统一时间及slider
    setTimeArr(newTimes.map((n, i) => i));
    setTimeNameArr(newTimes);
    setRateDatas(temp);
    setUpdate(_.round(update + 0.1, 1));
    compareState.getType = pms.dateType;
  }
  // 对完整对象进行处理
  function sliceUnmatchDates(fullData = {}, dateType = '') {
    let final = {};
    let dataSize = 0;
    // 找到最大的时间数据
    let maxSize = 0; let maxTimeList = [];
    _.keys(fullData).map(ids => {
      const get_size = _.get(fullData, `${ids}.timeSize`, -1);
      if (maxSize === 0 || get_size > maxSize) {
        maxSize = get_size;
        dataSize = _.size(_.get(fullData, `${ids}.pointList`, []));
        maxTimeList = _.get(fullData, `${ids}.timeList`, []);
      };
      // 基金数据为基准，赋值999，产品数据按照基金的时间轴进行计算，计算逻辑可不变
      if (get_size === 999) {
        maxSize = 999;
        maxTimeList = _.get(fullData, `${ids}.timeList`, []);
      }
    });
    // 再次遍历数据,赋值final
    _.keys(fullData).map(ids => {
      final[ids] = _.cloneDeep(_.get(fullData, ids));
      //小于最大时间数据进行处理
      if (_.get(fullData, `${ids}.timeSize`, 0) !== maxSize) {
        const isValidShort = isValidObj(_.get(fullData, ids)) && 'short_time' in _.get(fullData, ids) ? true : false
        // 短时间轴(含前一交易日);[前一,9-10,9-11...] ; 
        //【bug-fix】:一直使用timeList字段，会在大于2条线对比下，使用修复过的时间进行对比，导致数据前移，对不上；使用short_time初始时间进行计算
        const getShortTime = isValidShort ? _.get(fullData, `${ids}.short_time`, []) : _.get(fullData, `${ids}.timeList`, []);
        //【bug-fix】 用长时间轴遍历，短时间轴有数据返回对应自己时间的index值;
        let sindex_array = []; // 记录数组方便找到最小值
        let timeIndex = maxTimeList.map(t => {
          let sindex = getShortTime.indexOf(t) - 1; // 下面提取pointsNet数据是需-1，减掉前一index是正确的数据位
          if (sindex > -1) {
            sindex_array.push(sindex);
          }
          return sindex; // 生成长轴时间数组，对应短轴的数据位置；例：[-1,7,8,9...,-2,-2,-2,-2]  -1 =前一，-2为未找到
        });
        // 重新计算短轴时间数据，【bug-fix】短轴时间可早于长轴时间，所以需要重新计算
        let shortNets = timeIndex.map(ti => ti > -1 ? _.get(fullData, `${ids}.pointsNet[${ti}]`) : '-');
        let shortStart = _.min(sindex_array) > 0 ? _.get(fullData, `${ids}.pointsNet[${_.min(sindex_array) - 1}]`) : _.get(fullData, `${ids}.preNet`);
        //【bug-fix】 实时点位不对称，preNet实时为0，所以计算错误；该情况默认start=pointNet第一位净值数据
        if (!shortStart && dateType === 'TODAY') {
          shortStart = _.get(fullData, `${ids}.pointsNet[0]`);
        }
        shortNets[0] = shortStart; // 前一交易日 = shortStart的值
        _.set(final, `${ids}.pointList`, calRate(shortNets, shortStart));
        _.set(final, `${ids}.pointRetreat`, handleRetreat(shortNets));
        _.set(final, `${ids}.timeList`, maxTimeList);
        _.set(final, `${ids}.timeIndex`, timeIndex);
        _.set(final, `${ids}.short_time`, getShortTime);// 获取到的初始时间数据
        _.set(final, `${ids}.short_net`, shortNets);
      }
    });
    final.times = maxTimeList;
    final.dataSize = dataSize;
    return final;
  }
  //处理获取的收益率数据
  function handleRateData(datas = {}, dateType = '', isNor = true) {
    const getPointList = _.get(datas, 'pointList', []);
    const pointNetList = _.get(datas, 'netValueList', []); // 返回的净值数据
    const preNetVal = _.get(datas, 'preNetValue', 0); // 返回的净值数据
    const getTimeList = _.get(datas, 'timeList', []);
    const isToday = dateType === 'TODAY' ? true : false;
    // 返回的data,object ; 【新】新增虚拟字段
    let dataObj = { 'is_virtual': _.get(datas, 'is_virtual') };
    let points = _.size(getPointList) > 0 ? getPointList.map(n => renderFloats(n, 1)) : [];
    let pointsNet = pointNetList;

    if (isToday) { // 实时收益率先计算净值，然后按照计算回撤
      pointsNet = calNets(points);
    }
    let retreat = handleRetreat(pointsNet); // 回撤数据
    // isNor=是否为普通产品；基金没有前一交易日，已返回第一天作为前一日=0
    dataObj.pointList = isToday ? points : _.concat(isNor ? [0] : [], points);
    dataObj.pointRetreat = isToday ? retreat : _.concat(isNor ? [0] : [], retreat);
    dataObj.timeList = isToday ? getTimeList : _.concat([PRE_DAY_CN], getTimeList);
    dataObj.preNet = isToday ? 0 : preNetVal;
    dataObj.timeSize = isNor ? _.size(getTimeList) : 999;
    _.set(dataObj, 'pointsNet', pointsNet);
    return dataObj;
  }
  // BT 获取对比曲线
  async function _generateNewHistory(slider) {
    // 第一位默认是前一交易日，即使裁切后，第一位默认是0，所以start日期要往后一位
    let time_range = [
      _.get(timeNameArr, `[${(slider ? slider[0] : sliderValue[0]) + 1}]`),
      _.get(timeNameArr, `[${slider ? slider[1] : sliderValue[1]}]`)
    ];
    let params = {
      "step": parseInt(compareState.step), //取值范围3,5,10,20
      "ids": _.join(checkboxValues, ','), //产品id
      "start": time_range[0],
      "end": time_range[1]
    };
    const res = await generateNewHistory(params);
    if (_.get(res, 'code', '') === '200') {
      setCompareData({
        show: true,
        timeList: _.concat([PRE_DAY_CN], _.get(res, 'data.timeList', [])),
        product: _.concat(['0'], _.get(res, 'data.product', [])),
        pointList: _.concat([0], _.get(res, 'data.pointList', [])).map(n => _.round(n, 4)),
        ids: checkboxValues
      })
      setUpdate2(_.round(update2 + 0.1, 1));
    }
  };
  // 搜索私募
  async function _findFund() {
    if (isEmptyStringV2(compareState.searchTxt)) {
      return
    };
    try {
      compareState.floading = true;
      const data = await findFund(compareState.searchTxt);
      if (_.get(data, 'code', '') === '200') {
        const newStockData = _.get(data, 'data', []).map(n => labelValues([n.jjjc, n.jjdm]));
        setFundOptions(newStockData);
      } else {
        message.error('获取出错！')
      }
      compareState.floading = false;
    } catch (error) {
      message.error('获取出错！')
    }
  }

  useEffect(() => {
    let myChart = props.myChart; let myChart2 = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('compareChartsTwo'));
    myChart2 = echarts.init(document.getElementById('compareChartsTwo_retreat'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    const getNewIdxCodeName = _.get(props, 'newIdxCodeName', {});
    const pcdtMap = _.get(accState, 'productObj'); const accMap = _.get(accState, 'accObj');
    let newOption = _.cloneDeep(option); let newOption2 = _.cloneDeep(option2);
    let newSeires = []; let retreatSeires = []; let min = 0; let max = 0;
    let legend = []; let legVals = [];
    // 加载完整生成serise数据
    //console.log(rateDatas)
    if (isValidObj(rateDatas)) {
      let color_count = 0;
      _.keys(rateDatas).map((keyId, index) => {
        if (!_.includes(['dataSize', 'times', 'INDEX'], keyId)) {
          let getName = isAccount ? _.get(accMap, keyId) : _.get(pcdtMap, keyId);
          const short_name = _.get(props, 'plateform', 'A') + (index + 1);
          const slice_name = sliceName(getName); // 裁切名字 ; 
          if (isFund(keyId)) {
            getName = _.get(compareState.fundNames, keyId);
          }
          let sobj = createBaseLine(STATIC_RATE_COLOR[color_count], {
            'name': short_name, 'yAxisIndex': 0, keyId,
            'isDot': isFund(keyId),
            'data': _.get(rateDatas, `${keyId}.pointList`, [])
          });
          newSeires.push(sobj);
          let robj = createBaseLine(STATIC_RATE_COLOR[color_count], {
            'name': short_name, keyId,
            'isDot': isFund(keyId),
            'data': _.get(rateDatas, `${keyId}.pointRetreat`, [])
          });
          retreatSeires.push(robj);
          legend.push({
            'name': slice_name,
            'fullName': getName,
            'shortName': short_name,
            'isrdiff': _.includes(compareState.diff_id, String(keyId)) && compareState.diffStatus ? true : false,
            keyId,
          });
          legVals.push(keyId);
          min = calMaxMin(robj.data, min, 'min');
          max = calMaxMin(robj.data, max, 'max');
          if (color_count >= 9) {
            color_count = 0;
          } else {
            color_count++;
          }
        } else if (keyId === 'INDEX') {
          newSeires.unshift({
            name: getNewIdxCodeName[compareState.idxSymbol],
            is_index: true,
            yAxisIndex: isBt ? 1 : 0,
            type: 'line', symbol: 'none',
            data: _.get(rateDatas, 'INDEX.indexValues', []),
            ...INDEX_COLOR_OBJ
          });
          if (isBt) {
            const volMargin = handleVolMargin('new');
            newSeires = _.concat(newSeires, volMargin.final);
            _.set(newOption, 'yAxis[2].min', volMargin.min);
            _.set(newOption, 'yAxis[2].max', volMargin.max);
          }
          retreatSeires.unshift({
            name: getNewIdxCodeName[compareState.idxSymbol],
            is_index_retreat: true,
            type: 'line', symbol: 'none', data: _.get(rateDatas, 'INDEX.retreat', []),
            ...INDEX_COLOR_OBJ
          });
          min = calMaxMin(_.get(rateDatas, 'INDEX.retreat', []), min, 'min');
          max = calMaxMin(_.get(rateDatas, 'INDEX.retreat', []), max, 'max');
        }
      });
    }
    // 赋值slider的滑动数据
    if (_.last(timeArr)) {
      setSliderValue([0, _.last(timeArr)]);
      setSliderValue2([0, _.last(timeArr)]);
    }
    setFullSeries(newSeires);
    setFullSeriesRetreat(retreatSeires);
    // 更新checkbox；默认全选
    setCheckBoxItem(legend);
    setCheckBoxValues(legVals);

    newOption.xAxis.data = _.get(rateDatas, 'times', []); newOption2.xAxis.data = _.get(rateDatas, 'times', []);
    // newOption.legend.data = legArr; newOption2.legend.data = legArr;
    newOption.series = newSeires; newOption2.series = retreatSeires;
    newOption2.yAxis.min = min; newOption2.yAxis.max = max;

    setoption(newOption); setoption2(newOption2);
    myChart.setOption(newOption, true); myChart2.setOption(newOption2, true);
    myChart.hideLoading();
    myChart.resize(); myChart2.resize();
    echarts.connect([myChart, myChart2]);
  }, [update]);

  useUpdateEffect(() => {
    let myChart = props.myChart; let myChart2 = props.myChart;
    myChart = echarts.init(document.getElementById('compareChartsTwo'));
    myChart2 = echarts.init(document.getElementById('compareChartsTwo_retreat'));
    const isFullRange = isFullTimeRange(sliderValue, timeArr); // 全部时间轴时，说明无需截取，用收盘价作为第一点位数据。
    let newOption = _.cloneDeep(option); let newOption2 = _.cloneDeep(option2);
    let min = 0; let max = 0;
    const indexSeries = _.filter(fullSeries, o => 'is_index' in o);
    const indexRetreat = _.filter(fullSeriesRetreat, o => 'is_index_retreat' in o);
    let filterSeries = _.concat(indexSeries, _.filter(fullSeries, o => _.includes(checkboxValues, o.keyId)));
    let filterRetreat = _.concat(indexRetreat, _.filter(fullSeriesRetreat, o => _.includes(checkboxValues, o.keyId)));
    let compareLine = createBaseLine(null, { name: CP_NAME, keyId: 'compare', lineStyle: { width: 1 } });
    const is_show_compare = _.get(compareData, 'show');
    if (isFullRange) {
      const fullTimes = _.cloneDeep(timeNameArr);
      const newFilters = handleVolMargin('full', filterSeries);
      if (is_show_compare) {
        compareLine.data = _.get(compareData, 'pointList', []);
        newOption.series = _.concat(newFilters.final, compareLine);
      } else {
        newOption.series = newFilters.final;
      }
      newOption2.series = filterRetreat.map(r => { // 回撤需要重新计算最大最小值，重新遍历
        min = calMaxMin(r.data, min, 'min');
        max = calMaxMin(r.data, max, 'max');
        return r;
      });
      _.set(newOption, 'yAxis[2].min', newFilters.min);
      _.set(newOption, 'yAxis[2].max', newFilters.max);
      newOption.xAxis.data = fullTimes;
      newOption2.xAxis.data = fullTimes;
    } else { // 裁切
      const sliceTimes = renderSlice(timeNameArr, sliderValue[0], sliderValue[1]);
      let sdata = [];
      // 【bug-fix】 裁切也需要执行一次addMaLine，否则裁切filter数据里面不会有ma均线数据
      _.concat(filterSeries, addMaLine()).map((sir, i) => {
        const sirKey = _.get(sir, 'key', '');
        let sliceRates = []; let sliceOrg = [];
        if ('is_index' in sir) {
          if (_.includes(_.keys(VTYPES), sirKey)) {
            // 成交量，两融数据截取
            const newSlice = handleVolMargin('slice');
            sliceRates = newSlice.final;
            sliceOrg = [];
            sir.itemStyle = _.get(VTYPES, `${compareState.vtype}.itemStyle`, {});
            _.set(newOption, 'yAxis[2].min', newSlice.min);
            _.set(newOption, 'yAxis[2].max', newSlice.max);
          } else if (_.includes(sirKey, 'vol_avg')) {
            // 成交量均线; 传入均线至，计算均线数据
            sliceRates = _.get(handleVolMargin('slice_avg', null, sir.volVal), 'final');
            sliceOrg = [];
          } else {
            // 处理指数数据
            const indexPrice = _.get(rateDatas, `INDEX.priceValues`, []);
            const slicePriceOrg = renderSlice(indexPrice, sliderValue[0], sliderValue[1]);
            const indexStart = _.get(slicePriceOrg, '[0]', 0);
            sliceRates = calRate(slicePriceOrg, indexStart);
            sliceOrg = slicePriceOrg;
          }
        } else {
          //对series进行遍历裁剪
          const pointsData = _.get(rateDatas, `${sir.keyId}.pointList`, []);
          const pointsNet = calNets(pointsData); // 计算净值
          const slicePoints = renderSlice(pointsData, sliderValue[0], sliderValue[1]); //没有开始点位时保留原收益率曲
          const sliceRateOrg = renderSlice(pointsNet, sliderValue[0], sliderValue[1]);
          // 【bug-fix】如计算时已添加0为第一位，只滑动右侧，开始的计算会出问题，所以slice时start默认支取第0位的数据
          const getStart = _.get(sliceRateOrg, `[0]`);
          sliceRates = isValidNumber(getStart) ? calRate(sliceRateOrg, getStart) : slicePoints; // 重新计算收益率
          sliceOrg = sliceRateOrg;
        }
        sdata.push({ ...sir, data: sliceRates }); // 【bug-fix】第一位无需重新计算0，否则会引起偏移
        // 回撤裁剪
        let fidx = _.findIndex(newOption2.series, o => o.name === sir.name);
        const sliceRetreat = handleRetreat(sliceOrg);
        if (fidx > -1) { // 回撤与series因成交量不对等，需要找到index再赋值
          _.set(newOption2, `series[${fidx}].data`, sliceRetreat); // 【bug-fix】裁切回撤无需头部加 0
        }
        min = calMaxMin(sliceRetreat, min, 'min');
        max = calMaxMin(sliceRetreat, max, 'max');
      });
      // 对比线；重新进行裁切计算
      if (is_show_compare) {
        compareLine.data = _.get(compareData, `pointList`, []);
        newOption.series = _.concat(sdata, compareLine);
      } else {
        newOption.series = sdata;
      }
      newOption.xAxis.data = sliceTimes;
      newOption2.xAxis.data = sliceTimes;
      // 【bug-fix】裁切情况下checkbox变化回测无变化
      newOption2.series.map(sir => {
        if (!_.includes(checkboxValues, sir.keyId) && !_.get(sir, 'is_index_retreat')) {
          _.set(sir, 'data', []);
        }
      });
    }
    const vistualData = handleVisualMap(filterSeries);
    if (isValidArray(vistualData.piecesDatas)) {
      _.set(newOption, 'visualMap', {
        show: false,
        dimension: 0,
        seriesIndex: _.findIndex(newOption.series, o => o.name === CP_NAME),
        pieces: vistualData.piecesDatas
      });
      compareState.colorObj = vistualData.reveseId;
    } else {
      Reflect.deleteProperty(newOption, 'visualMap');
    }
    // console.log('newOption', newOption)
    newOption2.yAxis.min = min; newOption2.yAxis.max = max;
    setoption(newOption); setoption2(newOption2);
    myChart.setOption(newOption, true); myChart2.setOption(newOption2, true);
    myChart.resize(); myChart2.resize();
  }, [update2]);
  // 处理对比线的不同颜色区间，根据product字段数据进行处理；【参考: echarts:Distribution of Electricity】
  function handleVisualMap(serise) {
    const getProducts = _.get(compareData, 'product', []);
    const isShowCompare = _.get(compareData, 'show', false);
    const filterSerise = _.filter(serise, o => !('is_index' in o));
    // console.log('filterSerise', filterSerise)
    // 【bug:】 visualMap 配置，数据小于10个，颜色变化会导致echart报错，故增加限制
    let piecesDatas = [];
    let reveseId = {}; //记录相反颜色的object，用于最后一段的对比
    if (isShowCompare && _.size(filterSerise) === 2 && _.size(getProducts) > 10) {
      // 获取两条线的信息；0:指数 1和2为两条对比线
      const line1 = _.get(filterSerise, `[0]`, {});
      const line2 = _.get(filterSerise, `[1]`, {});
      // rcolor 等于另外一条线的颜色,rid 是另外一个id值
      _.set(reveseId, String(line1.keyId), { rid: _.get(line2, 'keyId'), rcolor: _.get(line2, 'lineStyle.color'), color: _.get(line1, 'lineStyle.color') });
      _.set(reveseId, String(line2.keyId), { rid: _.get(line1, 'keyId'), rcolor: _.get(line1, 'lineStyle.color'), color: _.get(line2, 'lineStyle.color') });
      let curId = 0; let next = { gt: 0 };
      getProducts.map((p, i) => {
        if (curId !== p && curId !== 0) {
          const linecolor = _.get(reveseId, `${String(p)}.rcolor`, '');
          if (next.gt !== 0) {// 一段id值结束，gt为该段开始位，lte为结束位
            piecesDatas.push({ 'gt': next.gt, 'lte': i, 'color': linecolor, 'id': p });
          } else { // 第一段：0-第一位不同
            piecesDatas.push({ 'lte': i, 'color': linecolor, 'id': p });
          }
          next.gt = i; // 首次及以后出现不同的id值，记录index值，用于下一次反转的gt值
        }
        curId = p; // 上一位值
      });
      //console.log('reveseId', reveseId)
      if (isValidArray(piecesDatas)) { // 补充最后一段的数据
        piecesDatas.push({ 'color': _.get(reveseId, `${_.last(piecesDatas).id}.color`), 'gt': _.last(piecesDatas).lte });
      }
    }
    return { reveseId, piecesDatas }
  };
  // 裁剪名字
  function sliceName(name = '') {
    const n_size = _.size(name);
    if (n_size > 10) {
      return name.substring(0, 8) + '...' + name.substring(n_size - 3, n_size);
    } else {
      return name;
    }
  };
  // 计算成交量均线
  function calMaLine(lists = [], maNum = 5) {
    let final = [];
    if (isValidArray(lists) && maNum) {
      lists.map((n, i) => {
        const tooShort = i < maNum ? true : false
        final[i] = tooShort ? '-' : _.chain(lists)
          .slice(i - (maNum - 1), i + 1)
          .map(k => parseFloat(k))
          .sum() // 累加
          .divide(maNum) // 求平均
          .round(2)
          .value();
      })
    }
    return final;
  }
  // 增加ma线；多选；数组内保存ma的值，空=0
  function addMaLine() {
    const getVol = _.get(rateDatas, 'INDEX.vol', []);
    let final = [];
    if (compareState.vtype === 'vol') {
      compareState.maVal.map(m => {
        if (m !== 0) {
          let sobj = createBaseLine(MA_COLORS[m], {
            name: `成交量${m}日均`,
            volVal: parseInt(m), // ma数值
            yAxisIndex: 2,
            is_index: true,
            key: 'vol_avg' + '_' + m, // 均线特殊key值
            data: calMaLine(getVol, m)
          });
          final.push(sobj);
        }
      });
    }
    return final;
  }
  // 合并处理成交量、两融、均线
  function handleVolMargin(type = '', fserise = [], maVol = 0) {
    let finalSerise = []; let datas = [];
    const idxExtras = _.get(rateDatas, `INDEX.${compareState.vtype}`, []);
    if (type === 'new') {
      let newObj = {
        name: _.get(VTYPES, `${compareState.vtype}.name`, ''),
        key: compareState.vtype,
        is_index: true,
        yAxisIndex: 2,
        type: 'bar',
        itemStyle: _.get(VTYPES, `${compareState.vtype}.itemStyle`, {}),
        data: isValidArray(idxExtras) ? idxExtras : []
      };
      finalSerise = _.concat(newObj, addMaLine());
      datas = newObj.data;
    } else if (type === 'full') {
      finalSerise = _.concat(
        _.cloneDeep(fserise).map(item => {
          if (_.includes(['vol', 'margin'], item.key)) {
            item.key = compareState.vtype;
            item.name = _.get(VTYPES, `${compareState.vtype}.name`, '');
            item.itemStyle = _.get(VTYPES, `${compareState.vtype}.itemStyle`, {});
            item.data = _.get(rateDatas, `INDEX.${compareState.vtype}`, []);
            datas = item.data;
          }
          return item;
        }),
        addMaLine()
      );
    } else if (type === 'slice') {
      const sliceVal = renderSlice(idxExtras, sliderValue[0], sliderValue[1]);
      finalSerise = sliceVal;
      datas = sliceVal;
    } else if (type === 'slice_avg') {
      const getVol = _.get(rateDatas, 'INDEX.vol', []);
      const sliceVol = renderSlice(getVol, sliderValue[0], sliderValue[1]);
      finalSerise = calMaLine(sliceVol, maVol);
    }
    // 最大最小值; 两融最小drop零，最小值的 90%
    return {
      'final': finalSerise,
      'min': compareState.vtype === 'margin' ? _.round(_.min(_.drop(datas)) * 0.9) : 0,
      'max': _.round(_.max(datas) * 1.05)
    }
  };
  function updatePage() {
    setUpdate2(_.round(update2 + 0.1, 1));
  }
  // 滑动change
  function afterSliderChange(v) {
    setSliderValue(v);
    setSliderValue2(v);
    updatePage();
    if (_.get(compareData, 'show', false)) {
      _generateNewHistory(v);
    }
  }
  const onSubValuesChange = (checkedValues) => {
    setCheckBoxValues(checkedValues);
    updatePage();
  };
  // 切换选择
  function onSelectChange(v, type) {
    compareState.visible = false;
    let newVal = 0;
    if (compareState.skey === 'account' || compareState.skey === 'product') {
      newVal = v;
    };
    if (type === 'fund') {
      newVal = v;
      compareState['fund'] = v;
      compareState.fundNames[v] = _.chain(fundOptions).filter(o => o.value === v).head().get('label').value();
    } else {
      _.set(compareState, compareState.skey, v);
    }
    _getRates(
      isAccount ? 'account' : 'product',
      newVal ? 'single' : 'batch',
      newVal
    );
    compareState.fundShow = false;
  };
  // 删除chekcbox的选项
  function onDelCheckItem(itm) {
    let tempItem = _.cloneDeep(checkboxItem);
    let tempCheck = _.cloneDeep(checkboxValues);
    let tempRate = _.cloneDeep(rateDatas);
    _.pullAllBy(tempItem, [{ 'keyId': itm.keyId }], 'keyId');
    _.pullAll(tempCheck, [itm.keyId]);
    setCheckBoxItem(tempItem);
    setCheckBoxValues(tempCheck);
    setRateDatas(_.omit(tempRate, [itm.keyId]));
    updatePage();
  }
  //更多操作中的tab内容; 成交量于对比选择模块
  const moreObj = {
    'step': { txt: '对比', btn: '执行', dis: _.size(checkboxValues) !== 2 || _.includes(['ONE_WEEK', 'TODAY'], compareState.type) },
    'maVal': { txt: '成交量', btn: '确定', dis: false }
  };
  function renderMore(key) {
    return <div style={{ height: 290 }}>
      {key === 'step' && <Row align='middle'>
        <Col span={4}><Text>{_.get(moreObj, `step.txt`, '')}</Text></Col>
        <Col span={20}>
          <Segmented value={compareState.step} options={STEP_OPTIONS}
            onChange={v => compareState.step = v}
          />
        </Col>
      </Row>}
      {key === 'maVal' && <List
        size='small'
        header={<Text style={{ marginLeft: 13 }}>{_.get(moreObj, `maVal.txt`, '')}</Text>}
        dataSource={MA_OPTIONS}
        renderItem={(item) => {
          return <List.Item
            actions={[
              <Checkbox checked={_.includes(compareState.maVal, item.value)}
                onChange={(e) => compareState.maVal[item.index] = e.target.checked ? item.value : 0}
              />
            ]}
          >
            <div style={{ width: '100%', cursor: 'pointer' }}
              onClick={() => compareState.maVal[item.index] = compareState.maVal[item.index] ? 0 : item.value}
            >
              {_.get(item, 'label', '')}
            </div>
          </List.Item>
        }}
      />}

      <div style={{ position: 'absolute', bottom: 20, width: '100%' }}>
        <Button block type='primary' disabled={_.get(moreObj, `${key}.dis`, false)} onClick={() => {
          if (key === 'step') {
            _generateNewHistory();
          } else {
            setUpdate2(_.round(update2 + 0.1, 1));
          }
          compareState.ismore = false;
        }}>
          {_.get(moreObj, `${key}.btn`)}
        </Button>
      </div>
    </div>
  };
  // 差异输入
  function renderDiffInput() {
    return <div style={{ height: 290, padding: isBt && isProduct ? 0 : 8 }}>
      <Row align='middle' style={{ marginTop: 0 }}>
        <Col span={4}><Text>差异：</Text></Col>
        <Col span={4}>
          <Button type='text' icon={<MinusCircleOutlined />} onClick={() => compareState.diffRate = _.round(compareState.diffRate - 0.1, 1)} />
        </Col>
        <Col span={12}>
          <Text strong>{compareState.diffRate}%</Text>
        </Col>
        <Col span={4}>
          <Button type='text' icon={<PlusCircleOutlined />} onClick={() => compareState.diffRate = _.round(compareState.diffRate + 0.1, 1)} />
        </Col>
      </Row>
      <div style={{ position: 'absolute', bottom: 20, width: isBt && isProduct ? '100%' : '90%' }}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Button block type='primary' onClick={() => {
              compareState.diffStatus = true;
              compareState.ismore = false;
              _getRates('product', 'batch');
            }}>
              确定
            </Button>
          </Col>
          <Col span={12}>
            <Button block onClick={() => {
              compareState.diffStatus = false;
              compareState.ismore = false;
              compareState.diffRate = 0;
              compareState.diff_id = '';
              _getRates('product', 'batch');
            }}>
              重置
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  }
  // console.log(compareState.diff_id)
  const iconStyle = { color: '#ababab', fontSize: 14 };
  const labelItems = _.concat([
    { label: '周期', key: 'type', span: 6, icon: <CaretDownOutlined style={iconStyle} /> },
    { label: (isProduct || isVirtual) ? '产品' : '账户', key: (isProduct || isVirtual) ? 'product' : 'account', span: 6, icon: <PlusOutlined style={iconStyle} /> },
    { label: _.get(props, `newIdxCodeName.${compareState.idxSymbol}`, ''), key: 'idxSymbol', span: 8, icon: <CaretDownOutlined style={iconStyle} /> },
  ],
    !isTamp ? [{ label: '', icon: <MoreOutlined />, key: 'vol_item', span: 2 }] : [],
    [{ label: '', icon: <SearchOutlined />, key: 'fund_search', span: 2 }],
  );
  const selectObj = {
    'type': DATE_OPTIONS,
    'account': _.get(accState, 'accountOptions', []),
    'product': _.get(accState, 'productOptions', []),
    'idxSymbol': _.get(props, 'plateform', '') === 'TAMP' && compareState.type !== 'TODAY' ? _.concat(_.get(props, 'customIndex', []), INDEX_OPTIONS) : INDEX_OPTIONS
  };
  // 只有SIMX虚拟账户用cardSelection选择；
  const isCardVisible = isVirtual && compareState.visible && compareState.skey === 'product' ? true : false;
  // bt对所有option按照 虚拟-每日-回测 进行排序
  const newCardOption = isBt ? _.get(accState, 'btCards', []) : _.get(accState, 'productOptions', []);
  return <>
    <Row align='middle'>
      {labelItems.map(n => <Col key={'label' + n.key} span={n.span} style={{ textAlign: 'center' }}>
        <div onClick={() => {
          if (n.key === 'vol_item') {
            compareState.ismore = true;
          } else if (n.key === 'fund_search') {
            compareState.fundShow = true;
          } else {
            compareState.soptions = n.key === 'product' && isVirtual ? [] : selectObj[n.key];
            compareState.visible = true;
            compareState.skey = n.key;
          }
        }}>
          <Space>
            <Text strong>{n.key === 'type' ?
              _.chain(DATE_OPTIONS).filter(o => o.value === _.get(compareState, 'type')).head().get('label').value()
              : n.label}</Text>
            {n.icon}
          </Space>
        </div>
      </Col>)}
    </Row >

    <NewDivider />

    <div className='compare-bar'>
      <Checkbox.Group
        style={{ width: '100%' }} value={checkboxValues} onChange={onSubValuesChange}
      >
        <Row gutter={[8, 8]} style={{ paddingTop: 4, paddingBottom: 4 }}>
          {checkboxItem.map((itm, i) => {
            const get_kid = _.get(itm, 'keyId', '');
            return <Col span={24} key={i}>
              <Space size={4}>
                <Checkbox value={get_kid}>{`${_.get(itm, 'shortName')} : ${_.get(itm, 'fullName')}`}</Checkbox>
                {_.get(itm, 'isrdiff', false) && <Tag color="#005b7b">差异</Tag>}
                {!_.includes(get_kid, VSTRING) && !isValidNumber(parseInt(get_kid)) && <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => onDelCheckItem(itm)}
                >
                  <CloseCircleOutlined style={{ color: '#a1a1a1' }} />
                </div>}
              </Space>
            </Col>
          })}
        </Row>
      </Checkbox.Group>
    </div>

    <div style={{ display: 'flex' }}>
      <div
        id="compareChartsTwo"
        style={{ width: '99%', height: 305 }}
      />
    </div>

    <MainSlider
      isNotToday={compareState.type !== 'TODAY' ? true : false}
      timeArray={timeNameArr}
      max={_.last(timeArr) || 0}
      svalue={sliderValue2}
      onSliderChange={(v) => setSliderValue2(v)}
      onRedo={() => afterSliderChange([0, _.last(timeArr)])}
      onSliderAfterChange={(v) => afterSliderChange(v)}
    />

    <div style={{ display: 'flex' }}>
      <div
        id="compareChartsTwo_retreat"
        style={{ width: '99%', height: 300 }}
      />
    </div>

    <CardSelection
      visible={isCardVisible}
      options={newCardOption}
      onPress={onSelectChange}
      onClose={() => compareState.visible = false}
    />

    <Selections
      pkey={getPageKeys}
      visible={!isCardVisible && compareState.visible}
      isFilter={_.includes(['account', 'product'], compareState.skey) && isBt ? true : false}
      options={compareState.soptions}
      filterSegment={BT_FILTER_TYPE}
      filterOptions={all_product_acc}
      onPress={onSelectChange}
      onClose={() => compareState.visible = false}
    />

    <BaseDrawer
      visible={compareState.ismore}
      onClose={() => compareState.ismore = false}
      height={isBt && isProduct ? 387 : 225}
    >
      {isBt && isProduct ? <Tabs
        activeKey={compareState.moreKey}
        onChange={(key) => compareState.moreKey = key}
        centered
        items={[
          { key: 'step', label: <BlockOutlined />, children: renderMore('step') },
          { key: 'ma', label: <BarChartOutlined />, children: renderMore('maVal') },
          { key: 'diff', label: <BranchesOutlined />, children: renderDiffInput() },
        ]}
      /> : renderDiffInput()}
    </BaseDrawer>

    <FullScreenModal visible={compareState.fundShow}>
      <div style={{ position: 'fixed', top: 0, width: '92vw', paddingTop: 28, paddingBottom: 8, backgroundColor: 'white', zIndex: 4 }}>
        <Row align={'middle'}>
          <Col span={22}>
            <Input variant="filled"
              value={compareState.searchTxt}
              onChange={e => {
                compareState.searchTxt = e.target.value;
                run();
              }}
            // onBlur={() => _findFund()}
            />
          </Col>
          <Col span={2} style={{ textAlign: 'center' }} onClick={() => compareState.fundShow = false}>
            <CloseOutlined style={{ color: 'grey' }} />
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: 52 }}>
        <List
          size='small'
          dataSource={fundOptions}
          loading={compareState.floading}
          renderItem={(item) => {
            return <List.Item>
              <div style={{ width: '100%', cursor: 'pointer' }}
                onClick={() => onSelectChange(item.value, 'fund')}
              >
                {_.get(item, 'label', '')}
              </div>
            </List.Item>
          }}
        />
      </div>
    </FullScreenModal>

    {
      isBt && <FloatButton
        description={compareState.vtype === 'vol' ? '量' : '融'}
        shape="square"
        icon={<BarChartOutlined style={{ color: _.get(VTYPES, `${compareState.vtype}.itemStyle.color`, '') }} />}
        onClick={() => {
          compareState.vtype = _.get(VTYPES, `${compareState.vtype}.rev`, '');
          setUpdate2(_.round(update2 + 0.1, 1));
        }} />
    }
  </>
}

export default TowCharts;
import React, { useEffect, useState } from 'react';
import { useUpdateEffect } from 'ahooks';
import { Spin } from 'antd';
import { createBaseLine } from '@/utils/chartInfo';
import _ from 'lodash';
import * as echarts from 'echarts';

// 价格差区间时间统计图表
export default function DiffCountCharts(props) {
  const [option, setOption] = useState({
    xAxis: { type: 'category', data: [] },
    yAxis: { type: 'value', splitNumber: 3, },
    legend: { data: ['全部', '买入', '卖出'] },
    series: [],
    tooltip: { trigger: 'axis' }
  });

  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();
    }
    myChart = echarts.init(document.getElementById('diffCountCharts'));

    let newOption = _.cloneDeep(option);

    const getDatas = _.get(props, 'datas', []);
    //console.log(getDatas)
    let times = []; let sdata = [
      createBaseLine('#0060ab', { name: '全部' }),
      createBaseLine('#e82200', { name: '买入' }),
      createBaseLine('#009755', { name: '卖出' })
    ];
    if (_.size(getDatas) > 0) {
      getDatas.map(item => {
        times.push(_.get(item, 'date', ''));
        sdata[0].data.push(_.get(item, 'leftCal.total', 0) - _.get(item, 'leftCal.validCount', 0))
        sdata[1].data.push(_.get(item, 'leftCal.buyTotal', 0) - _.get(item, 'leftCal.vBuyTotal', 0))
        sdata[2].data.push(_.get(item, 'leftCal.sellTotal', 0) - _.get(item, 'leftCal.vSellTotal', 0))
      })
    };
    newOption.xAxis.data = times;
    newOption.series = sdata;
    //console.log('newOption', newOption)
    setOption(newOption);
    myChart.setOption(newOption, true);
    myChart.resize();
  }, [props.upCount]);

  useUpdateEffect(() => {
    if (props.active === '5') {
      let myChart = props.myChart;
      myChart = echarts.init(document.getElementById('diffCountCharts'));
      myChart.resize();
    }
  }, [props.upcount3]);

  return (
    <Spin spinning={_.get(props, 'loading', false)}>
      <div style={{ display: 'flex', marginTop: 28 }}>
        <div
          id="diffCountCharts"
          style={{ width: '100%', height: 300 }}
        />
      </div>
    </Spin>
  )
}
import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Space, Tag, FloatButton, Typography, Badge } from 'antd';
import { PieChartFilled, SyncOutlined } from '@ant-design/icons';
import { getAllPosition, showPositionReturn, showCurrentIndexSpeed } from '@/api/main';
import { isTablet } from 'react-device-detect';
import { PLATE_NAME } from '@/utils/indexCode';
import { isValidArray, singleColumns, colWidth } from '@/utils/utils';
import { handlePositions } from '@/utils/list_utils';
import { RateText, NewDivider } from '@/utils/widgets';
import _ from 'lodash';

const { Text } = Typography;
/**
 * 持仓列表页；产品账户托管通用模块
 */
const Position = ({
  active = '',
  pageKey = '',
  role = '',
  // plateform = '',
  productId = 0,
  subAccountId = 0,
  rises = [],
  hostData = {}, // 反采的持仓数据
  upcount = 0,
  isReverse = false,
  isTrading = false,

  goDisplay,
  jumpStock,
  onSync,
}) => {
  const [fundList, setfundList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [posMk, setPosMk] = useState(new Map());
  const [topMk, setTopMk] = useState([]);
  const isProductPage = pageKey === 'product' ? true : false;
  const isHosting = pageKey === 'hosting' ? true : false; // 托管
  // const is_not_bt = plateform !== 'BT' ? true : false;

  const fundColumns = [
    {
      ...singleColumns(['股票代码', 'stockCode', 100]), // 0  
      fixed: 'left',
      render: (text, record, index) =>
        <div onClick={() => _.isFunction(jumpStock) ? jumpStock(record, fundList) : {}} style={{ fontWeight: 500, lineBreak: 'anywhere' }}>
          <Badge dot={_.includes(rises, _.get(record, 'stockName'))} offset={[0, 4]}>
            <Text>{`${index + 1}) ${_.get(record, 'stockName')} `}</Text>
          </Badge>
          <br />
          <Text type='secondary' style={{ marginLeft: 4 }}>{`${_.get(record, 'stockCode')}`}</Text>
        </div>,
    },
    {
      ...singleColumns(['板块', 'one_plate', 95]), //1
      sorter: (a, b) => a.plateKey - b.plateKey,
    },
    singleColumns(['总市值', 'totalMarketValue', 95, 'left', ['descend', 'totalMarketValue']]), //2
    {
      ...singleColumns(['涨速', 'speedIncrease', 80, 'left']),//3
      sorter: (a, b) => a.speedIncrease - b.speedIncrease,
      render: (text) => <RateText rate={text} />
    },
    {
      ...singleColumns(['涨跌幅', 'isHighLimit', 100, 'left', ['descend', 'pctChg']]), //4
      render: (text, record) => {
        const pctVal = _.get(record, 'pctChg', 0);
        return <Space size='small'>
          <RateText rate={pctVal} />
          {text && role !== 'visitor' ? <Tag color={'red'}>{'涨停'}</Tag> : <></>}
        </Space>
      }
    },
    singleColumns(['总收益', 'totalProfit', 100], 'updown'), //5
    singleColumns(['总收益率', 'totalRevenue', 90, 'left', ['descend', 'totalRevenue'], 'rate'], 'updown'), // 6
    singleColumns(['股票数量', 'stockAmount', 85]),//7
    singleColumns(['可交易数量', 'availableStockAmount', 100]),//8
    singleColumns(['成本价', 'costPrice', 80]),//9
    singleColumns(['买入时间', 'formatDate', 120, 'left', ['descend', 'orderKey']]),//10
    {
      ...singleColumns(['持仓比', 'totalMarketRate', 80]),
      sorter: (a, b) => a.totalMarketRate - b.totalMarketRate,
      render: (text) => _.round(text, 2) + '%'
    }, // 11
    {
      ...singleColumns(['涨速(板)', 'plate', 85]),  // 12
      sorter: (a, b) => a.plateSpeed - b.plateSpeed,
      render: (text, record) => <RateText rate={_.get(record, 'plateSpeed')} restStyle={{ fontWeight: 400 }} />
    },
    {
      ...singleColumns(['持仓比(板)', 'plate', 95]),  // 13
      sorter: (a, b) => a.plateKey - b.plateKey,
      render: (text, record) => <Text type='secondary'>{posMk.get(_.get(record, 'one_plate_code'))}%</Text>
    },
  ];

  useEffect(() => {
    if (active === 'position') {
      if (isHosting) {
        const getList = _.get(hostData, 'list', []);
        const getMkMap = _.get(hostData, 'mkMap', new Map());
        setfundList(getList);
        setPosMk(getMkMap);
        createTopMarket(getMkMap, _.get(hostData, 'speeds'));
      } else {
        getPositionDatas();
      }
    }
  }, [active, upcount]);

  // 获取持仓数据
  async function getPositionDatas() {
    setLoading(true);
    const res = isProductPage
      ? await getAllPosition({ productId }, isReverse ? 'FC' : 'NOR')
      : await showPositionReturn({ subAccountId }, isReverse ? 'FC' : 'NOR');
    const res2 = isTrading ? await showCurrentIndexSpeed() : {};
    if (_.get(res, 'code', '') === '200') {
      const getPosData = _.get(res, 'data', [])
      const getSpeed = _.get(res2, 'data', {});
      const newPosition = handlePositions(getPosData, getSpeed);
      setfundList(newPosition.newData);
      setPosMk(newPosition.marketMap);
      createTopMarket(newPosition.marketMap, getSpeed);
    }
    setLoading(false);
  }
  // 生成排序板块持仓比的数组
  function createTopMarket(map, speed = {}) {
    let arrMarket = Array.from(map);
    arrMarket.sort((a, b) => b[1] - a[1]);
    const tops = arrMarket.map(n => {
      return {
        'plate': _.get(PLATE_NAME, n[0]),
        'val': n[1],
        'speed': _.round(_.get(speed, n[0], 0) * 100, 2)
      }
    });
    const mobileTop = _.size(tops) > 2 ? _.slice(tops, 0, 3) : [];
    const padTop = _.size(tops) > 3 ? _.slice(tops, 0, 4) : [];
    setTopMk(isTablet ? padTop : mobileTop);
  };
  // 持仓卡片class
  function renderMkCardClass(v) {
    let initClass = 'topMk-card'
    if (v > 0) {
      initClass = initClass + ' tmkc-red';
    } else if (v < 0) {
      initClass = initClass + ' tmkc-green';
    }
    return initClass;
  }
  // col的index值静态数组，表示先后顺序
  const pro_col = [0, 1, 12, 11, 3, 4, 13, 2, 6, 7, 8, 9, 10];
  const acc_col = [0, 1, 12, 11, 4, 2, 13, 5, 6, 7, 8, 9, 10];
  const newColIndex = (isProductPage || isHosting) ? pro_col : acc_col;
  let newCol = newColIndex.map(idx => fundColumns[idx]);
  if (!isTrading) { // 盘后不显示涨速
    newCol = _.without(newColIndex, 3, 12).map(idx => fundColumns[idx]);
  };
  return <>
    <Row style={{ marginTop: 4, marginBottom: 8 }}>
      <Col span={12} style={{ paddingLeft: 16 }}>
        <Text type='secondary'>仓位比例:
          {isValidArray(fundList) ? _.chain(fundList).map(n => n.totalMarketRate).sum().round(2).value() : '0'}
          % </Text>
      </Col>
      <Col span={12} style={{ textAlign: 'right', paddingRight: 10 }}></Col>
    </Row>

    {isValidArray(topMk) && <Row gutter={[16, 16]} style={{ paddingLeft: 10, paddingRight: 10 }}>
      {topMk.map((mk, i) => {
        return <Col span={8} key={'plate' + i}>
          <div className={renderMkCardClass(_.get(mk, 'speed'))}>
            <Space direction='vertical'>
              <Text>{_.get(mk, 'plate', '')}</Text>
              <Text strong style={{ fontSize: 16 }}>{_.get(mk, 'val')}%</Text>
            </Space>
          </div>
        </Col>
      })}
    </Row >}

    <NewDivider />

    <Table
      rowKey="stockCode"
      dataSource={fundList}
      columns={newCol}
      scroll={{ x: colWidth(newCol) }}
      loading={loading}
      bordered={false}
      size='small'
      pagination={false}
    />

    <FloatButton.Group>
      <FloatButton.BackTop />
      <FloatButton icon={<SyncOutlined />} onClick={() => {
        if (isHosting) {
          onSync()
        } else {
          getPositionDatas();
        }
      }} />
      {!isHosting && <FloatButton
        icon={<PieChartFilled style={{ color: '#2774b8' }} />}
        onClick={goDisplay}
      />}
    </FloatButton.Group>
  </>
}

export default Position;
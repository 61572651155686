import React, { useEffect } from 'react';
import { Row, Col, Typography, Modal, Button, InputNumber, Card, List, Drawer } from 'antd';
import { EditOutlined } from '@ant-design/icons';
// import { isValidString } from '@/utils/utils';
import { useReactive } from 'ahooks';
// import moment from 'moment';
import _ from 'lodash';
import '../pages.scss';

const { Text } = Typography;
// 交易输入价格成交量modal
const TradeInputModal = ({ show = false, inits = {}, onOk, onClose }) => {
  const inputState = useReactive({ price: 0, vol: 0 })
  //弹出层打开，赋值range的值
  useEffect(() => {
    if (show) {
      inputState.price = _.get(inits, 'price', 0);
      inputState.vol = _.get(inits, 'volumn', 0);
    }
  }, [show]);

  const inputModalProps = { title: null, footer: null, keyboard: false, maskClosable: true, centered: true };
  const inputProps = { bordered: false };
  const editIcon = <EditOutlined style={{ color: '#0859ab' }} />
  return <Modal
    {...inputModalProps}
    onCancel={onClose}
    open={show}
    style={{ padding: 8 }} //top: 25 
  >
    <Row style={{ marginTop: 35 }} align='middle'>
      <Col span={6} style={{ textAlign: 'center' }}>
        <Text>价格</Text>
      </Col>
      <Col span={16}>
        <InputNumber {...inputProps} min={0} value={inputState.price} onChange={v => inputState.price = v} />
      </Col>
      <Col span={2}>{editIcon}</Col>
    </Row>
    <Row style={{ marginTop: 12 }} align='middle'>
      <Col span={6} style={{ textAlign: 'center' }}>
        <Text>数量</Text>
      </Col>
      <Col span={16}>
        <InputNumber {...inputProps} min={100} value={inputState.vol} onChange={v => inputState.vol = v} />
      </Col>
      <Col span={2}>{editIcon}</Col>
    </Row>
    <Row justify='space-around' style={{ marginTop: 24 }}>
      <Col span={10}>
        <Button block onClick={onClose}>
          取消
        </Button>
      </Col>
      <Col span={10}>
        <Button block type='primary' onClick={() => onOk({ 'price': inputState.price, 'vol': inputState.vol })}>
          {_.get(inits, 'btnTxt', '确定') ?? '确定'}
        </Button>
      </Col>
    </Row>
  </Modal>
}

// 卡片式drawer选框
const CardSelection = ({
  visible = false,
  options = [],
  title = { left: null, right: null },

  onClose,
  onPress,
}) => {

  const selectProps = {
    'placement': 'bottom', 'open': visible, 'title': null, 'keyboard': false, 'closeIcon': null,
    'bodyStyle': { padding: 8, backgroundColor: '#f5f5f5' }, 'height': 550
  };
  const gridObj = { gutter: 8, xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 };
  const getTitleLeft = _.get(title, 'left'); // 非空显示标题
  return <Drawer
    {...selectProps}
    onClose={onClose}
  >
    {getTitleLeft ? <Row style={{ marginTop: 2, marginBottom: 8 }}>
      <Col span={12} style={{ paddingLeft: 8 }}>{getTitleLeft}</Col>
      <Col span={12} style={{ textAlign: 'right' }}>{_.get(title, 'right', '')}</Col>
    </Row> : <div style={{ height: 16 }}></div>}
    <List
      grid={gridObj}
      dataSource={options}
      renderItem={(item) => {
        // item.comp字段直接显示组件，上层编辑样式
        return <List.Item>
          <Card bodyStyle={{ padding: 8 }}>
            <Row align='middle'
              style={{ cursor: 'pointer', paddingTop: 4, paddingBottom: 4 }}
              onClick={() => onPress(item.value)}
            >
              <Col span={18} style={{ paddingLeft: 8 }}>
                <Text>{_.get(item, 'label', '')}</Text>
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                {_.get(item, 'comp', '')}
                {/* {_.get(typeObj, item.type)} */}
              </Col>
            </Row>
          </Card>
        </List.Item>
      }}
    />
  </Drawer>
}

export {
  TradeInputModal,
  CardSelection
}
import React, { useEffect, useState } from 'react';
import { message, Layout, Button, Modal } from 'antd';
import { ExclamationCircleFilled, LoginOutlined, AccountBookFilled, HomeOutlined } from '@ant-design/icons';
import { Route, Redirect, Switch } from "react-router-dom";
import { useLocalObservable, Observer } from 'mobx-react';
import { store } from '@/store/mobx';
import { isMobile } from 'react-device-detect';
import { routeConfig } from '@/router/route.config';
import { isValidString } from '@/utils/utils';
import { useUnmount } from 'ahooks';
import _ from 'lodash';

const { Header, Content, Footer } = Layout;
message.config({ maxCount: 2 });
const topBtnProps = (marginRight = 0) => {
  return { size: 'small', ghost: true, type: 'text', style: { marginRight } }
}
/**
 *  home导航-layout页面，并监听全局mobx变化，userLogin清空后会执行回到登录界面;
 */
const Home = (props) => {
  const mobxStore = useLocalObservable(() => store);
  const [userInfo] = useState(JSON.parse(JSON.stringify(mobxStore.userInfo)) ?? {});
  const [userInfoFC] = useState(JSON.parse(JSON.stringify(mobxStore.userInfoFC)));
  const [userRole] = useState(_.get(userInfo, 'role', ''));
  const userPlateform = _.get(userInfo, 'plateform', '');
  const isTrader = userRole === 'trader' ? true : false;
  const isFcLogin = isValidString(_.get(userInfoFC, 'token', ''));

  useEffect(() => {
    // 监听token是否超时，会自动退出到登录
    if (!mobxStore.userLogin) {
      props.history.replace('/login');
      message.info('请重新登录！')
    }
  }, [props, mobxStore]);
  // home页面离开直接清空token信息
  useUnmount(() => {
    mobxStore.clearUserToken();
  });

  function checkRole(role) {
    return userRole === role ? true : false
  }

  function backAsset() {
    const getPath = _.get(props, 'location.pathname', '');
    if (getPath !== '/mobile/assets') {
      props.history.replace('/mobile/assets');
    }
  }

  function logout() {
    mobxStore.cancelLogin();
    mobxStore.changeAllProducts({});
    props.history.replace('/login');
  }

  function confirmLogout() {
    Modal.confirm({
      title: '是否退出登录?',
      icon: <ExclamationCircleFilled />,
      centered: true,
      onOk: () => logout(),
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const headerStyle = { display: 'flex', alignItems: 'center', height: 35 };
  return <Observer>
    {() => (
      <Layout className="layout">
        <Header style={{ ...headerStyle, backgroundColor: isTrader ? '#fbf4e9' : '#e1eafd' }}>
          <div style={{ width: '100%', textAlign: 'right' }}>
            <Button {...topBtnProps(8)}
              icon={<HomeOutlined style={{ color: '#b5b5b5' }} />}
              onClick={backAsset}
            />
            {userPlateform === 'TAMP' && <Button
              {...topBtnProps(8)}
              icon={<AccountBookFilled style={{ color: isFcLogin ? '#2774b8' : '#b5b5b5', fontWeight: 600 }} />}
              onClick={() => {
                message.info(isFcLogin ? '反采账户已登录！' : '反采账户未登录！');
              }}
            />}
            <Button {...topBtnProps()}
              icon={<LoginOutlined style={{ color: '#ff4c4e', fontWeight: 600 }} />}
              onClick={confirmLogout}
            />
          </div>
        </Header>
        <div className={isTrader ? 'content-background-trader' : 'content-background'}></div>
        <Content style={{ padding: '0 12px', marginBottom: 30, zIndex: 2, minHeight: isMobile ? '85vh' : '87vh' }}>
          <Switch>
            {
              routeConfig[1].routes.map((item, index) => {
                return <Route key={'menu' + index} path={item.path} component={item.component} />
              })
            }
            {
              (checkRole('fund_manager') || checkRole('visitor')) &&
              <Redirect to={{ pathname: "/mobile/assets" }} />
            }
          </Switch>
        </Content>
        <Footer style={{ textAlign: 'center', fontSize: 12 }}>
          COPYRIGHT © 2024 程创（北京）科技有限公司
        </Footer>
      </Layout>
    )}
  </Observer >

}
export default Home;
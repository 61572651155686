import React, { useState } from 'react';
import { Row, Col, Typography, Space, Segmented, List, message, Descriptions, Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { store } from '@/store/mobx';
import { useLocalObservable } from 'mobx-react';
import { showPositionReturn, klineDetail } from '@/api/main';
import { buy, sell, market, showList, cancel } from '@/api/stock'; // 股票池接口  getCurrentPositionStocks  getCurrentStocks
import { useMount, useReactive, useInterval, useUnmount } from 'ahooks';
import { labelValues, isValidArray, isValidString, isValidNumber, renderHugeFund, createDatasTimeKey } from '@/utils/utils';
import { NewCard, HeaderIcons, RateText, NewDivider, LabelValueComp, EasyAccordion, ValuesColor } from '@/utils/widgets';
import { cryptoDatas } from '@/utils/cryptoUtils';
import KlineMini from './comp/KlineMini';
import { TradeInputModal } from './comp/page_widgets';
import moment from 'moment';
import _ from 'lodash';
import './pages.scss'

const { Text } = Typography;
const MS_FORMAT = 'YY-MM-DD HH:mm:ss:SSS';
const DAY_FORMAT = 'YYYY-MM-DD';
const TODAY = moment().format(DAY_FORMAT);
const TYPE_OPTIONS = [labelValues(['买入', 'buy']), labelValues(['卖出', 'sell'])];
const SHOWTYPE_OPTIONS = [labelValues(['持仓', 'position']), labelValues(['记录', 'record'])];
const LIST_TITLE = [['股票', 6], ['收益/收益率', 6], ['持仓/可用', 6], ['买入时间', 6]];
const RECORD_TITLE = [['股票', 6], ['委托/成交价', 6], ['委托/成交量', 6], ['状态', 6]];
const LIST_RECORD_KEYS = {
  'record': ['price', 'avgPrice', 'quantity', 'cumQty'],
  'position': ['totalProfit', 'totalRevenue', 'stockAmount', 'availableStockAmount'],
}
const COLORS = ['#f36129', '#1677ff'] // 涨、跌
const STATUS_COLOR = { '完成': 'green', '未完成': 'red' };
const VOL_ITEM = [labelValues(['全仓', 1]), labelValues(['半仓', 2]), labelValues(['1/3', 3]), labelValues(['1/4', 4])];
const PRICE_TXT = { 'limit': '限价单', 'market': '市价单' };
const PRICE_REVERSE = { 'limit': 'market', 'market': 'limit' };
const renderFloat = (v = 0, f = 2) => parseFloat(v).toFixed(f);

let timer = null;
/* 
  交易员交易页面；买入卖出撤单；[除股票池部分逻辑，其他正常交易逻辑保留] 
*/
const TradingPage = (props) => {
  const mobxStore = useLocalObservable(() => store);
  const routeDatas = _.get(props, 'location.state', {});
  const [fundList, setfundList] = useState([]);
  const tradeState = useReactive({
    stockName: '股票', symbol: '300000', price: 0, volumn: 100, rate: 0, priceType: 'limit',
    type: 'buy', market: {}, isETF: 0, expend: false, stock: {}, showType: 'position',
    recordPms: {}, intrade: JSON.parse(JSON.stringify(mobxStore.inTrading)), init: true
  });
  const [update, setUpdate] = useState(0);
  const [kvalue, setKvalue] = useState({});
  const [orderList, setorderList] = useState({});
  const [clientOrderIdList, setclientOrderIdList] = useState([]);
  const [inter, setInter] = useState(undefined);
  const [inter2, setInter2] = useState(undefined);
  const [visible, setVisible] = useState({ show: false, value: {} });
  const getId = _.get(routeDatas, 'subAccountId', 0);

  useMount(() => {
    if (!isValidString(tradeState.intrade)) {
      mobxStore._getTradingInfo();
      // 延时获取更新
      timer = setTimeout(() => {
        let newIntrade = JSON.parse(JSON.stringify(mobxStore.inTrading));
        tradeState.intrade = newIntrade;
      }, 2500);
    }
    _showPositionReturn();
    _showList();
  });

  useUnmount(() => {
    setInter(undefined);
    setInter2(undefined);
    timer && clearTimeout(timer);
  })
  // 每5秒更新市值信息
  useInterval(() => {
    _market();
  }, inter);
  // 每60秒更新k线数据
  useInterval(() => {
    _klineDetail();
  }, inter2);
  // 获取持仓数据
  async function _showPositionReturn() {
    if (!getId) {
      return
    }
    const openMoment = moment(TODAY + ' 09:00:00');
    const res = await showPositionReturn({ subAccountId: getId });
    if (_.get(res, 'code', '') === '200') {
      const getData = _.get(res, 'data', []);
      let newData = []; let plateMap = new Map(); let count = 1;
      let plateSingleMap = new Map();
      newData = isValidArray(getData) ? getData.map(n => {
        const getPlate = _.get(n, 'plate', null);
        const plateArr = _.split(getPlate, ','); // 板块拆分为数组
        let newPlateKey = 0;
        if (getPlate && !plateMap.has(getPlate)) {
          plateMap.set(getPlate, count);
          newPlateKey = count;
          count++;
        } else if (getPlate && plateMap.has(getPlate)) {
          newPlateKey = plateMap.get(getPlate);
        };
        // 多个板块逗号拆分，map内不重复的所有板块
        if (isValidArray(plateArr)) {
          plateArr.map(a => {
            if (!plateSingleMap.has(a)) {
              plateSingleMap.set(a, count);
            }
          })
        }
        const buyDate_moment = moment(_.get(n, 'buyDate', ''));
        const finalItem = {
          ...n,
          'plateKey': newPlateKey,
          'plate_arr': plateArr,
          'formatDate': buyDate_moment.format(MS_FORMAT),
          'totalRevenue': _.round(_.get(n, 'totalRevenue', 0) * 100, 2),
          'formatDate_day': buyDate_moment.format(DAY_FORMAT), // 区分天
          'orderKey': openMoment.diff(buyDate_moment, 'millisecond') // 排序用
        }
        return finalItem;
      }) : [];
      let optionsPlate = []; // 将map转化成options的数组
      Array.from(plateSingleMap)?.map(arr => {
        const plItem = _.head(arr);
        if (isValidString(plItem)) {
          optionsPlate.push(labelValues([plItem, plItem]));
        }
      })
      setfundList(newData);
      tradeState.stock = _.head(newData);
      _market();
      _klineDetail();
      if (tradeState.init) {
        tradeState.init = false;
        if (tradeState.intrade === 'trading') {
          setInter(5000);
          setInter2(60000);
        }
      }
    }
  }
  // 市值接口及当前价格信息接口
  async function _market() {
    if (!getId) {
      return
    }
    const newRecord = _.cloneDeep(tradeState.stock);
    if (newRecord.stockName.indexOf('ETF') !== -1 || newRecord.stockName.indexOf('LOF') !== -1) {
      tradeState.isETF = 1;
    }
    let res = null;
    if (!newRecord.tsCode) {
      res = await market({ 'subAccountId': getId, 'tsCode': newRecord.stockCode });
    } else {
      res = await market({ 'subAccountId': getId, 'tsCode': newRecord.tsCode });
    }
    if (_.get(res, 'code', '') === '200') {
      if (res.data === null) {
        return message.info(res.message);
      }
      const getMarket = _.get(res, 'data.marketData', {});
      tradeState.market = _.get(res, 'data.marketData', {}); // 完整市值信息
      // 股票名称及代码
      tradeState.symbol = _.get(getMarket, 'tsCode') ? _.get(getMarket, 'tsCode') : _.get(getMarket, 'stockCode', '');
      tradeState.stockName = _.get(newRecord, 'stockName', '');
      // 计算涨跌幅
      const match = _.get(getMarket, 'match', 0) || 0;
      const preClose = _.get(getMarket, 'preClose', 0) || 0;
      tradeState.rate = (match - preClose) / preClose * 100;
      // 赋值价格
      if (tradeState.type === 'buy') {
        tradeState.price = renderFloat(_.get(getMarket, 'askPrice[0]'), 3)
      } else {
        tradeState.price = renderFloat(_.get(getMarket, 'bidPrice[0]'), 3)
      }
    }
  }
  // 获取K线数据；分钟/日K
  async function _klineDetail() {
    const newSymbol = _.get(tradeState.stock, 'stockCode');
    if (newSymbol) {
      let params = {
        symbol: newSymbol,
        beginDate: TODAY,
        endDate: TODAY,
        type: 'minute'
      }
      const res = await klineDetail(params);
      if (_.get(res, 'code', '') === '200') {
        let timeList = [];
        let newValueList = [];
        let priceList = [];
        if (isValidArray(_.get(res, 'data.pointList'))) {
          _.get(res, 'data.pointList', []).map(n => { // time open close high low revenue volume amount(成交额)
            timeList.push(moment(n[0]).format('HH:mm:ss'));
            priceList.push(parseFloat(n[2]));
            newValueList.push(_.drop(n));
          })
        }
        setKvalue({
          'time': timeList,
          'kval': newValueList,
          'cal': {
            high: _.max(priceList),
            low: _.min(priceList),
            startPrice: _.get(res, 'data.preClose', 0)
          }
        });
        setUpdate(_.round(update + 0.1, 1));
      } else {
        message.info(_.get(res, 'message', '获取失败！'));
      }
    }
  }
  //获取交易记录
  async function _showList() {
    if (!getId) {
      return
    }
    let params = {
      subAccountId: getId,
      state: false,
      beginTime: moment().subtract(7, 'd').format('YYYY-MM-DD'),
      endTime: moment().format('YYYY-MM-DD'),
      content: null,
    }
    tradeState.recordPms = params;
    const res = await showList(params);
    if (_.get(res, 'code', '') === '200' || _.get(res, 'code', '') === '205') {
      if (_.get(res, 'data', '')) {
        const getList = _.get(res, 'data.result', []);
        setorderList({ list: createDatasTimeKey(getList, 'commissionTime', 'fms') });
      } else {
        setorderList({});
      }
    } else {
      message.error(res.message);
    }
  }
  // 提交
  async function submit() {
    if (!getId) {
      return
    }
    const newRecord = _.cloneDeep(tradeState.stock);
    let params = {
      'subAccountId': getId,
      'highLimitPrice': _.get(tradeState.market, 'highLimited'),
      'stockName': newRecord.stockName,
      'stockCode': tradeState.symbol,
      'price': tradeState.price,
      'quantity': tradeState.volumn,
      'side': tradeState.type === 'buy' ? 'BUY' : 'SELL',
      'priceType': tradeState.priceType,
    }
    // 保留原平台下单判断逻辑
    if (!_.get(newRecord, 'tsCode')) {
      params.stockCode = newRecord.stockCode.substring(0, newRecord.stockCode.indexOf('.'));
      if (newRecord.stockCode.substring(newRecord.stockCode.indexOf('.') + 1) === 'SH') {
        params.market = 'SH_A';
      } else {
        params.market = 'SZ_A';
      }
    } else {
      params.stockCode = newRecord.stockCode;
      if (newRecord.tsCode.substring(newRecord.tsCode.indexOf('.') + 1) === 'SH') {
        params.market = 'SH_A';
      } else {
        params.market = 'SZ_A';
      }
    }
    if (!isValidNumber(parseFloat(params.price)) && params.priceType === 'limit') {
      return message.error("请输入委托价格");
    }
    if (!isValidNumber(parseFloat(params.quantity))) {
      return message.error("请输入委托数量");
    }
    if (params.priceType === 'market') {
      Reflect.deleteProperty(params, 'price')
    }
    const res = params.side === 'BUY' ? await buy(cryptoDatas(params)) : await sell(cryptoDatas(params));
    if (_.get(res, 'code') === '200') {
      message.success("交易成功！");
      refresh();
    } else {
      message.error(res.message);
    }
  }
  // 撤单
  async function _cancel(item) {
    let list = clientOrderIdList;
    list.push(item.clientOrderId);
    setclientOrderIdList(list);
    let params = {
      subAccountId: getId,
      relateId: item.clientOrderId
    }
    const data1 = await cancel(cryptoDatas(params));
    if (data1.code === '200') {
      message.success("撤单成功");
    } else {
      message.error(data1.message);
    }
    _showList();
  }
  // 修改价格； 每次增减 0.1
  function handlePrice(type) {
    const numberPrice = parseFloat(tradeState.price);
    if (type === 'add') {
      if (isValidNumber(numberPrice)) {
        tradeState.price = ((numberPrice * 100 + 0.01 * 100) / 100).toFixed(3);
      }
    } else {
      if (isValidNumber(numberPrice)) {
        tradeState.price = ((numberPrice * 100 - 0.01 * 100) / 100).toFixed(3);
      }
    }
  }
  // 修改成交量
  function handleVolumn(type, div) {
    if (type === 'add') {
      tradeState.volumn += 100;
    } else if (type === 'minus') {
      if (tradeState.volumn > 100) {
        tradeState.volumn -= 100;
      }
    } else if (type === 'item') { // 按比例修改成交量信息
      const getStock = _.chain(fundList).filter(o => o.tsCode === tradeState.symbol).head().value();
      tradeState.volumn = _.round(_.get(getStock, 'availableStockAmount', 0) / div, -2);
    }
  }
  // 弹出自定义modal
  function openInput() {
    setVisible({
      show: true, value: {
        'price': tradeState.price,
        'volumn': tradeState.volumn,
        'btnTxt': tradeState.type === 'buy' ? '买入' : '卖出',
      }
    });
  }
  // 切换priceType的类型
  function changePriceType() {
    tradeState.priceType = PRICE_REVERSE[tradeState.priceType];
  }
  //刷新
  function refresh() {
    if (tradeState.showType === 'position') {
      _showPositionReturn();
    } else {
      _showList();
    }
  }
  // 买1-买5价格，卖1-卖5价格
  function renderBuySellCentent(type, infos) {
    const bsObj = { 'buy': 'red', 'sell': 'green' };
    return infos.map((item, i) => (
      <Row key={'sel' + i}>
        <Col flex={1}><Text strong>{item.title}</Text></Col>
        <Col flex={1}><Text style={{ color: bsObj[type] }}>{tradeState.isETF === 1 ? renderFloat(item.value, 3) : renderFloat(item.value, 2)}</Text></Col>
        <Col flex={1}><Text style={{ color: bsObj[type] }}>{item.value || ''}</Text></Col>
      </Row>
    ))
  }
  // 市场静态字段信息
  const sellInfos = [
    { title: '卖5', value: _.get(tradeState.market, 'askPrice[4]', 0) },
    { title: '卖4', value: _.get(tradeState.market, 'askPrice[3]', 0) },
    { title: '卖3', value: _.get(tradeState.market, 'askPrice[2]', 0) },
    { title: '卖2', value: _.get(tradeState.market, 'askPrice[1]', 0) },
    { title: '卖1', value: _.get(tradeState.market, 'askPrice[0]', 0) },
  ];
  const buyInfos = [
    { title: '买1', value: _.get(tradeState.market, 'bidPrice[0]', 0) },
    { title: '买2', value: _.get(tradeState.market, 'bidPrice[1]', 0) },
    { title: '买3', value: _.get(tradeState.market, 'bidPrice[2]', 0) },
    { title: '买4', value: _.get(tradeState.market, 'bidPrice[3]', 0) },
    { title: '买5', value: _.get(tradeState.market, 'bidPrice[4]', 0) },
  ];
  const otherInfo = [
    ['最高', 'high', 'red'],
    ['最低', 'low', 'green'],
    ['开盘', 'open'],
    ['收盘', 'match'],
    ['昨收', 'preClose'],
    ['涨跌幅', 'match'],
    ['成交量', 'volume'],
    ['成交额', 'turnover'],
    ['涨停', 'highLimited'],
    ['跌停', 'lowLimited'],
  ].map(n => {
    const getVal = _.get(tradeState.market, n[1]);
    const textStyle = n[2] ? { style: { color: n[2] } } : {};
    // 交易量进行中文简化处理
    return {
      'label': n[0],
      'children': <Text {...textStyle}>{_.includes(['volume', 'turnover'], n[1]) ? renderHugeFund(getVal) : getVal}</Text>
    }
  });
  // 样式，根据买入卖出切换
  const buysellColor = tradeState.type === 'buy' ? COLORS[0] : COLORS[1];
  const buysellStyles = { border: `2px solid ${buysellColor}` };
  const listColProps = { span: 6, style: { textAlign: 'center' } };
  const smallTxtStyle = { fontSize: 12 };
  const isPosition = tradeState.showType === 'position' ? true : false;
  const listTitle = isPosition ? LIST_TITLE : RECORD_TITLE;
  return <>
    <HeaderIcons
      title={'手动交易'}
      pageKey='trading'
      onPress={() => props.history?.goBack()}
    />

    <NewCard pad={4} restStyle={{ marginTop: 12 }}>
      <div>
        <Space>
          <Text strong>{tradeState.stockName}</Text>
          <ValuesColor value={_.round(tradeState.rate, 2)} size={16} />
        </Space>
      </div>

      <KlineMini datas={kvalue} upcount={update} />

      <Row style={{ marginTop: 12, marginBottom: 8 }}>
        <Col span={4}></Col>
        <Col span={16} style={{ textAlign: 'center' }}>
          <Segmented options={TYPE_OPTIONS} value={tradeState.type} onChange={v => tradeState.type = v} />
        </Col>
        <Col span={4}></Col>
      </Row>

      <Row gutter={[16, 8]} style={{ marginTop: 12 }}>
        <Col span={14}>
          <Row className='stock-name' style={buysellStyles}>
            <Col flex={1}><Text style={{ fontSize: 17 }}>{tradeState.symbol}</Text></Col>
            <Col flex={1} style={{ textAlign: 'right' }}><Text style={{ fontSize: 17 }}>{tradeState.stockName}</Text></Col>
          </Row>
          <Row className='price-item' style={{ ...buysellStyles, backgroundColor: buysellColor }}>
            <Col flex="50px" className='pbtn' onClick={() => handlePrice('minus')}>-</Col>
            <Col flex="auto" className='pname' onClick={openInput}>{tradeState.price}</Col>
            <Col flex="50px" className='pbtn' onClick={() => handlePrice('add')}>+</Col>
          </Row>
          <Row className='limit-item'>
            <Col flex={1}>
              <Space size={2}>
                <Text>{'涨停'}</Text>
                <Text style={{ color: 'red' }}>{_.get(tradeState.market, 'highLimited')}</Text>
              </Space>
            </Col>
            <Col flex={1} className='price-type' onClick={changePriceType}>
              <Text>{PRICE_TXT[tradeState.priceType]}</Text>
            </Col>
            <Col flex={1} style={{ textAlign: 'right' }}>
              <Space size={2}>
                <Text>{'跌停'}</Text>
                <Text style={{ color: 'green' }}>{_.get(tradeState.market, 'lowLimited')}</Text>
              </Space>
            </Col>
          </Row>
          <Row className='price-item' style={{ ...buysellStyles, backgroundColor: buysellColor }}>
            <Col flex="50px" className='pbtn' onClick={() => handleVolumn('minus')}>-</Col>
            <Col flex="auto" className='pname' onClick={openInput}>{tradeState.volumn}</Col>
            <Col flex="50px" className='pbtn' onClick={() => handleVolumn('add')}>+</Col>
          </Row>
          <Row className='vol-bar' gutter={[8, 8]} justify='space-around'>
            {VOL_ITEM.map(n => <Col key={n.value + 'vol'} className='vol-btn' span={5} onClick={() => handleVolumn('item', n.value)}>
              {n.label}
            </Col>)}
          </Row>
          <div className='submit-btn'
            style={{ ...buysellStyles, backgroundColor: buysellColor }}
            onClick={submit}
          >
            {tradeState.type === 'buy' ? '买入' : '卖出'}
          </div>
        </Col>
        <Col span={10}>
          {renderBuySellCentent('sell', sellInfos)}
          <NewDivider />
          {renderBuySellCentent('buy', buyInfos)}
          <NewDivider />
          <div>
            <Space>
              <Text type='secondary'>当前报价:</Text>
              <Text>{_.get(tradeState.market, 'match', 0)}</Text>
            </Space>
          </div>
        </Col>
      </Row>

      <NewDivider size={8} />

      <EasyAccordion // max 是展开高度，heigh是搜索时的高度
        open={tradeState.expend} height={62} max={132}
        onPress={() => tradeState.expend = !tradeState.expend}
      >
        <Descriptions column={3} items={otherInfo} />
      </EasyAccordion>

      <Row style={{ marginTop: 12, marginBottom: 8 }}>
        <Col span={4}></Col>
        <Col span={16} style={{ textAlign: 'center' }}>
          <Segmented options={SHOWTYPE_OPTIONS} value={tradeState.showType} size='small'
            onChange={v => tradeState.showType = v}
          />
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Button icon={<SyncOutlined />} type='text' size='small' onClick={refresh} />
        </Col>
      </Row>

      <Row className='position-bar'>
        {listTitle.map((n, i) => <Col key={'col' + i} {...listColProps}>
          <Text type='secondary'>{n[0]}</Text>
        </Col>)}
      </Row>
      <List
        dataSource={isPosition ? fundList : _.get(orderList, 'list', [])}
        renderItem={(item) => {
          const record_status = _.get(item, 'orderStatus', ''); // 订单状态
          // 持仓/记录显示不同字段的值；
          const price_val_0 = _.get(item, LIST_RECORD_KEYS[tradeState.showType][0], '');
          const price_val_1 = _.get(item, LIST_RECORD_KEYS[tradeState.showType][1], '');
          const vol_val_2 = _.get(item, LIST_RECORD_KEYS[tradeState.showType][2], '');
          const vol_val_3 = _.get(item, LIST_RECORD_KEYS[tradeState.showType][3], '');
          const hasRemark = isValidString(_.get(item, 'remarks')) && record_status === '未完成' ? true : false;
          return <List.Item>
            <Row align='middle' style={{ width: '100%' }}>
              <Col {...listColProps} onClick={() => {
                if (isPosition) { // 持仓可直接更新交易信息
                  document.documentElement.scrollTop = document.body.scrollTop = 85;
                  tradeState.stock = item;
                  _market();
                  _klineDetail();
                  tradeState.volumn = vol_val_3;
                }
              }}>
                <LabelValueComp
                  space={2}
                  label={_.get(item, 'stockName', '')}
                  vComp={_.get(item, 'stockCode', '')}
                />
              </Col>
              <Col span={hasRemark ? 12 : 0} style={{ textAlign: 'center' }}>
                <Text style={smallTxtStyle}>{_.get(item, 'remarks', '')}</Text>
              </Col>
              <Col {...listColProps} span={hasRemark ? 0 : 6}>
                <LabelValueComp
                  space={2} type='downv'
                  label={isPosition ? <RateText
                    rate={price_val_0}
                    restStyle={{ fontSize: 15, fontWeight: 600 }}
                    noPrefix
                  /> : price_val_0}
                  vComp={isPosition ? <RateText
                    rate={_.get(item, LIST_RECORD_KEYS[tradeState.showType][1], '')}
                  /> : price_val_1}
                />
              </Col>
              <Col {...listColProps} span={hasRemark ? 0 : 6}>
                <LabelValueComp
                  space={2} type='downv'
                  label={vol_val_2}
                  vComp={vol_val_3}
                />
              </Col>
              {isPosition ? <Col {...listColProps}>
                <Text>{_.get(item, 'formatDate', '')}</Text>
              </Col> : <Col {...listColProps}>
                <LabelValueComp
                  space={2} type='downv'
                  label={
                    // clientOrderIdList 不含id 可撤单
                    clientOrderIdList.indexOf(_.get(item, 'clientOrderId', null)) === -1 ? ((record_status === '成交中' && _.get(item, 'isBreak') === false) ?
                      <Button type="link" size='small' danger onClick={() => _cancel(item)}>
                        撤单
                      </Button> : // 不满足撤单要求，显示状态文字
                      <Text style={{ color: STATUS_COLOR[record_status] ?? 'blue' }}>{record_status}</Text>
                    ) : //  包含id，并满足成交中，代表正在撤单
                      (record_status === '成交中' && <Text type='secondary'>撤单</Text>)
                  }
                  vComp={<Text style={smallTxtStyle}>{_.get(item, 'formatDate', '')}</Text>}
                />
              </Col>
              }
            </Row>
          </List.Item>
        }}
      />
    </NewCard>

    <TradeInputModal
      show={_.get(visible, 'show')}
      inits={_.get(visible, 'value')}
      onOk={(val) => {
        tradeState.price = _.get(val, 'price');
        tradeState.volumn = _.get(val, 'vol');
        setVisible({ show: false, value: {} });
        submit();
      }}
      onClose={() => setVisible({ show: false, value: {} })}
    />
  </>
}

export default TradingPage;
import React from 'react';
import { Table, Typography, Tag } from 'antd';
import { singleColumns, colWidth } from '@/utils/utils';
import { RateText } from '@/utils/widgets';
import _ from 'lodash';

const { Text } = Typography;
// 批量卖出表格
const SellTables = ({ datas = [], values = [], accNum = {}, onSelect }) => {
  const columns = [
    {
      ...singleColumns(['股票', 'stock', 130]),
      render: (text, record) => <Text>{`${_.get(record, 'stockCode')}-${_.get(record, 'stockName')}`}</Text>,
    },
    {
      ...singleColumns(['涨跌幅', 'pctChg', 85]),
      sorter: (a, b) => a.pctChg - b.pctChg,
      render: (text) => <RateText rate={text} />
    },
    singleColumns(['数量', 'stockAmount', 75]),
    singleColumns(['可用', 'availableStockAmount', 75]),
    singleColumns(['金额', 'totalMarketValue', 75]),
    {
      ...singleColumns(['账户', 'account', 95]),
      fixed: 'right',
      render: (text, record) => {
        const getSubarr = _.get(record, 'subarr', []);
        if (_.size(getSubarr) > 0) {
          return <>
            {getSubarr.map(code => <Tag key={'co' + code} style={{ marginRight: 2 }}>
              {_.get(accNum, code)}
            </Tag>)}
          </>
        }
      }
    }
  ]

  const rowSelection = {
    selectedRowKeys: values,
    onChange: (selectedRowKeys) => {
      onSelect(selectedRowKeys);
    },
  };
  return <>
    <Table
      rowKey="key"
      dataSource={datas}
      columns={columns}
      rowSelection={rowSelection}
      scroll={{ x: colWidth(columns), y: 625 }}
      bordered={false}
      size='small'
      pagination={false}
    />
  </>
}

export default SellTables;
import React, { useEffect, useState, useRef } from 'react';
import { useLocalObservable, Observer } from 'mobx-react';
import { store } from '@/store/mobx';
import { Form, Spin, Input, Button, message, Segmented } from 'antd';
import logo from '@/images/_logoEN.svg';
import { userLogin, userLoginFC } from '@/api/auth';
import { cryptoString } from '@/utils/cryptoUtils';
import { getPlateform, savePlateform } from '@/utils/saveUtils';
import { isValidString } from '@/utils/utils';
import { useMediaQuery } from 'react-responsive';
import { useMount } from 'ahooks';
import _ from 'lodash';
/**
  登录页
 */
const Login = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const mobxStore = useLocalObservable(() => store);
  const interVal = useRef(null);
  const [formUser] = Form.useForm();
  const [spinning, setspinning] = useState(false);
  const [plateformVal, setPlateformVal] = useState(getPlateform());
  const [copyShow, setCopyShow] = useState(true);

  useEffect(() => {
    mobxStore.cancelLogin();
    interVal.current = {
      time: 60,
      index: null,
    }

    return () => {
      clearInterval(interVal.current.index);
    }
  }, [mobxStore]);

  useMount(() => {
    // http://192.168.5.80:3000/login?plateform=SIMX; 跳转href地址格式；获取plateform之后的值
    const pathnameFormRoute = _.get(window, 'location.search', '');
    if (isValidString(pathnameFormRoute)) {
      let pl = _.chain(pathnameFormRoute).replace('?', '').split('=').get('[1]').value();
      setPlateformVal(pl)
    }
  });

  // 完成-登录
  const plateformArray = ['BT', 'SIMX', 'TAMP'];
  async function onFinish(values) {
    if (plateformArray.indexOf(plateformVal) === -1) {
      message.info('请选择登录平台！')
      return
    }
    let params = {
      account: values.account,
      password: cryptoString(values.password),
      rememberMe: 0,
      loginType: 'password'
    }
    setspinning(true);
    const res = await userLogin(params);
    const resFc = plateformVal === 'TAMP' ? await userLoginFC(params) : {};
    setspinning(false);
    if (_.get(res, 'code', '') === '200') {
      const user_login = _.assign(
        _.get(res, 'data', {}),
        { 'plateform': plateformVal }
      );
      // console.log('登录userinfo', user_login)
      mobxStore.landUserInfo(user_login);
      mobxStore.landLogin(_.get(res, 'data.token', null));
      props.history.push('/mobile/assets');
    } else {
      message.error(_.get(res, 'message', '登录错误！'));
    }
    // 反采登录
    if (_.get(resFc, 'code', '') === '200') {
      mobxStore.landUserInfoFC(_.get(resFc, 'data', {}));
      mobxStore.landLoginFC(_.get(resFc, 'data.token', null));
    }
    return
  }


  const COMMON_TAB_STYLE = { width: isMobile ? 268 : 400 }
  const COMMON_LOGO_STYLE = { width: isMobile ? 150 : 300, height: isMobile ? 55 : 110 };
  return (
    <Observer>
      {() => (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img
            src={logo}
            alt=""
            style={{ margin: '150px 0 20px', ...COMMON_LOGO_STYLE }}
          />
          <div style={COMMON_TAB_STYLE}>
            <Spin spinning={spinning}>
              <Form
                form={formUser}
                onFinish={onFinish}
              >
                <Form.Item
                  label=""
                  name='account'
                  rules={[{ required: true, message: '请输入账户!', },]}
                >
                  <Input placeholder="账户" size="large" onFocus={() => setCopyShow(false)} onBlur={() => setCopyShow(true)} />
                </Form.Item>

                <Form.Item
                  label=""
                  name='password'
                  rules={[{ required: true, message: '请输入密码', },]}
                >
                  <Input.Password placeholder="密码" size="large" onFocus={() => setCopyShow(false)} onBlur={() => setCopyShow(true)} />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Form.Item>
                    <Segmented
                      value={plateformVal}
                      options={['BT', 'SIMX', 'TAMP']}
                      onChange={v => {
                        setPlateformVal(v);
                        savePlateform(v);
                      }}
                    />
                  </Form.Item>
                </div>

                <Form.Item>
                  <Button type="primary" htmlType="submit" style={{ width: '100%', height: 45 }}>
                    登录
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </div>

          <p style={{ position: 'fixed', bottom: 16, fontSize: 12, display: copyShow ? 'block' : 'none' }}>
            COPYRIGHT © 2024 程创（北京）科技有限公司
          </p>
        </div>
      )}
    </Observer>
  )
}
export default Login
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('en');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>

  //Antd 组件配置
  <ConfigProvider theme={{
    token: { colorPrimary: '#0859AB' },
    components: {
      Descriptions: { // 组件默认时下边距=4 small=8
        itemPaddingBottom: 4
      },
      Alert: {
        defaultPadding: '4px 6px'
      }
    },
  }} locale={zhCN}>
    <App />
  </ConfigProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState, useRef } from 'react';
import { Typography, Space, Row, Col, List, Tag, Descriptions } from 'antd';
import { autoCol, PRODUCT_STRATEGY_TYPE } from '@/utils/utils';
import { RateText, NewCard, LabelValueComp, NewDivider } from '@/utils/widgets';
import { useReactive, useSize, useUnmount } from 'ahooks';
import _ from 'lodash';

const { Text } = Typography;
const REVERT_OBJ = { 'desc': 'asc', 'asc': 'desc' };
// 基础排序字段
const SORT_ARRAY = [
  ['总收益率', 'totalRevenueRate_float'],
  ['今日', 'dailyRevenueRate_float'],
];
// BT排序字段
const BT_SORT = [
  ['每日更新', 'daily', 0],
  ['回测中', 'ing', 1],
  // ['其他', 'other', 2],
  ...SORT_ARRAY,
  ['六月超额', 'avgExcess'],
  ['十日超额', 'tenDayAvgExcess'],
  ['累计超额', 'accumulateExcess'],
  ['最新日期', 'last_order'],
  ['交易天数', 'tradingDays'],
];
const BT_DESC = [
  ['总资产', 'totalAssets'],
  ['六月超额', 'avgExcess', 'rate'],
  ['十日超额', 'tenDayAvgExcess', 'rate'],
  ['累计超额', 'accumulateExcess', 'rate'],
  ['最新日期', 'latestDate'],
  ['交易天数', 'tradingDays'],
];
// 其他显示
const OTHER_SORT = [['开始日期', 'times_order']];
const OTHER_DESC = [
  ['总资产', 'totalAssets'],
  ['开始日期', 'times']
];
const TRADER_DESC = [
  ['总市值', 'position'], ['可用资金', 'balance'],
  ['总收益', 'totalRevenue'], ['日收益', 'dailyRevenue'],
];
const VIR_SORT = [['虚拟', 'vir_order']]
const SORT_OBJ = {
  'BT': _.concat(VIR_SORT, BT_SORT),
  'SIMX': _.concat(VIR_SORT, SORT_ARRAY, OTHER_SORT),
  'TAMP': _.concat(SORT_ARRAY, OTHER_SORT),
}
// 排序tag按钮
const SortTag = ({ txt, color, onPress }) => {
  return <div style={{ cursor: 'pointer' }} onClick={onPress}>
    <Tag bordered={false} color={color}>{txt}</Tag>
  </div>
};
// 虚拟icon
const VirIcon = ({ color }) => <svg t="1710905444530" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4297" width="18" height="18"><path d="M121.904762 330.947048l359.619048 154.575238v451.779047L165.351619 796.94019A73.142857 73.142857 0 0 1 121.904762 730.063238v-399.11619z m780.190476-9.337905v408.454095a73.142857 73.142857 0 0 1-43.446857 66.852572L554.666667 931.888762V481.694476L902.095238 321.584762zM541.696 86.308571l316.952381 140.751239c11.02019 4.87619 20.406857 12.312381 27.599238 21.382095l-371.151238 171.885714-382.537143-164.425143c7.558095-12.483048 18.895238-22.674286 32.792381-28.842666l316.952381-140.751239a73.142857 73.142857 0 0 1 59.392 0z"
  p-id="4298"
  fill={color}></path></svg>;
/* 
  资产页面下面的产品列表 
*/
const ProductList = ({
  list = [],
  plateform = '',
  isTrader = false,
  loading = false,

  goProduct,
  saveScreenWidth
}) => {
  const ref = useRef(null);
  const divSize = useSize(ref);
  const listState = useReactive({ sort: '', dir: 'desc' });
  const [filters, setFilters] = useState([]);
  const sortss = SORT_OBJ[plateform];
  // 离开页面时保存页面宽度信息
  useUnmount(() => {
    saveScreenWidth(divSize?.width ?? 565);
  });

  // 渲染排序颜色，sort=key为选中，通过升降序显示颜色
  const sort_color_obj = { 'desc': 'red', 'asc': 'green', 'filter': 'blue' };
  function renderSortColor(key) {
    let fcolor = 'gold';
    if (listState.sort === key) {
      fcolor = sort_color_obj[listState.dir];
    }
    return fcolor;
  }
  // 处理排序、筛选
  function handleSort(sitem) {
    if (plateform === 'BT' && _.size(sitem) === 3) { // BT分类进行筛选
      if (listState.sort === sitem[1]) { // 取消排序
        listState.sort = '';
        setFilters(list);
      } else { // 点击进行filter处理，aindex获取时提前处理
        listState.dir = 'filter';
        listState.sort = sitem[1];
        setFilters(_.filter(list, o => o.aindex === sitem[2]));
      }
    } else { // 其他平台进行排序
      if (listState.sort === sitem[1]) { //点击当前排序进行泛型排序操作
        listState.dir = REVERT_OBJ[listState.dir];
      } else { // 正常点击进行排序
        listState.sort = sitem[1];
        listState.dir = 'desc';
      }
      setFilters(_.orderBy(list, [sitem[1]], [listState.dir]));
    }
  };
  // 渲染描述信息
  function renderDescItem(desc, item) {
    return desc.map(n => {
      const getVal = _.get(item, n[1]);
      return {
        label: n[0],
        children: _.get(n, '[2]', '') === 'rate' ? <RateText rate={getVal} /> : <Text type='secondary'>{getVal}</Text>
      }
    });
  }

  const newList = listState.sort !== '' ? filters : list;
  const newDesc = isTrader ? TRADER_DESC : (plateform === 'BT' ? BT_DESC : OTHER_DESC);
  return <div style={{ marginTop: 12 }} ref={ref}>
    {isTrader ? <NewDivider /> : <div className='sort-div' style={{ width: divSize?.width }}>
      <Space size='small'>
        {sortss.map((s, i) => <SortTag key={'sort' + i}
          txt={s[0]}
          color={renderSortColor(s[1])}
          onPress={() => handleSort(s)}
        />)}
      </Space>
    </div>}
    <List
      grid={{ gutter: 8, xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }} //md ≥ 768px 例ipad Air=816则使用两列样式
      dataSource={newList}
      loading={loading}
      renderItem={(item) => {
        const getVir = _.get(item, 'vir_order');
        const descItems = renderDescItem(getVir === 2 ? OTHER_DESC : newDesc, item);
        return <List.Item>
          <NewCard pad={12} onPress={() => goProduct(item, getVir)}>
            <Row>
              <Col span={16}>
                <Text className='product-title'>
                  {_.get(item, isTrader ? 'subAccountName' : 'productName', '')}
                </Text>
              </Col>
              <Col span={8} style={{ textAlign: 'right' }}>
                {getVir === 2
                  ? <VirIcon color={_.get(item, 'totalRevenueRate', 0) > 0 ? 'red' : 'green'} />
                  : <Tag color='blue'>
                    {isTrader ? _.get(item, 'subAccountType', '') : PRODUCT_STRATEGY_TYPE[_.get(item, 'strategyType', 1)]}
                  </Tag>
                }
              </Col>
            </Row>
            <Row style={{ marginTop: 16 }}>
              <Col {...autoCol([6, 8, 8])}>
                <LabelValueComp
                  label={'总收益率'}
                  vComp={<RateText
                    rate={_.get(item, 'totalRevenueRate', '')}
                    restStyle={{ fontSize: 18, fontWeight: 600 }}
                  />}
                />
              </Col>
              <Col {...autoCol([6, 8, 8])}>
                <LabelValueComp
                  label={'今日收益率'}
                  vComp={<RateText
                    rate={_.get(item, 'dailyRevenueRate', '')}
                    restStyle={{ fontSize: 18, fontWeight: 600 }}
                  />}
                />
              </Col>
              <Col {...autoCol([6, 8, 8])}>
                <LabelValueComp
                  label={'净值'}
                  vComp={<Text strong style={{ fontSize: 18 }}>
                    {_.round(_.get(item, 'netValue', ''), 2)}
                  </Text>}
                />
              </Col>
            </Row>
            <NewDivider />
            <Descriptions column={2} items={descItems} />
          </NewCard>
        </List.Item>
      }}
    />
  </div>
}

export default ProductList;
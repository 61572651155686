import React, { useState } from 'react';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { withRouter } from "react-router-dom";
import {
  getfundManagerAssets, visShowAssetsAndProducts, // 管理人
  showAllSubAccount, showAssetsAndRevenue,  //交易员
  showVirtualProduct, // simx虚拟
} from '@/api/main';
import { BlockOutlined, BarsOutlined, UpSquareOutlined, CloudServerOutlined } from '@ant-design/icons';
import { useReactive, useMount } from 'ahooks';
import { accountOptionIndex, replaceMoney, isValidString, createDatasTimeKey } from '@/utils/utils';
import { EasySelect } from '@/utils/widgets';
import { saveStorage } from '@/utils/saveUtils';
import AssetsCards from './Components/AssetsCards';
import ProductList from './Components/ProductList';
import moment from 'moment';
import _ from 'lodash';
import './home.scss'

const FORMATE = 'YYYY-MM-DD';
const PLATEFORM_OPTIONS = {
  'TAMP': [
    { label: '实测对比', value: 'tampcompare', icon: <BlockOutlined /> },
    { label: '批量卖出', value: 'batchsell', icon: <UpSquareOutlined /> },
    { label: '湘财托管', value: 'hosting', icon: <CloudServerOutlined /> },
  ],
  'BT': [{ label: '全部回测', value: 'alltest', icon: <BarsOutlined /> }],
  'SIMX': []
};
const ADD_KEYS = ['totalProduct', 'totalAssets', 'totalRevenue', 'dailyRevenue', 'freeBalance'];
const RATE_KEYS = ['totalRevenueRate', 'dailyRevenueRate'];
/**
 * 资产首页; 区分交易员及管理员权限，获取不同接口内容；区分不同平台显示不同内容
 */
const AssetsPage = (props) => {
  const mobxStore = useLocalObservable(() => store);
  const [userInfo] = useState(JSON.parse(JSON.stringify(mobxStore.userInfo)) ?? {});
  const [userInfoFC] = useState(JSON.parse(JSON.stringify(mobxStore.userInfoFC)));
  const [userRole] = useState(_.get(userInfo, 'role', ''));
  const [homeResult, setHomeResult] = useState([]);
  const [assetss, setassetss] = useState({ currentProductResultList: [] });
  const [vproducts, setVproducts] = useState([]);
  const assetState = useReactive({ isShow: true, visible: false, loading: false });
  const userPlateform = _.get(userInfo, 'plateform', '');
  const isVisitor = userRole === 'visitor' ? true : false;
  const isTrader = userRole === 'trader' ? true : false;
  const isFcLogin = isValidString(_.get(userInfoFC, 'token', '')); //fc是否有token，是否正常登录 
  const isNoTamp = userPlateform !== 'TAMP' ? true : false;

  useMount(() => {
    mobxStore._getTradingInfo();
    if (isTrader) {
      _showAllSubAccount();
      _showAssetsAndRevenue();
    } else {  
      const mobxAllProducts = JSON.parse(JSON.stringify(mobxStore.allProducts));
      //产品缓存，首次加载执行loading
      let noloading = false;
      const proCacheTime = _.get(mobxAllProducts, 'timstamp', '');
      if (isValidString(proCacheTime)) {
        noloading = true;
        setassetss(_.get(mobxAllProducts, 'assets'));
        setHomeResult(_.get(mobxAllProducts, 'lists'));
      };
      _gets(noloading);
      
      mobxStore._getProductAccount(isFcLogin);
      mobxStore._getAllTradeDay();
      if (isNoTamp) {
        _showVirtualProduct();
      } else {
        mobxStore._showIndexConfig();
      }
    }
  })
  // 管理员获取及visitor权限接口
  async function _gets(noloading = false) {
    assetState.loading = noloading ? false : true;
    let params = {
      'isShow': assetState.isShow,
      'pageNum': 1,
      'pageSize': 99
    }
    const res = isVisitor ? await visShowAssetsAndProducts() : await getfundManagerAssets(params);
    const resFc = !isVisitor && isFcLogin ? await getfundManagerAssets({ ...params, 'isShow': false }, 'FC') : {};
    if (_.get(res, 'code', '') === '200') {
      if (res.data !== null && res.data.currentProductResultList.length !== 0) {
        res.data.currentProductResultList.map(item => {
          item.totalRevenueRate = parseFloat(item.totalRevenueRate).toFixed(2) + '%';
          item.totalRevenueRate_float = parseFloat(item.totalRevenueRate);
          item.dailyRevenueRate = parseFloat(item.dailyRevenueRate).toFixed(2) + '%';
          item.dailyRevenueRate_float = parseFloat(item.dailyRevenueRate);
          return item;
        })
        res.data.totalRevenueRate = parseFloat(res.data.totalRevenueRate).toFixed(2) + '%';
        res.data.dailyRevenueRate = parseFloat(res.data.dailyRevenueRate).toFixed(2) + '%';
      }
      let newAsset = _.get(res, 'data', {});
      let newInfo = []; let newResult = [];
      if (userPlateform !== 'TAMP') {
        newResult = _.get(res, 'data.currentProductResultList', []);
        newInfo = _.get(res, 'data.pageInfo.list', []);
      } else {
        newResult = _.concat(_.get(res, 'data.currentProductResultList', []), _.get(resFc, 'data.currentProductResultList', []));
        newInfo = _.concat(_.get(res, 'data.pageInfo.list', []), _.get(resFc, 'data.pageInfo.list', []));
        // 累计集中\反采账户，计算总资产；【tips】返回资产带",""的字符串，需转换数值进行计算
        ADD_KEYS.map(key => {
          const addNum = _.get(replaceMoney(_.get(res, `data.${key}`)), 'number');
          const addFc = _.get(replaceMoney(_.get(resFc, `data.${key}`)), 'number');
          _.set(newAsset, key, _.round(_.add(addNum, addFc), 2));
        });
      }
      // 没有配置首页显示卡，修改isShow，显示全部
      if (_.size(newResult) === 0 && params.isShow === true) {
        assetState.isShow = false;
        _gets();
        return;
      }
      let newList = [];
      newResult.map(n => {
        let findex = _.findIndex(newInfo, o => o.id === n.productId);
        if (userPlateform === 'BT') { // BT增加分类的aindex字段
          _.set(n, 'aindex', accountOptionIndex(n.productName, true));
        }
        if (findex !== -1) {
          let items = _.assign(n, { // 对需要排序的时间进行diff时间处理，返回number类型快速排序
            'times': moment(_.get(newInfo, `[${findex}].createTime`)).format(FORMATE),
            'times_order': moment(_.get(newInfo, `[${findex}].createTime`)).diff(moment(), 'd'),
            'begin_order': moment(_.get(n, 'beginDate')).diff(moment(), 'd'),
            'last_order': moment(_.get(n, 'latestDate')).diff(moment(), 'd'),
            'label': _.get(n, 'productName', ''),
            'value': _.get(n, 'productId', ''),
            'vir_order': 1,
          });
          if (userPlateform === 'TAMP') {
            // 排序用的float值，从字符串资产进行处理
            RATE_KEYS.map(key => {
              _.set(items, key + '_float', _.round(parseFloat(_.get(items, key)), 2));
              _.set(items, key, _.round(parseFloat(_.get(items, key)), 2) + '%');
            });
          }
          newList.push(items);
        }
      })
      // console.log('newList', newList)
      setassetss(newAsset);
      setHomeResult(isVisitor ? newResult : newList);
      mobxStore.changeAllProducts({
        'lists': newList,
        'assets': newAsset,
        'timstamp': moment().format('YYYY-MM-DD HH:mm:ss'),
      });
    }
    assetState.loading = false;
  }

  function jumpToProducts(pms = {}) {
    props.history.push({ pathname: '/mobile/products', state: pms });
  }
  // 交易员跳转时增加字段名称
  function jumpToAccount(pms = {}) {
    props.history.push({
      pathname: '/mobile/accounts',
      state: _.assign(pms, { id: _.get(pms, 'subAccountId'), name: _.get(pms, 'subAccountName'), isTrader })
    });
  }
  // 其他页面路由
  function jumpRoute(path = '', pms = {}) {
    props.history.push({ pathname: `/mobile/${path}`, state: { userRole, userPlateform, ...pms } });
  }
  // 交易员子账户asset信息
  async function _showAssetsAndRevenue() {
    const data = await showAssetsAndRevenue();
    if (_.get(data, 'code', '') === '200') {
      setassetss(_.get(data, 'data', {}));
    }
  }
  // 交易员子账户列表
  async function _showAllSubAccount() {
    const data1 = await showAllSubAccount();
    if (_.get(data1, 'code', '') === '200') {
      const getData = _.get(data1, 'data', []);
      const sizeData = _.size(getData);
      let newList = [];
      let rCount = 0; // 要显示托管账户的账户count
      let nCount = 0; // 无需显示的count
      if (getData && sizeData > 0) {
        getData.map(item => {
          item.netValue = item.netValue.toFixed(4);
          const getResult = _.get(item, 'stockAssetPositionResult', []);
          if (_.size(getResult) > 0) {
            rCount = rCount + 1;
            newList.unshift({ ...item, type: 'result' });
          } else {
            nCount = nCount + 1;
            newList.push({ ...item, type: 'normal' })
          }
        })
      }
      if (rCount > 0 && nCount > 0) {
        newList[rCount].needOffset = 1;
      }
      //console.log('newList', newList)
      setHomeResult(newList);
    }
  };

  // 获取虚拟产品信息
  async function _showVirtualProduct() {
    const res = await showVirtualProduct();
    if (_.get(res, 'code', '') === '200') {
      const getData = _.get(res, 'data', []);
      const orderData = createDatasTimeKey(getData, 'date');
      const roundKeys = ['totalAssets', 'totalRevenue', 'dailyRevenue'];
      // 给资产字段保留2位小数
      let newVdata = orderData.map(n => {
        let floatItm = {};
        roundKeys.map(k => {
          _.set(floatItm, k, _.round(n[k], 2));
        });
        // vir_order =2 为虚拟产品
        return _.assign(n, floatItm,
          {
            'vir_order': 2,
            'productName': n.name,
            'productId': n.id,
            'times': moment(_.get(n, 'date')).format(FORMATE),
            'times_order': moment(_.get(n, 'date')).diff(moment(), 'd'),
          }
        );
      });
      // console.log('newVdata', newVdata)
      setVproducts(newVdata);
    }
  }

  const plateformOptions = PLATEFORM_OPTIONS[userPlateform];
  return <div className="basic-contents">
    <AssetsCards
      assetss={assetss}
      isTrader={isTrader}
      isMore={_.size(plateformOptions) > 0 ? true : false}
      openMore={() => assetState.visible = true}
    />

    <ProductList
      list={isNoTamp ? _.concat(homeResult, vproducts) : homeResult}
      plateform={userPlateform}
      isTrader={isTrader}
      loading={assetState.loading}
      saveScreenWidth={(width) => saveStorage('s_width', JSON.stringify(width))}
      goProduct={(item, vir) => {
        if (isTrader) {
          jumpToAccount(item);
        } else {
          jumpToProducts(vir === 2 ? { ...item, vproducts } : item);
        }
      }}
    />

    <EasySelect
      visible={assetState.visible}
      options={plateformOptions}
      onClose={() => assetState.visible = false}
      onPress={(v) => {
        assetState.visible = false;
        jumpRoute(v);
      }}
    />
  </div>
}

export default withRouter(AssetsPage);
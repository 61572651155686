import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, message, Segmented, Table, Space, FloatButton } from 'antd';
import { SyncOutlined, LeftOutlined, RightOutlined, DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import {
  handleIndexValue, handleRetreat, handleRetreatExtra, arraysMinus, arraysMinusDiv, renderRetreatOption, multiplication,
  COMMON_CHART, RETREAT_OPTIONS, MAIN_RATE_OPTIONS,
} from '@/utils/chartInfo';
import { handleMainSeriesData } from '@/utils/list_utils';
import { INDEX_CODE_VALUE, INDEX_OPTIONS } from '@/utils/indexCode';
import { showIndexSpeed, showPositionSort, showIndexKline, showSubAccountHistory, showSingleProductHistory } from '@/api/main';
import { useReactive, useMount } from 'ahooks';
import { HeaderIcons, NewDivider, NewCard, NewCalendar, EasySelect } from '@/utils/widgets';
import { singleColumns, labelValues, isValidNumber, isValidArray, isValidObj, isValidString } from '@/utils/utils';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const TYPE_OPTIONS = [labelValues(['涨停票', 'limit']), labelValues(['两日', 'two']), labelValues(['速率', 'speed'])];
const TODAY = moment().format('YYYY-MM-DD');
const TYP_COLOR = { '今仓': '#007eb9', '昨仓': '#cf873b', '前仓': '#9397a8' };
const NO_SHOW_TODAY = ['866001', '866006', '000985'];
/**
 * 收益率今日详情页面;
 * tamp为主，将今日集成在图表中的速率、两日持仓提取出来，加上涨停票作为今日分析；
 */
const TodayPage = (props) => {
  const routeDatas = _.get(props, 'location.state', {});
  const mobxStore = useLocalObservable(() => store);
  const [tradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const [userInfo] = useState(JSON.parse(JSON.stringify(mobxStore.userInfo)) ?? {});
  const [speedVals, setSpeedVals] = useState({});
  const [rates, setRates] = useState({});
  const [upcount, setUpcount] = useState(0);
  const [getCount, setGetCount] = useState(0);
  const [option, setoption] = useState({
    ...MAIN_RATE_OPTIONS,
    tooltip: {
      ...COMMON_CHART.tooltipCustom,
      // formatter: renderFormatter
    },
    grid: { left: '7%', right: '3%', bottom: '10%', top: '3%' },
  });
  const [option2, setoption2] = useState({
    ...RETREAT_OPTIONS,
    grid: { left: '7%', right: '3%', bottom: '3%', top: '3%' },
  });
  const [positionSorts, setPositionSorts] = useState([]);
  const todState = useReactive({
    begin: _.get(routeDatas, 'date', ''), end: _.get(routeDatas, 'date', ''), loading: false, type: 'limit',
    timeOpen: { key: '', open: false, value: '', stamp: new Date() },
    stimes: [], syshow: false,
    symbol: _.get(routeDatas, 'symbol', ''), symbolName: _.get(routeDatas, 'symbolName', ''),
    isGet: false, tshow: false, tvalue: '',
  });
  const page_keys = _.get(routeDatas, 'pageKey');
  const userPlateform = _.get(userInfo, 'plateform', '');
  const is_reverse = _.get(routeDatas, 'isReverse');

  useMount(() => {
    _showIndexSpeed();
    _showPositionSort();
    setUpcount(_.round(upcount + 0.1, 1));
  });

  // 指数变化速率
  async function _showIndexSpeed() {
    if (userPlateform !== 'TAMP') {
      return
    }

    let params = {
      'symbol': _.get(routeDatas, 'symbol', ''),
      'date': todState.begin
    }
    const res = await showIndexSpeed(params);
    if (_.get(res, 'code', '') === '200') {
      const getData = _.get(res, 'data', {}); // {09:31:{up:[...],down:[...]}}
      // 时间排序
      let time_order = _.keys(getData).map((t, i) => {
        return { time: t, order: i, num_time: parseFloat(_.replace(t, ':', '')) }
      });
      let timeList = _.orderBy(time_order, ['num_time'], ['asc']).map(t => t.time);
      let newDatas = {};
      timeList = timeList.map(t => { // 合并up down数据并重新根据速率排序
        let up_down = _.concat(
          _.get(getData, `${t}.up`, []), _.get(getData, `${t}.down`, [])
        ).map((d, i) => _.set(d, 'key', _.get(d, 'code', '') + i));
        newDatas[t] = _.orderBy(up_down, ['speed'], ['desc']);
        return labelValues([t, t])
      })
      setSpeedVals(newDatas);
      todState.stimes = timeList;
      todState.tvalue = _.get(_.last(timeList), 'value');
    }
  }
  // 持仓分类
  async function _showPositionSort() {
    let params = {
      'date': _.get(routeDatas, 'date', '')
    };
    let isValidId = false;
    if (page_keys === 'product') {
      _.set(params, 'productId', _.get(routeDatas, 'productId'));
      isValidId = isValidNumber(_.get(routeDatas, 'productId'));
    } else if (page_keys === 'account') {
      _.set(params, 'subAccountId', _.get(routeDatas, 'subAccountId'));
      isValidId = isValidNumber(_.get(routeDatas, 'subAccountId'));
    }
    if (!isValidId) {
      return
    }
    const res = await showPositionSort(params, is_reverse ? 'FC' : 'NOR');
    if (_.get(res, 'code', '') === '200') {
      const getSort = _.get(res, 'data', {});
      if (isValidObj(getSort)) {
        let final = []; let key = 0;
        _.keys(getSort).map(keyname => {
          _.get(getSort, keyname, []).map(stock => {
            final.push({ 'name': stock, 'pos': keyname, key });
            key++;
          })
        })
        setPositionSorts(final);
      }
    }
  }
  // 获取账户或产品收益率
  async function _getRates() {
    let pms = {
      productId: routeDatas.productId, dateType: 'TODAY', date: todState.begin,
    }
    let index_pms = {
      symbol: todState.symbol, dateType: 'TODAY', date: todState.begin,
    }

    let isValidId = false;
    if (page_keys === 'product') {
      _.set(pms, 'productId', _.get(routeDatas, 'productId'));
      isValidId = isValidNumber(_.get(routeDatas, 'productId'));
    } else if (page_keys === 'account') {
      _.set(pms, 'subAccountId', _.get(routeDatas, 'subAccountId'));
      isValidId = isValidNumber(_.get(routeDatas, 'subAccountId'));
    }
    if (!isValidId) {
      return
    }
    const data1 = page_keys === 'account'
      ? await showSubAccountHistory(pms, is_reverse ? 'FC' : 'NOR')
      : await showSingleProductHistory(pms, is_reverse ? 'FC' : 'NOR');
    const res = await showIndexKline(index_pms);
    if (_.get(data1, 'message', '') !== 'success') {
      message.info(_.get(data1, 'message'))
    }
    if (_.get(res, 'message', '') !== 'success') {
      message.info(_.get(res, 'message'))
    }
    const getPoints = _.get(data1, 'data.pointList', []);
    if (_.get(data1, 'code', '') === '200' && isValidArray(getPoints)) {
      let newTimeList = _.get(data1, 'data.timeList', []);
      const newData = handleMainSeriesData(_.get(data1, 'data', {}), pms.dateType);
      const newIndexs = handleIndexValue(pms.dateType, newTimeList, res);
      const indexValues = newIndexs.indexValues;
      const pointsData = _.get(newData, 'pointList', []);
      const idNet = newIndexs.dayPriceVals
      const pointNet = _.get(newData, 'pointsNetList', []);
      const newExtra = handleExtraRate(pointsData, indexValues, pointNet, idNet);
      setRates({
        'times': newTimeList, 'points': pointsData, 'indexs': indexValues, 'extra': newExtra.extra,
        'points_retreat': _.get(newData, 'pointRetreat'),
        'idx_retreat': handleRetreat(newIndexs.priceValues),
        'extra_retreat': handleRetreatExtra(newExtra.extra),
        'limits': _.get(newData, 'fullMarks', [])
      });
    }
    setGetCount(_.round(getCount + 0.1, 1));
    todState.isGet = true;
  }
  // 计算超额收益率
  function handleExtraRate(rates = [], indexRates = [], nets = [], indexNets = []) {
    const extraVal = arraysMinus(rates, indexRates);
    const extraVal2 = multiplication(arraysMinusDiv(nets, indexNets));
    const extraVal3 = arraysMinus(nets, indexNets); // 超额统计计算数据
    return { 'extra': extraVal, 'extraGeo': extraVal2, 'extra3': extraVal3 }
  }
  // 更新；首次使用route数据，修改日期或其他使用接口获取
  useEffect(() => {
    let myChart = props.myChart; let myChart2 = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('main_charts'));
    myChart2 = echarts.init(document.getElementById('main_charts_retreat'));

    let newOption = _.cloneDeep(option); let newOption2 = _.cloneDeep(option2);
    // 切换数据源直接赋值
    const dataSource = todState.isGet ? rates : routeDatas;
    newOption.xAxis.data = _.get(dataSource, 'times', []);
    newOption.series[0].data = _.get(dataSource, 'points', []);
    newOption.series[1].data = _.get(dataSource, 'indexs', []);
    newOption.series[1].name = todState.symbolName;
    newOption.series[2].data = _.get(dataSource, 'extra', []);
    newOption2 = renderRetreatOption(
      newOption2,
      newOption,
      _.get(dataSource, 'points_retreat', []),
      _.get(dataSource, 'idx_retreat', []),
      _.get(dataSource, 'extra_retreat', []),
      _.get(dataSource, 'times', []),
    );

    setoption(newOption); setoption2(newOption2);
    myChart.setOption(newOption, true); myChart2.setOption(newOption2, true);
    myChart.resize(); myChart2.resize();
    echarts.connect([myChart, myChart2]); // 增加联动
  }, [upcount, getCount]);
  // 切换单日日期
  function handleDayChange(type) {
    const cur = _.cloneDeep(todState.begin);
    let cidx = _.findIndex(tradeDates, o => o.date === cur);
    if (type === 'last') {
      todState.begin = _.get(tradeDates, `[${cidx - 1}].date`, TODAY)
      todState.end = _.get(tradeDates, `[${cidx - 1}].date`, TODAY)
    } else if (type === 'next') {
      todState.begin = _.get(tradeDates, `[${cidx + 1}].date`, TODAY);
      todState.end = _.get(tradeDates, `[${cidx + 1}].date`, TODAY);
    }
    _getRates();
    _showIndexSpeed();
    _showPositionSort();
  }
  // 切换速率时间
  function handleSpeedTimes(type) {
    let cidx = _.findIndex(todState.stimes, o => o.value === todState.tvalue);
    if (type === 'last' && cidx > 0) {
      todState.tvalue = _.get(todState.stimes, `[${cidx - 1}].value`, TODAY)
    } else if (type === 'next' && cidx < _.size(todState.stimes) - 1) {
      todState.tvalue = _.get(todState.stimes, `[${cidx + 1}].value`, TODAY);
    }
  }
  // 表格数据源及表头
  const tableObj = {
    'limit': [['时间', 'xAxis'], ['股票', 'stock'], ['行业', 'industry'], ['板块', 'plate']],
    'two': [['名称', 'name'], ['持', 'pos']],
    'speed': [['代码', 'code'], ['名称', 'name'], ['速率', 'speed']],
    'limit_data': _.get(todState.isGet ? rates : routeDatas, 'limits', []),
    'two_data': positionSorts,
    'speed_data': _.get(speedVals, todState.tvalue, []),
  };
  const cols = _.get(tableObj, todState.type).map(n => {
    if (_.get(n, '[1]', '') === 'speed') {
      return {
        ...singleColumns(['速率', 'speed']),
        sorter: (a, b) => a.speed - b.speed,
        render: (text) => <Text strong>{_.round(parseFloat(text) * 100, 4) + '%'}</Text>
      }
    }
    if (_.get(n, '[1]', '') === 'pos') {
      return {
        ...singleColumns(['持', 'pos']),
        render: (text) => <Text style={{ color: TYP_COLOR[text] ?? 'black' }}>{text}</Text>
      }
    }
    return singleColumns([n[0], n[1]])
  });

  const chartProps = { style: { width: '100%', height: 185 } };
  const middleBarProps = { span: 8, style: { textAlign: 'center' } };
  const iconStyle = { color: '#ababab', fontSize: 14 };
  return <>
    <HeaderIcons
      title={'今日分析'}
      subs={_.get(routeDatas, 'subname', '')}
      pageKey='today_analysis'
      onPress={() => props.history?.goBack()}
    />

    <NewCard pad={4}>
      <Row align='middle' style={{ marginTop: 8, marginBottom: 12 }}>
        <Col {...middleBarProps}>
          <div onClick={() => handleDayChange('last')}>
            <Space size='small'>
              <DoubleLeftOutlined style={iconStyle} />
              <Text>前一日</Text>
            </Space>
          </div>
        </Col>
        <Col {...middleBarProps}>
          <div onClick={() => {
            todState.timeOpen = { open: true, value: todState.begin, stamp: moment(todState.begin).toDate(), key: 'single' };
          }}>
            <Text underline>{todState.begin}</Text>
          </div>
        </Col>
        <Col {...middleBarProps}>
          <div onClick={() => {
            if (moment().diff(moment(todState.begin), 'day') > 0) {
              handleDayChange('next')
            }
          }}>
            <Space size='small'>
              <Text>后一日</Text>
              <DoubleRightOutlined style={iconStyle} />
            </Space>
          </div>
        </Col>
      </Row>

      <NewDivider />

      <div style={{ display: 'flex' }}>
        <div id="main_charts" {...chartProps} />
      </div>

      <div style={{ display: 'flex' }}>
        <div id="main_charts_retreat" {...chartProps} />
      </div>

      <Row align='middle' style={{ marginTop: 24, marginBottom: 18 }}>
        <Col span={18}>
          <Segmented
            value={todState.type}
            options={TYPE_OPTIONS}
            onChange={(v) => todState.type = v}
          />
        </Col>
        <Col span={6} style={{ textAlign: 'center' }}>
          {todState.type === 'speed' ? <Space size='small'>
            <div onClick={() => handleSpeedTimes('last')}><LeftOutlined style={iconStyle} /></div>
            <div onClick={() => {
              if (isValidString(todState.tvalue)) {
                todState.tshow = true
              }
            }}>
              <Text>{todState.tvalue}</Text>
            </div>
            <div onClick={() => handleSpeedTimes('next')}><RightOutlined style={iconStyle} /></div>
          </Space> : <></>}
        </Col>
      </Row>

      <Table
        rowKey="key"
        dataSource={_.get(tableObj, todState.type + '_data', [])}
        columns={cols}
        // scroll={{ y: 550 }}
        bordered={false}
        size='small'
        pagination={false}
      />
    </NewCard>

    <NewCalendar
      values={[todState.begin, todState.end]}
      show={_.get(todState, 'timeOpen.open') ?? false}
      single
      onCancel={() => todState.timeOpen = false}
      onSubmit={(val) => {
        todState.begin = val[0];
        todState.end = val[1];
        todState.timeOpen = { key: '', open: false, value: '', stamp: new Date() };
        _getRates();
      }}
    />

    <EasySelect
      visible={todState.tshow}
      options={todState.stimes}
      maxHeight={410}
      onClose={() => todState.tshow = false}
      onPress={(v) => {
        todState.tshow = false;
        todState.tvalue = v;
      }}
    />

    <EasySelect
      visible={todState.syshow}
      options={INDEX_OPTIONS}
      maxHeight={410}
      onClose={() => todState.syshow = false}
      onPress={(v) => {
        if (_.includes(NO_SHOW_TODAY, v)) {
          message.info('暂无当日指数！');
          return;
        }
        todState.syshow = false;
        todState.symbol = v;
        todState.symbolName = INDEX_CODE_VALUE[v];
        _getRates();
      }}
    />

    <FloatButton.Group>
      <FloatButton icon={<SyncOutlined />} onClick={() => _getRates()} />
      <FloatButton description={_.head(todState.symbolName)} onClick={() => todState.syshow = true} />
      <FloatButton.BackTop />
    </FloatButton.Group>
  </>
}

export default TodayPage;
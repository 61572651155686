import React from 'react';
import { Table, Tag } from 'antd';
// import { CaretUpOutlined } from '@ant-design/icons';
import { singleColumns } from '@/utils/utils';
import moment from 'moment';
import _ from 'lodash';

const renderSide = (type) => type === 'BUY' ? <Tag color={'red'}>买入</Tag> : <Tag color={'green'}>卖出</Tag>
// 对比列表
const UntradeList = ({
  unTrading = [],
  fullParams = {},
  noneType = '',
}) => {

  const columnsUntrade = [
    singleColumns(['代码', 'stockCode', 70]),
    singleColumns(['名称', 'stockName', 70]),
    singleColumns(['贡献度', 'accountContributionRate', 80]),
    singleColumns(['平台', 'platform', 75]),
  ];
  const columnsNone = [
    {
      ...singleColumns(['平台', 'dir', 70]),
      render: (text) => _.toUpper(_.get(fullParams, `${text === 'left' ? 'baseType' : 'rightType'}`, ''))
    },
    {
      ...singleColumns(['股票', 'stockCode', 135]),
      render: (text, record) => {
        const conbindName = `${_.get(record, 'stockName', '')}  ${text}`;
        return conbindName
      }
    },
    singleColumns(['均价', 'avgPrice', 80]),
    singleColumns(['数量', 'tQuant', 75]),
    {
      ...singleColumns(['委托', 'ctime', 120]),
      render: (text, record) => moment(_.get(record, 'commissionTime')).format('HH:mm:ss.SSS')
    },
    {
      ...singleColumns(['成交', 'trtime', 120]),
      render: (text, record) => moment(_.get(record, 'transactTime')).format('HH:mm:ss.SSS')
    },
    {
      ...singleColumns(['方向', 'side', 65]),
      render: (text) => renderSide(text)
    },
  ];
  // console.log(unTrading)
  const isUntrade = noneType === 'untrade' ? true : false;
  const newCol = isUntrade ? columnsUntrade : columnsNone;
  return <Table
    rowKey={isUntrade ? 'id' : "key"}
    dataSource={unTrading}
    columns={newCol}
    scroll={{ x: _.sum(newCol.map(n => n.width)) }}
    bordered={false}
    size='small'
    pagination={false}
  />
}

export default UntradeList;
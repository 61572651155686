import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, Space, message, Spin } from 'antd';
import { getAllAccount, compareSubHistory, compareProductHistory } from '@/api/main';
import { useReactive, useUpdateEffect } from 'ahooks';
import {
  TWO_CHARTS, DEFAULT_CARRY, PRE_DAY_CN,
  calRate, createBaseLine, calRateFormula, renderSlice, isFullTimeRange,
} from '@/utils/chartInfo';
import { isValidArray, isValidNumber, isValidObj, labelValues, handlePlatformAccount } from '@/utils/utils';
import { CascaderMobile, NewDivider, MainSlider, TextSelect } from '@/utils/widgets';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const MA_CAL_NUM = 60;
const TODAY = moment().format('YYYY-MM-DD');
const DATE_OPTIONS = [
  labelValues(['周', 'WEEK']), labelValues(['月', 'MONTHS']),
  labelValues(['季', 'THREE_MONTH']), labelValues(['半年', 'SIX_MONTH']),
  labelValues(['年', 'ONE_YEAR']), labelValues(['三年', 'THREE_YEAR'])
];
const PRE_DAY = [PRE_DAY_CN];
// 判断是否是合法数组，但包括0
const validNumberIncludesZero = (num) => {
  let final = false;
  if (num === 0) {
    final = true;
  } else {
    final = isValidNumber(num);
  }
  return final;
}
const trimBtRest = (string) => string === 'bt_rest' ? 'bt' : string;
/**
 * 回测对比：子账户可选择BT平台的内容，对比收益率数据；新增产品层面逻辑及接口
 * 【240122】 移动均线提前60日数据，没有60日接口返回产品全部
 */
const BtCompare = (props) => {
  const [accOptions, setAccOptions] = useState({
    'bt': [], 'tamp': [], 'accoutMap': new Map(), 'productMap': new Map(),
  });
  const [update, setUpdate] = useState(0);
  const [update2, setUpdate2] = useState(0);
  const [fullSeries, setFullSeries] = useState([]); // 完整series数据
  const [compares, setCompares] = useState({});
  const [sliderValue, setSliderValue] = useState([]);
  const [sliderValue2, setSliderValue2] = useState([]);
  const [timeArr, setTimeArr] = useState([0]);
  const [timeNameArr, setTimeNameArr] = useState([]);
  const [option, setoption] = useState(TWO_CHARTS.main);
  const [option2, setoption2] = useState(TWO_CHARTS.avg);
  const compareState = useReactive({
    type: 'THREE_MONTH', bt: [], tamp: [], btType: '', bt_name: '回测', tamp_name: '实盘',
    visible: false, soptions: [], skey: '', count: 0, loading: false, accLoading: false,
    diffs: {}
  });
  const getPageKeys = _.get(props, 'pageKey', '');

  // tab切换获取当前收益率数据
  useEffect(() => {
    if (props.active === 'BTCOM') {
      _getAllAccount();
    }
  }, [props.active, props.upcount]);

  // 获取所有产品及账户
  async function _getAllAccount() {
    compareState.accLoading = true;
    const is_acc = getPageKeys === 'account' ? true : false;
    const is_reverse = _.get(props, 'isReverse', false);
    const res = await getAllAccount();
    const resFc = is_reverse ? await getAllAccount('FC') : {};
    if (_.get(res, 'code', '') === '200') {
      const accVal = handlePlatformAccount(res, resFc, is_acc);
      // console.log('accVal', accVal)
      const filterTamp = _.filter(_.get(accVal, 'platformArray', []), o => _.includes(o.value, 'tamp'))
      const filterBt = _.filter(_.get(accVal, 'platformArray', []), o => !_.includes(o.value, 'tamp'))
      // tamp账户单独进行提取；
      let newTamp = []; let tampAccs = [];
      filterTamp.map(n => {
        _.get(n, 'children', []).map(c => {
          tampAccs = _.concat(tampAccs, c.children)
        });
        newTamp = _.concat(newTamp, n.children);
      });
      setAccOptions({
        'tamp': newTamp,
        'bt': filterBt,
        'accoutMap': _.get(accVal, 'accoutMap'),
        'productMap': _.get(accVal, 'productMap'),
        'tampAccs': tampAccs,
      });
    }
    compareState.accLoading = false;
  }

  const plateform_keys = {
    'simx': 'simXSubAccountId',
    'bt': 'btSubAccountId', 'bt_rest': 'btSubAccountId', 'bt_testing': 'btSubAccountId'
  };
  const plateform_keys_pro = {
    'simx': 'simXProductId', 'simxVirtual': 'simxVirtualProductId',
    'bt': 'btProductId', 'bt_rest': 'btProductId', 'bt_testing': 'btProductId', 'btVirtual': 'btVirtualProductId',
  };
  // 获取账户/产品收益率
  async function getCompareDatas() {
    let params = {
      "dateType": compareState.type,
      "date": TODAY,
      "isCompare": true //是否仿真对比
    }
    let isReverse = false;
    const isAccountPage = getPageKeys === 'account' ? true : false;
    const isValidTamp = isAccountPage ? _.size(compareState.tamp) !== 2 : _.size(compareState.tamp) !== 1;
    if (isValidTamp) {
      message.info('请选择正确的实盘账户！');
      return
    } else {
      _.set(params, isAccountPage ? 'tampSubAccountId' : 'tampProductId', _.last(compareState.tamp));
      // tamp才有反采账户，判断当前选择的tamp是否是fc账户；
      const loginType = _.chain(_.get(accOptions, isAccountPage ? 'tampAccs' : 'tamp', []))
        .filter(o => o.value === _.last(compareState.tamp))
        .head()
        .get('centralizedLogin')
        .value();
      isReverse = loginType === 2 ? true : false;
    }
    let plate_key = ''; // simx或bt，获取后取值用
    let plate_pms_key = ''; // simx或bt，账户或产品，获取的参数key名称；
    const isValidBt = isAccountPage ? _.size(compareState.bt) !== 3 : _.size(compareState.bt) !== 2
    if (isValidBt) {
      message.info('请选择正确的仿真/回测账户！');
      return
    } else { // 获取时，bt_rest请求也是bt的字段
      const bt_key = _.head(compareState.bt); const bt_val = _.last(compareState.bt);
      plate_pms_key = isAccountPage ? plateform_keys[bt_key] : plateform_keys_pro[bt_key];
      _.set(params, plate_pms_key, bt_val);
      plate_key = trimBtRest(bt_key);
    }
    // 普通获取；
    compareState.loading = true;
    const res = isAccountPage ? await compareSubHistory(params) : await compareProductHistory(params);
    // 反采账户通过获取fc接口拿到数据，只保留tamp部分的id参数即可；
    let resFc = {};
    if (isReverse) {
      Reflect.deleteProperty(params, plate_pms_key);
      resFc = isAccountPage ? await compareSubHistory(params, 'FC') : await compareProductHistory(params, 'FC');
    }
    if (_.get(res, 'code', '') === '200') {
      const tampData = _.get(isReverse ? resFc : res, 'data.tamp', {});
      const btData = _.get(res, `data.${plate_key}`, {});
      const start_time = _.get(tampData, 'time', '');
      const tamp_time = _.get(tampData, 'timeList', []);
      const bt_time = _.get(btData, 'timeList', []);
      /**
       * 因移动均线需要之前20日的数据，所以起始日期需要按照start_time进行查找，而不是返回的preNet
       * 【tips】pointList是累计收益率，但是包含20日的累计，需要按照netValueList的点位重新计算
       */
      const tamp_start_idx = _.findIndex(tamp_time, o => o === start_time);
      // 净值计算每日收益
      const tampNetList = _.get(tampData, 'netValueList', []);
      const tampPreNetVal = _.get(tampData, 'preNetValue', 0);
      const btNetList = _.get(btData, 'netValueList', []);
      const btPreNetVal = _.get(btData, 'preNetValue', 0);
      const tamp_nets = tampNetList.map((p, i) => _.round(parseFloat(calRateFormula(p, i === 0 ? tampPreNetVal : tampNetList[i - 1])), DEFAULT_CARRY));   // 每日收益计算，(当日-前一日)/前一日
      const bt_nets = btNetList.map((p, i) => _.round(parseFloat(calRateFormula(p, i === 0 ? btPreNetVal : btNetList[i - 1])), DEFAULT_CARRY));
      let torder = []; // 生成时间及tamp为基准的数据
      // 已时间为基准，创建bt和tamp的数据
      tamp_time.map((t, i) => {
        const bt_fidx = bt_time.indexOf(t);
        let tobj = {
          'date': t,
          'order': moment(params.start).diff(moment(t), 'd'),
          'tindex': i, // tamp中的index位置
          'tamp_val': _.get(tampNetList, `[${i}]`, '-'), // tamp的净值
          'tnet': _.get(tamp_nets, `[${i}]`), //拿到对应的每日收益数据
          'bt_tindex': bt_fidx, // bt中对应时间的index值
          'bt_val': bt_fidx !== -1 ? _.get(btNetList, `[${bt_fidx}]`) : '-', // bt中的净值
          'bnet': bt_fidx !== -1 ? _.get(bt_nets, `[${bt_fidx}]`) : '-' //bt 拿到对应的每日收益数据
        }
        // 计算均线点位值，如果有时间差或无数据，则不会计算，避免数据偏差；
        let net_val = '-';
        if (validNumberIncludesZero(tobj.tnet) && validNumberIncludesZero(tobj.bnet)) {
          // bt - tamp 的每日收益，作为移动均线的当日点位
          net_val = _.round(tobj.bnet - tobj.tnet, DEFAULT_CARRY);
        }
        _.set(tobj, 'avg_val', net_val);
        torder.push(tobj);
      });
      const newOrders = _.orderBy(torder, ['order'], ['desc']); // 排序，时间早至后来时间
      let compareObj = {
        // [tips] 净值数据增加date字段用于slice时校验
        'tamp_net': tampNetList.map((n, i) => { return { 'date': tamp_time[i], 'val': n } }), // slice重新计算是使用
        'bt_net': btNetList.map((n, i) => { return { 'date': bt_time[i], 'val': n } }),
        'timeOrder': newOrders, // 更新后用该数据进行生成图表，并计算
        // [tips] 开始时间如果不在timeList里面，则默认加载第20个日期，避免没有开始日期的情况
        'start_time': tamp_start_idx !== -1 ? start_time : _.get(tamp_time, `[${MA_CAL_NUM}]`),
        'start_idx': tamp_start_idx !== -1 ? tamp_start_idx : MA_CAL_NUM,
      }
      // pre 前一交易日数据判断，start_idx不符合条件，则默认第19位，否则按照start_idx-1进行处理
      if (tamp_start_idx > 0 && tamp_start_idx !== -1) {
        compareObj.tamp_pre = tampNetList[tamp_start_idx - 1];
      } else if (tamp_start_idx === 0) {
        compareObj.tamp_pre = tampPreNetVal;
      } else {
        compareObj.tamp_pre = tampNetList[MA_CAL_NUM - 1];
      }
      // 计算bt的pre值
      const bt_start_idx = _.findIndex(bt_time, o => o === compareObj.start_time);
      if (bt_start_idx > 0 && bt_start_idx !== -1) {
        compareObj.bt_pre = btNetList[bt_start_idx - 1];
      } else if (bt_start_idx === 0) {
        compareObj.bt_pre = btPreNetVal;
      } else {
        // 如bt值小于20个，默认将pre值赋值为返回值，保证区间的曲线和收益差可显示；
        if (_.size(btNetList) < MA_CAL_NUM) {
          compareObj.bt_pre = btPreNetVal;
        } else {
          compareObj.bt_pre = btNetList[MA_CAL_NUM - 1];
        }
      }
      setCompares(compareObj);
    }
    setUpdate(_.round(update + 0.1, 1));
    compareState.loading = false;
  }

  useEffect(() => {
    let myChart = props.myChart; let myChart2 = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('bt_compare'));
    myChart2 = echarts.init(document.getElementById('bt_compare_avg'));
    myChart.showLoading({
      text: '数据获取中',
      effect: 'whirling'
    });

    let newOption = _.cloneDeep(option); let newOption2 = _.cloneDeep(option2);
    const newCompares = handleAvgData(
      _.get(compares, 'start_idx', 0), // 开始日期的index值
      _.size(_.get(compares, 'timeOrder')) // 结束日期使用timeorder.length，也就是全部遍历
    );
    // 对serise里面的data净值数据进行收益率曲线计算
    let newsData = calSeriseData(newCompares.sdata);
    let newTimes = newCompares.times;
    newOption.xAxis.data = _.concat(PRE_DAY, newTimes);
    newOption.series = [newsData[0], newsData[1]];
    let time_array = newTimes.map((n, i) => i);
    setTimeArr(time_array);
    setTimeNameArr(newTimes);
    // 赋值slider的滑动数据
    if (_.last(time_array)) {
      setSliderValue([0, _.last(time_array)]);
      setSliderValue2([0, _.last(time_array)]);
    }
    // 均线bar图表
    newOption2.xAxis.data = _.concat(PRE_DAY, newCompares.barTime);
    _.set(newOption2, 'series[0].itemStyle.color', '#017ab1');
    newOption2.series[0].data = _.concat([0], newCompares.barDatas);
    newOption2.series[0].name = '收益差';
    compareState.diffs = newCompares.diffAll;
    let fulldatas = [0];// 计算最大最小值用全部数据数组
    [2, 3, 4, 5].map(idx => { //与ma曲线创建时的index值相同
      _.set(newOption2, `series[${idx - 1}]`, newsData[idx]);
      fulldatas = _.concat(fulldatas, _.get(newsData, `[${idx}].data`, []));
    });
    // 两个Y轴同等比例计算，0 的位置大致相同
    const minMax = calMinMaxVal(newCompares.barDatas, fulldatas);
    _.set(newOption2, 'yAxis[0].min', minMax.min1);
    _.set(newOption2, 'yAxis[0].max', minMax.max1);
    _.set(newOption2, 'yAxis[1].min', minMax.min2);
    _.set(newOption2, 'yAxis[1].max', minMax.max2);
    // console.log('newCompares.barTime', newCompares)
    setFullSeries({
      'time': newTimes,
      'barTime': newCompares.barTime,
      'newOption': newOption,
      'newOption2': newOption2,
    });

    setoption(newOption); setoption2(newOption2);
    myChart.setOption(newOption, true); myChart2.setOption(newOption2, true);
    myChart.hideLoading();
    myChart.resize(); myChart2.resize();
    echarts.connect([myChart, myChart2]);
  }, [update]);

  useUpdateEffect(() => {
    let myChart = props.myChart; let myChart2 = props.myChart;
    myChart = echarts.init(document.getElementById('bt_compare'));
    myChart2 = echarts.init(document.getElementById('bt_compare_avg'));

    let newOption = _.cloneDeep(option); let newOption2 = _.cloneDeep(option2);
    const timeOrders = _.get(compares, 'timeOrder'); // 排序完成的时间数据
    const isFullRange = isFullTimeRange(sliderValue, timeArr);
    if (isFullRange) {
      newOption = _.cloneDeep(_.get(fullSeries, 'newOption'));
      newOption2 = _.cloneDeep(_.get(fullSeries, 'newOption2'));
    } else {
      // 裁切用相同的逻辑，处理timeOrder,并重新计算
      const sliceTimes = renderSlice(timeNameArr, sliderValue[0], sliderValue[1]);
      let firstIdx = _.findIndex(timeOrders, o => o.date === _.head(sliceTimes));
      let lastIdx = _.findIndex(timeOrders, o => o.date === _.last(sliceTimes));
      const newCompares = handleAvgData(firstIdx, lastIdx);
      // 裁切后重新计算收益率
      let newsData = calSeriseData(newCompares.sdata, firstIdx, _.head(sliceTimes));
      newOption.xAxis.data = newCompares.times; // 【bug-fix】裁切后时间轴错位，裁切不加前一交易日;
      newOption.series = [newsData[0], newsData[1]];
      newOption2.xAxis.data = newCompares.barTime;
      newOption2.series[0].data = newCompares.barDatas;
      compareState.diffs = newCompares.diffAll;
      let fulldatas = [];// 计算最大最小值用全部数据数组
      [2, 3, 4, 5].map(idx => {
        _.set(newOption2, `series[${idx - 1}]`, newsData[idx]);
        fulldatas = _.concat(fulldatas, _.get(newsData, `[${idx}].data`, []));
      });
      // 重新计算最大最小值
      const minMax = calMinMaxVal(newCompares.barDatas, fulldatas);
      _.set(newOption2, 'yAxis[0].min', minMax.min1);
      _.set(newOption2, 'yAxis[0].max', minMax.max1);
      _.set(newOption2, 'yAxis[1].min', minMax.min2);
      _.set(newOption2, 'yAxis[1].max', minMax.max2);
    }

    setoption(newOption); setoption2(newOption2);
    myChart.setOption(newOption, true); myChart2.setOption(newOption2, true);
    myChart.resize(); myChart2.resize();
  }, [update2]);
  //计算并创建均线和收益率数据; startKey为必填，lastKey如完整数据就传timeOrder的最大值
  function handleAvgData(startKey, lastKey) {
    const timeOrders = _.get(compares, 'timeOrder'); // 排序完成的时间数据
    const combindBt = _.head(compareState.bt) + '_' + _.last(compareState.bt); // 拼接平台及id的字符串，去查找名称
    const combindTamp = 'tamp_' + _.last(compareState.tamp);
    const ma_line_obj = { isAvg: true, isDash: true, yAxisIndex: 1 };
    let times = [];
    let sdata = [   // 创建serise数据，符合下方均线计算方式
      createBaseLine('#3a668d', { key: 'bt', name: '回测', fname: getPageKeys === 'account' ? accOptions.accoutMap.get(combindBt) : accOptions.productMap.get(combindBt), }),
      createBaseLine('#fb6539', { key: 'tamp', name: '实盘', fname: getPageKeys === 'account' ? accOptions.accoutMap.get(combindTamp) : accOptions.productMap.get(combindTamp) }),
      createBaseLine('#753d7f', { name: 'MA60', key: 'avg', maVal: 60, ...ma_line_obj }),
      createBaseLine('#e07600', { name: 'MA20', key: 'avg', maVal: 20, ...ma_line_obj }),
      createBaseLine('#00b6a9', { name: 'MA10', key: 'avg', maVal: 10, ...ma_line_obj }),
      createBaseLine('#f8c600', { name: 'MA5', key: 'avg', maVal: 5, ...ma_line_obj })
    ];
    let barTime = []; let barDatas = [];
    // 根据order数据进行遍历
    if (isValidArray(timeOrders)) {
      let count = 0; // 数据index
      timeOrders.map((n, i) => {
        if (i >= startKey && i <= lastKey) { // 从起始位开始处理
          times[count] = _.get(n, 'date');
          // 直接处理series.data数据，每个时间点进行赋值或者计算
          sdata = sdata.map(d => {
            if ('isAvg' in d) { // 平均线对点位进行计算
              const sindex = i - (_.get(d, 'maVal') - 1);// 往前推n日，包含当天，所以需要-1，不减去当日
              const avgs = calAvgPoints(timeOrders, sindex, _.get(d, 'maVal'));
              const avg_day_val = _.get(n, `avg_val`, 0);
              _.set(d, `data[${count}]`, avgs !== 0 ? avgs : '-'); // 均线为0则是空数据，不显示
              //  bar图表点位是bt-tamp的每日收益相减；只执行一次（d.name === 'MA20'）
              if (isValidNumber(avg_day_val) && d.name === 'MA20') {
                barTime.push(_.get(n, 'date'));
                barDatas.push(avg_day_val);
              }
            } else { // bt和tamp数据直接赋值
              _.set(d, `data[${count}]`, _.get(n, `${d.key}_val`));
            }
            return d;
          });
          count++;
        }
      });
    };
    const diff = { 'day': _.size(barDatas), 'sum': _.sum(barDatas) };
    // time：收益率时间。sdata：serise完整数组，barTime,barData是收益差和均线的饼图数据
    return {
      times, sdata, barTime, barDatas,
      'diffAll': { ...diff, 'all': _.round(diff.sum / (diff.day || 1), 4) }
    }
  }
  // 计算均线：前20日数据综合求平均，返回一个点位数据; [与策略系统PushPage-AvgCharts相同计算]
  function calAvgPoints(fullOrder = {}, sindex = -1, maNum = MA_CAL_NUM) {
    //fullOrder:完整order数据; sindex 从哪个index值开始计算；maNum将ma计算天数
    let avalues = []; let final = 0;
    if (sindex > -1) {
      // 从起始位开始遍历{均线日}次，拿到{均线日}个数据=avalues
      for (let index = _.cloneDeep(sindex); index < (sindex + maNum); index++) {
        avalues.push(_.get(fullOrder, `[${index}].avg_val`, 0)); // 该值根据均线点位的数据路径进行get
      }
    }
    // 累加计算平均
    if (isValidArray(avalues)) {
      let is_full = true;
      // 包含{均线日}完整数据可进行计算
      avalues.map(a => {
        if (!a) {
          is_full = false;
        }
      });
      if (is_full) {
        final = _.chain(avalues).sum().divide(maNum).round(6).value();
      }
    }
    return final;
  }

  // 重新计算serise的收益率数据；累积计算
  function calSeriseData(sdata = [], sliceIndex = 0, sliceTimes = '') {
    let newSdata = [];
    // 默认是获取计算的pre值
    let preObj = { 'bt': _.get(compares, `bt_pre`), 'tamp': _.get(compares, `tamp_pre`) };
    // 裁切重新计算开始值，取前一日数据
    const isSlice = (sliceIndex && (sliceIndex - 1) > -1 && sliceTimes) ? true : false;
    // 净值数据重新计算bt的index值，避免bt与tamp时间数据不同时，需要处理不同的index值
    if (isSlice) {
      const btIndex = _.findIndex(_.get(compares, 'bt_net'), o => o.date === sliceTimes);
      if (btIndex > -1) {
        preObj.bt = _.get(compares, `bt_net[${btIndex}].val`); //【bug-fix】原index-1，但截取计算从截取当天计算，无需提前一位
      }
      preObj.tamp = _.get(compares, `tamp_net[${sliceIndex}].val`);
    }
    newSdata = _.cloneDeep(sdata).map(s => {
      if (s.key !== 'avg') {
        let newData = isValidArray(_.get(s, 'data', []))
          ? _.concat(
            isSlice ? [] : [0], // 裁切无需增加0位
            calRate(_.get(s, 'data'), _.get(preObj, s.key))
          ) : [];
        _.set(s, 'data', newData);
      }
      return s;
    });
    return newSdata;
  }
  // 计算图表最大最小值；浮动比例
  function calMinMaxVal(data1, data2) {
    let newData2 = []
    data2.map(n => {
      if (isValidNumber(n)) {
        newData2.push(n)
      }
    });
    let min_bar = _.min(data1); let max_bar = _.max(data1); // 最大最小值
    let total_bar = Math.abs(min_bar) + Math.abs(max_bar); // 求总数
    let pas_rate = Math.abs(_.round((max_bar / total_bar) * 100, 2)); // 正数占比 最后是百分制%
    let nag_rate = Math.abs(_.round((min_bar / total_bar) * 100, 2)); // 负数占比
    let maxAvgLine = _.ceil(_.max(newData2), 2); // 移动均线最大值;向上取位数，保持最大值
    let nagAvgLine = _.round((maxAvgLine / pas_rate * 100) * (nag_rate / 100), 3); // 用正数占比求出均线总数，乘以负数占比(除100恢复正常计算 10%=0.1)
    // 最小值大于0时，强制最小轴为刻度0
    if (min_bar > 0) {
      min_bar = 0; nagAvgLine = 0;
    }
    if (max_bar < 0) { // 最大小于0 
      max_bar = 0; maxAvgLine = 0;
    }
    //【bugfix】 data2数据过少时，如全负数，会出现nagAvgLine是负数，所以增加小于0的判断，负数差距大已最小值为标准；
    if (nagAvgLine < 0) {
      if (nagAvgLine < _.min(newData2)) {
        nagAvgLine = _.min(newData2)
      }
    } else {   // 如果计算处理的最小比例大于最小值，那么直接使用最小值; 
      if (nagAvgLine > _.min(newData2)) {
        nagAvgLine = _.min(newData2)
      }
    }
    return {
      'min1': _.round(min_bar, 2), 'max1': _.ceil(max_bar, 2),
      'min2': nagAvgLine < 0 ? nagAvgLine : -nagAvgLine,// 最小值保持在负数
      'max2': maxAvgLine
    }
  }
  // 选择bt、实盘账户
  function onSelectFinish(v, value) {
    compareState.visible = false;
    // console.log('完成', v, value)
    _.set(compareState, compareState.skey, v);
    if (_.includes(['bt', 'tamp'], compareState.skey)) {
      const get_name = _.chain(value).last().get('label').split('').slice(0, 2).join('').value();
      _.set(compareState, compareState.skey + '_name', get_name + '...');
    }
    // 参数符合条件即可获取数据
    let is_valid = true;
    labelItems.map(n => {
      if (_.size(_.get(compareState, n.key)) === 0) {
        is_valid = false;
      }
    });
    if (_.includes(['WEEK', 'MONTHS'], v) && _.size(timeArr) > 20) {
      afterSliderChange([_.last(timeArr) - (v === 'WEEK' ? 5 : 20), _.last(timeArr)]);
      return
    }
    if (is_valid) {
      getCompareDatas();
    }
  }
  // 滑动change
  function afterSliderChange(v) {
    setSliderValue(v);
    setSliderValue2(v);
    setUpdate2(_.round(update2 + 0.1, 1));
  }

  const labelItems = [
    { label: '周期', key: 'type' },
    { label: compareState.tamp_name, key: 'tamp' },
    { label: compareState.bt_name, key: 'bt' },
  ];
  const selectObj = {
    'type': DATE_OPTIONS,
    'bt': _.get(accOptions, 'bt', []),
    'tamp': _.get(accOptions, 'tamp', []),
  };
  const getOptions = _.get(fullSeries, 'newOption.series', []) ?? [];
  return <>
    <Spin spinning={compareState.accLoading}>
      <Row align='middle'>
        {labelItems.map(n => <Col key={'label' + n.key} span={8} style={{ textAlign: 'center' }}>
          <TextSelect
            name={n.key === 'type' ?
              _.chain(DATE_OPTIONS).filter(o => o.value === _.get(compareState, 'type')).head().get('label').value()
              : n.label}
            onPress={() => {
              compareState.soptions = selectObj[n.key];
              compareState.visible = true;
              compareState.skey = n.key;
              compareState.count++;
            }} />
        </Col>)}
      </Row>
    </Spin>

    <NewDivider />

    <div className='compare-bar'>
      <Row gutter={[8, 8]}>
        {getOptions.map((n, i) => <Col span={24} key={n.name + i}>
          <Text>{`${n.name} : ${n.fname ?? ''}`}</Text>
        </Col>)}
      </Row>
    </div>

    <Spin spinning={compareState.loading}>
      <div style={{ display: 'flex' }}>
        <div
          id="bt_compare"
          style={{ width: '99%', height: 310 }}
        />
      </div>
    </Spin>

    <MainSlider
      isNotToday={compareState.type !== 'TODAY' ? true : false}
      timeArray={timeNameArr}
      max={_.last(timeArr) || 0}
      svalue={sliderValue2}
      onSliderChange={(v) => setSliderValue2(v)}
      onSliderAfterChange={(v) => afterSliderChange(v)}
      onRedo={() => afterSliderChange([0, _.last(timeArr)])}
    />

    <div style={{ display: 'flex', marginTop: 12 }}>
      <div
        id="bt_compare_avg"
        style={{ width: '99%', height: 300 }}
      />
    </div>

    {isValidObj(compareState.diffs) && <div style={{
      padding: '2px 4px', backgroundColor: '#f5f5f5', borderRadius: 6, textAlign: 'center',
      marginBottom: 24
    }}>
      <Space>
        <Text type='secondary'>平均收益差:</Text>
        <Text strong>{_.get(compareState.diffs, 'all', '')}</Text>
      </Space>
    </div>}

    <CascaderMobile
      curKey={compareState.skey}
      upcount={compareState.count}
      visible={compareState.visible}
      options={compareState.soptions}
      onFinish={(v, value) => onSelectFinish(v, value)}
      onClose={() => compareState.visible = false}
    />
  </>
}

export default BtCompare;
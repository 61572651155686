import _ from 'lodash';
//————————————————————————————交易记录-tradeChart——————————————————————————————
let nFifty = [];
let pFifty = [];
for (let index = -50; index < 51; index++) { // 生成-50到-1 1到50的数组
  if (index < 0) {
    nFifty.push(index);
  } else if (index > 0) {
    pFifty.push(index)
  }
}
const RANGE_ARRAY = [-50, -40, -30, -20, -10, -5, -1, -0.5, 0, 0.5, 1, 5, 10, 20, 30, 40, 50]; //【统计】 收益率区间范围array
const RANGE_ARRAY_EX = [- 5, -4, -3, -2, - 1, -0.8, -0.6, -0.4, -0.2, -0.1, 0, 0.1, 0.2, 0.4, 0.6, 0.8, 1, 2, 3, 4, 5]; // 【超额】
const RANGE_ARRAY_PLUS = _.concat(nFifty, [-0.5, 0, 0.5], pFifty); // 【统计+】基础上切分成1%为分隔的数组
export const NEW_RANGE_ARRAY = {
  'ex': RANGE_ARRAY_EX,
  'range': RANGE_ARRAY,
  'range_plus': RANGE_ARRAY_PLUS
}
// 根据收益率值返回区间的名称，并作为分组的唯一标识；
export const xnamePro = (v, type = 'range') => {
  let nIndex = NEW_RANGE_ARRAY[type].indexOf(v);
  const xnameObj = {
    '0': '平盘',
    '0.5': '0% ~ 1%',// 该统计算法因 0 区间的特殊性，%1分类是特殊处理显示
    '-0.5': '-1% ~ 0%',
    '-0.1': '负.1',// 0.1 和 0.5是自定义1-0之间的统计数字，作用是在1-0之前增增加特殊运算标识
    '0.1': '正.1',
  }
  const xnameVals = {
    '0': 0,
    '0.5': 1,// 该统计算法因 0 区间的特殊性，%1分类是特殊处理显示
    '-0.5': -1,
    '-0.1': -1,// 0.1 和 0.5是自定义1-0之间的统计数字，作用是在1-0之前增增加特殊运算标识
    '0.1': 1,
  }
  if (xnameObj[v]) { // 处理特殊字段
    return { 'xname': xnameObj[v], 'value': xnameVals[v] };
  } else {
    if (type === 'range_plus') {
      return { 'xname': String(NEW_RANGE_ARRAY[type][nIndex]), 'value': NEW_RANGE_ARRAY[type][nIndex] }; // string类型才可以点击是匹配name字段
    }
    if (v < 0) { //如果不是最大和最小，显示区间信息； 
      return {
        'xname': nIndex === 0 ? `<${v}%` : `${NEW_RANGE_ARRAY[type][nIndex - 1]}% ~ ${v}%`,
        'value': nIndex === 0 ? v : NEW_RANGE_ARRAY[type][nIndex - 1],
      }
    } else if (v > 0) {
      return {
        'xname': nIndex === (_.size(NEW_RANGE_ARRAY[type]) - 1) ? `>${v}%` : `${v}% ~ ${NEW_RANGE_ARRAY[type][nIndex + 1]}%`,
        'value': nIndex === (_.size(NEW_RANGE_ARRAY[type]) - 1) ? v : NEW_RANGE_ARRAY[type][nIndex + 1]
      }
    }
  }
}
// 查找收益率在RANGE中的index值,【合并两种同类算法】; 返回{idx:对应RANGE中index值,name：每条对应的分组名称}
export const findRangeIndex = (v, type = 'range') => {
  const top = _.last(NEW_RANGE_ARRAY[type]);
  const breakNum = { 'ex': 0.1, 'range': 0.5, 'range_plus': 0.5 }; //间隔字符，特殊处理0周边的区间数，进行正负处理
  const gapNum = { 'ex': 0.2, 'range': 1, 'range_plus': 1 }; // 间隔数值
  const findZeroIndex = _.findIndex(NEW_RANGE_ARRAY[type], o => o === 0);
  const findnOneIndex = _.findIndex(NEW_RANGE_ARRAY[type], o => o === -breakNum[type]); // nagetive间隔数
  const findpOneIndex = _.findIndex(NEW_RANGE_ARRAY[type], o => o === breakNum[type]); // positive间隔数
  let idx = 0;
  if (v === 0) {
    idx = findZeroIndex; // 0 进行直接返回index值
  } else if (v < 0) {
    if (v <= -top) { // 最小值，返回第一位
      idx = 0
    } else if (v > -gapNum[type]) {//0 附近的间隔数，范返回负间隔数
      idx = findnOneIndex;
    } else {
      for (let index = 0; index < _.size(NEW_RANGE_ARRAY[type]); index++) {
        if (v <= NEW_RANGE_ARRAY[type][index]) {
          idx = index;
          break;
        }
      }
    }
  } else if (v > 0) {
    if (v >= top) { // 最大值，返回最后一位
      idx = _.size(NEW_RANGE_ARRAY[type]) - 1
    } else if (v < gapNum[type]) { // 正间隔数
      idx = findpOneIndex;
    } else { // 正数可以反向循环，实现小于<开口向左，大于开口向右，实现相反的分组逻辑
      for (let index = _.size(NEW_RANGE_ARRAY[type]) - 1; index > 0; index--) {
        if (v >= NEW_RANGE_ARRAY[type][index]) {
          idx = index;
          break;
        }
      }
    }
  }
  return { idx, name: _.get(xnamePro(NEW_RANGE_ARRAY[type][idx], type), 'xname') }
};

export const renderDiv = (a, b, isRate = false, carry = 2) => a && b ? _.round(a / b, carry) + (isRate ? '%' : '') : 0;
// 计算y轴部分超出的值，max和markpoint，因y轴默认取最大值，有的时候会顶头不显示，所以需要计算额外部分；
export const renderExtraSize = (size, type) => {
  const breakArray = [10, 20, 30, 50, 100, 200]; // 对比size大小的数组
  const sizeObj = { 'range': [1, 2, 3, 5, 8, 10], 'range_plus': [1, 2, 3, 5, 8, 10], 'ex': [3, 6, 8, 10, 12, 20], 'max': [8, 10, 14, 18, 22, 28] }; // 分类，另外增加的数值；
  const maxObj = { 'range': 20, 'range_plus': 20, 'max': 30 }; // 最大值
  let extraVal = 0;
  if (size > 200) { // 超过200直接创建最大值
    extraVal = size + maxObj[type];
  } else { // 遍历，取第一个size小于的数值条件作为结果
    for (let index = 0; index < _.size(breakArray); index++) {
      if (size < breakArray[index]) {
        extraVal = size + sizeObj[type][index];
        break;
      }
    }
  }
  return extraVal;
}
// 计算正收益及超额部分
export const calExArray = (array) => {
  let final = 0; let finalSize = 0;
  if (array && _.size(array) > 0) {
    array.map(n => {
      final = final + n['tRate'];
      finalSize = finalSize + n['tSize'];
    })
  }
  return { rate: final, size: finalSize }
}

export const TRADE_OPTION = {
  bar: {
    tooltip: {
      trigger: 'item',
      //formatter: renderFormatter
    },
    xAxis: {
      type: 'category',
      data: [],
      axisLabel: { interval: 0, rotate: 50 },
      axisLine: { show: false }
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: '统计',
        type: 'bar',
        label: {
          show: true,
        },
        data: [],
        markPoint: { data: [] }
      }
    ]
  },
  line: {
    xAxis: {
      type: 'category',
      data: []
    },
    yAxis: {
      type: 'value',
      splitNumber: 3
    },
    series: [{
      name: '收益率',
      type: 'line',
      symbol: 'circle',
      lineStyle: {
        color: '#ffbb00',
      },
      itemStyle: {
        color: '#ffbb00'
      },
      data: [],
      markPoint: {
        data: []
      }
    }],
    tooltip: {
      trigger: 'axis',
    }
  }
}
import { COMMON_CHART } from './chartInfo';
import _ from 'lodash';

const upColor = '#ec0000';
const upBorderColor = '#ec0000';
const downColor = '#00da3c';
const downBorderColor = '#00da3c';
const OPTIONS_KLINE = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    },
    formatter: function (params) {
      //console.log(params)
      let maArray = [];
      let maString = '';
      let k_data = _.get(params, '[0]', {});
      if (_.size(params) > 1) {
        maArray = _.drop(params);
        maArray.map(n => {
          maString = maString + `<span style='color=${n.color}'>${n.seriesName}</span>` + ' :' + n.value + '<br>'
        })
      }

      return '开盘: ' + (k_data.value[1] || '') + '<br>' +
        '收盘: ' + (k_data.value[2] || '') + '<br>' +
        '最高: ' + (k_data.value[3] || '') + '<br>' +
        '最低: ' + (k_data.value[4] || '') + '<br>' + maString
    }
  },
  grid: { left: '10%', right: '10%', bottom: '15%' },
  xAxis: {
    type: 'category',
    data: [],
    boundaryGap: false,
    axisLine: { onZero: false },
    splitLine: { show: false },
    min: 'dataMin',
    max: 'dataMax'
  },
  yAxis: [{
    name: '股价',
    scale: true,
    splitArea: { show: true }
  }, {
    type: 'value',
    name: '波幅(%)',
    position: 'right',
    axisLine: { show: false },
    splitArea: { show: false },
    splitLine: { show: false },
    min: 0,
    max: 1,
  }],
  dataZoom: [
    { type: 'inside', start: 0, end: 100 },
    { show: true, type: 'slider', top: '90%', start: 0, end: 100, height: 12 }
  ],
  series: [
    {
      name: '分钟K',
      type: 'candlestick',
      data: [],
      itemStyle: {
        color: upColor,
        color0: downColor,
        borderColor: upBorderColor,
        borderColor0: downBorderColor
      },
      markPoint: {
        symbol: 'pin',
        // symbolSize:50,
        data: []
      }
    },
    {
      name: 'MA5',
      type: 'line',
      data: [],
      smooth: true,
      showSymbol: false,
      lineStyle: {
        width: 1,
        color: '#f8b318',
        opacity: 0.7
      }
    },
  ]
}

const OPTIONS_KLINE_RATE = {
  tooltip: {
    trigger: 'axis',
    axisPointer: { type: 'cross' }
  },
  legend: { data: ['收益K', '指数K'] },
  grid: { left: '9%', right: '3%', bottom: '14%', top: '10%' },
  xAxis: {
    type: 'category',
    data: [],
    boundaryGap: false,
    axisLine: { onZero: false },
    splitLine: { show: false },
    min: 'dataMin',
    max: 'dataMax'
  },
  yAxis: {
    name: '收益',
    scale: true,
    splitArea: { show: true }
  },
  // dataZoom: [
  //   { type: 'inside', start: 0, end: 100 },
  //   { show: true, type: 'slider', bottom: '5%', start: 0, end: 100, height: 12 }
  // ],
  series: [
    {
      name: '收益K',
      type: 'candlestick',
      data: [],
      itemStyle: {
        color: upColor,
        color0: downColor,
        borderColor: upBorderColor,
        borderColor0: downBorderColor
      },
    },
    {
      name: '指数K',
      type: 'candlestick',
      data: [],
      itemStyle: {
        color: '#ff9882',
        color0: '#adb880',
        borderColor: '#ff9882',
        borderColor0: '#adb880'
      },
    },
  ]
};

const EXTRA_COMPARE = {
  grid: { left: '8%', right: '3%', bottom: '10%', top: '10%' },
  xAxis: {
    type: 'category',
    ...COMMON_CHART.rateX
  },
  yAxis: [{ // 对比指数使用右侧Y轴，成交量作为右侧第二Y轴
    type: 'value',
    ...COMMON_CHART.rateY,
    position: 'left',
  }],
  series: [],
  tooltip: { trigger: 'axis' },
};

export {
  EXTRA_COMPARE,
  OPTIONS_KLINE,
  OPTIONS_KLINE_RATE
}
import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, Segmented, Spin, Button, message } from 'antd';
import { LeftOutlined, DoubleLeftOutlined, DoubleRightOutlined, RightOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { useMount, useReactive } from 'ahooks';
import { klineDetail } from '@/api/main';
import { labelValues, isValidArray } from '@/utils/utils';
import { NewCard, HeaderIcons, NewDivider, Selections, NewCalendar, TextSelect } from '@/utils/widgets';
import { OPTIONS_KLINE } from '@/utils/chartInfo2';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';

const FORMAT = 'YYYY-MM-DD';
const TODAY = moment().format(FORMAT);
const RANGE_TIME = [moment().subtract(90, 'd').format(FORMAT), TODAY];
const { Text } = Typography;
const PAGEKEY_BTN_OBJ = {
  'pro_pos': '持仓', 'pro_record': '记录',
  'acc_pos': '持仓', 'acc_record': '记录',
}
/**
 *  股票K线页面;
 *  routeDatas:list=调整前持仓/记录的数据; pageKey=调整页面key；其他record信息，动态显示股票名称与代码
 */
const KlinePage = (props) => {
  const routeDatas = _.get(props, 'location.state', {});
  const mobxStore = useLocalObservable(() => store);
  const [tradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const [stockList] = useState(isValidArray(_.get(routeDatas, 'list')) ? _.get(routeDatas, 'list').map(n => labelValues([n.stockName, n.stockCode])) : [])
  const [update, setUpdate] = useState(0);
  const [kvalue, setKvalue] = useState({});
  const kState = useReactive({
    begin: TODAY, end: TODAY, loading: false, type: 'minute',
    timeOpen: { key: '', open: false, value: '', stamp: new Date() },
    visible: false, symbol: _.get(routeDatas, 'symbol', ''), name: _.get(routeDatas, 'stockName', ''),
    kval: [], preClose: 0,
  });
  const page_key = _.get(routeDatas, 'pageKey', '');
  const [option, setoption] = useState({
    ...OPTIONS_KLINE,
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'cross' },
      formatter: function (params) {
        // console.log(params)
        let maArray = [];
        let maString = '';
        const ks = _.get(params, '[0]', {});
        const get_x = _.get(ks, 'axisValue', '');
        if (_.size(params) > 1) {
          maArray = _.drop(params);
          maArray.map(n => {
            maString = maString + `<span style='color=${n.color}'>${n.seriesName}</span>` + ' :' + n.value + '<br>'
          })
        }
        const kvalArr = Array.from(kState.kval);
        // console.log('kval', kvalArr);
        const open = ks.value[1] ?? ''; const close = ks.value[2] ?? '';
        const high = ks.value[3] ?? ''; const low = ks.value[4] ?? '';
        const amp = _.round((high - low) / low, 4); // 计算振幅
        let tidx = _.findIndex(kvalArr, o => o[0] === get_x);
        const pre = _.get(kvalArr, `[${tidx - 1}][2]`, 0); // 昨日收盘
        const amp2 = tidx > 0 ? _.round((close - pre) / pre, 4) : _.round((close - kState.preClose) / kState.preClose, 4)
        let baseString = '开盘: ' + open + '<br>' +
          '收盘: ' + close + '<br>' +
          '最高: ' + high + '<br>' +
          '最低: ' + low + '<br>';
        let ampString = '振幅: ' + `${_.round(amp * 100, 2)}%` + '<br>' +
          '波幅: ' + `${_.round(amp2 * 100, 2)}%` + '<br>';
        return kState.type === 'minute'
          ? baseString + maString
          : baseString + ampString + maString;
      }
    },
  });

  useMount(() => {
    _klineDetail();
  });
  // 获取K线数据；分钟/日K
  async function _klineDetail() {
    if (kState.symbol) {
      kState.loading = true;
      let params = {
        symbol: kState.symbol,
        beginDate: kState.begin,
        endDate: kState.end,
        type: kState.type
      }
      const res = await klineDetail(params);
      if (_.get(res, 'code', '') === '200') {
        const is_same = moment(params.beginDate).isSame(params.endDate, 'D'); // 同一天不显示日期
        let timeList = []; let priceList = [];
        let newValueList = []; let fullList = [];
        if (isValidArray(_.get(res, 'data.pointList'))) {
          _.get(res, 'data.pointList', []).map(n => { // time open close high low revenue volume amount(成交额)
            const newTime = params.type === 'daily' ? moment(n[0]).format(FORMAT) : n[0];
            const values = _.drop(n);
            timeList.push(is_same ? moment(n[0]).format('HH:mm:ss') : newTime);
            priceList.push(parseFloat(n[2]));
            newValueList.push(values);
            fullList.push(_.concat([newTime], values));
          })
        }
        setKvalue({
          'time': timeList,
          'kval': newValueList,
          'cal': {
            high: _.max(priceList),
            low: _.min(priceList),
            startPrice: _.get(res, 'data.preClose', 0)
          }
        });
        //tooltips计算振幅使用
        kState.kval = fullList;
        kState.preClose = _.get(res, 'data.preClose', 0);
        setUpdate(_.round(update + 0.1, 1));
      } else {
        message.info(_.get(res, 'message', '获取失败！'));
      }
      kState.loading = false;
    }
  }

  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('kline_charts'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });
    let newOption = _.cloneDeep(option);

    newOption.xAxis.data = _.get(kvalue, 'time', []);
    newOption.series[0].data = _.get(kvalue, 'kval', []);
    newOption.series[1].data = calMaLine(_.get(kvalue, 'kval', []));
    const getHigh = _.get(kvalue, 'cal.high', 0);
    const getLow = _.get(kvalue, 'cal.low', 0);
    const getStart = _.get(kvalue, 'cal.startPrice', 0);
    // 计算波幅
    const min = _.round(((getLow - getStart) / getLow) * 100, 2);
    const max = _.round(((getHigh - getStart) / getHigh) * 100, 2);
    newOption.yAxis[1].min = min > 0 ? 0 : min;
    newOption.yAxis[1].max = max < 0 ? 0 : max;

    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
  }, [update]);
  // 计算分钟移动均线
  const MA_NUM = 5;
  function calMaLine(kdata) {
    let final = [];
    if (isValidArray(kdata)) {
      kdata.map((n, i) => {
        if (_.size(n) > 1) { // 有效k线数据
          final[i] = _.chain(kdata)
            .slice(i < MA_NUM ? 0 : i - (MA_NUM - 1), i + 1) // 截取前num分钟数据，不足num分钟拿所有
            .map(k => parseFloat(k[1]))
            .sum() // 累加
            .divide(i < MA_NUM ? i + 1 : MA_NUM) // 求平均
            .round(2)
            .value();
        }
      })
    }
    return final;
  }
  // 切换一日或一周的时间切换
  function handleTimeSwitch(type) {
    const isMinute = kState.type === 'minute' ? true : false;
    const day_num = 30;
    if (type === 'left') {
      if (isMinute) {
        let curIdx = _.findIndex(tradeDates, o => o.date === kState.begin);
        kState.begin = _.get(tradeDates, `[${curIdx - 1}].date`, TODAY);
      } else {
        kState.begin = moment(kState.begin).subtract(day_num, 'd').format(FORMAT);
      }
    } else {
      const diffDay = moment().diff(moment(kState.end), 'd');
      let newEnd = 0;
      if (diffDay >= day_num) {
        newEnd = day_num;
      } else {
        newEnd = diffDay;
      }
      if (isMinute) {
        let curIdx = _.findIndex(tradeDates, o => o.date === kState.end);
        kState.end = _.get(tradeDates, `[${curIdx + 1}].date`, TODAY);
      } else {
        kState.end = moment(kState.end).add(newEnd, 'd').format(FORMAT);
      }
    }
    _klineDetail();
  }

  const labelItems = [
    { name: kState.begin, key: 'begin' },
    { name: kState.end, key: 'end' },
  ];
  const iconStyle = { color: '#ababab', fontSize: 14 };
  return <>
    <div className='basic-title'>
      <HeaderIcons
        title={`${kState.name} ${kState.symbol}`}
        subs={_.get(routeDatas, 'subname', '')}
        pageKey='kline'
        onPress={() => props.history?.goBack()}
      />
    </div>
    <NewCard pad={4} restBody={{ minHeight: 660 }}>
      <Row align='middle' style={{ marginTop: 8 }}>
        {labelItems.map(n => <Col key={'label' + n.key} span={12} style={{ textAlign: 'center' }}>
          <TextSelect name={n.name} onPress={() => {
            kState.timeOpen = { open: true, value: n.name, stamp: moment(n.name).toDate(), key: n.key };
          }} />
        </Col>)}
      </Row>
      <NewDivider />
      <Row>
        <Col span={8}>
          <Button type='text' icon={kState.type === 'minute' ? <LeftOutlined /> : <DoubleLeftOutlined />} onClick={() => handleTimeSwitch('left')} />
        </Col>
        <Col span={8} style={{ textAlign: 'center' }}>
          <Segmented options={[labelValues(['分钟K', 'minute']), labelValues(['日K', 'daily'])]}
            value={kState.type}
            onChange={v => {
              kState.type = v;
              kState.begin = v === 'daily' ? RANGE_TIME[0] : TODAY;
              kState.end = v === 'daily' ? RANGE_TIME[1] : TODAY;
              _klineDetail();
            }}
          />
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <Button type='text' disabled={moment().diff(moment(kState.end), 'day') <= 0 ? true : false}
            icon={kState.type === 'minute' ? <RightOutlined /> : <DoubleRightOutlined />}
            onClick={() => handleTimeSwitch('right')}
          />
        </Col>
      </Row>

      <Spin spinning={kState.loading}>
        <div style={{ display: 'flex' }}>
          <div
            id="kline_charts"
            style={{ width: '99%', height: 455 }}
          />
        </div>
      </Spin>

      <Row justify='center' style={{ marginTop: 24 }}>
        <Col span={16}>
          <Button icon={<MenuFoldOutlined />} block onClick={() => kState.visible = true}>
            {PAGEKEY_BTN_OBJ[page_key] ?? '其他'}
          </Button>
        </Col>
      </Row>
    </NewCard>

    <NewCalendar
      values={[kState.begin, kState.end]}
      show={_.get(kState, 'timeOpen.open') ?? false}
      onCancel={() => kState.timeOpen = false}
      onSubmit={(val) => {
        kState.begin = val[0];
        kState.end = val[1];
        kState.timeOpen = { key: '', open: false, value: '', stamp: new Date() };
        _klineDetail();
      }}
    />

    <Selections
      visible={kState.visible}
      options={stockList}
      onPress={(v) => {
        let fidx = _.findIndex(stockList, o => o.value === v);
        kState.symbol = v;
        kState.name = stockList[fidx].label;
        kState.visible = false;
        _klineDetail();
      }}
      onClose={() => kState.visible = false}
    />
  </>
}

export default KlinePage;
import React, { useEffect, useState } from 'react';
import { Typography, Descriptions, Space, Table, Segmented } from 'antd';
import { useMount, useReactive } from 'ahooks';
import { singleColumns, isValidObj, colWidth } from '@/utils/utils';
import { NEW_RANGE_ARRAY, TRADE_OPTION, xnamePro, findRangeIndex, renderDiv, renderExtraSize, calExArray } from '../Components/record_utils';
import { RateText, NewCard, HeaderIcons, NewDivider } from '@/utils/widgets';
import { renderValueColor } from '@/utils/chartInfo';
import * as echarts from 'echarts';
// import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
// 收益率超额分析页面
const RecordCalPage = (props) => {
  const routeDatas = _.get(props, 'location.state', {});
  const [option, setoption] = useState({
    ...TRADE_OPTION.bar,
    tooltip: {
      trigger: 'item',
      formatter: renderFormatter
    },
    dataZoom: [],
    grid: {
      left: '7%',
      right: '3%',
    },
  });
  // const [allToolData, setAllToolData] = useState([]);
  const [update, setUpdate] = useState(0);
  const [calDiv, setCalDiv] = useState({});
  const [toolData, setToolData] = useState([]);//收益率数据
  // const [marks, setMarks] = useState([]);
  const [calsAll, setCalsAll] = useState({});
  const calState = useReactive({ type: 'range' });

  function renderFormatter(params) {
    const getType = _.get(params, 'componentType', '');
    let finalString = '';
    if (getType === 'markPoint') {
      finalString = params.marker + `<span style='font-weight:600;margin-left:12px'>${params.value}</span>`
    } else {
      finalString = params.marker + ' ' + params.name
        + `<span style='font-weight:600;margin-left:12px'>${params.value}</span>`
        + '<br/>'
    }

    return finalString;
  }

  useMount(() => {
    setUpdate(update + 1);
  })

  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();
    }
    myChart = echarts.init(document.getElementById('tradeChats'));
    let newOption = _.cloneDeep(option);
    // 直接从路由拿到数据
    const getData = _.get(routeDatas, 'data', {});
    let itemArray = []; // 所有分类item的汇总数据；
    if (isValidObj(getData)) {
      let xArray = []; // x轴
      let final = []; // 图表value
      let markpoints = [];
      //{-1:[{item}]} key=收益范围 value[数组]=每个item里面是多条股票信息
      // 原key值已向上取整进行收益率分类，但需要统一计算所以，修改为用每个item内的收益率进行收益率分类计算...
      const finalRange = NEW_RANGE_ARRAY[calState.type] ?? [];
      final = finalRange.map((r, i) => { // final创建 x轴、图表value、及 markPoint数据  的初始数据；
        const xName = xnamePro(r, calState.type); // 不同命名规则，超额划分更精细的组别
        xArray.push(xName.xname);
        markpoints.push({ 'name': xName.xname, 'key': r, 'xval': xName.value, 'type': calState.type, 'tRate': 0, 'tSize': 0, 'bsize': 0 });
        return {
          value: 0,
          itemStyle: { color: renderValueColor(r, 'pure', { up: 'red', down: 'green', zero: '#9e9e9e' }) }
        }
      });
      // 分类tab切换，处理获取的数据数据，给final 和 markpoint数据进行赋值、统计等计算
      Object.keys(getData).map(range => {
        const item = getData[range];
        let newItem = [];
        if (item && _.size(item) > 0) {
          newItem = item.map((n, i) => { //用每个item内的收益率确定范围，并在final进行累加
            const newRate = _.get(n, 'rate', 0); // 收益率/超额收益率
            const rangeObj = findRangeIndex(newRate, calState.type); // 找到范围object
            const ridx = rangeObj.idx;
            const tradeCount = n.stockTradeList ? _.floor(_.size(_.get(n, 'stockTradeList', [])) / 2) : null; // 对数，只有一对算作 1，所以单数不算，除以2向下取整
            final[ridx].value = final[ridx].value + 1; // final赋值每个分组的个数value
            markpoints[ridx].tRate = markpoints[ridx].tRate + newRate; // 每个收益率累加汇总
            markpoints[ridx].tSize = markpoints[ridx].tSize + 1; // 每个收益率记录个数
            markpoints[ridx].bsize = markpoints[ridx].bsize + tradeCount; // 买卖对数，最后需要除以单组个数得出平均
            return {
              ...n,
              kIdx: _.get(n, 'stockCode') + i,
              keyName: rangeObj.name,
              orgRange: newRate,
              tradeListCount: tradeCount
            }
          })
        }
        itemArray = _.concat(itemArray, newItem);
      });
      // 【tips】:统计markpoint计算没10%进行统计，统计+是以1%拆分； 
      const newCals = calMarkpoints(markpoints, false);
      markpoints = newCals.marks;
      setCalDiv(newCals.cals);
      // setMarks(newCals.marks);
      setCalsAll(newCals.cals)
      setToolData(itemArray);
      let maxFinal = _.max(final.map(n => n.value));
      newOption.series[0].data = final;
      newOption.xAxis.data = xArray;
      newOption.series[0].markPoint.data = calState.type === 'range_plus' ? [] : markpoints; // 统计+  不需要收益率markpoint
      newOption.yAxis.max = renderExtraSize(maxFinal, 'max');
    } else {
      newOption.series[0].data = [];
      newOption.xAxis.data = [];
    }
    // console.log(routeDatas)
    let fstart = 0; let fend = 100;
    if (calState.type === 'range_plus') {
      fstart = 30; fend = 60;
    }
    newOption.dataZoom = [
      { type: 'inside', start: fstart, end: fend },
      { show: true, type: 'slider', bottom: '2%', start: fstart, end: fend, height: 12 },
    ];
    // setAllToolData(itemArray);
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.resize();
  }, [update]);

  // 提取计算统计funciton
  function calMarkpoints(mkpoints, is_ex) {
    const zindex = _.findIndex(mkpoints, o => o.key === 0);
    let newMarks = [];
    // 找到非0大小的负收益index值，同理用绝对值找到对称的正收益index值
    const findValidNegIndex = _.findIndex(mkpoints, o => o.key < 0 && o.tSize > 0);
    const findValidPasIndex = _.findIndex(mkpoints, o => o.key === Math.abs(_.get(mkpoints, `[${findValidNegIndex}].key`, 0)));
    let negPasObj = {}; // 计算正收益超额部分需要截取的数组
    if (findValidNegIndex !== -1 && findValidPasIndex !== -1) {
      negPasObj.neg = _.slice(mkpoints, findValidNegIndex, zindex + 1); //非0负数组
      negPasObj.pas = _.slice(mkpoints, zindex + 1, findValidPasIndex + 1); // 0-有效正收益数组
      negPasObj.pasEx = _.slice(mkpoints, findValidPasIndex + 1, _.size(mkpoints)) //超额部分数组
    }
    let calObj = {// calExArray返回对象{rate,size},使用时结构对象使用
      nagetive: 0, nagetiveSize: 0, positive: 0, positiveSize: 0, total: 0, totalSize: 0,
      positiveEx: calExArray(negPasObj.pasEx), positiveMain: calExArray(negPasObj.pas),
    }; // 计算正负及汇总平均收益率；
    // bar markpoint 标记收益率及买卖数量
    newMarks = mkpoints.map(n => {
      if (n.key < 0) { // 统计负收益及个数
        calObj.nagetive = calObj.nagetive + n.tRate;
        calObj.nagetiveSize = calObj.nagetiveSize + n.tSize;
      } else if (n.key > 0) {// 统计正收益及个数
        calObj.positive = calObj.positive + n.tRate;
        calObj.positiveSize = calObj.positiveSize + n.tSize;
      }
      calObj.total = calObj.total + n.tRate; // 总收益及个数
      calObj.totalSize = calObj.totalSize + n.tSize;
      const nval = renderDiv(n.tRate, n.tSize, true); //计算该分组平均收益率
      const bval = renderDiv(n.bsize, n.tSize, false); // 计算平均对数
      const showVal = nval + `\n (${bval})`;
      return {
        ...n,
        'value': is_ex ? showVal : nval,
        'xAxis': n.name, 'yAxis': renderExtraSize(n.tSize, n.type),
        'symbol': '', 'symbolSize': 0, 'label': { color: 'black', show: nval ? true : false }
      }
    });

    return { 'marks': newMarks, 'cals': calObj };
  }

  const columns = [
    {
      ...singleColumns(['股票代码', 'stockCode', 145]),
      fixed: 'left',
      //render: (text, record, index) => `${(pageInfo.page - 1) * pageInfo.pageSize + index + 1}) ${_.get(record, 'stockCode')}-${_.get(record, 'stockName')}`,
      render: (text, record, index) => `${index + 1}) ${_.get(record, 'stockCode')}-${_.get(record, 'stockName')}`,
    },
    {
      ...singleColumns(['收益', 'profit', 85]),
      render: (text, record) => <RateText rate={text} noPrefix={true} />
    },
    {
      ...singleColumns(['收益率', 'orgRange', 90]),
      sorter: (a, b) => a.orgRange - b.orgRange,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (text, record) => <RateText rate={text} />
    },
    singleColumns(['范围', 'keyName', 90]),
  ];

  //统计显示组件
  const CalBars = ({ all = {}, calValue = {} }) => {
    const basicArray = [
      { title: '正平均', color: 'red', valKey: 'positive', sizeKey: 'positiveSize' },
      { title: '负平均', color: 'green', valKey: 'nagetive', sizeKey: 'nagetiveSize' },
      { title: '总个数', key: 'all', sizeKey: 'totalSize' }
    ];
    return <Descriptions size='small' bordered column={1}>
      {basicArray.map((n, i) => {
        const crate = _.get(calValue, n.valKey, 0);
        const csize = _.get(calValue, n.sizeKey, 0);
        const all_size = _.get(all, n.sizeKey, 0);
        if (_.get(n, 'key', '') === 'all') {
          return <Descriptions.Item key={n.title + i} label={n.title}>
            <Text strong>{`${all_size ?? 0}支`}</Text>
          </Descriptions.Item>
        } else {
          return <Descriptions.Item key={n.title + i} label={n.title}>
            <Space size='small'>
              <Text strong style={{ color: n.color }}>{renderDiv(crate, csize, true)}</Text>
              <Text type='secondary'>{`(${csize ?? 0}支)`}</Text>
            </Space>
          </Descriptions.Item>
        }
      })}
      <Descriptions.Item label={'周期'}>
        <Text>{_.get(routeDatas, 'drange.begin', '')}</Text>
        <Text style={{ marginLeft: 12 }}>{_.get(routeDatas, 'drange.end', '')}</Text>
      </Descriptions.Item>
    </Descriptions>
  }

  return <>
    <div className='basic-title'>
      <HeaderIcons
        title={'交易统计'}
        subs={_.get(routeDatas, 'subname', '')}
        pageKey='recordcal'
        onPress={() => props.history?.goBack()}
      />
    </div>
    <NewCard pad={4}>
      <div style={{ paddingTop: 6, textAlign: 'center' }}>
        <Segmented
          value={calState.type}
          options={[{ label: '统计', value: 'range' }, { label: '统计+', value: 'range_plus' }]}
          onChange={v => {
            calState.type = v;
            setUpdate(update + 1);
          }}
        />
      </div>

      <div style={{ display: 'flex' }}>
        <div id="tradeChats" style={{ width: '100%', height: 400 }} />
      </div>

      <NewDivider />

      <CalBars
        all={calsAll}
        calValue={calDiv}
      />

      <NewDivider />

      <div>
        <Table
          rowKey={'clientOrderId'}
          dataSource={toolData}
          columns={columns}
          bordered={false}
          scroll={{ x: colWidth(columns), y: 550 }}
          size='small'
          pagination={false}
        />
      </div>

      <div style={{ height: 25 }}></div>
    </NewCard>
  </>
}

export default RecordCalPage;
import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, Space, Segmented, Descriptions, Spin, Slider } from 'antd';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import {
  getPersonalDisplayProductLevel, getPlateDisplayProductLevel, getStocksDisplayProductLevel,
  getPersonalDisplaySubAccountLevel, getPlateDisplaySubAccountLevel, getStocksDisplaySubAccountLevel,
  showTradeIndexSortProduct, showTradeIndexSort,
  getHighPositionDistribution, getPositionDistribution,
} from '@/api/main';
import { useReactive, useMount, useUpdateEffect, useDebounceFn } from 'ahooks';
import { isValidObj, isValidArray, labelValues, createDatasTimeKey } from '@/utils/utils';
import { Selections, NewDivider, HeaderIcons, NewCard, NewCalendar, EasySelect, TextSelect } from '@/utils/widgets';
import { createBaseLine } from '@/utils/chartInfo';
import { DISPLAY_OPTIONS, LEVELS, PLATES, SORT_INDEX, SORT_COLOR, DISPLAY_TYPE, MARKET_INFO } from './comp/page_utils';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';

const { Text, Paragraph } = Typography;
const FORMAT = 'YYYY-MM-DD';
const TODAY = moment().format(FORMAT);
const RANGE_TIME = [moment().subtract(7, 'd').format(FORMAT), TODAY];
const TYPE_OPTIONS = _.keys(DISPLAY_TYPE).map(n => labelValues([DISPLAY_TYPE[n], n]));
const TYPE_ACC_OPTIONS = _.filter(TYPE_OPTIONS, o => !_.includes(['6', '5'], o.value));
const GET_TYPE = { 'account': 'subAccountId', 'product': 'productId', 'virtual': 'virtualProductId' };
/* 
  持仓分布
  [tips]:useReactive可以及时更新，但是执行func内部作用域，isLevePlate的判断需再次获取判断，写着外部会之后更新;
*/
const DisplayPage = (props) => {
  const mobxStore = useLocalObservable(() => store);
  const [tradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const routeDatas = _.get(props, 'location.state', {});
  const [update, setUpdate] = useState(0);
  const [option, setoption] = useState(DISPLAY_OPTIONS.baseBar);
  const [option2, setOption2] = useState(DISPLAY_OPTIONS.line);
  const [option3, setOption3] = useState(DISPLAY_OPTIONS.level);
  const [option4, setOption4] = useState(DISPLAY_OPTIONS.plate);
  const [displayVal, setDisplayVal] = useState({});
  const displayState = useReactive({
    begin: RANGE_TIME[0], end: RANGE_TIME[1], loading: false, type: '1',
    timeOpen: { key: '', open: false, value: '', stamp: new Date() },
    visible: false, dayshow: false, ctype: 'open', // ctype:开盘、收盘 及 单日日期
    isSameDay: false,
  });
  const [disDatas, setDisDatas] = useState([]);
  const [timeArr, setTimeArr] = useState([]);
  const [highDatas, setHightDatas] = useState({});
  const [sliderValue2, setSliderValue2] = useState([]);
  const [timeNameArr, setTimeNameArr] = useState([]);
  const [timeArr2, setTimeArr2] = useState([0]);
  const [updatePie, setUpdatePie] = useState(0); // 统计饼图更新count
  const { run } = useDebounceFn(
    () => setUpdatePie(_.round(updatePie + 0.1, 1)),
    { wait: 400 },
  );
  const getPageKeys = _.get(routeDatas, 'pageKey', '');
  const isProduct = getPageKeys === 'product' ? true : false;
  const isAccount = getPageKeys === 'account' ? true : false;
  const isVirtual = getPageKeys === 'virtual' ? true : false;
  const isReverse = _.get(routeDatas, 'isReverse', false);
  // tab切换获取当前收益率数据
  useMount(() => {
    updatePage();
  });

  function updatePage() {
    const isBaseChart = _.includes(['1', '2', '3'], displayState.type);
    const isLevePlate = _.includes(['5', '6'], displayState.type);
    if ((isProduct || isVirtual) && isBaseChart) {
      getDisplayLevelProduct();
    } else if (isAccount && isBaseChart) {
      getDisplayLevelList();
    } else if (_.includes(['4', '7'], displayState.type)) {
      _showTradeIndexSort();
    } else if (isLevePlate) {
      _positionDistribution();
    }
  }
  // 产品获取 123
  async function getDisplayLevelProduct() {
    const newId = _.get(routeDatas, GET_TYPE[getPageKeys]);
    const pms_type = isVirtual ? 'vir' : (isReverse ? 'FC' : 'nor');
    if (newId) {
      let params = {
        [GET_TYPE[getPageKeys]]: newId,
        beginDate: displayState.begin,
        endDate: displayState.end
      }
      if (isVirtual) {
        _.set(params, 'mode', _.get(routeDatas, 'mode'));
      };
      displayState.loading = true;
      if (displayState.type === '1') {
        const data = await getPersonalDisplayProductLevel(params, pms_type);
        handleAccountLevel(data, '1')
      } else if (displayState.type === '2') {
        const data = await getPlateDisplayProductLevel(params, pms_type);
        handleAccountLevel(data, '2')
      } else if (displayState.type === '3') {
        const data = await getStocksDisplayProductLevel(params, pms_type);
        handleAccountLevel(data, '3')
      }
      displayState.loading = false;
      setUpdate(_.round(update + 0.1, 1));
    }
  };
  //子账户获取
  async function getDisplayLevelList() {
    const subId = _.get(routeDatas, 'subAccountId');
    const pms_type = isReverse ? 'FC' : 'NOR';
    if (subId) {
      displayState.loading = true;
      let params = {
        subAccountId: subId,
        beginDate: displayState.begin,
        endDate: displayState.end
      }
      // setCurrentParams(params);
      if (displayState.type === '1') {
        const data = await getPersonalDisplaySubAccountLevel(params, pms_type);
        handleAccountLevel(data, '1')
      } else if (displayState.type === '2') {
        const data = await getPlateDisplaySubAccountLevel(params, pms_type);
        handleAccountLevel(data, '2')
      } else if (displayState.type === '3') {
        const data = await getStocksDisplaySubAccountLevel(params, pms_type);
        handleAccountLevel(data, '3')
      }
      displayState.loading = false;
      setUpdate(_.round(update + 0.1, 1));
    }
  }
  // 处理数据Main图表获取数据
  function handleAccountLevel(data, type) {
    if (_.get(data, 'code', '') === '200') {
      if (type === '1') {
        const positionList = _.get(data, 'data.personalPositionList', []);
        const getMoney = _.get(data, 'data.moneyList', []);
        const timeList = _.get(data, 'data.timeList', []);
        setDisplayVal({
          '现金': getMoney,
          '总仓位': positionList,
          'times': timeList
        })
      } else {
        const plateList = _.get(data, 'data.data.platePositionList', []);
        //返回数据格式:  [{'其他':0.4,'股票名称':0.2},...{item,keyname=名称，value=值}] 数组大小=点位大小=时间array 
        const timeList = _.get(data, 'data.data.timeList', [])
        let pieObj = {}; //{名称:['名称',1,2,3,4...]} 用名称创建对应array
        plateList.map((item, index) => {
          Object.keys(item).map((keyname) => { //!返回数据不是每个点都有数据，所以要将时间index对应起来，没有数据是'-'
            // ['名称',{key:数据对应的index,value:值}]
            if (pieObj[keyname]) { // 有数据则直接push
              pieObj[keyname].push({ key: index, value: item[keyname] });
            } else { // 首次记录该名称数据，创建keyname ['名称',第一位数据]
              pieObj[keyname] = [{ key: index, value: item[keyname] }]
            }
          })
        });
        Object.keys(pieObj).map((key) => {
          // 填充空数组,+1第一位是名称
          let sizeArray = Array(_.size(plateList) + 1).fill('-');
          pieObj[key].map((n, i) => {
            if (_.isObject(n)) { // object代表是数据，key+1填充到空数组的对应位置
              sizeArray[n.key] = n.value;
            }
          })
        });
        // console.log('创建的数据object', _.assign(pieObj, { 'times': timeList }));
        setDisplayVal(_.assign(pieObj, { 'times': timeList }))
      }
    }
  }
  // 风格仓位 4
  async function _showTradeIndexSort() {
    const newId = _.get(routeDatas, GET_TYPE[getPageKeys]);
    const pms_type = isVirtual ? 'vir' : (isReverse ? 'FC' : 'nor');
    if (newId) {
      let params = {
        [GET_TYPE[getPageKeys]]: newId,
        beginDate: displayState.begin,
        endDate: displayState.end
      }
      if (isVirtual) {
        _.set(params, 'mode', _.get(routeDatas, 'mode'));
      };
      if (displayState.type === '7') {
        _.set(params, 'sortType', 'market');
      };
      const res = (isProduct || isVirtual) ?
        await showTradeIndexSortProduct(params, pms_type)
        : await showTradeIndexSort(params, pms_type);
      if (_.get(res, 'code', '') === '200') {
        const getData = _.get(res, 'data', {});
        // 原有平台的处理逻辑
        let newList = Object.keys(getData).map(date => {
          let pieValue = [];
          Object.keys(getData[date]).map(keyname => {
            pieValue.push({ name: keyname, value: getData[date][keyname] });
          })
          return { time: date, timeKey: moment().diff(moment(date), 'd'), data: pieValue }
        });
        newList = _.orderBy(newList, ['timeKey'], ['desc']);
        // 与123基础图表共用，调整数据结构用于展示
        let newObj = { times: [] };
        newList.map((item, i) => {
          newObj.times.push(_.get(item, 'time'));
          _.get(item, 'data', []).map(n => {
            if (n.name in newObj) {
              _.set(newObj, n.name + `[${i}].value`, n.value);
            } else { // 空则填充空数据
              _.set(newObj, n.name, Array(_.size(newList)).fill(0).map((zero, zi) => { return { 'value': zero, 'key': zi } }));
              _.set(newObj, n.name + `[${i}].value`, n.value);
            }
          });
        });
        setDisplayVal(newObj);
        setTimeArr(newObj.times.map(d => labelValues([d, d])));
        displayState.ctype = _.head(newObj.times);
        setUpdate(_.round(update + 0.1, 1));
      }
    }
  }
  // 重仓分布; 京沪深 5 6
  async function _positionDistribution() {
    const pms_type = isVirtual ? 'vir' : (isReverse ? 'FC' : 'nor');
    const newId = _.get(routeDatas, GET_TYPE[getPageKeys]);
    if (newId) {
      let params = {
        [GET_TYPE[getPageKeys]]: newId,
        beginDate: displayState.begin,
        endDate: displayState.end
      };
      if (isVirtual) {
        _.set(params, 'mode', _.get(routeDatas, 'mode'));
      };
      const res = displayState.type === '5'
        ? await getHighPositionDistribution(params, pms_type)
        : await getPositionDistribution(params, pms_type);
      if (_.get(res, 'code', '') === '200') {
        const getData = _.get(res, 'data', {});
        let newList = Object.keys(getData).map(date => {
          return _.assign(getData[date], { 'date': date })
        });
        setHightDatas(createDatasTimeKey(newList, 'date', 'd', 'asc'));
        setUpdate(_.round(update + 0.1, 1));
      }
    }
  }

  useEffect(() => {
    let myChart = props.myChart; let myChart2 = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('display_main'));
    myChart2 = echarts.init(document.getElementById('display_line'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    const isBaseChart = _.includes(['1', '2', '3'], displayState.type);
    const isLevePlate = _.includes(['5', '6'], displayState.type);
    const isSorts = _.includes(['4', '7'], displayState.type);
    // 新增仓位统计56；不同的options数据，同一个chart图表
    if (isLevePlate) {
      const isLevel = displayState.type === '5' ? true : false;
      let newOption3 = _.cloneDeep(option3);
      let newOption4 = _.cloneDeep(option4);

      const newBase = isLevel ? LEVELS : PLATES;
      let sdata = isLevel ? newOption3.series : newOption4.series;
      let table = []; let times = [];
      if (isValidArray(highDatas)) {
        highDatas.map((item, i) => {
          const xdate = _.get(item, 'date', '')
          times.push(xdate);
          newBase.map(n => {
            const val = _.round(_.get(item, 'key' in n ? n.key : n.name), 2);
            _.set(sdata[n.index], `data[${i}]`, val);
          });
          if (!isLevel) {
            table.push(labelValues([xdate, _.get(item, '总个数', 0) + '个']))
          }
        });
      };
      if (isLevel) {
        newOption3.xAxis.data = times;
        newOption3.series = sdata;
        newOption3.legend.data = newBase.map(n => n.name);
      } else {
        newOption4.xAxis.data = times;
        newOption4.series = sdata;
        newOption4.legend.data = newBase.map(n => n.name);
      }
      setDisDatas(table);
      setOption3(newOption3); setOption4(newOption4);
      myChart.setOption(isLevel ? newOption3 : newOption4, true);

    } else {
      //____________ 基础统计图 ____________
      let newOption = _.cloneDeep(option); let newOption2 = _.cloneDeep(option2);
      let datas = []; let table = []; let sdata = [];
      const isSameDay = moment(displayState.begin).diff(moment(displayState.end), 'd') === 0 ? true : false;
      displayState.isSameDay = isSameDay;

      if (isValidObj(displayVal)) {
        // 处理时间； 7天内按照分钟返回点位；按照收盘价汇总为一天一个点位；
        const newTimes = splitTimeIndex(_.get(displayVal, 'times', []));
        // 根据开盘收盘 ，板块仓位要提取对象里面的value字段；总仓位直接是数值；
        // console.log('displayVal', displayVal)
        _.keys(displayVal).map((item, index) => {
          if (item !== 'times') {
            const plateArr = _.get(displayVal, `${item}`);
            let get_val = {};
            // 风格仓位增加日期，ctype为日期是查找对应的日期数据
            if (displayState.ctype === 'open') {
              get_val = _.head(plateArr);
            } else if (displayState.ctype === 'close') {
              get_val = _.last(plateArr);
            } else {
              let fidx = _.findIndex(_.get(displayVal, 'times'), o => o === displayState.ctype);
              get_val = plateArr[fidx];
            }
            const showVal = displayState.type === '1' ? get_val : _.get(get_val, 'value');
            const percentVal = _.round(showVal * 100, 2) + '%';
            if (showVal) { // 占比为0 饼图不显示
              let barItm = {
                'value': showVal,
                'name': isBaseChart ? `${item}(${percentVal})` : `${item}(${showVal})`
              };
              if (isSorts) {
                _.set(barItm, 'itemStyle', { color: SORT_COLOR[item] });
              }
              datas.push(barItm);
            }
            const tableItem = labelValues([item, showVal ? (isBaseChart ? percentVal : showVal) : 0]);
            if (item === '现金') {
              table.unshift(tableItem)
            } else {
              table.push(tableItem);
            }
            // 创建折线图数据，超过一天返回每日点位，当天返回完整时间和数据点位...
            let daypoints = [];
            newTimes.indexs.map((idx, i) => {
              daypoints[i] = plateArr[idx];
            });
            sdata.push(createBaseLine(isSorts ? SORT_COLOR[item] : null, {
              'name': item,
              'data': isSameDay ? plateArr : daypoints,
              'key': isSorts ? SORT_INDEX[item] : index,
            }));
          }
        });
        const xaxisDate = isSameDay ? _.get(displayVal, 'times', []) : newTimes.dayArray.map(n => n.time)
        newOption2.xAxis.data = xaxisDate;
        // 不是同一天更新slider数据
        if (!isSameDay) {
          let sArray = xaxisDate.map((n, i) => i);
          setTimeArr2(sArray);
          setTimeNameArr(xaxisDate);
          // 赋值slider的滑动数据
          if (_.last(sArray)) {
            setSliderValue2([0, _.last(sArray)]);
          }
        }
      }
      // console.log('datas', datas)
      newOption.series[0].data = datas;
      // 风格和市值通过配置index大小，最后排序实现数据依次展示；
      newOption2.series = isSorts ? _.orderBy(sdata, ['key'], ['asc']) : sdata;

      setDisDatas(table);
      setoption(newOption); setOption2(newOption2);
      myChart.setOption(newOption, true); myChart2.setOption(newOption2, true);
    }

    myChart.hideLoading();
    myChart.resize(); myChart2.resize();
  }, [update]);
  // 滑动slider，重新计算并更新饼图
  useUpdateEffect(() => {
    let myChart = props.myChart;
    myChart = echarts.init(document.getElementById('display_main'));
    let newOption = _.cloneDeep(option);

    const isSorts = _.includes(['4', '7'], displayState.type);
    const sliderTime = timeNameArr[sliderValue2];
    // 行业仓位时间会出现 15:00:00的时间字样，所以统一format时间
    const formatTime = _.get(displayVal, 'times').map(t => moment(t).format(FORMAT));
    let datas = [];
    _.keys(displayVal).map(item => {
      if (item !== 'times') {
        const plateArr = _.get(displayVal, `${item}`);
        let fidx = _.findIndex(formatTime, o => o === sliderTime);
        let get_val = plateArr[fidx];
        const showVal = displayState.type === '1' ? get_val : _.get(get_val, 'value');
        const percentVal = _.round(showVal * 100, 2) + '%';
        if (showVal) {
          let barItm = {
            'value': showVal,
            'name': isBaseChart ? `${item}(${percentVal})` : `${item}(${showVal})`
          };
          if (isSorts) {
            _.set(barItm, 'itemStyle', { color: SORT_COLOR[item] });
          }
          datas.push(barItm);
        }
      }
    });
    newOption.series[0].data = datas;
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.resize();
  }, [updatePie]);
  // 切换日期
  function handleDayChange(type) {
    const isBaseChart = _.includes(['1', '2', '3'], displayState.type);
    if (isBaseChart) {
      const cur = _.cloneDeep(displayState.begin);
      let cidx = _.findIndex(tradeDates, o => o.date === cur);
      if (type === 'last') {
        displayState.begin = _.get(tradeDates, `[${cidx - 1}].date`, TODAY)
        displayState.end = _.get(tradeDates, `[${cidx - 1}].date`, TODAY)
      } else if (type === 'next') {
        displayState.begin = _.get(tradeDates, `[${cidx + 1}].date`, TODAY);
        displayState.end = _.get(tradeDates, `[${cidx + 1}].date`, TODAY);
      }
      updatePage();
    } else {
      // 风格仓位直接切换日期
      const fidx = _.findIndex(timeArr, o => o.value === displayState.ctype);
      let is_update = false;
      if (type === 'next' && fidx < _.size(timeArr) - 1) {
        displayState.ctype = timeArr[fidx + 1].value;
        is_update = true;
      } else if (type === 'last' && fidx > 0) {
        displayState.ctype = timeArr[fidx - 1].value;
        is_update = true;
      }
      if (is_update) {
        setUpdate(_.round(update + 0.1, 1));
      }
    }
  }
  //返回每天的时间，每日最后一个数据index
  function splitTimeIndex(times) {
    let last = ''; let dayArray = []; let indexs = [];
    times.map((t, i) => {
      if (i === 0) {
        last = moment(t).format('YYYY-MM-DD');
      } else {
        if (!moment(t).isSame(last, 'D')) { // 有不同时间，记录前一时间及最后一个点位index值
          dayArray.push({ 'time': last, 'index': i - 1 });
          indexs.push(i - 1);
        }
        last = moment(t).format('YYYY-MM-DD');
        if (!times[i + 1]) { // 补充最后一个数据
          dayArray.push({ 'time': last, 'index': i });
          indexs.push(i);
        }
      }
    });
    return { dayArray, indexs };
  };
  // 滑动change
  function afterSliderChange(v) {
    setSliderValue2(v);
    run(); // 防抖更新
  }

  const labelItems = [
    { name: displayState.begin, key: 'begin' },
    { name: displayState.end, key: 'end' },
  ];
  const iconStyle = { color: '#ababab', fontSize: 14 };
  const middleBarProps = { span: 8, style: { textAlign: 'center' } };
  const isBaseChart = _.includes(['1', '2', '3'], displayState.type);
  const isLevePlate = _.includes(['5', '6'], displayState.type);
  const formatter = (value) => timeNameArr[value] || 'none';

  return <>
    <HeaderIcons
      title={'仓位分析'}
      subs={_.get(routeDatas, 'subname', '')}
      pageKey='display'
      onPress={() => props.history?.goBack()}
    />

    <NewCard pad={4} restStyle={{ marginTop: 6 }}>
      <Row align='middle' style={{ marginTop: 8 }}>
        {labelItems.map(n => <Col key={'label' + n.key} span={8} style={{ textAlign: 'center' }}>
          <TextSelect name={n.name} onPress={() => {
            displayState.timeOpen = { open: true, value: n.name, stamp: moment(n.name).toDate(), key: n.key };
          }} />
        </Col>)}
        <Col span={8} style={{ textAlign: 'center' }}>
          <TextSelect
            name={DISPLAY_TYPE[displayState.type]}
            onPress={() => displayState.visible = true}
          />
        </Col>
      </Row>
      <NewDivider />

      <Spin spinning={displayState.loading}>
        <div style={{ display: 'flex' }}>
          <div
            id="display_main"
            style={{ width: '99%', height: isLevePlate ? 450 : 300 }}
          />
        </div>
      </Spin>

      <div style={{ display: isLevePlate ? 'none' : 'flex', }}>
        <div id="display_line" style={{ width: '99%', height: 135 }} />
      </div>

      {_.includes(['1', '2', '3', '4', '7'], displayState.type) && !displayState.isSameDay && <Row justify='center'
        style={{ marginTop: 12 }}
      >
        <Col span={20}>
          <Slider
            min={0}
            max={_.last(timeArr2) || 0}
            value={sliderValue2}
            tooltip={{ formatter }}
            onChange={(v) => afterSliderChange(v)}
          />
        </Col>
      </Row>}

      {!isLevePlate && displayState.isSameDay && <Row align='middle'>
        <Col {...middleBarProps}>
          {isBaseChart && <div onClick={() => handleDayChange('last')}>
            <Space size='small'>
              <DoubleLeftOutlined style={iconStyle} />
              <Text>前一日</Text>
            </Space>
          </div>}
        </Col>
        <Col {...middleBarProps}>
          {isBaseChart && <Segmented
            value={displayState.ctype}
            options={[labelValues(['开盘', 'open']), labelValues(['收盘', 'close'])]}
            onChange={(v) => {
              displayState.ctype = v;
              setUpdate(_.round(update + 0.1, 1));
            }}
          />}
          {displayState.type === '4' && <div onClick={() => displayState.dayshow = true}>
            <Text underline>{displayState.ctype}</Text>
          </div>}
        </Col>
        <Col {...middleBarProps}>
          {isBaseChart && <div onClick={() => {
            if (moment().diff(moment(displayState.end), 'day') > 0) {
              handleDayChange('next')
            }
          }}>
            <Space size='small'>
              <Text>后一日</Text>
              <DoubleRightOutlined style={iconStyle} />
            </Space>
          </div>}
        </Col>
      </Row>}

      <NewDivider />

      <Descriptions bordered column={2} size='small'>
        {isValidArray(disDatas) && disDatas.map((item, i) => {
          const isCash = item.label === '现金' ? true : false;
          return <Descriptions.Item key={item.label + i} span={isCash ? 2 : 1}
            label={item.label}>
            <Text strong>{item.value}</Text>
          </Descriptions.Item>
        })}
      </Descriptions>

      {displayState.type === '7' ? <Paragraph>
        <blockquote>
          {_.keys(MARKET_INFO).map(name => <Text key={name} type='secondary'>{name}: <Text>{MARKET_INFO[name]}</Text><br /></Text>)}
        </blockquote>
      </Paragraph> : <div style={{ height: 12 }}></div>}
    </NewCard>

    <NewCalendar
      values={[displayState.begin, displayState.end]}
      show={_.get(displayState, 'timeOpen.open') ?? false}
      onCancel={() => displayState.timeOpen = false}
      onSubmit={(val) => {
        displayState.begin = val[0];
        displayState.end = val[1];
        displayState.timeOpen = { key: '', open: false, value: '', stamp: new Date() };
        updatePage();
      }}
    />

    <Selections
      visible={displayState.visible}
      options={(isProduct || isVirtual) ? TYPE_OPTIONS : TYPE_ACC_OPTIONS}
      onPress={(v) => {
        displayState.type = v;
        displayState.visible = false;
        // 123 ctype 默认切换为开盘
        if (_.includes(['1', '2', '3'], v)) {
          displayState.ctype = 'open';
        }
        if (_.includes(['5', '6'], v)) {
          // 切换56时，如果是单日，默认加载切换为一周
          const isSameDay = moment(displayState.begin).diff(moment(displayState.end), 'd') === 0 ? true : false;
          if (isSameDay) {
            displayState.begin = moment().subtract(7, 'd').format(FORMAT);
            displayState.end = moment().format(FORMAT);
          }
        }
        updatePage();
      }}
      onClose={() => displayState.visible = false}
    />

    <EasySelect
      visible={displayState.dayshow}
      options={timeArr}
      onClose={() => displayState.dayshow = false}
      onPress={(v) => {
        displayState.dayshow = false;
        displayState.ctype = v;
        setUpdate(_.round(update + 0.1, 1));
      }}
    />
  </>
}

export default DisplayPage;
import { isValidArray, isValidNumber, isValidObj } from './utils';
import moment from 'moment';
import _ from 'lodash';
/*
  图表类func及静态配置文件；包含计算逻辑及若干静态参数
*/
let calCount = 0;
const calRateKey = () => {
  calCount++;
  return calCount - 1;
};
const createBaseLine = (color, rest = {}) => {
  let obj = { data: [], type: 'line', symbol: 'none', ...rest };
  if (color) {
    obj.lineStyle = { color };
    obj.itemStyle = { color };
  }
  if (('isDash' in rest) && rest.isDash === true) {
    obj.lineStyle = _.assign(obj.lineStyle, { type: 'dashed', width: 2 });
  }
  if (('isDot' in rest) && rest.isDot === true) {
    obj.lineStyle = _.assign(obj.lineStyle, { type: 'dotted', width: 2 });
  }
  return obj;
}
const fastMap = (array, key = 'key') => array.map(k => _.get(k, key));
/**
 * 以下根据需求创建的各种 KEY:VALUE 等参数对应数组数据；
 * 收益率图形所需array并分类 【index：对应数据index,如空值则创建对应的空值数据】; 
 * filterKey:分类所需的标签信息；分类选择时使用
 */
const createKeyArray = (calKey = '', array = []) => array.map(a => {
  return { // 移动版增加label,value字段
    key: a[0], value: a[0],
    calKey,
    index: calRateKey(),//【已废弃】；提前计算数据条数index，当前已改为动态，不根据index固定数据位置
    name: a[1], label: a[1],
    color: a[2] ?? 'black',
    filterKey: a[3] ?? []
  }
});
const UPDOWN_LINE = createKeyArray('updown', [
  ['rise', '涨停', '#fb2c2c', ['acc']],
  ['riseExceptFee', '涨停(手续费)', '#f45555', ['acc', 'fee']],
  ['riseAverageRate', '涨停平均', '#eb603d', ['avg']],
  ['down', '非涨停', '#1ccb79', ['acc']],
  ['downExceptFee', '非涨停(手续费)', '#4ccc90', ['acc', 'fee']],
  ['downAverageRate', '非涨停平均', '#44fba5', ['avg']],
  ['tRiseRate', '今仓涨停', '#0570d4', ['acc', 'today']],
  ['tDownRate', '今仓非涨停', '#1c91ff', ['acc', 'today']], // 自定义，需在处理option时计算
  ['tRiseAverageRate', '今仓涨停平均', '#0164c0', ['avg', 'today']],
  ['tDownAverageRate', '今仓非涨停平均', '#5485b2', ['avg', 'today']],
  ['yRiseRate', '昨仓涨停', '#f49735', ['acc', 'yesterday']],
  ['yRiseAverageRate', '昨仓涨停平均', '#da770e', ['avg', 'yesterday']],
  ['preYRiseRate', '前仓涨停', '#8c2fff', ['acc', 'preday']],
  ['preYRiseAverageRate', '前仓涨停平均', '#7328cf', ['avg', 'preday']],
  ['yLimitRate', '昨仓买入涨停', '#fc8d18', ['acc', 'yesterday']],
  ['yUnLimitRate', '昨仓买入非涨停', '#feda11', ['acc', 'yesterday']],
  ['yLimitAverageRate', '昨仓买入涨停平均', '#fc8d18', ['avg', 'yesterday']],
  ['yUnLimitAverageRate', '昨仓买入非涨停平均', '#feda11', ['avg', 'yesterday']],
]);
const TWODAY_LINE = createKeyArray('twoDay', [
  ['tRate', '今仓', '#007eb9', ['acc', 'today']],
  ['yRate', '昨仓', '#cf873b', ['acc', 'yesterday']],
  ['tRateExceptFee', '今仓(手续费)', '#00b9b9', ['today', 'fee']],
  ['yRateExceptFee', '昨仓(手续费)', '#e3a35f', ['acc', 'fee', 'yesterday']],
  ['preYRate', '前仓(所有)', '#9B90C2', ['acc', 'preday']],
  ['preYRateExceptFee', '前仓(所有)(手续费)', '#877dac', ['acc', 'fee', 'preday']],
  ['tAverageRate', '今仓平均', '#44aac9', ['avg', 'today']],
  ['yAverageRate', '昨仓平均', '#ffbc75', ['avg', 'yesterday']],
  ['tAverageRateExceptFee', '今仓平均(手续费)', '#955e3e', ['avg', 'today', 'fee']],
  ['yAverageRateExceptFee', '昨仓平均(手续费)', '#ffaf5b', ['fee', 'avg', 'yesterday']],
  ['preYAverageRate', '前仓(所有)平均', '#9a005f', ['preday', 'avg']],
  ['preYAverageRateExceptFee', '前仓(所有)平均(手续费)', '#9f007d', ['fee', 'avg', 'preday']],
  ['preRate', '前仓当天', '#9397a8', ['acc', 'preday']],
  ['preYRateOne', '前仓一', '#5a7b96', ['acc', 'preday']],
  ['preYRateTwo', '前仓二', '#85b3a2', ['acc', 'preday']],
  ['preYRateThree', '前仓三', '#34426a', ['acc', 'preday']],
  ['preAverageRate', '前仓当天平均', '#9397a8', ['avg', 'preday']],
  ['preYAverageOneRate', '前仓一平均', '#5a7b96', ['avg', 'preday']],
  ['preYAverageTwoRate', '前仓二平均', '#85b3a2', ['avg', 'preday']],
  ['preYAverageThreeRate', '前仓三平均', '#34426a', ['avg', 'preday']],
]);
const STR_PLUS = 'Plus';
// 收益率曲线配置对象；每条配置数据为一条收益率曲线，key对应后端返回字段
const MAIN_KEYS_OBJ = {
  'base': createKeyArray('base', [
    ['pointList', '收益率'],
    ['index', '指数波幅'],
    ['extraPoint', '超额收益率'],
    ['extraPoint2', '超额(几何)', '#e4b100'],
  ]),
  'updown': UPDOWN_LINE,
  'twoDay': TWODAY_LINE,
  'updown_plus': _.cloneDeep(UPDOWN_LINE).map(n => {
    return _.assign(n, {
      'name': n.name + '+', 'key': n.key + STR_PLUS, 'color': n.color + 'cc',
      'label': n.name + '+', 'value': n.key + STR_PLUS,
    })
  }),
  'twoDay_plus': _.cloneDeep(TWODAY_LINE).map(n => {
    return _.assign(n, {
      'name': n.name + '+', 'key': n.key + STR_PLUS, 'color': n.color + 'cc',
      'label': n.name + '+', 'value': n.key + STR_PLUS,
    })
  }),
  'week': createKeyArray('week', [
    ['oneDayAverageRate', '一天平均', '#b00404'],
    ['twoDayAverageRate', '二天平均', '#684a02'],
    ['threeDayAverageRate', '三天平均', '#649700'],
    ['fourDayAverageRate', '四天平均', '#e16f91'],
    ['fiveDayAverageRate', '五天平均', '#056fd9'],
  ]),
  'bs': createKeyArray('bs', [
    ['buyRate', '买入', '#cc0073'],
    ['sellRate', '卖出', '#6eca99'],
  ]),
  'plate': createKeyArray('plate', [
    ['mainBoardAverageRateExceptFee', '主板平均', '#ffe910', ['plate', 'avg']],
    ['mainBoardRateExceptFee', '主板', '#e5c709', ['plate']],
    ['kCAverageRateExceptFee', '科创创业平均', '#dd0048', ['plate', 'avg']],
    ['kCRateExceptFee', '科创创业', '#cf6f7c', ['plate']],
  ]),
  'active': createKeyArray('active', [  // 活跃度、反弹、趋势
    ['activeOneRate', '活跃度1', '#ff4700'],
    ['activeTwoRate', '活跃度2', '#ff733e'],
    ['activeThreeRate', '活跃度3', '#ff946c'],
    ['activeFourRate', '活跃度4', '#ffb092'],
    ['activeOneAverageRate', '活跃度1平均', '#f03606', ['avg']],
    ['activeTwoAverageRate', '活跃度2平均', '#d15433', ['avg']],
    ['activeThreeAverageRate', '活跃度3平均', '#d37056', ['avg']],
    ['activeFourAverageRate', '活跃度4平均', '#bb7867', ['avg']],
  ]),
  'rebound': createKeyArray('rebound', [
    ['reboundOneRate', '反弹度1', '#0084b0'],
    ['reboundTwoRate', '反弹度2', '#2f8faf'],
    ['reboundThreeRate', '反弹度3', '#5096ad'],
    ['reboundFourRate', '反弹度4', '#81aebd'],
    ['reboundOneAverageRate', '反弹度1平均', '#006b7f', ['avg']],
    ['reboundTwoAverageRate', '反弹度2平均', '#2b7887', ['avg']],
    ['reboundThreeAverageRate', '反弹度3平均', '#498f9d', ['avg']],
    ['reboundFourAverageRate', '反弹度4平均', '#78a4ad', ['avg']],
  ]),
  'trend': createKeyArray('trend', [
    ['trendOneRate', '趋势1', '#9ba035'],
    ['trendTwoRate', '趋势2', '#aaaf4d'],
    ['trendThreeRate', '趋势3', '#adb166'],
    ['trendFourRate', '趋势4', '#bfc373'],
    ['trendOneAverageRate', '趋势1平均', '#127f56', ['avg']],
    ['trendTwoAverageRate', '趋势2平均', '#2c916b', ['avg']],
    ['trendThreeAverageRate', '趋势3平均', '#4a9d7d', ['avg']],
    ['trendFourAverageRate', '趋势4平均', '#71b198', ['avg']],
  ]),
  'mainBoard': createKeyArray('mainBoard', [
    ['shMainBoardAverageRateExceptFee', '上证主板平均', '#f16149', ['avg']],
    ['shMainBoardRateExceptFee', '上证主板', '#e82200'],
    ['szMainBoardAverageRateExceptFee', '深证主板平均', '#c16832', ['avg']],
    ['szMainBoardRateExceptFee', '深证主板', '#ac4a0f'],
  ])
};

//1天-5天平均显示涨停个数
const WEEK_LIMIT_NUMBER = {
  '一天平均': 'oneDayNum',
  '二天平均': 'twoDayNum',
  '三天平均': 'threeDayNum',
  '四天平均': 'fourDayNum',
  '五天平均': 'fiveDayNum'
}
// 收益率的keys 【1. tooltip显示数据正常需要先增加】 【格式修改为动态增加主要keys
let all_key_array = []; let rate_keys = {}; let key_zero = []; let key_show = [];
_.keys(MAIN_KEYS_OBJ).map(keyname => {
  all_key_array = _.concat(all_key_array, MAIN_KEYS_OBJ[keyname]);
  _.set(rate_keys, keyname, fastMap(MAIN_KEYS_OBJ[keyname])); // 添加分类key的对象
  if (keyname !== 'base') {
    key_zero.push(keyname); // 添加头部需要判0 keys
    if (keyname !== 'bs') {
      key_show.push(keyname); // 添加显示的收益率 keys
    }
  }
});
// art(活跃、反弹、趋势) 个数字段的keys
const active_number_keys = ['activeOneAvgNum', 'activeTwoAvgNum', 'activeThreeAvgNum', 'activeFourAvgNum']
const rebound_number_keys = ['reboundOneAvgNum', 'reboundTwoAvgNum', 'reboundThreeAvgNum', 'reboundFourAvgNum']
const trend_number_keys = ['trendOneAvgNum', 'trendTwoAvgNum', 'trendThreeAvgNum', 'trendFourAvgNum']
// keys配置汇总对象
const RATE_KEYS_OBJ = {
  ...rate_keys,
  'weekLimit': Object.keys(WEEK_LIMIT_NUMBER).map(n => WEEK_LIMIT_NUMBER[n]),
  'rdKeys': ['riseNum', 'riseAverageRate', 'downNum', 'downAverageRate'],
  'tdKeys': ['preYNum', 'preYRiseAverageRate', 'yNum', 'tNum', 'tRiseNum', 'tRiseAverageRate', 'tDownAverageRate', 'yRiseNum', 'yRiseAverageRate'],
  'art': _.concat(active_number_keys, rebound_number_keys, trend_number_keys),
  'mainbKeys': ['shNum', 'kCNum', 'mainBoardNum'],
  'plus': fastMap(_.concat(MAIN_KEYS_OBJ.updown_plus, MAIN_KEYS_OBJ.twoDay_plus)),
  'key_show': key_show,
  'key_zero': key_zero,
  'all': all_key_array,
  'allKeys': fastMap(all_key_array),
}
// tooltip数据展示title和key  【2. 在增加显示的title和key】 typeKey为根据选中动态分类筛选显示
// const TOOLTIP_SHOW_LIST = [
//   { title: '----涨停-非涨停----', key: 'riseMinusDown', typeKey: 'updown' },
//   { title: '涨停个数', key: 'riseNum', typeKey: 'updown' },
//   { title: '非涨停个数', key: 'downNum', typeKey: 'updown' },
//   { title: '前仓(所有)个数', key: 'preYNum', typeKey: 'two_day' },
//   { title: '前仓涨停个数', key: 'preNum', typeKey: 'two_day' },
//   { title: '昨仓个数', key: 'yNum', typeKey: 'two_day' },
//   { title: '昨仓涨停个数', key: 'yRiseNum', typeKey: 'two_day' },
//   { title: '今仓个数', key: 'tNum', typeKey: 'two_day' },
//   { title: '今仓涨停个数', key: 'tRiseNum', typeKey: 'two_day' },
//   { title: '上证主板数量', key: 'shNum', typeKey: 'main_board' },
//   { title: '科创数量', key: 'kCNum', typeKey: 'main_board' },
//   { title: '主板数量', key: 'mainBoardNum', typeKey: 'main_board' },
// ];
// tooltips里面需要额外显示个数的对象；收益率key:对应的个数key
const TOOLTIP_SHOW_NUM = {
  'activeOneAverageRate': 'activeOneAvgNum',
  'activeTwoAverageRate': 'activeTwoAvgNum',
  'activeThreeAverageRate': 'activeThreeAvgNum',
  'activeFourAverageRate': 'activeFourAvgNum',
  'reboundOneAverageRate': 'reboundOneAvgNum',
  'reboundTwoAverageRate': 'reboundTwoAvgNum',
  'reboundThreeAverageRate': 'reboundThreeAvgNum',
  'reboundFourAverageRate': 'reboundFourAvgNum',
  'trendOneAverageRate': 'trendOneAvgNum',
  'trendTwoAverageRate': 'trendTwoAvgNum',
  'trendThreeAverageRate': 'trendThreeAvgNum',
  'trendFourAverageRate': 'trendFourAvgNum',
}
//【3.添加select的选项 options，key=switchAll重中的key值】 ;  移动修改为label,value字段
const RATE_CONFIG = [
  { value: 'updown', label: '涨停', children: MAIN_KEYS_OBJ.updown, extraGetKey: RATE_KEYS_OBJ.rdKeys },
  { value: 'two_day', label: '近2日', children: MAIN_KEYS_OBJ.twoDay, extraGetKey: RATE_KEYS_OBJ.tdKeys },
  { value: 'updown_plus', label: '涨停+', children: MAIN_KEYS_OBJ.updown_plus, extraGetKey: RATE_KEYS_OBJ.rdKeys },
  { value: 'two_day_plus', label: '近2日+', children: MAIN_KEYS_OBJ.twoDay_plus, extraGetKey: RATE_KEYS_OBJ.tdKeys },
  { value: 'five_day', label: '5日', children: MAIN_KEYS_OBJ.week, extraGetKey: RATE_KEYS_OBJ.weekLimit },
  { value: 'buy_sell', label: '买卖', children: MAIN_KEYS_OBJ.bs },
  { value: 'plate', label: '板块', children: MAIN_KEYS_OBJ.plate },
  { value: 'active', label: '活跃度', children: MAIN_KEYS_OBJ.active, extraGetKey: active_number_keys },
  { value: 'rebound', label: '反弹度', children: MAIN_KEYS_OBJ.rebound, extraGetKey: rebound_number_keys },
  { value: 'trend', label: '趋势', children: MAIN_KEYS_OBJ.trend, extraGetKey: trend_number_keys },
  { value: 'main_board', label: '主板', children: MAIN_KEYS_OBJ.mainBoard },
];

const PRE_DAY_CN = '前一交易日';
const SHOW_CARRY = 4;
const DEFAULT_CARRY = 5;
const CAL_CARRY = DEFAULT_CARRY + 1;
const renderFloats = (val, mul, pos = DEFAULT_CARRY) => _.round(parseFloat(val) * mul, pos);
/* 
  通用图表配置
*/
const COMMON_CHART = {
  'rateX': {
    boundaryGap: false,
    splitLine: { show: true },
    data: []
  },
  'rateY': {
    splitArea: { show: true },
    splitLine: { show: true },
  },
  'tooltipCustom': {
    show: true,
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      lineStyle: {
        type: 'dashed',
        width: 1
      }
    },
    padding: 5,
  },
  'extraRate2': createBaseLine('#e4b100', { name: '超额收益率(几何)', isDash: true }),
  'extraBar': {
    grid: { left: '10%', right: '10%', bottom: '7%', top: '5%' },
    tooltip: { trigger: 'axis', },
    xAxis: { type: 'category', data: [], },
    yAxis: { type: 'value', splitNumber: 4, minInterval: 0.5 },
    series: [
      {
        name: '超额',
        type: 'bar',
        data: [],
        markLine: {
          symbol: 'none',
          data: [{ yAxis: -1.5 }, { yAxis: -1 }, { yAxis: -0.5 }, { yAxis: 0 }, { yAxis: 0.5 }, { yAxis: 1 }, { yAxis: 1.5 }]
        }
      }
    ],
  },
  'extraPie': {
    tooltip: { trigger: 'item' },
    series: [
      {
        name: '占比',
        type: 'pie',
        radius: '45%',
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  }
}
// 回撤配置
const RETREAT_OPTIONS = {
  xAxis: { name: '时间', type: 'category', ...COMMON_CHART.rateX },
  yAxis: {
    name: '回撤',
    type: 'value',
    min: -10,
    max: 0,
    splitNumber: 5,
    ...COMMON_CHART.rateY
  },
  series: [
    createBaseLine('#007db1', { name: '回撤' }),
    createBaseLine('#B5495B', { name: '指数' }),
    createBaseLine('#ffa600', { name: '超额回撤(几何)' }),
  ],
  tooltip: { trigger: 'axis' },
  grid: { left: '8%', right: '3%', bottom: '10%', top: '10%' },
}

const MAIN_RATE_OPTIONS = {
  xAxis: { name: '时间', type: 'category', ...COMMON_CHART.rateX },
  yAxis: {
    name: '收益率(%)',
    type: 'value',
    splitNumber: 5,
    ...COMMON_CHART.rateY
  },
  tooltip: { trigger: 'axis' },
  series: [{
    name: '收益率',
    ...createBaseLine('#007db1'),
    base_key: 'base_key',
  }, {
    name: '指数波幅',
    data: [],
    type: 'line',
    symbol: 'none',
    base_key: 'base_key',
    lineStyle: {
      color: '#B5495B',
      type: 'dashed',
      width: 1,
    },
    itemStyle: {
      color: '#B5495B'
    },
  }, {
    ...createBaseLine('#ffa600', { name: '超额收益率' }),
    symbol: '',
    data: [],
    base_key: 'base_key',
  }],
  grid: { left: '8%', right: '3%', bottom: '10%', top: '10%' },
}

const TWO_CHARTS = {
  'main': {
    grid: { left: '8%', right: '3%', bottom: '10%', top: '10%' },
    legend: { data: [] },
    xAxis: { type: 'category', ...COMMON_CHART.rateX },
    yAxis: [{ // 对比指数使用右侧Y轴，成交量作为右侧第二Y轴
      type: 'value',
      ...COMMON_CHART.rateY,
      position: 'left',
    }, {
      type: 'value',
      name: '指数',
      position: 'right',
      splitLine: { show: false },
      data: []
    }, {
      type: 'value',
      // name: '成交量',
      offset: 35,
      position: 'right',
      splitLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      data: []
    }],
    series: [],
    tooltip: { trigger: 'axis' },
  },
  'retreat': {
    grid: { left: '8%', right: '3%', bottom: '10%', top: '10%' },
    xAxis: { type: 'category', ...COMMON_CHART.rateX },
    legend: { data: [], left: 95 },
    yAxis: {
      name: '回撤',
      type: 'value',
      min: -10,
      max: 0,
      splitNumber: 5,
      ...COMMON_CHART.rateY
    },
    series: [],
    tooltip: { trigger: 'axis' },
  },
  'avg': {
    grid: { left: '10%', right: '10%', bottom: '10%', top: '10%' },
    legend: { data: ['MA60', 'MA20', 'MA10', 'MA5'] },
    tooltip: { trigger: 'axis' },
    xAxis: { type: 'category', data: [] },
    yAxis: [
      { type: 'value', splitLine: { show: false }, splitNumber: 3, },
      { type: 'value', interval: 0.05 },// 轴拆分线间隔大小,
    ],
    series: [
      {
        name: '',
        type: 'bar',
        yAxisIndex: 0,
        label: { show: false },
        data: [],
      }
    ]
  }
}

// [计算超额收益率]两个数组值相减，得到新数组
const arraysMinus = (array1 = [], array2 = []) => {
  let final = [];
  array1.map((n, i) => {
    final[i] = renderFloats(n - _.get(array2, `[${i}]`, 0), 1, CAL_CARRY);
  })
  return final;
}
// 同上 [超额回撤]，因处理负数，结果大于0则返回0
const arraysMinus2 = (array1 = [], array2 = []) => {
  let final = [];
  array1.map((n, i) => {
    const result = renderFloats(n - _.get(array2, `[${i}]`, 0), 1, CAL_CARRY);
    final[i] = result > 0 ? 0 : result
  })
  return final;
}
// 计算收益率
function calRate(listArray = [], start = 0, rv = 100) {
  const isValidStart = isValidNumber(start);
  return _.size(listArray) > 0 ? listArray.map(p => {
    let rateVal = isValidStart ? _.round((p - start) / start, CAL_CARRY) * rv : 0;
    return p !== '-' && isValidStart ? parseFloat(rateVal.toFixed(DEFAULT_CARRY)) : p;
  }) : [];
}
// 计算净值
function calNets(listArray = []) {
  return _.size(listArray) > 0 ? listArray.map(n => {
    let netVal = _.round(1 * (1 + (n / 100)), CAL_CARRY);
    return n !== '-' ? parseFloat(netVal.toFixed(DEFAULT_CARRY)) : n
  }) : [];
}
// 获取数据后，指数与收益率时间轴有差距，须重新计算指数；【返回】前一交易日收盘价
function calTimeDiff(pointTime = [], indexTime = [], points = [], close = 0, notToday) {
  /* notToday：【解决同样指数数据，切换功能时指数改变】 当日指数会返回全部数据，也就是9点-15点数据，
  但是收益率只返回到当时时间（例如 9:50）,所以计算时间差返回了数据第一个点，而不是昨日收盘价；
  */
  const sizeDiff = _.size(pointTime) - _.size(indexTime); //两组时间array差值；大于1则进行处理
  let final = 0;
  if (Math.abs(sizeDiff) > 1 && _.size(pointTime) > 0 && notToday) {
    const idx = indexTime.indexOf(pointTime[1]); //pointTime[0]位是 “前一交易日” 进行占位，所以无法找到时间数据
    const newIdx = idx !== 0 ? idx - 1 : 0; //idx=0说明指数也是第一位,不需要-1找到前一交易日数据
    final = newIdx > 0 ? points[newIdx] : close; // idx=0直接用数据返回的收盘价close
  } else {
    final = close;
  }
  return final;
}
// 计算数组内大小值
function calMaxMin(array = [], cur = 0, type = 'min') {
  // 计算array时需要判断无 "-" 占位 及NaN数据
  const newArray = _.size(array) > 0 ? array.map(n => n && n !== '-' ? n : 0) : [0];
  if (type === 'min') {
    let minVal = _.min(newArray);
    return minVal < cur ? minVal : cur;
  } else if (type === 'max') {
    let maxVal = _.max(newArray);
    return maxVal > cur ? maxVal : cur;
  }
}
// y轴最大最小值计算，如小于默认值区间则返回默认值
function calcNumber(number, type) {
  if (type == 'min') {
    if (number <= 0 && number < -1) {
      return _.ceil(number * 1.1, 2)
    } else {
      return -1
    }
  } else {
    if (number >= 0 && number > 1) {
      return _.ceil(number * 1.1, 2)
    } else {
      return 1
    }
  }
};
// 累乘方法
function multiplication(array = [], carry = 6, rv = 100) {
  let sum = 0;
  let newValArray = [];
  let orrrg = [];
  let nonZero = false; // 判断非零位，true=已经遍历出有效值，如中间数据为0，可继续执行计算，而不是直接输出0；
  // 【累加乘公式】 (1 + n)(1 + n1)(1 + n2) 连成计算结果  ;  [运算保留精度6位]
  if (isValidArray(array)) {
    array.map(val => {
      if (nonZero && sum !== 0) {
        let calval = (1 + val) * sum; //累计收益率计算公式
        sum = calval;
        newValArray.push(renderFloats(calval - 1, rv, carry)); // 百分比结果：（val - 1） * 100
      } else if (val !== 0 && sum === 0) {
        newValArray.push(renderFloats(val, rv, carry));
        sum = 1 + val; // 进行1+处理
        nonZero = true;
      } else {
        newValArray.push(renderFloats(val, rv, carry));
      }
      orrrg.push(val);
    })
  }
  return newValArray;
};
// 累加
function accumulate(array = [], rv = 1) {
  let sum = 0; let final = [];
  if (isValidArray(array)) {
    array.map(n => {
      sum = sum + (n * rv);
      final.push(_.round(sum, DEFAULT_CARRY));
    });
  };
  return final;
};
// 收益率渲染及计算; 返回 图表option对象及最大最小值
function handleRateOption(subVals = [], options = {}, sData = {}, idKey = 0, slArray = [0, 0], type = 'emp') {
  // subVals当前check选中的keys；options图表option；sData全局Series的数据;idKey第一位数据；slArray裁剪数组；hlArray当前最大最小值数组；isList:是否是子账户页面；switchs是开关数组; type判断空还是正常数据渲染
  let nopion = _.cloneDeep(options);
  let high = 0; let low = 0; // 先处理收益率，最大最小值初始 0；
  let newSeries = [];
  //【废弃】 type = emp 时，生成serice数据，但是data:[],这样避免echart查到空数据报错
  // type = full时，用原始获取数据，无需 计算净值 & 通过净值计算收益率 操作
  if (isValidArray(subVals)) {
    subVals.map(keys => { // check已选择遍历，创建series item
      //const is_show = isList && _.includes(switchs, keys) ? true : false;
      const is_full = type === 'full' ? true : false
      const getSeriesDat = _.get(sData, keys, []);
      //选择单条数据用，如checkbox未选择，则将数据置空 ； 且type=emp时也是同样效果
      const calNetsValue = is_full ? getSeriesDat : calNets(getSeriesDat); // 先恢复为净值
      let sliceVal = is_full ? getSeriesDat : renderSlice(calNetsValue, slArray[0], slArray[1]);
      let calSliceVal = null;
      if (!is_full) {
        // 截取plus用原始数据进行截取并重新计算累加
        if (_.includes(keys, STR_PLUS)) {
          const val_key = _.replace(keys, STR_PLUS, '');
          const getFromOther = renderSlice(sData.other, slArray[0], slArray[1]);
          calSliceVal = _.concat([0],
            accumulate(getFromOther.map(n => _.get(n, val_key)))
          );
        } else {
          const startValue = parseFloat(_.get(sliceVal, `[${idKey}]`, 0));
          calSliceVal = calRate(sliceVal, startValue); // 在从截取位置开始计算收益率
        };
      };
      const finalsData = is_full ? sliceVal : calSliceVal
      //console.log(keys, getSeriesDat)
      high = calMaxMin(finalsData, high, 'max');
      low = calMaxMin(finalsData, low, 'min');
      // 根据key从所有KEYS里面找到静态object，赋值
      const findKeyIndex = _.findIndex(RATE_KEYS_OBJ.all, o => o.key === keys);
      if (findKeyIndex !== -1) {
        let sObj = {
          name: _.get(RATE_KEYS_OBJ.all, `[${findKeyIndex}].name`),
          keyname: _.get(RATE_KEYS_OBJ.all, `[${findKeyIndex}].key`),
          data: finalsData,
          rate_show: 'rate_show', // 删除用的该分类key
          type: 'line',
          symbol: 'none',
          lineStyle: {
            color: _.get(RATE_KEYS_OBJ.all, `[${findKeyIndex}].color`), opacity: 0.7
          },
          itemStyle: { color: _.get(RATE_KEYS_OBJ.all, `[${findKeyIndex}].color`) }
        }
        newSeries.push(sObj);
      }
    });
    // base_key 是收益率、指数、超额 4条基础曲线，合并赋值serise
    nopion.series = _.concat(_.filter(nopion.series, o => 'base_key' in o), newSeries);
  } else {
    // 取消选择或者重载，删除带有rate_show的其他收益曲线
    nopion.series.map(s => {
      if (!('rate_show' in s)) {
        newSeries.push(s);
      }
    });
    nopion.series = newSeries;
  }
  // 计算最大最小值 ; [移动端无需计算min\max]
  // nopion.yAxis.min = low < _.get(options, 'yAxis.min') ? calcNumber(low, 'min') : low;
  // nopion.yAxis.max = high > _.get(options, 'yAxis.max') ? calcNumber(high, 'max') : high;
  return nopion
}
// 计算选中收益的超额收益； 返回options数据及最大最小值
const arraysMinusDiv = (arr1 = [], arr2 = []) => {
  let final = [];
  arr1.map((n, i) => {// 收益率累乘计算时要将收益率的百分比 除以100进行累乘计算
    final[i] = _.round(parseFloat((n - _.get(arr2, `[${i}]`, 0)) / 100), DEFAULT_CARRY);
  })
  return final;
}

function renderSlice(array = [], start = 0, end = 0) {
  return _.slice(array, start, end + 1)
};
// 计算收益公式；
const calRateFormula = (val, pre) => _.round((val - pre) / pre, CAL_CARRY) * 100;
// 通过收益率时间渲染指数的数据，并计算波幅；【波幅公式】 n-收盘价 / 收盘价
function handleIndexValue(dateType = '', timesArray = [], indexRes = {}) {
  // 从index返回提取数据
  const is_not_today = dateType !== 'TODAY' ? true : false;
  const last_index = _.get(indexRes, 'lastIndex', 9999); //默认9999，产品对比时不执行invalid判断
  const indexPoints = _.get(indexRes, 'data.pointList', []);
  const indexTimes = _.get(indexRes, 'data.timeList', []);
  const indexPreClose = _.get(indexRes, 'data.preClose', 0);
  const indexVol = _.get(indexRes, 'data.volumeList', []);
  const indexMargin = _.get(indexRes, 'data.margin', []);
  const newCloseValue = is_not_today ? calTimeDiff(timesArray, indexTimes, indexPoints, indexPreClose, true) : indexPreClose;

  let indexValues = ['-']; let priceValues = []; let dayPriceVals = [];
  let pureValue = []; let purePrice = [];
  let vol = []; let margin = []; // [新增]成交量,两融
  let stimeArr = [];
  // 通过收益率时间渲染指数的数据，并计算波幅；【波幅公式】 n-收盘价 / 收盘价
  timesArray.map((t, i) => {
    const idxTime = indexTimes.indexOf(t);
    const rateValue = newCloseValue === 0 ? 0 : calRateFormula(indexPoints[idxTime], newCloseValue);
    if (idxTime !== -1) {
      const rate = rateValue.toFixed(DEFAULT_CARRY); const idrate = indexPoints[idxTime];
      const invalidPoint = !is_not_today && i > last_index; // 实时增加盘中没到的时间指数不显示
      if (!invalidPoint) {
        indexValues[i] = parseFloat(rate);
        priceValues[i] = _.round(idrate, DEFAULT_CARRY);
        pureValue.push(parseFloat(rate));
        purePrice.push(idrate);
        vol.push(_.round(parseFloat(indexVol[idxTime]) / 10000));
        margin.push(_.round(parseFloat(indexMargin[idxTime]) / 100000000));//除 亿
      }
    } else {
      indexValues[i] = '-';
      priceValues[i] = '-'
    }
    stimeArr.push(i);
  });
  // 不是实时数据，timesArray收益率数据第一位是’前一交易日‘，所以indexValues[0]='-',故填充为0；
  if (is_not_today) {
    indexValues[0] = 0;
    priceValues[0] = newCloseValue;
    vol.unshift(0);
    // 每日收益计算，(当日-前一日)/前一日
    dayPriceVals = priceValues.map((p, i) => {
      return _.round(parseFloat(calRateFormula(p, i === 0 ? newCloseValue : priceValues[i - 1])), DEFAULT_CARRY)
    });
  }
  // indexValues,priceValues：根据时间赋值的价格及计算后波幅数据；dayPriceVals：每日指数收益
  // purePrice,pureValue:价格和波幅的纯数据(无占位字段)，用于判断数组大小
  // stimeArr:slider滑动所需的纯数字数组;
  return {
    indexValues, priceValues,
    purePrice, pureValue,
    dayPriceVals,
    stimeArr,
    vol, margin,  // vol 成交量,两融
    'close': newCloseValue,  // 前一收盘作为参数返回，可复用
  }
}
// 计算回撤数据 ； 查找当前点之前的最大值，当前点-前最大值 / 最大值； 都是负数
function handleRetreat(array = []) {
  let retreat = [];
  if (isValidArray(array)) {
    array.map((n, i) => {
      let cur = 0;
      if (i > 0) {
        const beforeMax = _.max(_.slice(array, 0, i + 1));
        cur = beforeMax ? _.round(((n - beforeMax) / beforeMax) * 100, DEFAULT_CARRY) : 0;
      }
      retreat.push(cur >= 0 ? 0 : cur);
    })
  }
  return retreat;
}
// 超额回撤: 查找当前点之前的最大值，当前点-前最大值 / (1+前最大值/100)
const handleRetreatExtra = (array = []) => {
  let retreat = [];
  if (isValidArray(array)) {
    array.map((n, i) => {
      let cur = 0;
      if (i > 0) {
        const beforeMax = _.max(_.slice(array, 0, i + 1));
        cur = beforeMax ? _.round((n - beforeMax) / (1 + (beforeMax / 100)), DEFAULT_CARRY) : 0;
      }
      retreat.push(cur >= 0 ? 0 : cur);
    });
  }
  return retreat;
}
// 处理超额部分的options数据 [回撤options, 回撤数据，指数回撤，超额数据，时间]
function renderRetreatOption(option2 = {}, options = {}, rePoint = [], reIdx = [], exGeo = [], times = []) {
  // 基础回撤
  let newOption2 = _.cloneDeep(option2);
  let baseSeries = _.cloneDeep(RETREAT_OPTIONS.series);
  let min = 0; let max = 0;
  baseSeries[0].data = rePoint;
  baseSeries[1].data = reIdx;
  baseSeries[2].data = exGeo;
  newOption2.xAxis.data = times

  min = calMaxMin(_.concat(reIdx, rePoint, exGeo), 0, 'min');
  max = calMaxMin(_.concat(reIdx, rePoint, exGeo), 0, 'max');
  //其他收益率动态增加回撤; 【bug-fix】引用也需要cloneDeep，否则会把收益率值修改掉
  const cloneSeries = _.cloneDeep(_.get(options, 'series', []));
  const otherRates = _.filter(cloneSeries, o => 'rate_show' in o);
  let reSerise = [];
  if (isValidArray(otherRates)) {
    otherRates.map(sitem => {
      const nets = calNets(_.get(sitem, 'data', []));
      const reDatas = handleRetreat(nets);
      reSerise.push(_.assign(sitem, { 'data': reDatas }));
      min = calMaxMin(reDatas, min, 'min');
      max = calMaxMin(reDatas, max, 'max');
    });
  }
  newOption2.series = _.concat(baseSeries, reSerise);
  newOption2.yAxis.min = min;
  newOption2.yAxis.max = max;

  return newOption2;
}

const UPDOWN_COLOR = { // 颜色数组从深到浅
  'redLevel': ['#cf0128', '#f32f54', '#f75f7c', '#ff97aa'],
  'greenLevel': ['#0da550', '#18c966', '#60ffa6', '#a4ffcc'],
  'up': 'red', 'flat': 'grey', 'down': 'green'
};
// 超额统计区间
const INTERVAL_KEYS = [
  { key: '1', name: '小于负1.5', min: -10000000, max: -1.5, colorPath: 'greenLevel[0]' },
  { key: '2', name: '负1.5到1', min: -1.5, max: -1, colorPath: 'greenLevel[1]' },
  { key: '3', name: '负1到0.5', min: -1, max: -0.5, colorPath: 'greenLevel[2]' },
  { key: '4', name: '负0.5到0', min: -0.5, max: 0, colorPath: 'greenLevel[3]' },
  { key: '0', name: '零', min: 0, max: 0, colorPath: 'flat' },
  { key: '5', name: '0到0.5', min: 0, max: 0.5, colorPath: 'redLevel[3]' },
  { key: '6', name: '正0.5到1', min: 0.5, max: 1, colorPath: 'redLevel[2]' },
  { key: '7', name: '正1到1.5', min: 1, max: 1.5, colorPath: 'redLevel[1]' },
  { key: '8', name: '大于1.5', min: 1.5, max: 10000000, colorPath: 'redLevel[0]' },
];
//新增10个静态颜色; 10个方便下一轮颜色方便计算
const STATIC_RATE_COLOR = [
  '#438497', '#f07e00', '#bbca21', '#006b7f', '#e95268',
  '#a00080', '#cf8c00', '#97a88e', '#e82200', '#f56000',
];

// 渲染 涨红跌绿 颜色
function renderValueColor(value, vType = 'pure', colors = { up: 'red', down: 'green', zero: 'black' }) {
  let pureValue = vType === 'replace' ? _.replace(value || '', ',', '') : value;
  const floatValue = pureValue ? _.round(parseFloat(pureValue), 3) : 0;
  if (floatValue > 0) {
    return colors.up;
  } else if (floatValue < 0) {
    return colors.down;
  } else {
    return colors.zero;
  }
}
// 计算超额图表数据 - 计算周月统计
const calRangeAvg = (range = {}) => {
  let allsum = 0; let allsize = 0; let passsize = 0;
  if (isValidObj(range)) {
    _.keys(range).map(m => {
      allsum += range[m];
      allsize++;
      if (range[m] > 0) {
        passsize++;
      }
    });
  }
  return {
    avg: _.round(allsum / allsize, SHOW_CARRY),
    psize: _.round((passsize / allsize) * 100, 2),
    size: allsize,
  }
};
// 计算周月维度；按照每日进行累乘，plus数据进行累加，得出周月层面结果； 返回weeks相同的对象
function calRangeValue(range = {}, isextra = false, isaccumulate = false) {
  let final = {};
  if (isValidObj(range)) {
    _.keys(range).map(rname => {
      const ranRate = range[rname].map(n => n[0] / 100);
      const idxRate = range[rname].map(n => n[1] / 100);
      const ranVal = isaccumulate ? accumulate(range[rname].map(n => n[0])) : multiplication(ranRate, 4);
      const idxVal = isaccumulate ? accumulate(range[rname].map(n => n[1])) : multiplication(idxRate, 4);
      const rvalue = _.last(ranVal);
      final[rname] = isextra ? _.round(rvalue - _.last(idxVal), 4) : rvalue;
    });
  };
  return final;
}
// 超额饼图
function handleRatePies(calsData) {
  let pieData = []; let pieObj = {};
  INTERVAL_KEYS.map(k => {
    const get_size = _.get(calsData, `${k.key}.size`, 0); // 出现个数
    const get_avg = _.get(calsData, `${k.key}.avg`, 0); // 收益率平均
    if (isValidNumber(get_size)) {
      pieData.push({
        'value': get_size,
        'name': k.name + `(${get_avg}%)`,
        'kname': k.name,
        'itemStyle': { 'color': _.get(UPDOWN_COLOR, k.colorPath) }
      });
      // 增加对象格式
      // _.set(pieObj, k.name, get_size);
      pieObj[k.name] = get_size;
    }
  });
  return { pieData, pieObj };
}
// 计算超额图表数据 
// 【新】增加对0的处理，0增加到全部计算中，size！=0及可进行计算
function handleDaliyRates(
  daliyData = [], // 每日数据
  indexNet = [], // 每日指数
  timeData = [], // 时间
  tDates = [],// 全部交易时间
  isRangeExtra = false, // 是否超额计算
  isAccumulate = false, // 是否进行累加处理
) {
  let bar3 = []; let bar4 = []; let pure = [];
  let cal = { 'timeSize': _.size(timeData) - 1 };
  let months = {}; let weeks = {}; let season = {};
  // 创建计算对象的空数值
  INTERVAL_KEYS.map(k => {
    _.set(cal, k.key, { size: 0, sum: 0, darray: [] });
  });
  if (isValidArray(daliyData)) {
    daliyData.map((n, ix) => {
      pure.push(n); // pure超额值
      // bar3 记录正负值
      bar3.push({ 'value': n, 'index': ix, 'itemStyle': { color: renderValueColor(n, 'pure', { up: 'red', down: 'green', zero: '#9e9e9e' }) } })
      // 首位0的判断
      const isFirst = n === 0 && ix === 0 ? true : false;
      const idnetVal = indexNet[ix] ?? 0;
      const curVal = [n, idnetVal ?? 0]; // 当前值，指数值
      const newVal = isRangeExtra ? _.round(n - idnetVal, 4) : n;
      // 符合区间条件的记录index值，比min大，比max小，并且判断是否等于0
      let interIdx = -1;
      INTERVAL_KEYS.map((k, ki) => {
        if (newVal > _.get(k, 'min') && newVal < _.get(k, 'max')) {
          interIdx = ki;
        };
        if (newVal === 0 && !isFirst) {
          interIdx = 4;
        }
      });
      //符合条件的递增cal内的个数和收益率和sum数据
      if (interIdx !== -1) {
        const cal_key = _.get(INTERVAL_KEYS, `[${interIdx}].key`);
        _.set(cal, `${cal_key}.size`, _.get(cal, `${cal_key}.size`) + 1);
        _.set(cal, `${cal_key}.sum`, _.get(cal, `${cal_key}.sum`) + newVal);
        // darray push对应的日期数据；可在popover中直接遍历显示
        cal[cal_key]['darray'].push(timeData[ix]);
        bar4.push({
          'value': newVal,
          'index': ix,
          'calKey': cal_key,
          'itemStyle': { 'color': _.get(UPDOWN_COLOR, INTERVAL_KEYS[interIdx]['colorPath']) }
        });
      }
      // 第一位=0 是前一交易日，用0补充
      if (isFirst) {
        bar4.push({ value: 0 })
      };
      //按月统计累计超额
      if (timeData[ix] !== PRE_DAY_CN) {
        const spl_month = _.split(timeData[ix], '-')
        const mval = spl_month[0] + '-' + spl_month[1];
        if (mval in months) {
          months[mval].push(curVal);
        } else {
          _.set(months, mval, [curVal]);
        };
        const find_date = _.findIndex(tDates, o => o.date === timeData[ix]);
        // 周统计；week字段为全局时间处理，提前划分周信息
        const years = moment(timeData[ix]).year();
        const shortYear = String(years).substring(2);
        const wval = _.get(tDates, `[${find_date}].week`, 0);
        const wvalString = `${shortYear}-${wval}周(${_.get(tDates, `[${find_date}].start`, 0)})`
        if (wvalString in weeks) {
          weeks[wvalString].push(curVal);
        } else {
          _.set(weeks, wvalString, [curVal]);
        }
        // 季度统计
        const sval = _.get(tDates, `[${find_date}].season`, 0);
        const svalString = `${shortYear}-${sval}季(${_.get(tDates, `[${find_date}].seaStart`, 0)})`;
        if (svalString in season) {
          season[svalString].push(curVal);
        } else {
          _.set(season, svalString, [curVal]);
        }
      }
    });
    // 再次遍历计算每组平均超额收益率; 及全部超额统计
    let all_sum = 0; let all_size = 0; let passtive_size = 0;
    INTERVAL_KEYS.map(k => {
      const get_size = _.get(cal, `${k.key}.size`, 0);
      const get_sum = _.get(cal, `${k.key}.sum`, 0);
      if (get_size) {
        _.set(cal, `${k.key}.avg`, _.round(get_sum / get_size, SHOW_CARRY));
        all_sum += get_sum;
        all_size += get_size;
        if (get_sum > 0) {
          passtive_size += get_size;
        }
      }
    });
    if (all_sum && all_size) { // 总超额
      _.set(cal, 'day.size', _.size(timeData) - 1); // 交易日数量
      _.set(cal, 'day.avg', _.round(all_sum / all_size, SHOW_CARRY));
      if (passtive_size) { // 计算正超额天数
        _.set(cal, 'day.psize', _.round((passtive_size / all_size) * 100, 2));
      }
    }
  };
  // console.log('weeks', weeks)
  const newWeek = calRangeValue(weeks, isRangeExtra, isAccumulate);
  const newMonth = calRangeValue(months, isRangeExtra, isAccumulate);
  const newSeason = calRangeValue(season, isRangeExtra, isAccumulate);
  // console.log('newWeek', isRangeExtra, newWeek)
  // 统计月与周的超额胜率；day.months.weeks字段同上层切换字段
  _.set(cal, 'weeks', calRangeAvg(newWeek));
  _.set(cal, 'months', calRangeAvg(newMonth));
  _.set(cal, 'season', calRangeAvg(newSeason));
  // 饼图与range所需的对象数据进行提前处理
  const pies = handleRatePies(cal);
  return {
    bar3, bar4, pure, cal,
    'months': newMonth, 'weeks': newWeek, 'season': newSeason,
    // 新增pie图表计算，合并为同一个对象
    'pies': pies.pieData, 'ranges': pies.pieObj,
  }
};

// slider是否滑动
function isFullTimeRange(sliders, timearr) {
  const sliderValueOne = _.get(sliders, '[0]', 0);
  const sliderValueTwo = _.get(sliders, '[1]', 0);
  const timeValueFirst = _.get(timearr, '[0]', 0);
  const timeValueLast = _.last(timearr);
  let finalBool = false;
  if (sliderValueOne === timeValueFirst && sliderValueTwo !== 0 && timeValueLast && timeValueLast === sliderValueTwo) {
    finalBool = true;
  }
  return finalBool
}
function isSliderFirstZero(sliders, timearr) { // range 最小值为0时，收盘价无需修改
  const sliderValueOne = _.get(sliders, '[0]', 0);
  const timeValueFirst = _.get(timearr, '[0]', 0);
  let finalBool = false;
  if (sliderValueOne === timeValueFirst) {
    finalBool = true;
  }
  return finalBool
}
// 静态颜色集，可适用于图表的颜色；[未命名颜色为相近或不常使用颜色]
const COLOR_ARRAY = [
  '#e29e1f', "#7EC0EE", "#FF9F7F", "#FFD700",
  '#5DAC81', //岩竹
  '#336774', // 深蓝
  '#F596AA', // 桃
  '#CA7A2C', // 琥珀
  '#AB3B3A', // 真朱-红
  '#646A58', // 岩叶
  '#6E75A4', // 滕鼠
  '#9E7A7A', // 梅鼠-棕
  '#0fbab7', '#ffcc54', '#a5d349',
  '#91989F', //白鼠
  '#B5495B', // 莓
  '#554235', // 深褐
  '#376B6D', //御召茶
  '#063f78', '#050dd9', '#ea8c3a', '#f22727', '#a7f706', '#73f6ae', '#99e0f4', '#649700',
  '#e16f91', '#056fd9', '#e4c686', '#8d8c8c', '#6105d9', '#ab0548', '#b00404', '#684a02',
  '#947A6D', // 胡桃色
  '#69B0AC', //青瓷
  '#BB81BB', // 香芋色
  '#6D2E5B', // 葡萄
  '#B07736', //丁子柒
  '#91AD70', // 柳染
  '#A0674B', //糖茶
  '#867835', //黄海松茶
  '#F9BF45', //玉子-黄
  '#9B90C2', // 香芋2
  '#F6C555', //栀子
  '#96513D', // 小豆-棕偏红
  '#7C73CE', // 紫
  '#ECF0F2', // 淡灰
  '#3A3F60', // 冷兰
  '#8B7352', // 冷棕
  '#A99786', // 棕
  '#D5A431', '#7E9E91', '#CFCFCF', '#948C77', '#5668FF',
  '#303153', // 墨色
  '#9D9CAC', // 墨色
];
// 计算移动均线;区分k线或者收益率均线
function calMaLine(kdata = [], ma = 5, type = 'kline') {
  let final = [];
  if (isValidArray(kdata)) {
    kdata.map((n, i) => {
      if (type === 'rate') {
        final[i] = i <= ma ? '-' : _.chain(kdata)
          .slice(i < ma ? 0 : i - (ma - 1), i + 1)
          .map(k => k)
          .sum() // 累加
          .divide(i < ma ? i + 1 : ma) // 求平均
          .round(4)
          .value();
      } else if (type === 'kline' && _.size(n) > 1) {
        final[i] = _.chain(kdata)
          .slice(i < ma ? 0 : i - (ma - 1), i + 1) // 截取前num分钟数据，不足num分钟拿所有
          .map(k => parseFloat(k[1]))
          .sum() // 累加
          .divide(i < ma ? i + 1 : ma) // 求平均
          .round(2)
          .value();
      }
    })
  }
  return final;
}

export {
  // 静态配置
  SHOW_CARRY, DEFAULT_CARRY,
  RATE_KEYS_OBJ, RATE_CONFIG,
  TOOLTIP_SHOW_NUM,
  STATIC_RATE_COLOR, COLOR_ARRAY,
  PRE_DAY_CN,
  UPDOWN_COLOR, INTERVAL_KEYS,
  STR_PLUS,
  // chart-options
  COMMON_CHART,
  RETREAT_OPTIONS,
  MAIN_RATE_OPTIONS,
  TWO_CHARTS,
  // 通用方法
  createBaseLine,
  renderFloats,
  renderValueColor,
  // mainChart等收益率计算方法
  arraysMinus, arraysMinus2, arraysMinusDiv,
  calRate, calNets,
  calTimeDiff,
  calMaxMin, calcNumber,
  multiplication, accumulate,
  handleRateOption,
  renderSlice,
  calRateFormula,
  handleIndexValue,
  handleRetreat, handleRetreatExtra,
  handleDaliyRates,
  isFullTimeRange, isSliderFirstZero,
  renderRetreatOption,
  calMaLine,
}
import React from 'react';
import Guards from '@/router/Guards';
import { Provider } from 'mobx-react';
import { store } from '@/store/mobx';
import '@/utils/global';


function App() {
  return (
    <Provider store={store}>
      <Guards />
    </Provider>
  );
}

export default App;



const LEVELS = [
  { name: '高', color: '#dd0000', key: 'high', index: 0 },
  { name: '中', color: '#ffcd00', key: 'middle', index: 1 },
  { name: '低', color: '#00a388', key: 'down', index: 2 },
];
const PLATES = [
  { name: '北交所', color: '#775fa0', index: 0 },
  { name: '上证主板', color: '#c7161d', index: 1 },
  { name: '深证主板', color: '#dab301', index: 2 },
  { name: '科创板', color: '#aacf53', index: 3 },
  { name: '创业板', color: '#005eab', index: 4 },
];

const DISPLAY_OPTIONS = {
  'baseBar': {
    tooltip: { trigger: 'item' },
    series: [
      {
        name: '仓位分析',
        type: 'pie',
        radius: '53%',
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  },
  'line': {
    grid: { left: '8%', right: '5%', bottom: '15%', top: '5%' },
    tooltip: {
      trigger: 'axis',
      enterable: true,
      extraCssText: 'max-height:250px;overflow:scroll',
      confine: true,
    },
    xAxis: { type: 'category', data: [] },
    yAxis: { type: 'value' },
    series: []
  },
  'plate': {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
    },
    legend: { bottom: 0 },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value} %'
      },
      min: 0,
      max: 100
    },
    xAxis: { type: 'category', data: [] },
    series: PLATES.map(n => {
      return {
        name: n.name, type: 'bar', stack: 'total',
        // label: { show: true, formatter: '{c}%' },
        emphasis: { focus: 'series' },
        itemStyle: { color: n.color },
        data: []
      }
    }),
  },
  'level': {
    tooltip: { trigger: 'axis' },
    legend: { data: [], bottom: 10 },
    xAxis: { type: 'category', data: [] },
    yAxis: { type: 'value' },
    series: LEVELS.map(n => {
      return {
        name: n.name, type: 'bar', data: [],
        itemStyle: { color: n.color },
      }
    })
  }
};

const DISPLAY_TYPE = {
  '1': '总仓位', '2': '行业仓位', '3': '个股仓位', '4': '风格仓位', '7': '市值统计', '5': '重仓分布', '6': '京沪深'
};
const SORT_INDEX = {
  '微盘股': 1, '小市值': 2, '小盘股': 3, '中小盘股': 4, '中大盘股': 5, '大盘股': 6, '超大盘股': 7,
  '沪深300': 21, '中证1000': 22, '中证500': 23, '其他': 24
};
const SORT_COLOR = {
  '微盘股': '#5ac2e1', '小市值': '#017bb8', '小盘股': '#9dbb2b', '中小盘股': '#f2ce5d', '中大盘股': '#e9a53d', '大盘股': '#df7164', '超大盘股': '#c7161d',
  '沪深300': '#7f4886', '中证1000': '#d8200c', '中证500': '#589a6f', '其他': '#fabe01'
};
const MARKET_INFO = {
  '微盘股': '市值<25亿元',
  '小市值': '25亿元< 市值 <50亿元',
  '小盘股': '50亿元 < 市值 < 100亿元',
  '中小盘股': '100亿元 < 市值 < 300亿元',
  '中大盘股': '300亿元 < 市值 < 500亿元',
  '大盘股': '500亿元 < 市值 < 1000亿元',
  '超大盘股': '市值 > 1000亿元',
};
export {
  DISPLAY_OPTIONS, LEVELS, PLATES,
  DISPLAY_TYPE, MARKET_INFO,
  SORT_INDEX, SORT_COLOR
}
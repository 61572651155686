import Login from '@/view/Login/Login';
import Home from '@/view/Home/Home';
import AssetsPage from '@/view/Home/AssetsPage';
import ProductsPage from '@/view/Home/Products/ProductsPage';
import AccountsPage from '@/view/Home/Accounts/AccountsPage';
import RateCalPage from '@/view/Home/pages/RateCalPage';
import RecordCalPage from '@/view/Home/pages/RecordCalPage';
import DisplayPage from '@/view/Home/pages/DisplayPage';
import TampComparePage from '@/view/Home/pages/TampComparePage';
import AllTestPage from '@/view/Home/pages/AllTestPage';
import KlinePage from '@/view/Home/pages/KlinePage';
import TradingPage from '@/view/Home/pages/TradingPage';
import BatchSellPage from '@/view/Home/pages/BatchSellPage';
import TodayPage from '@/view/Home/pages/TodayPage';
import HostingPage from '@/view/Home/pages/HostingPage';
import _ from 'lodash';

const renderRoutes = (path, name, component) => {
    return { path, name, component }
}

export const routeConfig = [
    {
        path: "/login",
        name: "登陆",
        exact: true,
        component: Login,
    },
    {
        path: "/mobile",
        name: "主页",
        component: Home,
        routes: [
            renderRoutes("/mobile/assets", '资产页', AssetsPage),
            renderRoutes("/mobile/products", '产品', ProductsPage),
            renderRoutes("/mobile/accounts", '子账户', AccountsPage),
            renderRoutes("/mobile/ratecal", '超额页面', RateCalPage),
            renderRoutes("/mobile/recordcal", '记录统计', RecordCalPage),
            renderRoutes("/mobile/displays", '仓位分析', DisplayPage),
            renderRoutes("/mobile/tampcompare", '实测对比', TampComparePage),
            renderRoutes("/mobile/alltest", '全部回测', AllTestPage),
            renderRoutes("/mobile/kline", 'k线', KlinePage),
            renderRoutes("/mobile/trading", '交易', TradingPage),
            renderRoutes("/mobile/batchsell", '批量卖出', BatchSellPage),
            renderRoutes("/mobile/todays", '今日详情', TodayPage),
            renderRoutes("/mobile/hosting", '湘财托管', HostingPage),
        ]
    },
]
import {
  RATE_KEYS_OBJ, DEFAULT_CARRY, STR_PLUS,// 常量
  calNets, calRateFormula, renderFloats, multiplication, accumulate
} from './chartInfo';
import {
  CUSTOMIDX_STRING,
  isValidArray, isValidString, isValidNumber, isValidObj, labelValues
} from './utils';
import { PLATE_CODE, DEFAUL_INDEX_CODE } from './indexCode';
import { getStorage } from './saveUtils';
import moment from 'moment';
import _ from 'lodash';

// 计算收益率与指数数据时间不匹配时，进行裁剪数据; 返回response完整的data对象
function sliceUnmatchTimes(resPoint, resIndex) {
  let tempResponse = _.cloneDeep(resPoint);
  let tempIdxResponse = _.cloneDeep(resIndex);
  const pointTimes = _.get(resPoint, 'timeList', []);
  const indexTimes = _.get(resIndex, 'timeList', []);
  if (_.head(pointTimes) !== _.head(indexTimes)) { // 第一位时间不匹配进行处理
    if (_.size(pointTimes) > _.size(indexTimes)) { // 收益率时间 > 指数，已指数为基准对收益率数据进行裁剪
      let findex = pointTimes.indexOf(_.head(indexTimes)); // 指数第一位对应收益率的index值
      if (findex > 0) {
        Object.keys(tempResponse).map(keyname => {
          if (_.isArray(tempResponse[keyname])) { // 对对象里每个值是数组的数据进行裁剪，findex开始到最后一位
            tempResponse[keyname] = _.slice(tempResponse[keyname], findex, _.size(tempResponse[keyname]));
          }
        });
        // 对preNetValue收盘净值进行赋值，findex-1就是前一日的净值
        _.set(tempResponse, 'preNetValue', _.get(resPoint, `netValueList[${findex - 1}]`));
      }
    } else { // 指数同理，指数数据>收益率数据，已收益率为基准对指数进行裁剪
      let pindex = indexTimes.indexOf(_.head(pointTimes));
      if (pindex > 0) {
        Object.keys(tempIdxResponse).map(keyname => {
          if (_.isArray(tempIdxResponse[keyname])) {
            tempIdxResponse[keyname] = _.slice(tempIdxResponse[keyname], pindex, _.size(tempIdxResponse[keyname]));
          }
        });
        // 处理前一日收盘价preClose = pindex-1 指数值
        _.set(tempIdxResponse, 'preClose', _.get(resIndex, `pointList[${pindex - 1}]`));
      }
    }
  }
  return {
    'indexs': tempIdxResponse,
    'points': tempResponse
  };
}

//原在chart图表加载时处理的数据，提取出来，在获取结束后处理，返回 dataObj 对象；
//包含多种收益率曲线及其他数据的计算及markpoint的涨停标记信息；
const renderKeyArray = (keys) => {
  let final = [];
  keys.map(k => {
    final = _.concat(final, _.get(RATE_KEYS_OBJ, k))
  });
  return final
};
// 收益率计算
function handleMainSeriesData(datas, tabs) {
  const getAnayDatas = _.get(datas, 'rateAnalysisList', []);
  const getPointList = _.get(datas, 'pointList', []);
  const pointNetList = _.get(datas, 'netValueList', []); // 返回的净值数据
  const preNetVal = _.get(datas, 'preNetValue', 0); // 返回的净值数据
  const getLimits = _.get(datas, 'limitResult', []); // 涨停票统计
  const getTimes = _.get(datas, 'timeList', []);
  const isRealtime = tabs === 'TODAY' ? true : false;
  const keyArrayHeadZero = _.concat(['pointList', 'pointRetreat'], renderKeyArray(RATE_KEYS_OBJ.key_zero)); // 需要判断头部非0的keys
  const keyArrayNoLine = renderKeyArray(['rdKeys', 'tdKeys', 'weekLimit', 'art', 'mainbKeys']); // 处理tooltip里面不需要线条展示，但是需要在formater里面处理显示的数据
  const keyArrayShow = renderKeyArray(RATE_KEYS_OBJ.key_show);// 显示收益率曲线的keys
  const keyArrayNoMulti = _.concat(RATE_KEYS_OBJ.bs) //买入卖出 ; 不进行连乘运算
  const keyArrayPlus = RATE_KEYS_OBJ.plus; // 近两日和涨停不进行累成的key
  // console.log('getDatas', getDatas)
  let dataObj = {}; // 返回的data,object
  RATE_KEYS_OBJ.all.map(item => { //创建对应key的空值
    dataObj[item.key] = [];
  })
  let retreat = []; // 回撤数据
  let points = _.size(getPointList) > 0 ? getPointList.map(n => renderFloats(n, 1)) : [];
  let pointsNet = calNets(points); // 收益率先计算净值，然后按照计算回撤
  pointsNet.map((n, i) => {
    let cur = 0;
    if (i > 0) {
      const beforeMax = _.max(_.slice(pointsNet, 0, i + 1));
      cur = beforeMax ? _.round(((n - beforeMax) / beforeMax) * 100, DEFAULT_CARRY) : 0;
    }
    retreat.push(cur);
  });
  let dataTime = {}; // 时间+收益率的数据
  getTimes.map((t, i) => {
    _.set(dataTime, t, points[i]);
  });
  dataObj.pointList = points;
  dataObj.other = [] // 非图形数据，包含数量 及 平均收益率
  dataObj.pointRetreat = retreat;
  dataObj.pointsNetList = pointNetList.map((p, i) => {
    // 每日收益计算，(当日-前一日)/前一日
    return _.round(parseFloat(calRateFormula(p, i === 0 ? preNetVal : pointNetList[i - 1])), DEFAULT_CARRY)
  });
  dataObj.dataTime = dataTime;
  //rateAnalysisList里面数据分类提取
  _.size(getAnayDatas) > 0 && getAnayDatas.map((anay, index) => {
    let item = { time: getTimes[index] };
    // 实时不进行累计收益率计算
    keyArrayShow.map(keyname => {
      const is_plus = _.includes(keyname, STR_PLUS);
      const newKeyname = is_plus ? _.replace(keyname, STR_PLUS, '') : keyname;
      let anaVal = _.get(anay, newKeyname);
      if (keyname === 'tDownRate' || keyname === 'tDownRatePlus') { //自定义字段： 今仓非涨停=今仓-今仓涨停
        anaVal = _.get(anay, 'tRate') - _.get(anay, 'tRiseRate');
      };
      let val = renderFloats(anaVal, isRealtime ? 100 : 1, 6); //曲线预算增加1位进位，保留更大精度
      dataObj[keyname].push(val);
      item[keyname] = isRealtime ? val : renderFloats(val, 100, 6); // 将重新计算原始值保存在other里面
    });
    keyArrayNoLine.map(keyname => {
      if (keyname.indexOf('Rate') !== -1) { //Rate 都需要进行*100 处理
        item[keyname] = renderFloats(anay[keyname], 100);
      } else { // 数量的数据
        item[keyname] = anay[keyname];
      }
    })
    keyArrayNoMulti.map(keyname => {
      let val = renderFloats(anay[keyname], 100);
      dataObj[keyname].push(val);
      item[keyname] = val;
    })
    // 自定义字段，前仓涨停=涨停-今天-昨仓 ;
    item['preNum'] = _.get(item, 'riseNum') - _.get(item, 'tRiseNum') - _.get(item, 'yRiseNum');
    // item['riseMinusDown'] = _.round(_.get(item, 'rise') + _.get(item, 'down'), 4);
    dataObj.other.push(item);
  })
  // 除实时收益率外，第一位数据默认为 0；
  keyArrayHeadZero.map(keyname => {
    if (_.head(dataObj[keyname]) !== 0 && !isRealtime) {
      dataObj[keyname].unshift(0);
    } else {
      if (tabs !== 'TODAY') { //【临时处理】 修复部分首位非0的bug
        dataObj[keyname].unshift(0);
      }
    }
  })
  if (!isRealtime) { //【累成计算】 不是实时数据需要重新进行计算，并且将结果 *100 处理
    Object.keys(dataObj).map(keyname => {
      //【累成计算】 不是实时数据需要重新进行计算，并且将结果 *100 处理
      if (keyArrayShow.indexOf(keyname) !== -1 && !_.includes(keyname, STR_PLUS)) {
        dataObj[keyname] = multiplication(dataObj[keyname]);
      };
      // 累加计算，plus字段计算
      if (keyArrayPlus.indexOf(keyname) !== -1) {
        dataObj[keyname] = accumulate(dataObj[keyname], 100);
      };
    });
  }
  // 涨停票markpoint，增加limitMarks字段数据
  let markpoint = []; let fullMarks = []; let yArray = [];
  if (_.size(getLimits) > 0) {
    let stockCal = {};
    getLimits.map((l, i) => {
      const x_time = _.get(l, 'xAxis', '');
      const y_points = renderFloats(getPointList[i], 1);
      let mindex = _.findIndex(markpoint, o => o.xAxis === x_time)
      let pushObj = {
        'name': l.name + i, 'value': l.name, 'xAxis': l.time, 'yAxis': y_points + 0.2, 'date': l.tradeDate,
        'key': l.name + i, 'symbol': '', 'symbolSize': 0, 'label': { color: 'red', show: true }, 'stock': l.name,
        'industry': l.industry, 'plate': l.plate, 'symbol_x': l.symbol, 'timeKey': moment().diff(moment(x_time), 'm')
      }
      fullMarks.push(_.cloneDeep(pushObj));
      yArray.push(y_points);
      if (stockCal[x_time] && mindex !== -1) { // 多只 symbol(数量n) 
        stockCal[x_time] = stockCal[x_time] + 1;
        markpoint[mindex].value = markpoint[mindex].stock + `(${stockCal[x_time]})`
      } else { // 单只最后markpoint上只显示symbol名称
        stockCal[x_time] = 1;
        markpoint.push(pushObj);
      }
    });
  }
  dataObj.limitMarks = markpoint;
  dataObj.fullMarks = fullMarks;
  return dataObj
}
// 处理持仓数据，增加自定义字段
function handlePositions(datas = [], speeds = {}, type = '') {
  const MS_FORMAT = type === 'hosting' ? 'MM-DD HH:mm:ss:SSS' : 'YY-MM-DD HH:mm:ss:SSS';
  const DAY_FORMAT = type === 'hosting' ? 'MM-DD' : 'YYYY-MM-DD';
  const TODAY = moment().format(DAY_FORMAT);
  const openMoment = moment(TODAY + ' 09:00:00');
  // 全局板块涨速； 乘100后，持仓直接根据code使用
  let newSpeed = {};
  if (isValidObj(speeds)) {
    _.keys(speeds).map(code => {
      const spVal = parseFloat(speeds[code]) ?? 0;
      newSpeed[code] = isValidNumber(spVal) ? _.round(spVal * 100, 2) : 0;
    });
  };
  // 持仓票，板块排序Key，时间格式；返回新对象
  let newData = []; let plateMap = new Map(); let count = 1;
  let plateSingleMap = new Map();
  let marketMap = new Map(); // 板块累计持仓比
  newData = isValidArray(datas) ? datas.map(n => {
    const getPlate = _.get(n, 'plate', null);
    const plateArr = _.split(getPlate, ','); // 板块拆分为数组
    const one_plate = _.head(plateArr);
    const getMkRate = _.round(_.get(n, 'totalMarketRate'), 2);
    const one_plate_code = _.get(PLATE_CODE, one_plate);
    let newPlateKey = 0;
    if (one_plate && !plateMap.has(one_plate)) {
      plateMap.set(one_plate, count);
      newPlateKey = count;
      marketMap.set(one_plate_code, getMkRate);
      count++;
    } else if (one_plate && plateMap.has(one_plate)) {
      newPlateKey = plateMap.get(one_plate);
      marketMap.set(one_plate_code, _.round(marketMap.get(one_plate_code) + getMkRate, 4));
    };
    // 多个板块逗号拆分，map内不重复的所有板块
    if (isValidArray(plateArr)) {
      plateArr.map(a => {
        if (!plateSingleMap.has(a)) {
          plateSingleMap.set(a, count);
        }
      })
    }
    const buyDate_moment = moment(_.get(n, 'buyDate', ''));
    return {
      ...n,
      'plateKey': newPlateKey,
      'plate_arr': plateArr,
      'one_plate': _.size(plateArr) > 1 ? one_plate + '*' : one_plate,
      'one_plate_code': one_plate_code,
      'plateSpeed': _.get(newSpeed, one_plate_code, 0),
      'formatDate': buyDate_moment.format(MS_FORMAT),
      'totalRevenue': _.round(_.get(n, 'totalRevenue', 0) * 100, 2),
      'formatDate_day': buyDate_moment.format(DAY_FORMAT), // 区分天
      'orderKey': openMoment.diff(buyDate_moment, 'millisecond'), // 排序用
      'speedIncrease': _.get(n, 'speedIncrease', 0) ?? 0,
    }
  }) : [];
  let optionsPlate = []; // 将map转化成options的数组
  Array.from(plateSingleMap)?.map(arr => {
    const plItem = _.head(arr);
    if (isValidString(plItem)) {
      optionsPlate.push(labelValues([plItem, plItem]));
    }
  });
  return { newData, optionsPlate, marketMap };
}

// ALL = 成立以来，数据过多或者三年数据还没有，统一执行裁剪
const unmatch_types = ['ALL', 'THREE_YEAR'];
// 产品子账户处理指数及历史收益率返回数据；
function handleHistoryRate(res, resIdx, dateType) {
  let indexDatas = _.get(resIdx, 'data', {});
  let newTimeList = _.get(res, 'data.timeList', []);
  let newPoints = _.get(res, 'data', {});
  // 指数遇到合并则进行计算
  const isCusIndex = _.get(resIdx, 'isBatch', false);
  if (isCusIndex) {
    indexDatas = calCustomIndex(
      _.get(resIdx, 'data', {}),
      _.get(resIdx, 'rate'),
      newTimeList,
      dateType
    );
  };
  if (_.includes(unmatch_types, dateType)) { // 遇到ALL和三年长数，进行裁剪
    const newRes = sliceUnmatchTimes(newPoints, indexDatas);  // 传入收益率和指数的response.data对象
    newTimeList = _.get(newRes, 'points.timeList', []);// 重新赋值时间、收益率数据
    newPoints = _.get(newRes, 'points', {});
    indexDatas = _.get(newRes, 'indexs', {});
  }
  return {
    'timeList': newTimeList,
    'datas': newPoints,
    'newData': handleMainSeriesData(newPoints, dateType),
    'indexVal': indexDatas
  }
};

// 判断指数参数是否包含自定义指数
const handleCustomIndexPms = (pms = {}, csIndex = []) => {
  let newCusIndex = [];
  let isCusFromStorage = false;
  if (isValidArray(csIndex)) {
    newCusIndex = csIndex;
  } else { // 如mobx为空，获取storage数据
    const getCinde = getStorage('cidx');
    newCusIndex = isValidString(getCinde) ? JSON.parse(getCinde) : [];
    isCusFromStorage = true;
  };
  let isBatchIndex = false;
  let symbolInit = false;
  let batchRate = {}; let codeName = {};
  // 包含自定义字符，修改pms；
  if (_.includes(pms.symbol, CUSTOMIDX_STRING)) {
    let findex = _.findIndex(newCusIndex, o => o.value === pms.symbol);
    const indexArr = _.get(newCusIndex, `[${findex}].index`, []);
    _.set(pms, 'symbol', _.join(indexArr.map(n => {
      batchRate[n.code] = n.rate;
      return n.code;
    }), ','));
    // 添加自定义名称和代码
    newCusIndex.map(item => {
      codeName[item.value] = item.label;
    });

    isBatchIndex = true;
    // 实时返回默认指数；
    if (_.get(pms, 'dateType') === 'TODAY') {
      symbolInit = true;
      pms.symbol = DEFAUL_INDEX_CODE;
      isBatchIndex = false;
    }
  };
  // 返回params参数，和rate比例值对象；
  return {
    'isBatch': isBatchIndex, 'symbolInit': symbolInit,
    'rates': batchRate, 'customs': newCusIndex,
    'params': pms, 'isCusFromStorage': isCusFromStorage,
    'codeName': codeName
  }
};

const PRE_NUM = 1000;
// 自定义指数公式：1+(指数-开始点 /开始点)*500(分配比例值) = 新指数点；另外一个指数相同并逐个点相加； 
function calCustomIndex(data = {}, rates = {}, pointDates = [], dateType = '') {
  // 自定义指数公式：1+(指数-开始点 /开始点)*500(分配比例值) = 新指数点；另外一个指数相同并逐个点相加； 
  const calsFormula2 = (val, pre) => (val - pre) / pre;
  const arrayAdd = (arr1, arr2) => {
    return arr1.map((n, i) => _.add(n, arr2[i]));
  };

  let final = { 'preClose': PRE_NUM };
  const errorNum = _.round(PRE_NUM / _.size(data));
  const isAll = dateType === 'ALL' ? true : false;
  _.keys(data).map(symbol => {
    let get_time = _.get(data, `${symbol}.timeList`, []);
    const point_list = _.get(data, `${symbol}.pointList`, []);
    const get_pre = _.get(data, `${symbol}.preClose`, 0);
    const multi_num = _.get(rates, symbol, 0) ? _.get(rates, symbol) * 1000 : errorNum;
    let pre_close = isAll ? 0 : get_pre;
    let newList = [];
    // 【bug-fix】全部数据根据收益率时间进行查找，两个指数ALL的时间轴不一定相同，会计算错误，故重新截取；
    if (isAll) {
      get_time = pointDates;
      get_time.map((t, i) => {
        let tindex = _.findIndex(_.get(data, `${symbol}.timeList`), o => o === t);
        if (i === 0) { // 第一位修改pre值，如没有前一位直接拿到preclose值
          pre_close = point_list[tindex - 1] ?? get_pre;
        }
        const npoint = point_list[tindex];
        newList[i] = _.round((1 + calsFormula2(npoint, pre_close)) * multi_num, 4)
      });
    } else {
      point_list.map((p, i) => {
        newList[i] = _.round((1 + calsFormula2(p, pre_close)) * multi_num, 4);
      });
    }
    if ('pointList' in final) {
      _.set(final, 'pointList', arrayAdd(final.pointList, newList));
    } else {
      _.set(final, 'pointList', newList);
    };
    _.set(final, 'timeList', get_time);

  });
  // console.log('final', final)
  return final;
}

export {
  handleMainSeriesData,
  handlePositions,
  handleHistoryRate,
  handleCustomIndexPms,
  calCustomIndex,
}
import React, { useState } from 'react';
import { Typography, Row, Col, Space, message, Button, Tabs, Tag, Spin } from 'antd';
import { CaretDownOutlined, SwapOutlined, CalendarOutlined } from '@ant-design/icons';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { getAllAccount, showAllList } from '@/api/main';
import { useReactive, useMount, useUnmount } from 'ahooks';
import { isValidArray, isValidObj, isValidString, labelValues, handlePlatformAccount } from '@/utils/utils';
import { CascaderMobile, NewDivider, NewCard, HeaderIcons, EasySelect, NewCalendar, TextSelect } from '@/utils/widgets';
import { saveStorage, getStorage } from '@/utils/saveUtils';
import CompareList from './pcomp/CompareList';
import UntradeList from './pcomp/UntradeList';
import DiffCharts from './pcomp/DiffCharts';
import DiffCountCharts from './pcomp/DiffCountCharts';
import DiffRateCharts from './pcomp/DiffRateCharts';
import ContributeCharts from './pcomp/ContributeCharts';
import moment from 'moment';
import _ from 'lodash';
import './pages.scss'

const { Text } = Typography;
const TODAY = moment().format('YYYY-MM-DD');
const DEFAULT_RANGE = [moment(TODAY).subtract(7, 'd').format('YYYY-MM-DD'), TODAY];
const STORAGE_KEY = 'cmp';
const SIDE_BUY = 'BUY'; const SIDE_SELL = 'SELL';
const DIR_TYPE_ARRAY = ['BUY', 'SELL'];
const BASE_TYPE = ['left', 'right'];
const DIR_OBJ = { 'left': [], 'right': [] };
const REQUEST_KEY_OBJ = {// 请求时通过平台找到对应的请求参数
  'bt': 'btSubAccountId', 'bt_rest': 'btSubAccountId', 'bt_testing': 'btSubAccountId',
  'simx': 'simXSubAccountId', 'tamp': 'tampSubAccountId', 'tamp_fc': 'tampSubAccountId'
};
const FORMAT_TYPE = { // 格式化平台的值；bt_rest = bt;
  'bt': 'bt', 'bt_rest': 'bt', 'bt_testing': 'bt',
  'simx': 'simx',
  'tamp': 'tamp', 'tamp_fc': 'tamp'
};
const TAG_COLORS = { 'bt': 'blue', 'simx': 'geekblue', 'tamp': 'purple' };
let timer = null;
/**
 *  实测对比页面
 *  240130: 增加存储上次对比账户记录，使用选择完整的数组+对象数据，stringfy之后存储及parse恢复
 */
const TampComparePage = (props) => {
  const mobxStore = useLocalObservable(() => store);
  const [userInfoFC] = useState(JSON.parse(JSON.stringify(mobxStore.userInfoFC)));
  // const routeDatas = _.get(props, 'location.state', {});
  const [accOptions, setAccOptions] = useState({
    'bt': [], 'tamp': [], 'accoutMap': new Map(), 'productMap': new Map(),
  });
  const [allList, setAllList] = useState({ ...DIR_OBJ, pms: {} }); // 可以跨天的所有数据
  const [untrading, setUntrading] = useState([]);
  const [glbPms, setGlbPms] = useState({}); // 全局参数；获取及组件间的统一参数
  const [newList, setNewList] = useState({ ...DIR_OBJ, totalLeft: 0, totalRight: 0 }); // 表格当前显示的数据
  const [loading, setLoading] = useState(false);
  const [arrayDates, setArrayDates] = useState({ ...DIR_OBJ });
  const [switchObj, setSwitchObj] = useState({});
  const [finalUntrade, setFinalUntrade] = useState([]);
  const [defaultSave, setDefaultSave] = useState({});
  const compareState = useReactive({
    bt: [], tamp: [], bt_name: '账户A', tamp_name: '账户B', ctype: BASE_TYPE[0], singleDate: TODAY,
    visible: false, soptions: [], skey: '', count: 0, loading: false, accLoading: false,
    begin: DEFAULT_RANGE[0], end: DEFAULT_RANGE[1], timeOpen: { key: '', open: false, value: '', stamp: new Date() },
    dayshow: false, active: '1', count2: 0, count3: 0
  });
  const isFcLogin = isValidString(_.get(userInfoFC, 'token', ''));

  useMount(() => {
    _getAllAccount();
    // 恢复上次账户数据
    const storageVal = getStorage(STORAGE_KEY);
    const getDefault = isValidString(storageVal) ? JSON.parse(storageVal) : {};
    if (isValidObj(getDefault)) {
      compareState.bt = _.get(getDefault, 'bt', []);
      compareState.bt_name = _.get(getDefault, 'bt_name', '');
      compareState.tamp = _.get(getDefault, 'tamp', []);
      compareState.tamp_name = _.get(getDefault, 'tamp_name', '');
      setDefaultSave(getDefault);
      handleParams();
    }
  });

  useUnmount(() => {
    timer && clearTimeout(timer);
  });

  // 获取所有产品及账户
  async function _getAllAccount() {
    compareState.accLoading = true;
    const res = await getAllAccount();
    const resFc = isFcLogin ? await getAllAccount('FC') : {};
    if (_.get(res, 'code', '') === '200') {
      const accVal = handlePlatformAccount(res, resFc, true);
      setAccOptions({
        'tamp': _.get(accVal, 'platformArray', []),
        'bt': _.get(accVal, 'platformArray', []),
        'accoutMap': _.get(accVal, 'accoutMap'),
        'productMap': _.get(accVal, 'productMap'),
      });
    }
    compareState.accLoading = false;
  }

  // 获取对比的表格数据; timeType:[single/range] 是时间区间获取，还是单日获取
  async function _showAllList(params = {}, timeType = '') {
    // 正常操作优先获取一段时间的数据，单日选择加载过的数据日期selectDate参数可快速赋值
    const pmType = _.get(compareState, 'ctype'); // 区分对比数据的left/right
    const hasGetTwice = 'plate_form' in params || _.get(params, 'leftType_org') === 'tamp_fc' || _.get(params, 'rightType_org') === 'tamp_fc';
    let newParams = { state: true };

    if ('plate_form' in params) { // 相同平台先赋值基准id
      _.set(newParams, _.get(params, 'plate_form'), _.get(params, 'lid'));
    } else {     //符合条件的两个平台赋值newParams的key值
      Object.keys(REQUEST_KEY_OBJ).map(type => {
        const getKeyname = REQUEST_KEY_OBJ[type];
        if (getKeyname in params) {
          newParams[getKeyname] = _.get(params, getKeyname);
        }
      });
    }

    let isGet = true; // 是否需要获取更新
    if (timeType === 'single') {// 单日选择，如有在区间日期内，直接赋值当日数据
      let findex = _.findIndex(_.get(allList, pmType, []), o => o.date === _.get(params, 'selectDate'));
      isGet = findex !== -1 ? false : true;
      if (findex !== -1) {
        setNewList(allList[pmType][findex] || {});
        compareState.count2++;
        untradeChange(compareState.active);
      } else {
        newParams.beginTime = _.get(params, 'selectDate');
        newParams.endTime = moment(_.get(params, 'selectDate')).add(1, 'd').format('YYYY-MM-DD');
      };
    }
    if (timeType === 'range') { // 区间获取
      newParams.beginTime = params.beginTime;
      newParams.endTime = moment(params.endTime).add(1, 'd').format('YYYY-MM-DD');
    }
    // params页面所需所有参数，newParams是接口获取的参数;
    const assign_params = _.assign(params, newParams);
    setSwitchObj({
      'left': _.upperCase(_.get(assign_params, 'baseType')), 'right': _.upperCase(_.get(assign_params, 'rightType')),
      'left_color': TAG_COLORS[_.get(assign_params, 'baseType')], 'right_color': TAG_COLORS[_.get(assign_params, 'rightType')],
    });
    if (isGet) {
      setLoading(true);
      const res = await showAllList(newParams, _.get(params, 'leftType_org') === 'tamp_fc' ? 'FC' : 'NOR');
      // 相同平台再获取一次，用另一个rid进行获取； 所有right_type部分用res2的数据.. 以下运算逻辑则相同
      const res2 = hasGetTwice ? await showAllList(
        { ...newParams, [_.get(params, 'plate_form')]: _.get(params, 'rid') },
        _.get(params, 'rightType_org') === 'tamp_fc' ? 'FC' : 'NOR'
      ) : {};
      if (_.get(res, 'code', '') === '200') {
        const base_type = _.get(params, 'baseType');
        const right_type = _.get(params, 'rightType');
        // 根据选择项获取对应平台数据
        const getLeftData = _.get(res, `data.${base_type}.result`, {});
        const getRightData = isValidObj(res2) ? _.get(res2, `data.${right_type}.result`, {}) : _.get(res, `data.${right_type}.result`, {});
        //const getStrategy = _.get(res, `data.${base_type}.strategyArgs`, {});
        if (isValidObj(getLeftData) && isValidObj(getRightData)) {
          handleResultData(getLeftData, getRightData, assign_params); // 处理计算【1】
          //setStrategyAvgs(getStrategy); // 动态策略参数，暂时每次获取使用同一套动态参数值
          // 未交易数据 【1-1】
          handleUntradingData(
            _.get(res, `data.${base_type}.unTrading`, {}),
            isValidObj(res2) ? _.get(res2, `data.${right_type}.unTrading`, {}) : _.get(res, `data.${right_type}.unTrading`, {}),
            assign_params
          );
        } else {
          if (!isValidObj(getLeftData)) {
            message.info(`${base_type}无数据!`);
          }
          if (!isValidObj(getRightData)) {
            message.info(`${right_type}无数据!`);
          }
          setAllList({ ...DIR_OBJ, pms: {} });
          setNewList({ ...DIR_OBJ, totalLeft: 0, totalRight: 0 });
        }
        //获取成功默认记录本次账户信息
        const isValidSave = _.size(_.get(defaultSave, 'bt', [])) === 3 && _.size(_.get(defaultSave, 'tamp', [])) === 3 ? true : false;
        if (isValidSave) {
          saveStorage(STORAGE_KEY, JSON.stringify(defaultSave));
        }
      }
      setLoading(false);
      compareState.count2++;
      if (compareState.active !== '1') {
        compareState.active = '1';
      }
    }
  };
  // 【1】处理返回数据
  function handleResultData(leftData = {}, rightData = {}, fullPms = {}) {
    const getType = _.get(compareState, 'ctype');
    const curDate = _.get(fullPms, 'selectDate', null);
    const current = moment();
    let renderLeft = []; let renderRight = [];
    //每日数据在date为key的对象内，依次进行提取处理数据
    Object.keys(leftData).map(date => {
      const leftItem = handleDayItems(date, leftData[date], rightData[date]); // 对每日的数据进行左右对比，生成完整对比表格作为每天的item数据
      renderLeft.push({ 'date': date, 'dateKeys': current.diff(moment(date), 'd'), ...leftItem })
    })
    // 同时处理tamp数据，如切换tamp为基准则无需再次处理数据
    Object.keys(rightData).map(date => {
      const rightItem = handleDayItems(date, rightData[date], leftData[date]);
      renderRight.push({ 'date': date, 'dateKeys': current.diff(moment(date), 'd'), ...rightItem })
    });

    let orderAll = { //并已date作为判断去重，进行排序
      left: _.chain(renderLeft).unionBy('date').orderBy(['dateKeys'], ['desc']).value(),
      right: _.chain(renderRight).unionBy('date').orderBy(['dateKeys'], ['desc']).value(),
      pms: fullPms
    };
    // console.log('orderAll', orderAll)
    setAllList(orderAll);
    //记录有数据的日期数组
    const arr_dates = {
      'left': orderAll.left.map(n => labelValues([n.date, n.date])),
      'right': orderAll.right.map(n => labelValues([n.date, n.date]))
    }
    setArrayDates(arr_dates);
    //selectDate是单日选择的datePicker，找到当日数据，赋值给newList，查看当天对比表格；
    let findex = _.findIndex(_.get(orderAll, getType, []), o => o.date === curDate);
    let lastList = findex !== -1 ? orderAll[getType][findex] : [];
    if (findex === -1) {
      const lastDate = _.last(arr_dates.left);
      let lastindex = _.findIndex(_.get(orderAll, getType, []), o => o.date === lastDate);
      lastList = lastList !== -1 ? orderAll[getType][lastindex] : [];
      compareState.singleDate = lastDate;
      _.set(fullPms, 'selectDate', lastDate);
    }
    setNewList(lastList);
    setGlbPms(fullPms);
  }
  //【2】 处理返回的每日数据； 左右依此遍历统计数据，最后排序生成可以使用的table数据
  function handleDayItems(datein = '', leftin = [], rightin = []) {
    const curDayMoment = moment(datein + ' 09:00:00'); // 当日9点moment，因精确到毫秒，时间值控制不要过大..
    let left = []; let right = []; //let uncompare = [];
    // 计算统计对象
    let leftCal = {
      validCount: 0, diffs: 0, total: 0, // 左右符合条件数量，差额，总数
      buyTotal: 0, buyDiff: 0, buyBreak: 0, vBuyTotal: 0, // 买入总数，买入价格差，买入拆单价格差，有效买入总数
      sellTotal: 0, sellDiff: 0, sellBreak: 0, vSellTotal: 0, // 卖总，卖差，卖拆单,有效卖出总数
      avg: 0, bavg: 0, savg: 0, bbavg: 0, ssavg: 0, // 求平均
      sellRate: 0, sellDiffRate: 0, sr_avg: 0, sr_diff_avg: 0, sr_avg2: 0, sr_diff_avg2: 0, //卖出收益率，不同收益率; 加权重的平均收益率
      buyContri: 0, sellContri: 0, buyContriDiff: 0, sellContriDiff: 0, // 贡献度统计，买入相同/不同，卖出相同/不同
    };
    let rightCal = {
      validCount: 0, total: 0,
      bdiff: 0, bcount: 0, bavg: 0, // 拆单与均价对比计算，及数量
      sellRate: 0, sellDiffRate: 0, sr_avg: 0, sr_diff_avg: 0, sr_avg2: 0, sr_diff_avg2: 0,
      buyContri: 0, sellContri: 0, buyContriDiff: 0, sellContriDiff: 0,
    };
    // 左右依此遍历；
    if (isValidArray(leftin)) {
      // orders为股票表示，相同股票是相同的orders；【tips】使用场景，当多时间多笔相同股票买卖时可用该数字查找同类或排序
      let leftCodeObj = {}; let rightCodeObj = {};  // {'test': { orders: 股票orders, key:key值, otherRecord: 合并同股票记录,tPrice:汇总价格,tQuant:汇总数量 } }
      let leftCodeMap = new Map(); let rightCodeMap = new Map();
      let orders = 1; let ordersRight = 5000; // 右侧避免重复，5000开始； 保证每只股票一个数字不重复即可
      // 左右处理数据codeObj的内部function,双边返回相同的数据结构
      const codeObjectHandler = (items, codeObj, dir, kindex) => {
        const getSide = _.get(items, 'side');
        const getCode = _.get(items, 'stockCode');
        const sideCode = getCode + '-' + getSide; // 对象key名称 000000-BUY/SELL
        const hasRecord = sideCode in codeObj;
        const secondTimeKey = curDayMoment.diff(moment(items.commissionTime), 'millisecond'); // 记录秒timeKey,最后可按秒排序
        const timekey2 = curDayMoment.diff(moment(items.transactTime), 'millisecond'); // 【新】增成交时间
        const avg_price = _.get(items, 'avgPrice', 0); const cum_qty = _.get(items, 'cumQty', 0);
        const isValidItem = isItemValid(avg_price, cum_qty);
        // 如果有未成交数据，直接返回; 
        if (!isValidItem) { // 所以交易量和价格 = 0 的数据不会出现在 left/rightCodeObj 数据里面;
          return codeObj;
        }

        // 记录一样股票代码；一般出现两次也是当天一次买一次卖；
        if (dir === 'left') {
          if (leftCodeMap.has(getCode)) {
            leftCodeMap.set(getCode, leftCodeMap.get(getCode) + 1);
          } else {
            leftCodeMap.set(getCode, 1);
          }
        } else {
          if (rightCodeMap.has(getCode)) {
            rightCodeMap.set(getCode, rightCodeMap.get(getCode) + 1);
          } else {
            rightCodeMap.set(getCode, 1);
          }
        }

        let tempObj = _.cloneDeep(codeObj);
        if (hasRecord) { // 重复出现sideCode，累加、计算  
          //【拆单2.0】，理论上没有重复出现股票，保留逻辑，但拆单等方式已不执行该合并代码
          tempObj[sideCode].tPrice += avg_price;
          tempObj[sideCode].tQuant += cum_qty;
          tempObj[sideCode].stotal += 1;
          //【原】total字段，每只股票算作一笔，【fix】stotal记录拆单的数量，这样avgPrice字段算法求均价一致
          tempObj[sideCode].avgPrice = _.round(tempObj[sideCode].tPrice / tempObj[sideCode].stotal, 2);
          tempObj[sideCode].otherRecord.push({ // 详细记录push数据
            ...items,
            'timeKey': secondTimeKey, 'timekey2': timekey2
          });
        } else { // 未出现股票创建股票记录对象
          let newOneObj = {
            ...items,
            'dindex': orders, // dindex为点击时使用的值
            'orders': orders,
            'key': kindex, // table所需key值
            'stockCode': getCode,
            'stockName': _.get(items, 'stockName'),
            'tPrice': _.round(avg_price, 4), // 首次记录第一条数据的均价
            'tQuant': cum_qty,
            'avgPrice': _.round(avg_price, 4),
            'total': 1, 'stotal': 1,
            'side': getSide,
            'rate': _.get(items, 'rate') ?? 0,
            'ctime': curDayMoment.diff(moment(items.commissionTime), 'millisecond'), // 详细委托时间; 【新】 根据该字段排序，详细到毫秒级别
            'trtime': curDayMoment.diff(moment(items.transactTime), 'millisecond'), // 详细成交时间; 
            'otherRecord': [{ // 所有详细记录
              ...items,
              'timeKey': secondTimeKey, 'timekey2': timekey2,
            }]
          };
          // 【拆单2.0】:用tamp获取的breakList数据进行拆单对比；
          if (isValidArray(_.get(newOneObj, 'breakList', []))) { // time_key 负数越小，时间越早，越靠近开盘时间
            const newBreakList = _.orderBy( // 以毫秒为单位进行排序，确保第一位永远是最早的一笔订单
              newOneObj.breakList.map(n => { return { ...n, time_key: curDayMoment.diff(moment(n.commissionTime), 'millisecond') } }),
              ['time_key'],
              ['desc']
            );
            newOneObj.breakList = newBreakList;
            let firstIdx = _.findIndex(newBreakList, o => o.orderStatus === '完成'); // 第一笔完成的的订单
            newOneObj.breakFirstOrder = firstIdx !== -1 ? newBreakList[firstIdx] : {}; // 创建一个第一的字段
            // 查单与均价价格差
            const breakDiff = isItemDiff(
              _.get(newOneObj, 'breakFirstOrder.avgPrice', 0),
              _.get(newOneObj, 'avgPrice'),
              _.get(newOneObj, 'side'),
              'price',
              4
            );
            newOneObj.breakDiff = breakDiff;
          }
          if (dir === 'right') { // 有对比，order和dindex使用左侧同步的数值
            newOneObj.dindex = sideCode in leftCodeObj ? leftCodeObj[sideCode].dindex : ordersRight;
            newOneObj.orders = sideCode in leftCodeObj ? leftCodeObj[sideCode].dindex : ordersRight;
          } else {
            orders++; // 左侧需要order自加
          }
          tempObj[sideCode] = newOneObj;
        }
        // 出现过的股票增加hasSame字段
        const isMapHasCode = dir === 'left' ? leftCodeMap.get(getCode) > 1 : rightCodeMap.get(getCode) > 1;
        if (isMapHasCode) {
          DIR_TYPE_ARRAY.map(d => {
            const keyString = `${getCode}-${d}`;
            if (keyString in codeObj) {
              tempObj[keyString].hasSame = true;
            }
          })
        }

        return tempObj;
      }
      // 左侧基准遍历
      isValidArray(leftin) && leftin.map((leftItem, i) => {
        leftCodeObj = codeObjectHandler(leftItem, leftCodeObj, 'left', i);
      });
      // 右侧数据遍历，根据leftCodeObj进行对比，计算差异
      isValidArray(rightin) && rightin.map((rightItem, i) => {
        const getSide = _.get(rightItem, 'side'); const getCode = _.get(rightItem, 'stockCode');
        const getRightCode = getCode + '-' + getSide;
        const hasLeftRecord = getRightCode in leftCodeObj; // 是否有对比项

        rightCodeObj = codeObjectHandler(rightItem, rightCodeObj, 'right', i);

        const hasRightRecord = getRightCode in rightCodeObj; // 保证rightCodeObj里面有该股票数据，因 price,quant = 0 的时候数据将不会记录;
        // 有对比数据，左右增加hasDiff字段标识，及差异计算结果
        if (hasLeftRecord && hasRightRecord) {
          // 对比成交均价\成交数量
          const diffPriceVal = isItemDiff(_.get(leftCodeObj, `${getRightCode}.avgPrice`, 0), _.get(rightCodeObj, `${getRightCode}.avgPrice`, 0), getSide, 'price', 4);
          const diffQuantVal = isItemDiff(_.get(leftCodeObj, `${getRightCode}.tQuant`, 0), _.get(rightCodeObj, `${getRightCode}.tQuant`, 0), getSide, 'quant');
          // 【对比拆单】 并赋值拆单字段breakBuy/breakSell 
          let leftBreakPrice = _.get(leftCodeObj, `${getRightCode}.breakFirstOrder.avgPrice`, null)
          let rightBreakPrice = _.get(rightCodeObj, `${getRightCode}.breakFirstOrder.avgPrice`, null)
          const diffBreak = isItemDiff( //leftBreakPrice || rightBreakPrice ===0 说明该笔没有拆单，使用均价进行对比
            leftBreakPrice ?? _.get(leftCodeObj, `${getRightCode}.avgPrice`),
            rightBreakPrice ?? _.get(rightCodeObj, `${getRightCode}.avgPrice`),
            getSide,
            'price',
            4
          );
          // 【非拆单买入卖出】：遇到拆单买入卖出用最早一笔金额计算汇总平均
          if (getSide === SIDE_BUY) {
            leftCodeObj[getRightCode].breakBuy = diffBreak; // diffBreak 此时结果如有拆单则是拆单对比，没有和不拆单的 diffPriceVal 相等
            rightCodeObj[getRightCode].breakBuy = diffBreak;
          } else {
            leftCodeObj[getRightCode].breakSell = diffBreak;
            rightCodeObj[getRightCode].breakSell = diffBreak;
          }
          // 增加是否diff表示
          leftCodeObj[getRightCode].hasDiff = true;
          rightCodeObj[getRightCode].hasDiff = true;
          // 左右同时赋值差异字段及数值；统一赋值对象，根据所需计算内容找到对应字段进行使用；
          leftCodeObj[getRightCode].pricef = diffPriceVal;
          leftCodeObj[getRightCode].qtf = diffQuantVal;
          rightCodeObj[getRightCode].pricef = diffPriceVal;
          rightCodeObj[getRightCode].qtf = diffQuantVal;
        } else {
          ordersRight++; // 没有对比增加右侧 order
        }
      });
      // 统计数据，生成表格
      if (_.size(leftCodeObj) > 0) {
        Object.keys(leftCodeObj).map(keyname => {
          let concatItem = _.cloneDeep(leftCodeObj[keyname]);
          const getDiffRate = _.get(concatItem, 'pricef.diffRate', 0);
          const isDiff = 'hasDiff' in concatItem; // 是否有对比项
          const isValidDiffRate = isDiff && getDiffRate !== undefined && getDiffRate !== null ? true : false; //差值可以=0 不能直接 !getDiffRate 判断
          const itmOrderRecord = _.orderBy(concatItem.otherRecord, ['timeKey'], ['desc']);
          const getContriRate = _.get(concatItem, 'accountContributionRate', 0);
          // 汇总价格差； DiffCharts使用price| Diff | Total字段数据绘制图表
          if (isValidDiffRate) {
            leftCal.diffs = _.round(leftCal.diffs + getDiffRate, 2);
            if (concatItem.side === SIDE_BUY) { // 分别计算买入卖出 ；拆单同理求和
              leftCal.buyDiff += getDiffRate;
              leftCal.buyBreak += _.get(concatItem, 'breakBuy.diffRate', 0);
            } else {
              leftCal.sellDiff += getDiffRate;
              leftCal.sellBreak += _.get(concatItem, 'breakSell.diffRate', 0);
            }
          } else { //【tips】 没有对比项的数据，创建'量差''价差'的表格字段，值=0，否则空字段排序会乱掉
            _.set(concatItem, 'qtf.diffAmount', 0);
            _.set(concatItem, 'pricef.diffRate', 0);
          };

          leftCal.total += concatItem.total;

          if (isDiff) {// 统计左右都有的股票数; 有效股票数
            leftCal.validCount += 1;
          }
          if (concatItem.side === SIDE_BUY) { // 区分买卖方向，有效股票才进行增加；
            leftCal.buyTotal += 1;
            if (isValidDiffRate) {
              leftCal.vBuyTotal += 1;
              leftCal.buyContri += getContriRate;
            } else {
              leftCal.buyContriDiff += getContriRate;
            }
          } else if (concatItem.side === SIDE_SELL) { // 卖出增加统计收益率数据
            leftCal.sellTotal += 1;
            if (isValidDiffRate) {
              leftCal.sellRate += _.get(concatItem, 'rate', 0);
              leftCal.vSellTotal += 1;
              leftCal.sellContri += getContriRate;
            } else {
              leftCal.sellDiffRate += _.get(concatItem, 'rate', 0);
              leftCal.sellContriDiff += getContriRate;
            }
          }
          // left数据push，并执行otherRecord的排序
          left.push({ ...concatItem, 'otherRecord': itmOrderRecord });
        });

        Object.keys(rightCodeObj).map(keyname => {
          let concatItem = _.cloneDeep(rightCodeObj[keyname]);
          const hasRightDiff = 'hasDiff' in concatItem;
          const getContriRate = _.get(concatItem, 'accountContributionRate', 0);
          if ('hasDiff' in concatItem) {
            rightCal.validCount += 1;
          } else {
            _.set(concatItem, 'qtf.diffAmount', 0);
            _.set(concatItem, 'pricef.diffRate', 0);
          };
          // 右侧买入卖出
          if (concatItem.side === SIDE_BUY) {
            if (hasRightDiff) {
              rightCal.buyContri += getContriRate;
            } else {
              rightCal.buyContriDiff += getContriRate;
            }
          } else if (concatItem.side === SIDE_SELL) {
            rightCal.sellTotal += 1;
            if (hasRightDiff) {
              rightCal.vSellTotal += 1;
              rightCal.sellRate += _.get(concatItem, 'rate', 0);
              rightCal.sellContri += getContriRate;
            } else {
              rightCal.sellDiffRate += _.get(concatItem, 'rate', 0);
              rightCal.sellContriDiff += getContriRate;
            }
          }
          // 右侧统计拆单差及汇总
          if ('breakDiff' in concatItem) {
            rightCal.bdiff += _.get(concatItem, 'breakDiff.diffRate');
            rightCal.bcount += 1;
          }
          rightCal.total += concatItem.total;

          right.push({ ...concatItem, 'otherRecord': _.orderBy(concatItem.otherRecord, ['timeKey'], ['desc']) });
        });

        // 计算所需要的平均值
        leftCal.avg = calAvg(leftCal.diffs, leftCal.validCount, 2);
        leftCal.bavg = calAvg(leftCal.buyDiff, leftCal.vBuyTotal);
        leftCal.savg = calAvg(leftCal.sellDiff, leftCal.vSellTotal);
        leftCal.bbavg = calAvg(leftCal.buyBreak, leftCal.vBuyTotal);
        leftCal.ssavg = calAvg(leftCal.sellBreak, leftCal.vSellTotal);
        rightCal.bavg = calAvg(rightCal.bdiff, rightCal.bcount);
        // 卖出收益率
        leftCal.sr_avg = calAvg(leftCal.sellRate, leftCal.vSellTotal);
        leftCal.sr_diff_avg = calAvg(leftCal.sellDiffRate, (leftCal.sellTotal - leftCal.vSellTotal));
        leftCal.sr_avg2 = calAvg(leftCal.sellRate, leftCal.total);
        leftCal.sr_diff_avg2 = calAvg(leftCal.sellDiffRate, leftCal.total);
        rightCal.sr_avg = calAvg(rightCal.sellRate, rightCal.vSellTotal);
        rightCal.sr_diff_avg = calAvg(rightCal.sellDiffRate, (rightCal.sellTotal - rightCal.vSellTotal));
        rightCal.sr_avg2 = calAvg(rightCal.sellRate, rightCal.total);
        rightCal.sr_diff_avg2 = calAvg(rightCal.sellDiffRate, rightCal.total);
      }
    };
    let finalTables = { //返回每日item对象； 左右先按照股票orders排序，然后按照时间早晚排序；
      'left': _.chain(left).orderBy(['orders'], ['asc']).orderBy(['ctime'], ['desc']).value(),
      'leftCal': leftCal,
      'right': _.chain(right).orderBy(['orders'], ['asc']).orderBy(['ctime'], ['desc']).value(),
      'rightCal': rightCal,
      'totalLeft': _.size(left),
      'totalRight': _.size(right),
    }
    // 处理不同的数据，显示时合并左右即可
    _.set(
      finalTables,
      'leftDiffList',
      _.filter(finalTables.left, o => !('hasDiff' in o)).map(n => _.assign(n, { 'dir': 'left', 'key': n.key + 'left' }))
    )
    _.set(
      finalTables,
      'rightDiffList',
      _.filter(finalTables.right, o => !('hasDiff' in o)).map(n => _.assign(n, { 'dir': 'right', 'key': n.key + 'right' }))
    )
    return finalTables;
  }
  // 买入，a-b/b 对比 b；卖出，b-a/a 对比 a;   return对象
  function isItemDiff(a = 0, b = 0, side, type, pos = 2) {
    const MAX_OBJ = { quant: 5, price: 0 }; //价格和数量的差值，大于才算差；
    let diffObj = { isDiff: false, diffRate: 0, diffAmount: 0 };
    const diffFomate = (f1, f2) => f2 !== 0 ? _.round(((f1 - f2) / f2) * 100, pos) : 0
    if (a !== b) { // 计算时结果可以是0，但是运算避免报错要做分母不为0的操作
      let cal = side === SIDE_BUY ? diffFomate(a, b) : diffFomate(b, a);
      let calAbs = Math.abs(a - b);
      diffObj.isDiff = Math.abs(cal) > MAX_OBJ[type] ? true : false; // 是否差异
      diffObj.diffRate = cal; // 相差比率
      diffObj.diffAmount = calAbs; // 相差数量
      diffObj.color = cal > 0 ? 'red' : 'green';
    }
    diffObj.console = `${side}: a:${a}  b:${b}` // 打印，校对时使用
    return diffObj;
  };
  // 是否是有效记录，成交量和成交均价!==0，因有部分成交数据，撤单数据不能算进去
  function isItemValid(price, quant) {
    return price !== 0 && quant !== 0 ? true : false;
  }
  // 计算平均值并保留位数; 需判断除数和被除数不为0;
  function calAvg(a = 0, b = 0, pos = 4) {
    return a !== 0 && b !== 0 ? _.round(a / b, pos) : 0;
  }
  //【1-1】处理返回未交易数据，与【1】异步处理，最后统计出所有未交易数据，数据赋值状态
  function handleUntradingData(left = {}, right = {}, pms = {}) {
    // 创建未交易数据，统计为数组
    const createUntradeArray = (data, dir) => {
      let final = [];
      Object.keys(data).map(datein => {
        data[datein].map(item => {
          final.push({ ...item, 'platform': _.upperCase(dir), 'dateIn': datein });
        })
      });
      return final;
    };
    const untradeObj = {
      'left': createUntradeArray(left, _.get(pms, 'baseType')),
      'right': createUntradeArray(right, _.get(pms, 'rightType'))
    };
    // console.log(untradeObj, pms)
    setUntrading(untradeObj);
  };

  // 处理获取params
  const REVERT_SWITCH = { 'left': 'right', 'right': 'left' }; // 切换选择，切换相反的key
  function handleParams(ftype = '') {
    const getValue = (itm) => _.get(itm, 'value');     // 同凭条操作逻辑，需要重新提取对象里面的value字段
    const leftId = getValue(_.last(compareState.bt)); const rightId = getValue(_.last(compareState.tamp));
    const leftPlatform = getValue(_.head(compareState.bt)); const rightPlatform = getValue(_.head(compareState.tamp));
    const plateObj = { 'left': leftPlatform, 'right': rightPlatform }
    let finishParams = {
      'beginTime': compareState.begin,
      'endTime': compareState.end,
      'selectDate': compareState.singleDate,
      'baseType': FORMAT_TYPE[plateObj[compareState.ctype]], // 账户基准
      'rightType': FORMAT_TYPE[plateObj[REVERT_SWITCH[compareState.ctype]]], // 格式化后，bt_rest 和bt 都等于bt，可执行同平台逻辑
      'leftType_org': leftPlatform, // 反采账户需要调用fc接口，增加标识
      'rightType_org': rightPlatform,
    }
    // 根据选择平台赋值对应参数keyname
    if (finishParams.baseType === finishParams.rightType) { // 对比相同平台，用特殊字段表示
      _.set(finishParams, 'lid', leftId); // 左侧id
      _.set(finishParams, 'rid', rightId); // 右侧id
      _.set(finishParams, 'plate_form', REQUEST_KEY_OBJ[leftPlatform]);
    } else {
      _.set(finishParams, REQUEST_KEY_OBJ[leftPlatform], leftId);
      _.set(finishParams, REQUEST_KEY_OBJ[rightPlatform], rightId);
    }
    if (ftype === 'switch') {
      const nextType = REVERT_SWITCH[compareState.ctype];
      compareState.ctype = nextType;
      finishParams.type = nextType
      finishParams.baseType = nextType === 'left' ? leftPlatform : rightPlatform;
      finishParams.rightType = nextType === 'left' ? rightPlatform : leftPlatform;
      _showAllList(finishParams, 'single');
    } else if (ftype === 'date') {
      _showAllList(finishParams, 'single');
    } else {
      _showAllList(finishParams, 'range');
    }
  }
  // 不同-差异处理不同untrade数据
  function untradeChange(key) {
    //根据方向筛选未交易数据
    const findUntradingList = (dir) => {
      const getList = _.chain(_.get(untrading, dir, []))
        .filter(o => o.dateIn === compareState.singleDate)
        .value();
      return getList;
    }

    let finals = [];
    if (key === '2') {
      finals = findUntradingList(compareState.ctype)
    } else if (key === '3') {
      finals = _.concat(_.get(newList, 'leftDiffList', []), _.get(newList, 'rightDiffList', []));
    };
    // console.log(finals)
    setFinalUntrade(finals.map((n, i) => { return 'key' in n ? { ...n, 'key': `${n.key}${i}` } : n }));
  }

  const labelItems = [
    { label: compareState.begin, key: 'begin' },
    { label: compareState.end, key: 'end' },
  ];
  const labelItems2 = [
    { label: compareState.bt_name, key: 'bt' },
    { label: compareState.tamp_name, key: 'tamp' },
  ];
  const selectObj = {
    'bt': _.get(accOptions, 'bt', []),
    'tamp': _.get(accOptions, 'tamp', []),
  };
  const iconProps = { style: { color: '#ababab', fontSize: 14 } }
  const dayOptions = isValidArray(_.get(arrayDates, compareState.ctype)) ? _.get(arrayDates, compareState.ctype) : [];
  const commonProps = { 'fullParams': glbPms, 'tableDatas': newList, 'cDate': compareState.singleDate };
  const untradeProps = { 'active': compareState.active, 'unTrading': finalUntrade };
  const chart_props = { 'upCount': compareState.count2, 'active': compareState.active, 'upcount3': compareState.count3 }
  return <>
    <HeaderIcons
      title={'实测对比'}
      pageKey='tamp_compare'
      onPress={() => props.history?.goBack()}
    />

    <NewCard pad={4} restStyle={{ marginTop: 12 }}>
      <Row align='middle' justify='center'>
        {labelItems.map(n => <Col key={'label' + n.key} className='compare-item-col' span={12}
          style={{ borderRight: `1px solid ${n.key === 'begin' ? '#f5f5f5' : 'white'}` }}
        >
          <TextSelect name={n.label} onPress={() => {
            compareState.timeOpen = { open: true, value: n.label, stamp: moment(n.label).toDate(), key: n.key };
          }} />
        </Col>)}
        {labelItems2.map(n => <Col key={'label' + n.key} className='compare-item-col' span={12}
          style={{ borderRight: `1px solid ${n.key === 'bt' ? '#f5f5f5' : 'white'}` }}
        >
          <Spin spinning={compareState.accLoading}>
            <div onClick={() => {
              compareState.soptions = selectObj[n.key];
              compareState.visible = true;
              compareState.skey = n.key;
              compareState.count++;
            }}>
              <Space direction='vertical' size={2} >
                <Text strong>{n.label}</Text>
                <CaretDownOutlined {...iconProps} />
              </Space>
            </div>
          </Spin>
        </Col>)}
        <Col span={12} className='compare-item-col' onClick={() => handleParams('switch')} style={{ borderRight: `1px solid #f5f5f5` }}>
          <Space>
            <Tag color={_.get(switchObj, 'left_color', 'default')} bordered={false}>
              <Text>{_.get(switchObj, 'left', '切换')}</Text>
            </Tag>
            <SwapOutlined {...iconProps} />
            <Tag color={_.get(switchObj, 'right_color', 'default')} bordered={false}>
              <Text>{_.get(switchObj, 'right', '')}</Text>
            </Tag>
          </Space>
        </Col>
        <Col span={12} className='compare-item-col' onClick={() => {
          if (isValidArray(dayOptions)) {
            compareState.dayshow = true
          }
        }}>
          <Space>
            <Text strong>{moment(compareState.singleDate).format('MM-DD')}</Text>
            <CalendarOutlined {...iconProps} />
          </Space>
        </Col>
        <Col span={18}>
          <Button type='primary' block style={{ marginTop: 16, marginBottom: 8 }} loading={loading}
            onClick={() => handleParams()}
          >
            确定
          </Button>
        </Col>
      </Row>

      <NewDivider />

      <Tabs activeKey={compareState.active} type='card'
        items={[
          {
            key: '1', label: '记录',
            children: <CompareList
              {...commonProps}
              loading={loading}
            />
          },
          {
            key: '2', label: '未交易',
            children: <UntradeList
              noneType='untrade'
              {...untradeProps}
              {...commonProps}
            />
          },
          {
            key: '3', label: '差异',
            children: <UntradeList
              noneType='none'
              {...untradeProps}
              {...commonProps}
            />
          },
          {
            key: '4', label: '价格差统计',
            children: <DiffCharts
              {...chart_props}
              datas={_.get(allList, compareState.ctype, [])}
            />
          },
          {
            key: 'contribute', label: '贡献度',
            children: <ContributeCharts
              {...chart_props}
              datas={_.get(allList, compareState.ctype, [])}
              unTrading={untrading}
              ctype={compareState.ctype}
            />
          },
          {
            key: '5', label: '数量差',
            children: <DiffCountCharts
              {...chart_props}
              datas={_.get(allList, 'left')}
            />
          },
          {
            key: '6', label: '卖出收益率',
            children: <DiffRateCharts
              {...chart_props}
              fullParams={glbPms}
              datas={_.get(allList, 'left')}
            />
          },
        ]}
        onChange={(key) => {
          compareState.active = key;
          untradeChange(key);
          timer = setTimeout(() => {
            compareState.count3++;
          }, 300);
        }}
      />
    </NewCard>

    <CascaderMobile
      curKey={compareState.skey}
      upcount={compareState.count}
      visible={compareState.visible}
      options={compareState.soptions}
      onFinish={(v, value) => {
        compareState.visible = false;
        // console.log(compareState.skey, value)
        _.set(compareState, compareState.skey, value); // 输出格式为obj; {label,value}
        if (_.includes(['bt', 'tamp'], compareState.skey)) {
          const get_name = _.chain(value).last().get('label').value();//.split('').slice(0, 2).join('')
          const short_name = _.size(get_name) > 12 ? _.chain(get_name).split('').slice(0, 13).join('').value() + '..' : get_name;
          _.set(compareState, compareState.skey + '_name', short_name);
          // value值为proxy格式，转换为普通数组和对象格式，用于存储；
          let temp = _.cloneDeep(defaultSave);
          let carray = [];
          value.map(arr => {
            let cobj = {};
            _.keys(arr).map(keyname => cobj[keyname] = arr[keyname]);
            carray.push(cobj);
          });
          _.set(temp, compareState.skey, carray);
          _.set(temp, compareState.skey + '_name', short_name);
          setDefaultSave(temp);
        }
      }}
      onClose={() => compareState.visible = false}
    />

    <NewCalendar
      values={[compareState.begin, compareState.end]}
      show={_.get(compareState, 'timeOpen.open') ?? false}
      onCancel={() => compareState.timeOpen = false}
      onSubmit={(val) => {
        compareState.begin = val[0];
        compareState.end = val[1];
        compareState.timeOpen = { key: '', open: false, value: '', stamp: new Date() };
      }}
    />

    <EasySelect
      visible={compareState.dayshow}
      options={dayOptions}
      onClose={() => compareState.dayshow = false}
      onPress={(v) => {
        compareState.dayshow = false;
        compareState.singleDate = v;
        handleParams('date');
      }}
    />
  </>
}

export default TampComparePage;
import React, { useEffect, useState } from 'react';
import { Spin, Space, Tag, Typography } from 'antd';
import { useUpdateEffect } from 'ahooks';
import { SwapOutlined } from '@ant-design/icons';
import { createBaseLine } from '@/utils/chartInfo';
import _ from 'lodash';
import * as echarts from 'echarts';

const { Text } = Typography;
// 价格差区间时间统计图表
export default function DiffRateCharts(props) {
  const [option, setOption] = useState({
    xAxis: { type: 'category', data: [] },
    yAxis: { type: 'value', splitNumber: 4 },
    series: [],
    legend: { data: [] }, //x: 'left', y: 'bottom'
    tooltip: { trigger: 'axis' }
  });

  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();
    }
    myChart = echarts.init(document.getElementById('diffRateCharts'));

    let newOption = _.cloneDeep(option);

    const getLeft = _.get(props, 'datas', []);
    //console.log(getDatas)
    let times = []; let sdata = [
      createBaseLine('#6ab273', { name: '相同A' }),
      createBaseLine('#03a17c', { name: '相同B' }),//1
      createBaseLine('#aacf53', { name: '不同A' }),//2
      createBaseLine('#cfdc26', { name: '不同B' }),//3
      createBaseLine('#8ebbce', { name: 'R相同A' }),//4
      createBaseLine('#0060ab', { name: 'R相同B' }),//5
      createBaseLine('#eacc88', { name: 'R不同A' }),//6
      createBaseLine('#b4bfd0', { name: 'R不同B' }),//7
    ];
    if (_.size(getLeft) > 0) {
      getLeft.map(left => {
        times.push(_.get(left, 'date'));
        sdata[0].data.push(_.get(left, 'leftCal.sr_avg'));
        sdata[2].data.push(_.get(left, 'leftCal.sr_diff_avg'));
        sdata[4].data.push(_.get(left, 'leftCal.sr_avg2'));
        sdata[6].data.push(_.get(left, 'leftCal.sr_diff_avg2'));
        // 无需区分左右，用左侧数据右侧计算即可..
        sdata[1].data.push(_.get(left, 'rightCal.sr_avg'))
        sdata[3].data.push(_.get(left, 'rightCal.sr_diff_avg'))
        sdata[5].data.push(_.get(left, 'rightCal.sr_avg2'))
        sdata[7].data.push(_.get(left, 'rightCal.sr_diff_avg2'))
      });
    };
    newOption.xAxis.data = times;
    newOption.series = sdata;
    newOption.legend.data = sdata.map(n => n.name);
    //console.log('newOption', newOption)
    setOption(newOption);
    myChart.setOption(newOption, true);
    myChart.resize();
  }, [props.upCount]);

  useUpdateEffect(() => {
    if (props.active === '6') {
      let myChart = props.myChart;
      myChart = echarts.init(document.getElementById('diffRateCharts'));
      myChart.resize();
    }
  }, [props.upcount3]);

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Space>
          <Tag bordered={false}>
            <Text>A</Text>
          </Tag>
          <SwapOutlined style={{ color: '#ababab', fontSize: 14 }} />
          <Tag bordered={false}>
            <Text>B</Text>
          </Tag>
        </Space>
      </div>

      <Spin spinning={_.get(props, 'loading', false)}>
        <div style={{ display: 'flex', marginTop: 12 }}>
          <div
            id="diffRateCharts"
            style={{ width: '100%', height: 400 }}
          />
        </div>
      </Spin>
      <Text type='secondary' style={{ fontSize: 11 }}>R=权重</Text>
    </>
  )
}
import React, { useEffect, useState } from 'react';
import { Typography, Row, Col,Spin } from 'antd';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { showContribution } from '@/api/main';
import { useReactive, useMount } from 'ahooks';
import { NewDivider, NewCard, NewCalendar, TextSelect } from '@/utils/widgets';
import { COLOR_ARRAY } from '@/utils/chartInfo';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const FORMAT = 'YYYY-MM-DD';
const TODAY = moment().format(FORMAT);
const RANGE_TIME = [TODAY, TODAY];
// 持仓分布
const Contribution = (props) => {
  const mobxStore = useLocalObservable(() => store);
  const [tradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const [profitDatas, setProfitDatas] = useState({ timeList: [], datas: [], nameObj: {} });
  const [upcount, setUpcount] = useState(0);
  const [option, setoption] = useState({
    xAxis: {
      type: 'category',
      name: '时间',
      data: [],
      splitLine: {
        show: false
      },
      boundaryGap: false,
      axisTick: {
        show: false
      },
      axisLine: {
        show: true,
        symbol: ['none', 'arrow'],
        symbolSize: [8, 8],
        symbolOffset: 7,
        onZero: false,
      },
    },
    yAxis: [
      {
        type: 'value',
        name: '贡献度(%)',
        position: 'left',
        axisLine: {
          show: true,
          symbol: ['none', 'arrow'],
          symbolSize: [8, 8],
          symbolOffset: 7,
        },
        splitArea: {
          show: true
        },
        splitLine: {
          show: false
        },
      }
    ],
    series: [],
    tooltip: { trigger: 'axis', },
    legend: {
      type: 'scroll',
      selectedMode: 'multiple',
    },
  });

  const conState = useReactive({
    begin: RANGE_TIME[0], end: RANGE_TIME[1], loading: false, type: '1',
    timeOpen: { key: '', open: false, value: '', stamp: new Date() },
  });

  // tab切换获取当前收益率数据
  useMount(() => {
    _showContribution();
  });

  useEffect(() => {
    if (props.active === 'contribution') {
      _showContribution();
    }
  }, [props.upcount]);

  async function _showContribution() {
    const is_reverse = _.get(props, 'isReverse', false);
    if (props.productId) {
      conState.loading = true;

      let params = {
        productId: props.productId,
        beginDate: conState.begin,
        endDate: conState.end,
      }
      const data = await showContribution(params, is_reverse ? 'FC' : 'NOR');
      //  console.log('盈亏', data)
      if (_.get(data, 'code', '') === '200') {
        setProfitDatas({
          timeList: _.get(data, 'data.timeList', []),
          datas: _.get(data, 'data.subAccountHistoryList', []),
          nameObj: _.get(data, 'data.subAccountIdNameMap', {})
        });
        setUpcount(upcount + 1);
      }
      conState.loading = false;
    }
  }

  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('contribution'));
    myChart.showLoading({
      text: '数据获取中',
      effect: 'whirling'
    });

    let newOption = _.cloneDeep(option);
    newOption.xAxis.data = _.get(profitDatas, 'timeList', []);
    newOption.series = handleSeriesData();

    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
  }, [upcount]);

  function handleSeriesData() {
    const result = _.get(profitDatas, 'datas', []);
    const dataKeys = _.head(result) ? Object.keys(_.head(result)) : [];
    let series = [];
    if (_.size(dataKeys) > 0) {
      dataKeys.map(keyname => {
        let sobj = {};
        sobj.name = keyname;
        sobj.type = 'line';
        sobj.data = result.map(r => r[keyname] || 0);
        sobj.symbol = 'none';
        series.push(sobj);
      });
    }
    let colorCount = 0;
    let finalData = _.isArray(series) && _.size(series) > 0 ? series.map((child, index) => {
      let ceilData = child.data.map(n => _.round(n, 5));
      if (index > 19) {
        colorCount = colorCount + 1
      }
      if (colorCount === 19) {
        colorCount = 0;
      }
      return {
        ...child,
        name: _.get(profitDatas, `nameObj.${child.name}`) ?? child.name,
        itemStyle: {
          normal: {
            color: index > 19 ? COLOR_ARRAY[colorCount] : COLOR_ARRAY[index],
            lineStyle: {
              color: index > 19 ? COLOR_ARRAY[colorCount] : COLOR_ARRAY[index]
            }
          }
        },
        data: ceilData
      }
    }) : []
    return finalData;
  }

  function handleDayChange(type) {
    const cur = _.cloneDeep(conState.begin);
    let cidx = _.findIndex(tradeDates, o => o.date === cur);
    if (type === 'last') {
      conState.begin = _.get(tradeDates, `[${cidx - 1}].date`, TODAY);
      conState.end = _.get(tradeDates, `[${cidx - 1}].date`, TODAY);
    } else if (type === 'next') {
      conState.begin = _.get(tradeDates, `[${cidx + 1}].date`, TODAY);
      conState.end = _.get(tradeDates, `[${cidx + 1}].date`, TODAY);
    }
    _showContribution();
  }

  const labelItems = [
    { name: conState.begin, key: 'begin' },
    { name: conState.end, key: 'end' },
  ];
  const iconStyle = { color: '#ababab', fontSize: 14 };
  const barProps = { span: 4, style: { textAlign: 'center' } };
  return <>
    <NewCard pad={4}>
      <Row align='middle' style={{ marginTop: 8 }}>
        <Col {...barProps}>
          <div onClick={() => handleDayChange('last')}>
            <DoubleLeftOutlined style={iconStyle} />
          </div>
        </Col>
        {labelItems.map(n => <Col key={'label' + n.key} span={8} style={{ textAlign: 'center' }}>
          <TextSelect name={n.name} onPress={() => {
            conState.timeOpen = { open: true, value: n.name, stamp: moment(n.name).toDate(), key: n.key };
          }} />
        </Col>)}
        <Col {...barProps}>
          <div onClick={() => {
            if (moment().diff(moment(conState.end), 'day') > 0) {
              handleDayChange('next')
            }
          }}>
            <DoubleRightOutlined style={iconStyle} />
          </div>
        </Col>
      </Row>
      <NewDivider />

      <Spin spinning={conState.loading}>
        <div style={{ display: 'flex' }}>
          <div
            id="contribution"
            style={{ width: '99%', height: 350 }}
          />
        </div>
      </Spin>
    </NewCard>

    <NewCalendar
      values={[conState.begin, conState.end]}
      show={_.get(conState, 'timeOpen.open') ?? false}
      onCancel={() => conState.timeOpen = false}
      onSubmit={(val) => {
        conState.begin = val[0];
        conState.end = val[1];
        conState.timeOpen = { key: '', open: false, value: '', stamp: new Date() };
        _showContribution();
      }}
    />
  </>
}

export default Contribution;
import React, { useState } from 'react';
import { Row, Col, Table, Typography, Tag, Space, Modal, FloatButton } from 'antd';
import { CaretUpOutlined } from '@ant-design/icons';
import { singleColumns, colWidth } from '@/utils/utils';
import { RateText } from '@/utils/widgets';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const FILTER_ARRAY = [{ text: '买入', value: 'BUY' }, { text: '卖出', value: 'SELL' }]
const renderSide = (type) => type === 'BUY' ? <Tag color={'red'}>买入</Tag> : <Tag color={'green'}>卖出</Tag>
// 渲染差异字段; [显示差异为 0]，disable代表右侧是否无对称数据
const checkDiffs = (text, hasRightData) => {
  const getIsDiff = _.get(text, 'isDiff', false);
  const getRate = _.get(text, 'diffRate', 0);
  const newText = getRate !== undefined && getRate !== null && hasRightData ? getRate + '%' : '';
  return getIsDiff ? <Text strong style={{ color: _.get(text, 'color', 'black') }}>{newText}</Text> : newText;
};
const renderTime = (record, time_key) => {
  const sizeRecord = _.size(_.get(record, 'otherRecord'));
  return sizeRecord > 1 ? `${sizeRecord}笔订单` : moment(_.get(record, time_key)).format('HH:mm:ss.SSS');
}
// 对比列表
const CompareList = ({
  fullParams = {},
  // cDate = '',
  tableDatas = {},
  loading = false
}) => {
  const [shows, setShows] = useState({ visible: false, data: [] });
  const getBaseType = _.get(fullParams, 'baseType', '');

  const columns = [
    {
      ...singleColumns(['股票', 'stockCode', 145]),
      fixed: 'left',
      sorter: (a, b) => a.orders - b.orders,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        const conbindName = `${_.get(record, 'stockName', '')}  ${text}`;
        const isDiff = 'hasDiff' in record;
        // 点击编号可关联左右相同条件的数据; 【只现在符合条件的第一位】
        return <div onClick={() => {
          if (isDiff) {
            let findex = _.findIndex(_.get(tableDatas, 'right'), o => o.stockCode === _.get(record, 'stockCode') && o.side === _.get(record, 'side'));
            if (findex > -1) {
              const plate_left = { 'plateform': _.get(fullParams, 'baseType', '') }
              const plate_right = { 'plateform': _.get(fullParams, 'rightType', '') }
              setShows({
                visible: true,
                data: [_.assign(record, plate_left), _.assign(tableDatas['right'][findex], plate_right)]
              });
            }
          }
        }}>
          <Text type={isDiff ? 'default' : 'secondary'}>{conbindName}</Text>
          {isDiff && <CaretUpOutlined style={{ color: '#ababab' }} />}
        </div>
      }
    },
    singleColumns(['均价', 'avgPrice', 65]),
    singleColumns(['数量', 'tQuant', 65]),
    {
      ...singleColumns(['方向', 'side', 65]),
      filters: FILTER_ARRAY,
      onFilter: (value, record) => record.side.indexOf(value) === 0,
      render: (text) => renderSide(text)
    },
    {
      ...singleColumns(['贡献度', 'accountContributionRate', 85]),
      sorter: (a, b) => a.accountContributionRate - b.accountContributionRate,
      render: (text, record) => text ? <RateText rate={text} carry={4} /> : ''
    },
    {
      ...singleColumns(['价格差', 'pricef', 80]),
      sorter: (a, b) => a?.pricef?.diffRate - b?.pricef?.diffRate,
      render: (text, record) => checkDiffs(text, 'hasDiff' in record)
    },
    { // 只有一笔是显示委托事情，更多需要展开显示更多信息
      ...singleColumns(['委托', 'ctime', 120]),
      sorter: (a, b) => a.ctime - b.ctime,
      render: (text, record) => renderTime(record, 'commissionTime')
    },
    getBaseType === 'tamp' ? {
      ...singleColumns(['成交', 'trtime', 120]),
      sorter: (a, b) => a.trtime - b.trtime,
      render: (text, record) => renderTime(record, 'transactTime')
    } : {},
  ];

  const colShow = [
    singleColumns(['平台', 'plateform']),
    {
      ...singleColumns(['股票', 'stockCode']),
      render: (text, record) => {
        const conbindName = `${_.get(record, 'stockName', '')}  ${text}`;
        return conbindName
      }
    },
    singleColumns(['数量', 'tQuant']),
    singleColumns(['均价', 'avgPrice']),
    {
      ...singleColumns(['方向', 'side']),
      render: (text) => renderSide(text)
    },
  ];

  // const isNoneList = noneType !== 'normal' ? true : false;
  const renderTitle = (type) => {
    const footerObj = {
      'left': {
        'keys': ['total', 'validCount', 'diffs', 'avg'],
        'calKey': 'leftCal',
        'rText': '价格差: ',
      },
      'right': {
        'keys': ['total', 'validCount', 'bdiff', 'bavg'],
        'calKey': 'rightCal',
        'rText': '拆单差: ',
      },
    }
    const finalKeys = footerObj[type].keys;
    const calKey = footerObj[type].calKey;
    return <Row gutter={[4, 4]} style={{ textAlign: 'center' }}>
      <Col span={24}>
        <Text>总数: {_.get(tableDatas, `${calKey}.${finalKeys[0]}`, 0)}</Text>
      </Col>
      <Col span={24}>
        <Text>有效票: {_.get(tableDatas, `${calKey}.${finalKeys[1]}`, 0)}</Text>
      </Col>
      <Col span={24}>
        <Space>
          <Text>{footerObj[type].rText}</Text>
          <RateText
            rate={_.get(tableDatas, `${calKey}.${finalKeys[2]}`, 0)}
            carry={4}
            restStyle={{}}
          />
        </Space>
      </Col>
      <Col span={24}>
        <Space>
          <Text>平均: </Text>
          <RateText
            rate={_.get(tableDatas, `${calKey}.${finalKeys[3]}`, 0)}
            carry={4}
            restStyle={{}}
          />
        </Space>
      </Col>
    </Row>
  };

  const modalProps = { centered: true, title: null, footer: null, style: { padding: 8 }, styles: { padding: 8 }, keyboard: false, maskClosable: true, closeIcon: null };
  return <>
    <Row style={{ marginBottom: 12 }}>
      <Col span={12} style={{ borderRight: '1px solid #f5f5f5' }}>
        {renderTitle('left')}
      </Col>
      <Col span={12}>
        {renderTitle('right')}
      </Col>
    </Row>

    <Table
      rowKey="key"
      loading={loading}
      dataSource={_.get(tableDatas, 'left', [])}
      columns={columns}
      scroll={{ x: colWidth(columns) }}
      bordered={false}
      size='small'
      pagination={false}
    />

    <FloatButton.BackTop />

    <Modal
      {...modalProps}
      onCancel={() => {
        setShows({ visible: false, data: [] })
      }}
      open={_.get(shows, 'visible')}
    >
      <Table
        rowKey="key"
        dataSource={_.get(shows, 'data', [])}
        columns={colShow}
        bordered
        size='small'
        pagination={false}
      />
    </Modal>
  </>
}

export default CompareList;
import { Typography } from 'antd';
import { RateText } from './widgets';
import moment from 'moment';
import _ from 'lodash';
const { Text } = Typography;
// 创建label:value类的选项对象
const labelValues = (array) => {
  let obj = { label: array[0], value: array[1] };
  if (array[2]) {
    obj.children = array[2]
  }
  return obj;
};

const PRODUCT_STRATEGY_TYPE = {
  1: '量化多头策略',
  2: '指数增强策略',
  3: '期货CTA策略',
  4: '市场中性策略',
  5: '多空灵活策略',
  6: '参与新股发行策略',
  7: '量化套利策略',
  8: '日内回转策略',
  9: '其他策略',
}

const DATE_TYPE = {
  '1': 'TODAY',
  '2': 'ONE_WEEK',
  '3': 'ONE_MONTH',
  '4': 'THREE_MONTH',
  '5': 'SIX_MONTH',
  '6': 'ONE_YEAR',
  '7': 'THREE_YEAR',
  '8': 'ONE_YEAR',
  '9': 'ALL',
  '11': 'TODAY',
}
const DATE_TYPE_OPTIONS = [
  labelValues(['全部', 'ALL']),
  labelValues(['今', 'TODAY']),
  labelValues(['周', 'ONE_WEEK']),
  labelValues(['月', 'ONE_MONTH']),
  labelValues(['季', 'THREE_MONTH']),
  labelValues(['年', 'ONE_YEAR']),
];

const BT_FILTER_TYPE = ['每日更新', '回测中', '其他'];
// 自定义指数value自定义字符
const CUSTOMIDX_STRING = 'CusTom';
//___________________________________________________________________________________________
// 自适应：默认中屏幕12，小屏幕24沾满一行
const autoCol = (span = [0, null, null]) => {
  return { xxl: span[0], xl: span[0], lg: span[1] || 12, md: span[2] || 24, sm: span[2] || 24, xs: span[2], flex: 'auto' }
}

function isEmptyStringV2(value) {
  if (value == "undefined" || !value || !/[^\s]/.test(value)) {
    return true;
  } else {
    return false;
  }
}
// 属性判断
const isValidArray = (array) => array && _.isArray(array) && _.size(array) > 0 ? true : false;
const isValidObj = (object) => object && _.isObject(object) && _.size(object) > 0 ? true : false;
const isValidNumber = (number) => number && _.isNumber(number) ? true : false;
const isValidString = (string) => string && _.isString(string) && string !== '' ? true : false;

// 创建单一列的column信息
const ALIGN_KEYS = ['left', 'center', 'right']; // 单列数据中最后一位默认是align，如需要配置该行其他数据，可以通过非对齐字段进行判断。配合type使用
const SORT_KEYS = ['descend', 'ascend'];
const singleColumns = (pmArray = ['', ''], type = '') => {
  // pmArray 创建单列数据的参数数组; 根据使用频率进行排列及拓展；
  //0：名称 1:key字段 2宽度 3对齐（可用0占位默认值）4:默认排序
  const pmGet = (pathArr) => _.get(pmArray, pathArr, '');
  let obj = {
    title: pmGet(['0']),
    dataIndex: pmGet(['1']),
    key: pmGet(['1']),
    align: pmArray[3] && ALIGN_KEYS.indexOf(pmGet(['3'])) !== -1 ? pmGet(['3']) : ALIGN_KEYS[0]
  }
  if (pmArray[2]) {
    obj.width = pmGet(['2']);
  }
  if (pmArray[4] && _.isArray(pmGet(['4']))) {
    const getFourth = pmGet(['4']);
    obj.sorter = (a, b) => a[pmGet(['4', '1'])] - b[pmGet(['4', '1'])];
    obj.sortDirections = SORT_KEYS;
    if (pmGet(['4', '2']) === 'default' && SORT_KEYS.indexOf(pmGet(['4', '2'])) !== -1) {
      obj.defaultSortOrder = _.head(getFourth);
    }
  }
  // type 生成些标准需求且复用率高的列表渲染内容，更复杂无需在此继续增加type
  if (type === 'dateTime') {
    obj.render = function (text, record) { return text ? moment(text).format('YYYY-MM-DD HH:mm') : '' }
  } else if (type === 'updown') {
    const isRate = _.last(pmArray) === 'rate' ? false : true; // 收益字段，array最后一位是rate，则添加百分号
    obj.render = function (text, record) { return <RateText rate={text} noPrefix={isRate} /> }
  } else if (type === 'strong') {
    obj.render = function (text, record) { return <Text strong>{text}</Text> }
  } else if (type === 'strongPrice') {
    obj.render = function (text, record) {
      const priceVal = text ? _.round(parseFloat(text), 2) : 0;
      return <Text strong>{priceVal}</Text>
    }
  }
  return obj
}

// 提取出产品内部的符合条件的子账户；同TradeCompare处理逻辑
const labelIncludesFalse = (label) => { // 检查是否包含中英文括号
  const bracket_en = ["(", ")"]; const bracket_cn = ["（", "）"];
  let ens = bracket_en.map(b => _.includes(label, b));
  let cns = bracket_cn.map(b => _.includes(label, b));
  return !ens.includes(false) || !cns.includes(false) ? true : false; // 中英文有一组都不包含false的，则匹配完整括号 返回true
}
// labelIncludesFalse版本2，检测()进行分类，并检测是否包含在首页当中
const accountOptionIndex = (label, isHome) => {
  const bracket_en = ["(", ")"]; const bracket_cn = ["（", "）"];
  let ens = bracket_en.map(b => _.includes(label, b));
  let cns = bracket_cn.map(b => _.includes(label, b));
  const finalBool = !ens.includes(false) || !cns.includes(false) ? true : false;
  //返回上层分类的index值: 0:每日更新；1:回测中 2:其他 
  if (finalBool === true) {
    return 0;
  } else if (finalBool === false && isHome) {
    return 1;
  } else if (finalBool === false) {
    return 2;
  }
}
// 时间排序及创建timeKey字段; pure_date=数组内为时间字符串，不是对象格式
const format_obj = {
  'd': 'YYYY-MM-DD',
  's': 'HH:mm:ss', 'fs': 'YYYY-MM-DD HH:mm:ss',
  'm': 'HH:mm', 'fm': 'YYYY-MM-DD HH:mm',
  'fms': 'YY-MM-DD HH:mm:ss:SSS', 'ms': 'HH:mm:ss:SSS',
};
const key_obj = {
  d: 'd', s: 's', m: 'm', ms: 'millisecond',
  fs: 's', fm: 'm', fms: 'millisecond',
};
const createDatasTimeKey = (array = [], compareKey = '', timeString = 'd', orders = 'desc') => {
  let final = [];
  if (isValidArray(array) && isValidString(compareKey)) {
    final = array.map(n => {
      const cval = compareKey === 'pure_date' ? n : _.get(n, compareKey);
      return _.assign(compareKey === 'pure_date' ? { 'date': n } : { ...n }, {
        'timeKey': moment(cval).diff(moment(), key_obj[timeString]),
        'formatDate': moment(cval).format(format_obj[timeString] || 'YYYY-MM-DD'),
      })
    });
  }
  return _.orderBy(final, ['timeKey'], [orders]);
}
// 大额资金中文简写
function renderHugeFund(value) {
  const carryObject = { 1: '万', 2: '亿', 3: '万亿' }
  const handleCarry = (number, currentCarry) => {
    return number >= 10000 || number <= -10000 ?
      _.round(number / 10000, 1) + carryObject[currentCarry + 1]
      : number + carryObject[currentCarry]
  }

  const newValue = _.isNumber(value) ? value : parseInt(value);
  if (newValue > 100000000 || newValue < -100000000) { // 大于 亿
    return handleCarry(_.round(newValue / 100000000, 1), 2)
  } else if (newValue > 10000 || newValue < -10000) {
    return handleCarry(_.round(newValue / 10000, 1), 1)
  } else {
    return newValue
  }
}

const colWidth = (cols = []) => {
  return _.size(cols) > 0 ? _.sum(cols.map(c => c.width ?? 0)) : 0
};
// 将字符串逗号分隔金额转换成number
const replaceMoney = (number = 0) => {
  const pureNum = isValidString(number) ? parseFloat(number.replaceAll(',', '')) : number;
  let splitNumber = _.reverse(_.split(pureNum, '')); // 翻转数组，从小数点后进行遍历
  let c = 0;
  let isDim = true;
  let stringArray = [];
  splitNumber.map(n => {
    let num = n !== '.' && n !== '-' ? parseInt(n) : null;
    if (n === '.') { //遇到小数点开始后循环开始c++
      isDim = false;
    }
    // 每3位进位，push【,】进行分隔，从小数点后整数开始计算，并且最后一位是负数不处理；
    if (c === 3 && !isDim && n !== '-') {
      c = 0;
      stringArray.push(',');
    }
    if (num !== null && !isDim) { //整数位c递增
      c++;
    }
    stringArray.push(n);
  })
  return { 'number': pureNum, 'string': _.join(_.reverse(stringArray), '') };
}

//bt回测 和 实测对比中的账户处理 ; 返回platformArray
const plateform_items = [
  { key: 'simx', name: 'SIMX' },
  { key: 'simxVirtual', name: 'SIMX_虚拟' },
  { key: 'tamp', name: 'TAMP' },
  { key: 'tamp_fc', name: 'TAMP_反采' },
  { key: 'bt', name: 'BT_每日更新' },
  { key: 'bt_testing', name: 'BT_回测中' },
  { key: 'bt_rest', name: 'BT_其他' },
  { key: 'btVirtual', name: 'BT_虚拟' },
];
const TWO_VIR = ['simxVirtual', 'btVirtual']; // 虚拟产品key
const BT_SPLIT_KEY = ['bt', 'bt_testing', 'bt_rest']; // bt分拆的key

function handlePlatformAccount(res = {}, resFc = {}, isAcc = false) {
  let accMap = new Map(); let proMap = new Map();
  let accCompare = new Map();
  // 格式: {simx_210:名称....}  通过字符串区分平台，避免重复
  const createAccountItem = (account, type) => {
    const setType = type === 'tamp_fc' ? 'tamp' : type;
    const proNameKey = _.includes(TWO_VIR, type) ? 'name' : 'productName'; // simx虚拟账户名称和id的字段不同
    const proIdKey = _.includes(TWO_VIR, type) ? 'id' : 'productId';
    let array = [];
    if (isValidArray(account)) {
      account.map(product => {
        // 每个group对象内，增加baseType字段，可判断账户基准
        let newItem = {
          ...product,
          label: _.get(product, proNameKey),
          children: [],
          value: _.get(product, proIdKey),
          centralizedLogin: type === 'tamp_fc' ? 2 : 1
        };
        proMap.set(setType + '_' + newItem.value, newItem.label);
        // 增加产品兼容性；product页面则不再遍历子账户数据
        isAcc && isValidArray(_.get(product, 'securitiesAccountList', [])) && product.securitiesAccountList.map(acc => {
          isValidArray(_.get(acc, 'subAccountList', [])) && acc.subAccountList.map(sub => {
            let newSub = {
              ...sub,
              label: _.get(sub, 'subAccountName', ''),
              value: _.get(sub, 'subAccountId', 0),
              centralizedLogin: type === 'tamp_fc' ? 2 : 1
            };
            accMap.set(setType + '_' + newSub.value, newSub.label); // 用id作为key，label作为value，可根据id找到名称
            accCompare.set(newSub.value, newSub.label); // compare使用 value-label进行设置
            newItem.children.push(newSub);
          })
        });
        // bt分拆
        if (_.includes(BT_SPLIT_KEY, type)) {
          const isValidBt = labelIncludesFalse(newItem.label);
          if (type === 'bt' && isValidBt) {  // bt 回测，产品名称包含“括号”的才进行显示
            array.push(newItem);
          } else if (!isValidBt) {
            // 其他里面 isShow = 1的增加回测中分类
            if (type === 'bt_testing' && _.get(newItem, 'isShow') === '1') {
              array.push(newItem);
            } else if (type === 'bt_rest' && _.get(newItem, 'isShow') === '0') {
              array.push(newItem);
            }
          }
        } else {
          array.push(newItem);
        }
      })
    }
    return array; // tamp直接返回账户array
  }
  const newPlateformItems = isAcc ? _.filter(plateform_items, o => !_.includes(TWO_VIR, o.key)) : plateform_items;
  let platformArray = newPlateformItems.map(k => {
    let itemData = [];
    if (k.key === 'tamp_fc') {
      itemData = createAccountItem(_.get(resFc, 'data.tamp'), 'tamp_fc');
    } else {
      // bt 拆分需继续调用bt数据
      itemData = createAccountItem(
        _.get(res, `data.${_.includes(BT_SPLIT_KEY, k.key) ? 'bt' : k.key}`, []),
        k.key
      );
    }
    return labelValues([k.name, k.key, itemData]);
  });
  // console.log(platformArray)
  return {
    'platformArray': platformArray,
    'accoutMap': accMap,
    'productMap': proMap, // 增加产品层面名称
    'accCompare': accCompare //compare 不同map字段
  };
}
// 计算当前是否是开盘时间
const isMarketTrading = (isTradeDay = true) => {
  const TOTAY = moment().format('YYYY-MM-DD');
  const OPEN_TIME = moment(TOTAY + ' 9:30');
  const CLOSE_TIME = moment(TOTAY + ' 15:00');
  const currentTime = moment();
  let isGet = false;
  const diffOpenMin = currentTime.diff(OPEN_TIME, 'm');
  const diffCloseMin = currentTime.diff(CLOSE_TIME, 'm');
  // 用开盘时间判断: 9.30之后 11.30之前  ; 分钟diff，31分钟之前还会持续获取
  if (diffOpenMin >= 0 && diffOpenMin <= 120) {
    isGet = true;
  };
  // 收盘时间判断: 13.00之后 15.00之前 
  if (diffCloseMin >= -120 && diffCloseMin <= 0) {
    isGet = true;
  };
  // 非交易日直接返回false
  if (!isTradeDay) {
    isGet = false;
  }
  return isGet;
};
// 查找交易日期，返回实时或者其他周期
function initDateType(trades = [], defalut = '1') {
  const today = moment().format('YYYY-MM-DD');
  let dindex = _.findIndex(trades, o => o.date === today);
  return {
    'type': dindex !== -1 ? DATE_TYPE[defalut] : DATE_TYPE['2'],
    'tab': dindex !== -1 ? defalut : '2',
    'isTradeDay': dindex !== -1 ? true : false,
  }
}

export {
  // 静态
  PRODUCT_STRATEGY_TYPE,
  DATE_TYPE, DATE_TYPE_OPTIONS,
  BT_FILTER_TYPE,
  CUSTOMIDX_STRING,
  // 方法
  autoCol,
  isEmptyStringV2,
  isValidArray, isValidObj, isValidString, isValidNumber,
  labelValues,
  singleColumns,
  labelIncludesFalse, accountOptionIndex,
  createDatasTimeKey,
  renderHugeFund,
  colWidth,
  replaceMoney,
  handlePlatformAccount,
  isMarketTrading,
  initDateType
}
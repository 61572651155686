import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, message, Space, Tag, Popover, Button } from 'antd';
import { CaretDownOutlined, PlusOutlined, AccountBookOutlined, ProjectFilled } from '@ant-design/icons';
import { store } from '@/store/mobx';
import { useLocalObservable } from 'mobx-react';
import {
  showSubAccountHistory, showSingleProductHistory, showBatchProductHistory, showBatchSubAccountHistory, showSingleProductHistoryVir,
} from '@/api/main';
import { useReactive, useUnmount, useUpdateEffect } from 'ahooks';
import {
  STATIC_RATE_COLOR, PRE_DAY_CN, arraysMinus, createBaseLine, renderSlice,
  calRateFormula, handleRetreat, calRate, multiplication, isFullTimeRange
} from '@/utils/chartInfo';
import { EXTRA_COMPARE } from '@/utils/chartInfo2';
import { BT_FILTER_TYPE, isValidString, isValidArray, isValidObj, isValidNumber, labelValues } from '@/utils/utils';
import { Selections, NewDivider, MainSlider, LabelValueComp, ValuesColor } from '@/utils/widgets';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const TODAY = moment().format('YYYY-MM-DD');
const DATE_OPTIONS = [labelValues(['周', 'ONE_WEEK']),
labelValues(['月', 'ONE_MONTH']), labelValues(['季', 'THREE_MONTH']), labelValues(['半年', 'SIX_MONTH']),
labelValues(['年', 'ONE_YEAR']), labelValues(['三年', 'THREE_YEAR']), labelValues(['全部', 'ALL'])
];
const GET_TYPE = { 'account': 'subAccountId', 'product': 'productId', 'vir': 'productId', 'fund': 'jid' };
const PAGEKEY_SHORT = { 'A': 'account', 'P': 'product', 'V': 'virtual' };
const VSTRING = 'V';
const EXTRA_TITLE = '产品超额';
let timer = null; let timer2 = null;
// 创建option；叠加虚拟产品的选项
const createAccountState = (alls, virs) => {
  let virObj = {}; let virOptions = [];
  if (isValidArray(virs)) {
    virs.map(itm => {
      virOptions.push({
        'label': itm.name,
        'value': VSTRING + itm.id,
        'comp': <Tag color='orange'>虚拟</Tag>,
        'aindex': -1,
      });
      _.set(virObj, VSTRING + itm.id, itm.name);
    })
  };

  const concatProduct = _.concat(virOptions, _.get(alls, 'product'));
  return {
    'productObj': _.assign(_.get(alls, 'productObj'), virObj),
    'accObj': _.get(alls, 'accObj'),
    'accountOptions': _.get(alls, 'account'),
    'productOptions': concatProduct,
    'accLogin': _.get(alls, 'accLogin'),
    'productLogin': _.get(alls, 'productLogin'),
    'btCards': _.orderBy(_.filter(concatProduct, o => o.aindex !== 2), ['aindex'], ['asc'])
  }
};
//V字符的删减与判断
const trimId = (id) => parseInt(_.trim(id, VSTRING));
const isVid = (id) => _.includes(id, VSTRING);
/* 
  超额分析
*/
const ExtraCompare = (props) => {
  const mobxStore = useLocalObservable(() => store);
  const isBt = _.get(props, 'plateform', '') === 'BT' ? true : false;
  const isTamp = _.get(props, 'plateform', '') === 'TAMP' ? true : false;
  const [all_product_acc, set_all_product_acc] = useState(JSON.parse(JSON.stringify(mobxStore.allProductAcc)));
  const [accState, setAccState] = useState(createAccountState(all_product_acc, _.get(props, 'vOptions')));
  const [update, setUpdate] = useState(0);
  const [update2, setUpdate2] = useState(0);
  const [sliderValue, setSliderValue] = useState([]);
  const [sliderValue2, setSliderValue2] = useState([]);
  const [timeArr, setTimeArr] = useState([0]);
  const [timeNameArr, setTimeNameArr] = useState([]); // 完整时间数据
  const [fullSeries, setFullSeries] = useState([]);
  const [fullExtra, setFullExtra] = useState({});
  const [rateDatas, setRateDatas] = useState({});
  const [checkboxItem, setCheckBoxItem] = useState([]);
  const extraState = useReactive({
    dateType: 'ALL', getType: '', account: '', product: '', isExtra: false, propId: 0,
    visible: false, soptions: [], skey: '',
  });
  const [option, setoption] = useState(EXTRA_COMPARE);
  const getPageKeys = _.get(props, 'pageKey', '');
  const isAccount = getPageKeys === PAGEKEY_SHORT['A'];
  const isProduct = getPageKeys === PAGEKEY_SHORT['P'];
  const isVirtual = getPageKeys === PAGEKEY_SHORT['V'];

  useUnmount(() => {
    timer && clearTimeout(timer);
    timer2 && clearTimeout(timer2);
  });
  // tab切换获取当前收益率数据
  useEffect(() => {
    if (props.active === 'extraCompare') {
      _updatePage();
    }
  }, [props.active, props.upcount]);

  function _updatePage() {
    const hasAccObject = () => isValidObj(_.get(accState, isAccount ? 'accObj' : 'productObj'));
    const hasAccOptions = () => isValidArray(_.get(accState, isAccount ? 'accountOptions' : 'productOptions'));

    let is_get = false; let is_acc_get = false;
    const isActive = props.active === 'extraCompare' ? true : false;
    if (isActive) {
      _getRates(isAccount ? 'account' : 'product');
      is_get = true;
      is_acc_get = hasAccObject() && hasAccOptions() ? false : true;
    }

    if (is_get && is_acc_get) {
      mobxStore._getProductAccount(_.get(props, 'plateform', '') === 'TAMP' ? true : false);
      // 延时获取更新
      timer = setTimeout(() => {
        let newProductAcc = JSON.parse(JSON.stringify(mobxStore.allProductAcc));
        set_all_product_acc(newProductAcc);
        setAccState(createAccountState(newProductAcc, _.get(props, 'vOptions')));
      }, 2000);
    }
  };
  //
  async function _getRates(type = '', gtype = '', id = 0) {
    const isSingle = gtype !== 'batch';
    // promise需要的获取单一产品的方法
    const singleGetRate = async (id, pms, isRev) => {
      const singleGet = type === 'account' ? await showSubAccountHistory(pms, isRev ? 'FC' : 'NOR') : await showSingleProductHistory(pms, isRev ? 'FC' : 'NOR');
      // 返回格式与批量返回相同 {id:{data数据}}
      return new Promise(resolve => resolve({ [id]: _.get(singleGet, 'data', {}) }));
    }
    // promise需要的获取单一产品的方法； 普通产品接口和虚拟产品接口
    const virtualGet = async (id, pms, isVir) => {
      const singleGet = isVir ? await showSingleProductHistoryVir(pms) : await showSingleProductHistory(pms)
      // 如果是单一获取，则返回数据; 虚拟统一返回虚拟字段
      return new Promise(resolve => resolve(
        isSingle ? _.assign(_.get(singleGet, 'data', {}), { 'is_virtual': isVir })
          : { [id]: _.get(singleGet, 'data', {}), 'is_virtual': isVir }
      ));
    };
    const currentOptions = _.get(accState, type === 'account' ? 'accountOptions' : 'productOptions', []);
    const typeKeys = _.filter(currentOptions, o => o.centralizedLogin === 2).map(n => n.value); // 所有反采id
    // 处理接口参数； vOptions只在虚拟产品页面的props才有
    const getVirOptions = _.get(props, 'vOptions', []);
    const virIds = getVirOptions.map(itm => VSTRING + itm.id);
    const propsId = _.get(props, GET_TYPE[type], 0);
    const newId = id ? id : propsId;
    extraState.propId = propsId;
    if (newId) {
      let params = {
        'dateType': extraState.dateType,
        'date': TODAY,
        [GET_TYPE[type]]: isVid(newId) ? trimId(newId) : newId
      };
      // 判断是否批量
      let isNorBatch = gtype === 'batch' ? true : false;
      let needProGet = false; // 是否使用promise进行获取
      let proAll = []; // promise 数组
      // 当前选项中是否包含虚拟账户
      const isValidCheckbox = isValidArray(checkboxItem);
      const virArray = isValidCheckbox ? checkboxItem.map(c => {
        const kId = isVid(c.keyId) ? c.keyId : parseInt(c.keyId);
        return _.includes(virIds, kId);
      }) : [];
      // 当前选项中是否包含反采账户
      const revArray = isValidCheckbox ? checkboxItem.map(c => {
        const get_key_id = parseInt(c.keyId);
        const is_rev = _.get(accState, `${type === 'account' ? 'accLogin' : 'productLogin'}.${get_key_id}`) === 2 ? true : false;
        return is_rev;
      }) : [];
      //要使用promise的情况进行分别获取
      if (isSingle && _.includes(virIds, id)) {
        // id是虚拟产品
        proAll.push(virtualGet(id, params, true));
        needProGet = true;
      } else if (gtype === 'batch' && _.includes(virArray, true)) {
        // 批量（切换时间区间）；并且账户包含虚拟产品
        checkboxItem.map(c => {
          const kId = isVid(c.keyId) ? c.keyId : parseInt(c.keyId);
          const is_virtual = _.includes(virIds, kId);
          let singlePms = { ...params };
          if (isValidNumber(kId)) {
            _.set(singlePms, GET_TYPE[type], kId);
          } else if (isVid(kId)) {
            _.set(singlePms, GET_TYPE[type], trimId(kId));
          }
          proAll.push(virtualGet(kId, singlePms, is_virtual));
          needProGet = true;
        });
      } else if (!id && _.includes(virIds, propsId) && isSingle) {
        // 首次进入或updatePage，并且当前对比产品id是虚拟产品
        proAll.push(virtualGet(id, params, true));
        needProGet = true;
      } else if (!id && _.includes(typeKeys, propsId)) {
        // 首次进入或updatePage，并且是反采
        proAll.push(singleGetRate(propsId, params, true));
        needProGet = true;
      };

      if (_.includes(revArray, true) && isTamp) {
        checkboxItem.map(c => {
          const get_key_id = parseInt(c.keyId);
          const is_rev = _.get(accState, `${type === 'account' ? 'accLogin' : 'productLogin'}.${get_key_id}`) === 2 ? true : false;
          let singlePms = { ...params, [GET_TYPE[type]]: get_key_id };
          // console.log(get_key_id, singlePms, is_rev);
          proAll.push(singleGetRate(get_key_id, singlePms, is_rev));
          // 普通批量 = false；执行promise = true
          if (_.includes(typeKeys, get_key_id) && gtype === 'batch') {
            isNorBatch = false;
            needProGet = true;
          }
        });
      };
      // 普通批量时删除虚拟的id
      if (isNorBatch) {
        let newKeyIds = [];
        checkboxItem.map(c => {
          const unVir = !isVid(c.keyId);
          if (unVir) {
            newKeyIds.push(c.keyId);
          };
          // 【bug-fix】 批量包含虚拟以及私募，则普通id也进行promise操作
          if (unVir && needProGet) {
            let normalParams = {
              ...params,
              'dateType': params.dateType,
              'date': params.date,
              [GET_TYPE[type]]: c.keyId
            };
            proAll.push(singleGetRate(c.keyId, normalParams));
          };
        });
        _.set(params, GET_TYPE[type], _.join(newKeyIds, ','));
      };
      // 清除或者在已经2个的情况下再次选择，则覆盖掉另外一个产品id进行批量获取；
      if ((gtype === 'clear' || extraState.isExtra) && !isNorBatch) {
        isNorBatch = true;
        _.set(params, GET_TYPE[type], _.join(isValidNumber(id) ? [propsId, id] : [propsId], ','));
      };

      let res = {};
      const isAccGet = type === 'account' ? true : false;
      if (isAccGet && !needProGet) {
        res = isNorBatch ? await showBatchSubAccountHistory(params) : await showSubAccountHistory(params);
      } else if (!isAccGet && !needProGet) {
        res = isNorBatch ? await showBatchProductHistory(params) : await showSingleProductHistory(params);
      }
      // 批量用单一接口获取数据，将使用response的统一格式进行处理
      if (needProGet) {
        const allResult = await Promise.all(proAll);
        let resData = {}
        allResult.map(result => {
          resData = _.assign(resData, result);
        });
        res = { 'code': '200', 'data': resData };
      }
      if (_.get(res, 'code', '') === '200') {
        handleResData(
          res,
          _.assign(params, { 'id': _.get(params, GET_TYPE[type]), 'isBatch': isNorBatch }),
        );
      }
    }
  };
  // 统一处理返回数据
  async function handleResData(res, pms) {
    const is_batch = _.get(pms, 'isBatch');
    const is_reverse = _.get(pms, 'isReverse');
    const isNeedBatch = is_batch || is_reverse ? true : false;
    // 批量和反采都是以空创建数据；【tips】反采切换周期时直接清空累加数据，需要重新选择
    let initTemp = isNeedBatch ? {} : _.cloneDeep(rateDatas);
    const getData = _.get(res, 'data', {});
    // 处理批量数据
    if (isNeedBatch && isValidObj(getData)) {
      _.keys(getData).map(ids => {
        const sub_data = _.get(getData, ids, {});
        if (isValidObj(sub_data) && isValidArray(_.get(sub_data, 'timeList'))) {
          // 虚拟vid重新增加v字符
          const rates = handleRateData(sub_data, pms.dateType);
          _.set(initTemp, _.get(rates, 'is_virtual') ? VSTRING + ids : ids, rates);
        }
      })
    } else { // 单独增加的数据进行累加赋值
      if (!isValidObj(getData) || !isValidArray(_.get(getData, 'timeList'))) {
        message.info('暂无数据!');
        extraState.account = '';
        extraState.product = '';
        return;
      }
      const rates = handleRateData(getData, pms.dateType);
      _.set(initTemp, _.get(rates, 'is_virtual') ? VSTRING + pms.id : pms.id, rates);
    }
    let temp = sliceUnmatchDates(initTemp, pms.dateType); // 裁剪数据
    // 指数计算；使用main图表计算逻辑，计算指数曲线
    const newTimes = _.get(temp, 'times');
    // 设置统一时间及slider
    setTimeArr(newTimes.map((n, i) => i));
    setTimeNameArr(newTimes);
    setRateDatas(temp);
    setUpdate(_.round(update + 0.1, 1));
    extraState.getType = pms.dateType;
  };
  // 对完整对象进行处理
  function sliceUnmatchDates(fullData = {}, dateType = '') {
    let final = {};
    let dataSize = 0;
    // 找到最大的时间数据
    let maxSize = 0; let maxTimeList = [];
    _.keys(fullData).map(ids => {
      const get_size = _.get(fullData, `${ids}.timeSize`, -1);
      if (maxSize === 0 || get_size > maxSize) {
        maxSize = get_size;
        dataSize = _.size(_.get(fullData, `${ids}.pointList`, []));
        maxTimeList = _.get(fullData, `${ids}.timeList`, []);
      };
    });
    // 再次遍历数据,赋值final
    _.keys(fullData).map(ids => {
      final[ids] = _.cloneDeep(_.get(fullData, ids));
      //小于最大时间数据进行处理
      if (_.get(fullData, `${ids}.timeSize`, 0) !== maxSize) {
        const isValidShort = isValidObj(_.get(fullData, ids)) && 'short_time' in _.get(fullData, ids) ? true : false
        const getShortTime = isValidShort ? _.get(fullData, `${ids}.short_time`, []) : _.get(fullData, `${ids}.timeList`, []);
        //【bug-fix】 用长时间轴遍历，短时间轴有数据返回对应自己时间的index值;
        let sindex_array = []; // 记录数组方便找到最小值
        let timeIndex = maxTimeList.map((t, i) => {
          const sindex = getShortTime.indexOf(t);
          // 【bug-fix】twocompare对sindex有-1操作，因为pointNet操作，超额则删掉改方法
          if (sindex > -1) {
            sindex_array.push(sindex);
          }
          return sindex; // 生成长轴时间数组，对应短轴的数据位置；例：[-1,7,8,9...,-2,-2,-2,-2]  -1 =前一，-2为未找到
        });
        let daliy_rate = [];
        // 重新计算短轴时间数据，【bug-fix】短轴时间可早于长轴时间，所以需要重新计算
        let shortNets = timeIndex.map(ti => {
          daliy_rate.push(ti > -1 ? _.get(fullData, `${ids}.daliyRate[${ti}]`) : 0);
          return ti > -1 ? _.get(fullData, `${ids}.pointsNet[${ti}]`) : '-';
        });
        let shortStart = _.min(sindex_array) > 0 ? _.get(fullData, `${ids}.pointsNet[${_.min(sindex_array) - 1}]`) : _.get(fullData, `${ids}.preNet`);
        //【bug-fix】 实时点位不对称，preNet实时为0，所以计算错误；该情况默认start=pointNet第一位净值数据
        if (!shortStart && dateType === 'TODAY') {
          shortStart = _.get(fullData, `${ids}.pointsNet[0]`);
        }
        shortNets[0] = shortStart; // 前一交易日 = shortStart的值
        _.set(final, `${ids}.pointList`, calRate(shortNets, shortStart));
        _.set(final, `${ids}.pointRetreat`, handleRetreat(shortNets));
        _.set(final, `${ids}.timeList`, maxTimeList);
        _.set(final, `${ids}.timeIndex`, timeIndex);
        _.set(final, `${ids}.short_time`, getShortTime);// 获取到的初始时间数据
        _.set(final, `${ids}.short_net`, shortNets);
        _.set(final, `${ids}.daliyRate`, daliy_rate);
      }
    });
    final.times = maxTimeList;
    final.dataSize = dataSize;
    return final;
  }
  //处理获取的收益率数据
  function handleRateData(datas = {}, dateType = '') {
    const pointNetList = _.get(datas, 'netValueList', []); // 返回的净值数据
    const preNetVal = _.get(datas, 'preNetValue', 0); // 返回的净值数据
    const getTimeList = _.get(datas, 'timeList', []);
    const isToday = dateType === 'TODAY' ? true : false;
    // 返回的data,object ; 【新】新增虚拟字段
    let dataObj = { 'is_virtual': _.get(datas, 'is_virtual') };
    const retreat = handleRetreat(pointNetList); // 回撤数据
    const dayliRate = pointNetList.map((p, i) => {
      return _.round(parseFloat(calRateFormula(p, i === 0 ? preNetVal : pointNetList[i - 1])) / 100, 6)
    });
    dataObj.retreat = isToday ? retreat : _.concat([0], retreat);
    dataObj.timeList = isToday ? getTimeList : _.concat([PRE_DAY_CN], getTimeList);
    dataObj.preNet = isToday ? 0 : preNetVal;
    dataObj.timeSize = _.size(getTimeList);
    _.set(dataObj, 'daliyRate', isToday ? dayliRate : _.concat([0], dayliRate));
    _.set(dataObj, 'pointsNet', pointNetList);
    _.set(dataObj, 'start_date', getTimeList[0]);
    return dataObj;
  };

  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('extra_compare'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    const pcdtMap = _.get(accState, 'productObj'); const accMap = _.get(accState, 'accObj');
    let newOption = _.cloneDeep(option);
    let newSeires = []; let newExtra = [];
    let legend = []; let count = 0;
    // 当前对比count及rate数据最大=2，所以排序及颜色需要固定
    if (isValidObj(rateDatas)) {
      _.keys(rateDatas).map(keyId => {
        if (!_.includes(['dataSize', 'times'], keyId)) {
          const getName = getPageKeys === PAGEKEY_SHORT['A'] ? _.get(accMap, keyId) : _.get(pcdtMap, keyId);
          const getDaliyRate = _.get(rateDatas, `${keyId}.daliyRate`, []);
          const isFirst = String(extraState.propId) === keyId ? true : false;
          const rcolor = STATIC_RATE_COLOR[isFirst ? 0 : 1];
          const sobj = createBaseLine(rcolor, {
            'name': getName, keyId,
            'order': isFirst ? 1 : 2,
            'orgData': getDaliyRate.map(n => _.round(n * 100, 4)),
            'data': multiplication(getDaliyRate, 4)
          });
          newSeires.push(sobj);
          legend.push({
            'name': getName,
            'fullName': getName,
            keyId
          });
          count++;
        };
      });
      newSeires = _.orderBy(newSeires, ['order'], ['asc']);
      if (count === 2) {
        const extras = calExtraData(newSeires);
        newExtra = [extras];
        extraState.isExtra = true;
        setFullExtra(extras);
      }
    }
    // 赋值slider的滑动数据
    if (_.last(timeArr)) {
      setSliderValue([0, _.last(timeArr)]);
      setSliderValue2([0, _.last(timeArr)]);
    }
    // 更新checkbox；默认全选
    setCheckBoxItem(legend);

    newOption.xAxis.data = _.get(rateDatas, 'times', []);
    newOption.series = _.concat(newSeires, newExtra);
    setFullSeries(_.concat(newSeires, newExtra));

    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
  }, [update]);
  //slider滑动调用，更新数据；
  useUpdateEffect(() => {
    let myChart = props.myChart;
    myChart = echarts.init(document.getElementById('extra_compare'));
    // 全部时间轴时，说明无需截取，用收盘价作为第一点位数据。
    const isFullRange = isFullTimeRange(sliderValue, timeArr);
    let newOption = _.cloneDeep(option);
    if (isFullRange) {
      const fullTimes = _.cloneDeep(timeNameArr);
      newOption.series = fullSeries;
      newOption.xAxis.data = fullTimes;
    } else {
      const sliceTimes = renderSlice(timeNameArr, sliderValue[0], sliderValue[1]);
      let sdata = [];
      fullSeries.map(sir => {
        const keyId = _.get(sir, 'keyId', '');
        if (keyId !== 'pro_extra') {
          const getDaliyRate = _.get(rateDatas, `${keyId}.daliyRate`, []);
          const sliceDaliy = renderSlice(getDaliyRate, sliderValue[0], sliderValue[1]);
          const sliceRate = multiplication(sliceDaliy, 4);
          sdata.push({ ...sir, 'data': sliceRate, 'orgData': sliceDaliy.map(n => _.round(n * 100, 4)) });
        };
      });
      if (extraState.isExtra) {
        const extras = calExtraData(sdata);
        sdata.push(extras);
      };
      newOption.series = sdata;
      newOption.xAxis.data = sliceTimes;
    };
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.resize();
  }, [update2]);
  // 计算超额曲线
  function calExtraData(seires) {
    const extraVal = arraysMinus(_.get(seires, '[0].data', []), _.get(seires, '[1].data', []));
    const extraOrg = arraysMinus(_.get(seires, '[0].orgData', []), _.get(seires, '[1].orgData', []));
    const extra = createBaseLine('#ffc845', {
      'name': EXTRA_TITLE, keyId: 'pro_extra', 'order': 3,
      'data': extraVal, 'orgData': extraOrg
    });
    return extra;
  };

  function updateThisChart() {
    setUpdate2(_.round(update2 + 0.1, 1));
  }
  // slider完成后
  function afterSliderChange(v) {
    setSliderValue(v);
    setSliderValue2(v);
    updateThisChart();
  };
  // 切换选择
  function onSelectChange(v) {
    extraState.visible = false;
    let newVal = 0;
    if (extraState.skey === 'account' || extraState.skey === 'product') {
      newVal = v;
    };
    _.set(extraState, extraState.skey, v);
    _getRates(
      isAccount ? 'account' : 'product',
      newVal ? 'single' : 'batch',
      newVal
    );
  };
  // 跳转rate详情页面
  function goPageRates(name1, name2) {
    props.goRates({
      name1, name2,
      sliderValue, sliderValue2, timeNameArr, timeArr,
      'dateType': extraState.dateType,
      'extra3': fullExtra.orgData,
      'times': timeNameArr,
      'isExCompare': true,
    });
  };
  // 裁剪名字
  function sliceName(name = '') {
    const n_size = _.size(name);
    if (n_size > 7) {
      return name.substring(0, 5) + '...' + name.substring(n_size - 2, n_size);
    } else {
      return name;
    }
  };

  // 周期选择
  const dateTypeContent = (
    <Space direction='vertical'>
      {DATE_OPTIONS.map(n => <div key={n.value} style={{ cursor: 'pointer' }} onClick={() => {
        extraState.skey = 'dateType';
        onSelectChange(n.value);
      }}>
        <Text>{n.label}</Text>
      </div>)}
    </Space>
  );

  const nameInfos = getPageKeys === PAGEKEY_SHORT['A'] ? _.get(_.get(accState, 'accObj'), extraState.propId) : _.get(_.get(accState, 'productObj'), extraState.propId);
  const selectName = getPageKeys === PAGEKEY_SHORT['A'] ? _.get(_.get(accState, 'accObj'), extraState.account) : _.get(_.get(accState, 'productObj'), extraState.product);
  const iconStyle = { color: '#ababab', fontSize: 14 };
  const defaultLabel = (isProduct || isVirtual) ? '产品' : '账户'
  const labelItems = [
    { label: sliceName(nameInfos), key: 'name', span: 8, icon: <AccountBookOutlined style={iconStyle} /> },
    { label: isValidString(selectName) ? sliceName(selectName) : defaultLabel, key: (isProduct || isVirtual) ? 'product' : 'account', span: 10, icon: <PlusOutlined style={iconStyle} /> },
    { label: '', key: 'extra', span: 2, icon: <ProjectFilled style={{ color: !extraState.isExtra ? 'grey' : '#4ba9e7' }} /> },
  ];
  const selectObj = {
    'account': _.get(accState, 'accountOptions', []),
    'product': _.get(accState, 'productOptions', []),
  };

  return <>
    <Row align='middle'>
      <Col span={4} style={{ textAlign: 'center' }}>
        <Popover placement="bottom" trigger='click' content={dateTypeContent}>
          <Button size='small' type='text' icon={<CaretDownOutlined style={iconStyle} />} >
            {_.chain(DATE_OPTIONS).filter(o => o.value === _.get(extraState, 'dateType')).head().get('label').value()}
          </Button>
        </Popover>
      </Col>
      {labelItems.map(n => <Col key={'label' + n.key} span={n.span} style={{ textAlign: 'center' }}>
        <div onClick={() => {
          if (n.key === 'extra') {
            if (extraState.isExtra) {
              goPageRates(nameInfos, selectName);
            };
            return
          }
          if (n.key !== 'name') {
            extraState.soptions = n.key === 'product' && isVirtual ? [] : selectObj[n.key];
            extraState.visible = true;
            extraState.skey = n.key;
          }
        }}>
          <Space>
            {n.icon}
            <Text strong>{n.label}</Text>
          </Space>
        </div>
      </Col>)}
    </Row>

    <NewDivider />

    <Row style={{ marginTop: 8, marginBottom: 8 }}>
      {extraState.isExtra && _.get(option, 'series').map(item => <Col key={item.keyId}
        span={8}
        style={{ textAlign: 'center' }}
      >
        <LabelValueComp
          label={<Text style={{ color: item.name === EXTRA_TITLE ? '#faad14' : '#979797' }}>{sliceName(item.name)}</Text>}
          vComp={<ValuesColor value={_.round(_.last(item.data), 2)} size={16} showIcon={false} />}
          txtType='default'
          type='downv'
          space={3}
        />
      </Col>)}
    </Row>

    <div style={{ display: 'flex' }}>
      <div id="extra_compare" style={{ width: '99%', height: 305 }} />
    </div>

    <MainSlider
      isNotToday
      timeArray={timeNameArr}
      max={_.last(timeArr) || 0}
      svalue={sliderValue2}
      onSliderChange={(v) => setSliderValue2(v)}
      onRedo={() => afterSliderChange([0, _.last(timeArr)])}
      onSliderAfterChange={(v) => afterSliderChange(v)}
    />

    <div style={{ height: 30 }}></div>

    <Selections
      pkey={getPageKeys}
      visible={extraState.visible}
      isFilter={_.includes(['account', 'product'], extraState.skey) && isBt ? true : false}
      options={extraState.soptions}
      filterSegment={BT_FILTER_TYPE}
      filterOptions={all_product_acc}
      onPress={onSelectChange}
      onClose={() => extraState.visible = false}
    />
  </>
};

export default ExtraCompare;
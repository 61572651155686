import React, { useState } from 'react';
import { Typography, Tabs, Row, Col, Tag, Alert } from 'antd';
import { FundFilled, PieChartFilled, DatabaseFilled } from '@ant-design/icons';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { showIndexKline, showTradeReport, showTradeReportSub, showCurrentIndexSpeed } from '@/api/main';
import { useMount, useReactive, useUnmount, useInterval } from 'ahooks';
import { INDEX_CODE_NAME, INDEX_CODE_VALUE } from '@/utils/indexCode';
import { isValidArray, isValidString, labelValues, isMarketTrading, initDateType } from '@/utils/utils';
import { NewCard, HeaderIcons, NewDivider, TopInfoHeader, TextSelect } from '@/utils/widgets';
import { handlePositions } from '@/utils/list_utils';
import { calNets, handleRetreat } from '@/utils/chartInfo';
import { CardSelection } from './comp/page_widgets';
import MainCharts from '../Components/MainCharts';
import Position from '../Components/Position';
import Records from '../Components/Records';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const MS_FORMAT = 'MM-DD HH:mm:ss:SSS';
const TODAY = moment().format('YYYY-MM-DD');
const INTERVAL_NUM = 30000; const TIME_INTERVAL = 10000;
// 账户和产品配置key；通过key拿到对应的value ; switch_代表切换，另外类型的一个key
const GET_KEY_OBJ = {
  'product': {
    'rate': 'historyInfoToLaySingleProduct',
    'trade': 'tradeOrderResults',
    'id': 'productId',
    'active': 'active',
    'switch_act': 'subActive',
    'nameKey': 'productName',
    'tabLeft': <Tag color='orange'>产品</Tag>
  },
  'list': {
    'rate': 'subAccountHistory',
    'trade': 'tradeOrderResults',
    'id': 'subAccountId',
    'active': 'subActive',
    'switch_act': 'active',
    'nameKey': 'name',
    'tabLeft': <Tag color='blue'>账户</Tag>
  },
};
const TOP_ITEMS = [
  labelValues(['总收益率', 'totalRevenueRate']),
  labelValues(['日收益率', 'dailyRevenueRate']),
  labelValues(['净值', 'netValue']),
];
const ACC_DESC = [
  ['总资产', 'totalAssets'],
  ['当前市值', 'positionsAssets'],
  ['可用资金', 'totalBalance'],
  ['冻结资金', 'totalFrozenBalance'],
];
const PRO_DESC = [ACC_DESC[0]];
const tabIcons = (txt, icon) => <span>{icon}{txt}</span>;
let timer = null;
/* 
  反采托管账户页面;
  组件与产品相同，故数据调整按照账户组件需求调整数据结构
  240305 增加子账户数据，增加新的tab，可在产品和子账户直接切换；GET_KEY_OBJ维护切换的key信息
*/
const HostingPage = (props) => {
  const mobxStore = useLocalObservable(() => store);
  const [tradeDates, setTradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const init_type = initDateType(tradeDates, '1');
  const initTrading = isMarketTrading(init_type.isTradeDay);
  const [isTrading, setIsTrading] = useState(initTrading);
  const [indexValue, setIndexValue] = useState({});
  const [products, setProducts] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [filterOption, setFilterOption] = useState([]);
  const [idKeys, setIdKeys] = useState({});
  const [countMain, setCountMain] = useState(0);
  const [idxParams, setIdxParams] = useState({});
  const [inter, setInter] = useState(initTrading ? INTERVAL_NUM : undefined);
  const [inter2, setInter2] = useState(TIME_INTERVAL);
  const hostState = useReactive({
    tabs: 'mainchart', active: '', init: true, symbol: INDEX_CODE_NAME['中证1000'],
    visible: false, subActive: '', type: 'product'
  });

  useMount(() => {
    if (!isValidArray(tradeDates)) {
      mobxStore._getAllTradeDay();
      timer = setTimeout(() => {
        setTradeDates(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
      }, 2000);
    }
    _getHostingData('mount');
  });

  useUnmount(() => {
    setInter(undefined);
    setInter2(undefined);
    timer && clearTimeout(timer);
  });

  useInterval(() => {
    _getHostingData();
  }, inter);

  useInterval(() => {
    const is_trading = isMarketTrading(init_type.isTradeDay);
    // console.log('trading:', is_trading, 'inter', inter);
    if (is_trading && !inter) {
      setInter(INTERVAL_NUM);
    } else if (!is_trading && inter) {
      setInter(undefined);
      setIsTrading(true);
    }
  }, inter2);

  // 获取托管账户接口
  async function _getHostingData(initType) {
    let idx_pms = {
      symbol: hostState.symbol,
      dateType: 'TODAY',
      date: TODAY,
    }
    const res = await showTradeReport();
    const res2 = await showTradeReportSub();
    const idxRes = await showIndexKline(idx_pms);
    const res3 = (isTrading || initType === 'mount') ? await showCurrentIndexSpeed() : {};
    if (_.get(res, 'code', '') === '200') {
      const getSpeed = _.get(res3, 'data', {});
      const getProduct = handleResult(_.get(res, 'data', []), getSpeed, 'product');
      const getAccount = handleResult(_.get(res2, 'data', []), getSpeed, 'list');
      setProducts(getProduct.data);
      setAccounts(getAccount.data);
      setFilterOption(_.concat(getProduct.options, getAccount.options));
      setIdKeys(_.assign(getProduct.ids, getAccount.ids));
      //指数
      setIndexValue(_.get(idxRes, 'data', {}));
      setIdxParams(_.assign(
        idx_pms,
        { 'symbolName': INDEX_CODE_VALUE[hostState.symbol] }
      ));
      // 首次加载切换第一个tab
      if (initType === 'mount') {
        hostState.init = false;
        hostState.active = String(_.get(_.head(getProduct.data), 'productId'));
      }
      setCountMain(_.round(countMain + 0.1, 1));
    }
  }
  // 根据key(产品或账户)处理数据
  function handleResult(data = [], speeds = {}, key = '') {
    const parseData = isValidString(data) ? JSON.parse(data) : [];
    // console.log(key, data);
    let options = []; let idObj = {};
    if (isValidArray(parseData)) {
      const rateKey = _.get(GET_KEY_OBJ, key + '.rate');
      const tradeKey = _.get(GET_KEY_OBJ, key + '.trade');
      parseData.map(items => {
        const point_list = _.get(items, `${rateKey}.pointList`, []);
        const point_net = key === 'list' ? calNets(point_list) : _.get(items, `${rateKey}.netValueList`, []);
        // 收益率转义,float类型
        _.set(items, `${rateKey}.pointList`, point_list.map(n => parseFloat(n)))
        // 增加回撤
        _.set(items, `${rateKey}.pointRetreat`, handleRetreat(point_net))
        // 修改持仓列表中的格式化时间
        const getTrades = _.get(items, tradeKey, []);
        _.set(items, `${tradeKey}`, isValidArray(getTrades) ? getTrades.map((r, ri) => {
          return _.assign(r, {
            'formatDate': moment(r.commissionTime).format(MS_FORMAT),
            'newKey': r.stockCode + ri
          });
        }) : []);
        // 持仓数据
        const newPosition = handlePositions(items.position, speeds, 'hosting');
        _.set(items, 'position', newPosition.newData);
        _.set(items, 'marketMap', newPosition.marketMap);
        _.set(items, 'speeds', speeds);
        // 切换产品/账户
        options.push({
          'label': _.get(items, _.get(GET_KEY_OBJ, `${key}.nameKey`)),
          'value': String(_.get(items, _.get(GET_KEY_OBJ, `${key}.id`))),
          'comp': _.get(GET_KEY_OBJ, `${key}.tabLeft`),
        });
        // id:list 或者id:product；快速根据id找到类型
        _.set(idObj, String(items[_.get(GET_KEY_OBJ, `${key}.id`)]), key);
      })
    }
    return { 'data': parseData, 'options': options, 'ids': idObj };
  }
  // 根据key值找到对应的item，并且返回
  function findActiveItem(active = '', datas = [], key = '') {
    const find_key = _.get(GET_KEY_OBJ, `${key}.id`);
    return isValidString(active) ? _.head(_.filter(datas, o => String(o[find_key]) === active)) : {};
  }
  // active=productId的值，用于筛选； active为字符串类型，需转换
  const newItems = findActiveItem(
    hostState[_.get(GET_KEY_OBJ, `${hostState.type}.active`)],
    hostState.type === 'product' ? products : accounts,
    hostState.type
  );
  const newName = _.get(newItems, _.get(GET_KEY_OBJ, `${hostState.type}.nameKey`), '');
  const newDesc = hostState.type === 'product' ? PRO_DESC : ACC_DESC;
  return (
    <>
      <div className='basic-title'>
        <HeaderIcons
          title={'湘财托管'}
          // subs={_.get(routeDatas, 'subname', '')}
          pageKey='hosting'
          onPress={() => props.history?.goBack()}
        />
      </div>

      <NewCard pad={12}>
        <Row style={{ marginBottom: 12 }}>
          <Col span={18}>
            <TextSelect name={newName} onPress={() => hostState.visible = true} />
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            {_.get(GET_KEY_OBJ, `${hostState.type}.tabLeft`)}
          </Col>
        </Row>
        <NewDivider />
        <TopInfoHeader
          topItems={TOP_ITEMS}
          desc={newDesc}
          value={newItems}
        />
      </NewCard>

      <Tabs activeKey={hostState.tabs}
        onChange={(key) => hostState.tabs = key}
        items={[
          {
            key: 'mainchart', label: tabIcons('收益率', <FundFilled />),
            children: <NewCard>
              {!init_type.isTradeDay && <Alert type='info' message='今日无交易' banner />}
              <MainCharts
                pageKey='hosting'
                upcount={countMain}
                datas={_.get(newItems, _.get(GET_KEY_OBJ, hostState.type + '.rate'), {})}
                indexValue={indexValue}
                pms={idxParams}
                active={hostState.tabs}
                onSymbolChange={(symbol) => {
                  hostState.symbol = symbol;
                  _getHostingData();
                }}
                onSync={() => _getHostingData()}
              />
            </NewCard>
          },
          {
            key: 'position', label: tabIcons('持仓', <PieChartFilled />),
            children: <NewCard pad={4}>
              <Position
                pageKey='hosting'
                active={hostState.tabs}
                upCount={countMain}
                isTrading={isTrading}
                hostData={{
                  'list': _.get(newItems, 'position'),
                  'mkMap': _.get(newItems, 'marketMap'),
                  'speeds': _.get(newItems, 'speeds'),
                }}
                onSync={() => _getHostingData()}
              />
            </NewCard>
          },
          {
            key: 'record', label: tabIcons('记录', <DatabaseFilled />),
            children: <NewCard pad={4}>
              <Records
                pageKey='hosting'
                active={hostState.tabs}
                hostList={_.get(newItems, 'tradeOrderResults', [])}
              />
            </NewCard>
          },
        ]}
      />

      <CardSelection
        visible={hostState.visible}
        options={filterOption}
        onPress={(val) => {
          const get_type = _.get(idKeys, String(val));
          const curActive = _.get(GET_KEY_OBJ, `${get_type}.active`);
          hostState[curActive] = val; // 切换active
          hostState[_.get(GET_KEY_OBJ, `${get_type}.switch_act`)] = ''; // 将另外一个active置为空字符串
          hostState.type = get_type; //type赋值为当前的key
          setCountMain(_.round(countMain + 0.1, 1));
          hostState.visible = false;
        }}
        onClose={() => hostState.visible = false}
      />
    </>
  )
}

export default HostingPage;
import React, { useEffect, useState } from 'react';
import { OPTIONS_KLINE } from '@/utils/chartInfo2';
import { isValidArray, isValidNumber } from '@/utils/utils';
import * as echarts from 'echarts';
import _ from 'lodash';

// const AxisText = ({ top = 0, left = 0, right = 0, children }) => {
//   let astyle = { position: 'absolute' };
//   if (isValidNumber(top)) {
//     astyle.top = top;
//   }
//   if (isValidNumber(left)) {
//     astyle.left = left;
//   }
//   if (isValidNumber(right)) {
//     astyle.right = right;
//   }
//   return <span style={astyle}>{children}</span>;
// }
// k线mini，正常应该是分时图
const KlineMini = (props) => {
  const [option, setoption] = useState({
    ...OPTIONS_KLINE,
    xAxis: {
      type: 'category',
      data: [],
      boundaryGap: false,
      axisLine: { onZero: false, show: false },
      splitLine: { show: false, },
      axisTick: { show: false }
    },
    yAxis: [{
      name: '股价',
      scale: true,
      axisLine: { show: false },
      splitNumber: 2,
      // splitLine: { show: false },
    }, {
      type: 'value',
      name: '波幅(%)',
      position: 'right',
      axisLine: { show: false },
      splitLine: { show: false },
      min: 0,
      max: 1,
    }],
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100
      }
    ],
    grid: { // 留白部分的修改
      containLabel: false,
      top: '1%',
      right: '1%',
      bottom: '1%',
      left: '1%',
    }
  });

  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('kline_mini'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    const getDatas = _.get(props, 'datas', {})
    let newOption = _.cloneDeep(option);

    newOption.xAxis.data = _.get(getDatas, 'time', []);
    newOption.series[0].data = _.get(getDatas, 'kval', []);
    newOption.series[1].data = calMaLine(_.get(getDatas, 'kval', []));
    const getHigh = _.get(getDatas, 'cal.high', 0);
    const getLow = _.get(getDatas, 'cal.low', 0);
    const getStart = _.get(getDatas, 'cal.startPrice', 0);
    // 计算波幅
    newOption.yAxis[1].min = _.round((getLow - getStart) / getLow, 2) * 100;
    newOption.yAxis[1].max = _.round((getHigh - getStart) / getHigh, 2) * 100;

    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
  }, [props.upcount]);
  // 计算分钟移动均线
  const MA_NUM = 5;
  function calMaLine(kdata) {
    let final = [];
    if (isValidArray(kdata)) {
      kdata.map((n, i) => {
        if (_.size(n) > 1) { // 有效k线数据
          final[i] = _.chain(kdata)
            .slice(i < MA_NUM ? 0 : i - (MA_NUM - 1), i + 1) // 截取前num分钟数据，不足num分钟拿所有
            .map(k => parseFloat(k[1]))
            .sum() // 累加
            .divide(i < MA_NUM ? i + 1 : MA_NUM) // 求平均
            .round(2)
            .value();
        }
      })
    }
    return final;
  }

  return <>
    <div style={{ display: 'flex' }}>
      <div
        id="kline_mini"
        style={{ width: '100%', height: 170 }}
      />
    </div>
    {/* <AxisText top={15} left={2}>{_.get(props, 'datas.cal.high', 0)}</AxisText>
    <AxisText top={55} left={2}>{_.get(props, 'datas.cal.low', 0)}</AxisText> */}
  </>
}

export default KlineMini;
import React, { useState } from 'react';
import { message, Tabs, Alert, Button, Space, Typography, FloatButton } from 'antd';
import {
  FundFilled, AccountBookFilled, PieChartFilled, SlidersFilled, DatabaseFilled, ControlFilled,
  SwitcherFilled, SyncOutlined, SwapOutlined, GoldenFilled, ProjectFilled, FileTextFilled
} from '@ant-design/icons';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import {
  showSingleProductHistory, showIndexKline, getPositionUp5, getfundManagerAssets, showAllProductHistory,
  visShowAssetsAndProducts, showSingleProductHistoryVir
} from '@/api/main';
import { useReactive, useMount, useUnmount } from 'ahooks';
import { INDEX_CODE_NAME, INDEX_CODE_VALUE } from '@/utils/indexCode';
import { DATE_TYPE, BT_FILTER_TYPE, labelValues, isValidArray, createDatasTimeKey, initDateType, isValidNumber } from '@/utils/utils';
import { NewCard, HeaderIcons, Selections, TopInfoHeader, RateText } from '@/utils/widgets';
import { handleHistoryRate, handleCustomIndexPms } from '@/utils/list_utils';
import { CardSelection } from '../pages/comp/page_widgets';
import Marquee from 'react-fast-marquee';
import MainCharts from '../Components/MainCharts';
import Position from '../Components/Position';
import AccountList from './AccountList';
import TowCharts from '../Components/TwoCharts';
import Records from '../Components/Records';
import BtCompare from '../Comp2/BtCompare';
import Contribution from '../Comp2/Contribution';
import LevelCharts from './LevelCharts';
import KRateCharts from '../Components/KRateCharts';
import ExtraCompare from '../Comp2/ExtraCompare';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const TODAY = moment().format('YYYY-MM-DD');
const TOP_ITEMS = [
  labelValues(['总收益率', 'totalRevenueRate']),
  labelValues(['日收益率', 'dailyRevenueRate']),
  labelValues(['净值', 'netValue']),
];
const BT_DESC = [
  ['六月超额', 'avgExcess', 'rate'],
  ['十日超额', 'tenDayAvgExcess', 'rate'],
  ['开始日期', 'beginDate'],
  ['最新日期', 'latestDate'],
];
const DEFAULT_SYMBOL = INDEX_CODE_NAME['中证1000'];
const tabIcons = (txt, icon) => <span>{icon}{txt}</span>;
const addProductField = (product = []) => isValidArray(product) ? product.map(itm => {
  return _.assign(itm, {
    'comp': <Space direction='vertical' size={1}>
      <RateText rate={_.get(itm, 'dailyRevenueRate_float', 0)} />
      <Text type='secondary' style={{ fontSize: '12px' }}>{_.get(itm, 'times', '')}</Text>
    </Space>
  })
}) : [];
let timer = null;
/**
 * 产品详情页；
 * mainCharts为外部获取，收益率及指数数据外部获取，props进行维护；[tips]与平台相同，便于定时任务设计
 * 240321:增加虚拟产品的判断，通过路由拿到虚拟产品数据；
 */
const ProductsPage = (props) => {
  const mobxStore = useLocalObservable(() => store);
  const [userInfo] = useState(JSON.parse(JSON.stringify(mobxStore.userInfo)) ?? {});
  const [routemobx] = useState(JSON.parse(JSON.stringify(mobxStore.routeMobx)) ?? {});
  const [tradeDates, setTradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const [isTrading] = useState(JSON.parse(JSON.stringify(mobxStore.inTrading)) === 'trading' ? true : false);
  const [all_product_acc] = useState(JSON.parse(JSON.stringify(mobxStore.allProductAcc)));
  const mobxAllProducts = JSON.parse(JSON.stringify(mobxStore.allProducts));
  const [allProducts] = useState(addProductField(_.get(mobxAllProducts, 'lists')));
  const [customIndex, setCustomIndex] = useState(JSON.parse(JSON.stringify(mobxStore.customIndex)) ?? [])
  const userPlateform = _.get(userInfo, 'plateform', '');
  const routeDatas = _.get(props, 'location.state', {});
  const TAB_NUM = userPlateform === 'BT' ? '9' : '1';
  const initType = initDateType(tradeDates, TAB_NUM);
  const [idxParams, setIdxParams] = useState({
    'symbol': DEFAULT_SYMBOL, 'symbolName': INDEX_CODE_VALUE[DEFAULT_SYMBOL],
    'dateType': userPlateform === 'BT' ? DATE_TYPE['9'] : initType.type,
    'date': TODAY
  });
  const [pointDatas, setPointDatas] = useState({ timeList: [], datas: [], newData: {} });
  const [indexValue, setIndexValue] = useState({});
  const [countMain, setCountMain] = useState(0); // 收益率图表更新
  const [validDate, setValidDate] = useState([]); // 收益率时间区间
  const isUpdateTab = _.get(routemobx, 'from', '') === 'product' && _.get(routemobx, 'active') !== 'mainchart' ? true : false;
  const pageState = useReactive({
    active: isUpdateTab ? _.get(routemobx, 'active') : 'mainchart',
    symbol: idxParams.symbol,
    tabUpdate: false, //收益率更新控制；调整返回 & 切换产品
    proInfo: _.cloneDeep(routeDatas), switchCount: 0, visible: false, //产品信息变量（可切换 
    singleDate: TODAY,
  });
  const [riseList, setRiseList] = useState([]);
  const [allassets, setallassets] = useState({});
  const [indexCodeName2, setIndexCodeName2] = useState({});
  const isVisitor = _.get(userInfo, 'role', '') === 'visitor' ? true : false;
  const userRole = _.get(userInfo, 'role', '');
  const centraLogin = _.get(pageState.proInfo, 'centralizedLogin', 0);
  const isReverse = centraLogin === 2 ? true : false;
  const isVirtual = _.get(routeDatas, 'vir_order', 0) === 2 && userPlateform !== 'TAMP' ? true : false;

  useMount(() => {
    //console.log('当前产品：', pageState.proInfo);
    if (isVirtual) {
      _showSingleProductHistory();
      return
    }
    // k线页面返回，跳转到特定tab
    if (isUpdateTab) {
      pageState.tabUpdate = true;
      mobxStore.changeRouteMobx({});
    } else {
      _showSingleProductHistory();
    }
    _getPositionUp5();
    _showAllProductHistory();
    // 使用全局设置初始资产数据
    setallassets(_.cloneDeep(pageState.proInfo));

    if (!isValidArray(tradeDates)) {
      // 延时获取交易日期
      mobxStore._getAllTradeDay();
      timer = setTimeout(() => {
        setTradeDates(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
      }, 2500);
    }
  });

  useUnmount(() => {
    timer && clearTimeout(timer);
  });

  // 收益率获取
  async function _showSingleProductHistory(type = null) {
    const is_reverse = _.get(pageState.proInfo, 'centralizedLogin', 0) === 2 ? true : false;
    if (_.get(pageState.proInfo, 'productId')) {
      let pms = {
        'productId': pageState.proInfo.productId,
        'dateType': type ? type : _.get(idxParams, 'dateType'),
        'date': pageState.singleDate,
      }
      let index_pms = {
        'symbol': pageState.symbol,
        'dateType': type ? type : _.get(idxParams, 'dateType'),
        'date': pageState.singleDate,
      };
      if (isValidNumber(_.get(pageState.proInfo, 'diffVal'))) {
        _.set(pms, 'rate', _.get(pageState.proInfo, 'diffVal') / 100);
      };
      const idxPms2 = handleCustomIndexPms(index_pms, customIndex);
      if (idxPms2.symbolInit) { // 切换回实时，将symbol切换回默认值；
        pageState.symbol = DEFAULT_SYMBOL;
      };
      if (idxPms2.isCusFromStorage) { //如 mobx无数据；从storage拿数据重新赋值
        setCustomIndex(_.get(idxPms2, 'customs', []));
      };
      const newIndexCode = _.assign(INDEX_CODE_VALUE, _.get(idxPms2, 'codeName', {}));
      const res = isVirtual ? await showSingleProductHistoryVir(pms) : await showSingleProductHistory(pms, is_reverse ? 'FC' : 'NOR');
      const resIdx = await showIndexKline(idxPms2.params, idxPms2.isBatch ? 'batch' : 'nor');
      setIdxParams(_.assign(
        { 'symbolName': newIndexCode[pageState.symbol] },
        index_pms,
        pms,
      ));
      if (_.get(res, 'message', '') !== 'success') {
        message.info(_.get(res, 'message'))
      }
      if (_.get(resIdx, 'message', '') !== 'success') {
        message.info(_.get(resIdx, 'message'))
      }
      const getPoints = _.get(res, 'data.pointList', []);
      if (_.get(res, 'code', '') === '200' && isValidArray(getPoints)) {
        const rateData = handleHistoryRate(
          res,
          _.assign(resIdx, idxPms2),
          pms.dateType
        );
        setPointDatas({ 'timeList': rateData.timeList, 'datas': rateData.datas, 'newData': rateData.newData });
        setIndexValue(rateData.indexVal);
      } else {
        setPointDatas({ timeList: [], datas: {}, newData: {} });
        setIndexValue({});
      };
      setCountMain(countMain + 1);
      setIndexCodeName2(newIndexCode);
    }
  }
  // 每3分钟涨幅5%的票；
  async function _getPositionUp5() {
    if (_.get(pageState.proInfo, 'productId')) {
      const is_reverse = _.get(pageState.proInfo, 'centralizedLogin', 0) === 2 ? true : false;
      const res = await getPositionUp5(_.get(pageState.proInfo, 'productId'), is_reverse ? 'FC' : 'NOR');
      setRiseList(createDatasTimeKey(_.get(res, 'data', []), 'time', 's'));
    }
  }
  //实时更新产品资产、收益等信息
  async function _getAsset() {
    const is_reverse = _.get(pageState.proInfo, 'centralizedLogin', 0) === 2 ? true : false;
    let params = { 'isShow': false, 'pageNum': 1, 'pageSize': 10, 'productId': pageState.proInfo.productId }
    const data = userRole === 'visitor' ? await visShowAssetsAndProducts() : await getfundManagerAssets(params, is_reverse ? 'FC' : 'NOR');
    if (_.get(data, 'code', '') === '200' && _.size(_.get(data, 'data.currentProductResultList', [])) === 1) {
      setallassets(_.head(_.get(data, 'data.currentProductResultList', [])));
    }
  }
  // 获取子账户有收益率数据的时间区间
  async function _showAllProductHistory() {
    const is_reverse = _.get(pageState.proInfo, 'centralizedLogin', 0) === 2 ? true : false;
    if (_.get(pageState.proInfo, 'productId')) {
      const res = await showAllProductHistory(pageState.proInfo.productId, is_reverse ? 'FC' : 'NOR');
      if (_.get(res, 'code', '') === '200') {
        let getDateArray = _.get(res, 'data', [])
        // 返回区间如不包含今天则添加今天，避免获取问题
        if (getDateArray.indexOf(TODAY) === -1) {
          getDateArray.push(TODAY);
        }
        setValidDate(getDateArray);
      }
    }
  }
  // 收益率tab情况下，更新资产、拉升票、和收益率曲线
  function updatePage() {
    _showSingleProductHistory();
    _getPositionUp5();
    _getAsset();
    _showAllProductHistory();
  }
  // 路由跳转
  function goAccount(pms) {
    props.history.push({ pathname: '/mobile/accounts', state: { ...pms, 'centralizedLogin': centraLogin } });
  }
  function goRates(pms) {
    props.history.push({ pathname: '/mobile/ratecal', state: { ...pms, subname: _.get(pageState.proInfo, 'productName', '') } });
  }
  function goRecord(pms, range) {
    props.history.push({ pathname: '/mobile/recordcal', state: { data: pms, drange: range, subname: _.get(pageState.proInfo, 'productName', '') } });
  }
  function goDisplay() {
    props.history.push({
      pathname: '/mobile/displays', state: {
        'pageKey': isVirtual ? 'virtual' : 'product',
        [isVirtual ? 'virtualProductId' : 'productId']: _.get(pageState.proInfo, 'productId'),
        'subname': _.get(pageState.proInfo, 'productName', ''),
        'mode': _.get(pageState.proInfo, 'mode', null),
        isReverse
      }
    });
  }
  function goStock(key, record, list) {
    mobxStore.changeRouteMobx({ 'active': pageState.active, 'from': 'product' });
    props.history.push({
      pathname: '/mobile/kline',
      state: { 'pageKey': key, 'symbol': _.get(record, 'stockCode'), 'subname': _.get(pageState.proInfo, 'productName', ''), list, ...record }
    });
  };
  // 切换产品
  function changeProduct(v) {
    let findex = _.findIndex(allProducts, o => o.value === v);
    if (findex !== -1) {
      pageState.proInfo = _.cloneDeep(allProducts[findex]);
    }
    pageState.visible = false;
    pageState.switchCount++;
    // 收益率tab直接更新；否则切换状态，切换时更新
    if (pageState.active === 'mainchart') {
      updatePage();
    } else {
      pageState.tabUpdate = true;
    }
  }

  const newDesc = _.concat(
    [['总资产', 'totalAssets']],
    (userPlateform === 'BT' && !isVirtual) ? BT_DESC : [['开始日期', 'times']]
  );
  const getProId = _.get(pageState.proInfo, 'productId');
  const baseProps = {
    'productId': isVirtual ? 'V' + getProId : getProId, 'active': pageState.active,
    'pageKey': isVirtual ? 'virtual' : 'product', 'upcount': pageState.switchCount,
    'isReverse': isReverse, 'isTrading': isTrading
  };
  const mainProps = {
    'pageKey': isVirtual ? 'virtual' : 'product', 'upcount': countMain, 'active': pageState.active,
    'plateform': userPlateform, 'datas': pointDatas, 'validDateRange': validDate,
    'indexValue': indexValue, 'pms': idxParams, 'tradeDates': tradeDates, 'isReverse': isReverse,
    'customIndex': customIndex,
  };
  return (
    <>
      <div className='basic-title'>
        <HeaderIcons
          title={_.get(pageState.proInfo, 'productName', '')}
          pageKey={isVirtual ? 'virtual' : 'product'}
          isHide={isVisitor}
          onPress={() => props.history?.goBack()}
          onSwap={() => pageState.visible = true}
        />
      </div>

      <NewCard pad={12}>
        <TopInfoHeader
          topItems={TOP_ITEMS}
          desc={newDesc}
          value={isVirtual ? routeDatas : allassets}
        />
      </NewCard>

      {isValidArray(riseList) && <Alert showIcon={false} type="warning"
        message={
          <Marquee pauseOnClick gradient={false}>
            {riseList.map((r, ri) => <span key={r.name + ri} style={{ marginRight: 12 }}>{`${r.name} ${r.formatDate}`}</span>)}
          </Marquee>
        }
        action={<Button size='small' type='text' icon={<SyncOutlined />} onClick={() => {
          _getPositionUp5();
          if (pageState.active === 'mainchart') {
            _showSingleProductHistory();
          }
        }} />}
      />}

      {/* <div style={{ height: 8 }}></div> */}
      <Tabs activeKey={pageState.active}
        onChange={(key) => {
          pageState.active = key;
          if ((pageState.tabUpdate && key === 'mainchart') || (isTrading && key === 'mainchart')) {
            _showSingleProductHistory();
            pageState.tabUpdate = false;
          }
        }}
        items={[
          {
            key: 'mainchart', label: tabIcons('收益率', <FundFilled />),
            children: <NewCard>
              <MainCharts
                {...mainProps}
                onSymbolChange={(symbol) => {
                  pageState.symbol = symbol;
                  _showSingleProductHistory();
                }}
                onDatetypeChange={(type) => _showSingleProductHistory(type)}
                goRates={(val) => goRates(val)}
                onSync={() => updatePage()}
                rateDiffChange={(diffVal) => {
                  _.set(pageState.proInfo, 'diffVal', diffVal);
                  _showSingleProductHistory();
                }}
                onDateChange={(date) => {
                  pageState.singleDate = date;
                  _showSingleProductHistory();
                }}
              />
              {isVirtual && <FloatButton
                icon={<PieChartFilled style={{ color: '#2774b8' }} />}
                onClick={goDisplay}
              />}
            </NewCard>
          },
          isVirtual ? {} : {
            key: 'kratechart', label: tabIcons('收益K', <ProjectFilled />),
            children: <NewCard pad={4}>
              <KRateCharts  {...baseProps}
                indexPms={idxParams}
                newProps={props}
              />
            </NewCard>
          },
          isVirtual ? {} : {
            key: 'position', label: tabIcons('持仓', <PieChartFilled />),
            children: <NewCard pad={4}>
              <Position  {...baseProps}
                role={_.get(userInfo, 'role', '')}
                plateform={userPlateform}
                rises={_.uniq(riseList.map(n => n.name))}
                goDisplay={goDisplay}
                jumpStock={(record, list) => goStock('pro_pos', record, list)}
              />
            </NewCard>
          },
          isVirtual ? {} : {
            key: 'accountList', label: tabIcons('账户', <AccountBookFilled />),
            children: <NewCard pad={10}>
              <AccountList {...baseProps}
                jumpToAccout={goAccount}
                onListChange={(list) => mobxStore.changeCurrentAcc(list)}
              />
            </NewCard>
          },
          {
            key: 'compare', label: tabIcons('对比', <SwitcherFilled />),
            children: <NewCard>
              <TowCharts {...baseProps}
                customIndex={customIndex}
                newIdxCodeName={indexCodeName2}
                idxPms={idxParams}
                plateform={userPlateform}
                vOptions={_.get(routeDatas, 'vproducts', [])}
              />
            </NewCard>
          },
          isVirtual ? {} : {
            key: 'record', label: tabIcons('记录', <DatabaseFilled />),
            children: <NewCard pad={4}>
              <Records {...baseProps}
                jumpRecord={goRecord}
                jumpStock={(record, list) => goStock('pro_record', record, list)}
              />
            </NewCard>
          },
          userPlateform === 'TAMP' ? {
            key: 'BTCOM', label: tabIcons('回测对比', <ControlFilled />),
            children: <NewCard>
              <BtCompare {...baseProps} />
            </NewCard>
          } : {},
          {
            key: 'highposition', label: tabIcons('重仓分布', <GoldenFilled />),
            children: <NewCard>
              <LevelCharts {...baseProps}
                id={getProId}
                virMode={_.get(pageState.proInfo, 'mode', 0)}
              />
            </NewCard>
          },
          isVirtual ? {} : {
            key: 'contribution', label: tabIcons('盈亏分布', <SlidersFilled />),
            children: <Contribution {...baseProps} />
          },
          isVirtual ? {} : {
            key: 'extraCompare', label: tabIcons('超额分析', <FileTextFilled />),
            children: <NewCard>
              <ExtraCompare {...baseProps}
                idxPms={idxParams}
                plateform={userPlateform}
                vOptions={_.get(routeDatas, 'vproducts', [])}
                goRates={(val) => { // 调整恢复，配置指数会加载不出来
                  // mobxStore.changeRouteMobx({ 'active': pageState.active, 'from': 'product' });
                  goRates(val);
                }}
              />
            </NewCard>
          },
        ]}
      />

      {userPlateform === 'BT' ? <Selections
        pkey={'product'}
        visible={pageState.visible}
        isFilter={true}
        filterSegment={BT_FILTER_TYPE}
        filterOptions={all_product_acc}
        options={allProducts}
        onPress={(v) => changeProduct(v)}
        onClose={() => pageState.visible = false}
      /> : <CardSelection
        visible={pageState.visible}
        options={allProducts}
        title={{
          left: <Space size={4}><Text>产品切换</Text><SwapOutlined style={{ color: '#999999' }} /></Space>,
          right: <Text type='secondary'>日收益率</Text>
        }}
        onPress={(v) => changeProduct(v)}
        onClose={() => pageState.visible = false}
      />}

    </>
  )
}

export default ProductsPage;